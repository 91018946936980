import React, {useEffect, useState} from "react"
import {ChaptersContainer, FlexBox} from "../../styles/Containers.js"
import {Button, Divider, Icon, Loader, Message} from "semantic-ui-react"
import {useHistory} from "react-router-dom"
import {useDispatch} from "react-redux"
import Biography from "../../store/reducers/biography/actions.js"
import {QuillDeltaToHtmlConverter} from "quill-delta-to-html"
import styled from "styled-components"
import PermissionsService from "../../services/permissions.js"
import {mappedOptions} from "../../config/options.map.js"
import Media from "../../store/reducers/media/actions.js"
import ChapterImage from "../../assets/icons/chapter.svg"
import ChaptersMenu, {formContentChapterList} from "./ChaptersMenu.js"
import Root from "../../store/reducers/root/actions.js"
import ImageNotFound from "@images/image-not-found.png"
import {StickySubNav} from "../../styles/Layout"
import useWindowSize from "../../utils/dimensions"
import {CONSTANTS} from "../../config/constants"
import customTheme from "../../styles/theme"
import HorizontalGallery from "../../components/media/HorizontalGallery"
import {sortByIndex} from "../../utils/sortIndex"
import {usePageScope} from "../../store/hooks/page.hooks.js";

const Chapters = () => {
  const {width} = useWindowSize()
  const history = useHistory()
  const dispatch = useDispatch()
  const RootDispatch = new Root(dispatch)
  const mediaDispatch = new Media(dispatch)
  const BiographyDispatch = new Biography(dispatch)
  const {page, permissions, pageId} = usePageScope()
  const edit = PermissionsService.checkPermissions(permissions, "canEdit")

  const [scrollTop, setScrollTop] = useState(0)

  const chaptersLoading = page?.chaptersLoading;
  const chapterError = page?.chaptersError;

  useEffect(() => {
    if (page?.chaptersError === undefined && !chaptersLoading && !page?.isDemo) {
      BiographyDispatch.fetchChapters(pageId);
    }
  }, [page])

  useEffect(() => {
    function onScroll() {
      let currentPosition = document.getElementById("body-content").scrollTop
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition)
    }

    document.getElementById("body-content")?.addEventListener("scroll", onScroll)
    return () => document.getElementById("body-content")?.removeEventListener("scroll", onScroll)
  }, [])

  useEffect(() => {
    const chapters = formContentChapterList(page, pageId)
    let selected = null
    for (const chapter of chapters) {
      const heightFromTop = document.getElementById(chapter.id)?.getBoundingClientRect().top
      if (heightFromTop < 0) {
        selected = chapter
      } else {
        setChapter(selected)
        return
      }
    }
  }, [scrollTop])

  const setChapter = (o) => {
    if (o) {
      console.warn(o.title)
      RootDispatch.setChapterNavSection(o.did)
      // history.push({
      //   pathname: history.location.pathname,
      //   search: "?ch=" + o.did
      // })
    }
  }

  const formGallery = (gallery) => {
    const formed = []
    for (let img of gallery) {
      formed.push(page.gallery.find(o => o.Location === img.Location) || img)
    }
    return formed
  }

  const observeContent = () => {
    document.getElementById("quill-inner-html")?.childNodes?.forEach(node => {
      node.childNodes?.forEach(innerNode => {
        if (innerNode.nodeName === "IMG") {
          if (innerNode.complete && (innerNode.naturalHeight === 0 || innerNode.naturalWidth === 0)) {
            innerNode.src = ImageNotFound
          }
        }
      })
    })
  }

  const loadChapter = (chapter) => {
    if (chapter && chapter.operations) {
      const gallery = chapter.gallery || []
      const converter = new QuillDeltaToHtmlConverter(chapter.operations)
      converter.afterRender(function (groupType, htmlString) {
        observeContent()
        return htmlString
      })
      const html = converter.convert()
      const name = chapter.chapterTitle || mappedOptions("chapterData").find(o => o.value === chapter.chapterTitleType)?.text

      const contributors = page.contributors.filter(o => o.details === chapter.chapterId)

      return (
        <div style={{marginBottom: 80}} id={`chapter-${pageId}-${chapter.chapterId}`}>
          <ChapterTitle justify={'space-between'} align={'center'}>
            <div>
              {name} {chapter.status === "DRAFT" ? "(Draft)" : ""}
            </div>
            {edit &&
            <Button onClick={() => history.push(`/${pageId}/chapters/manage/?chapterId=${chapter.chapterId}`)}
                    size={'tiny'} basic><Icon name={'edit'}/>Edit</Button>}
          </ChapterTitle>


          {contributors.length > 0 &&
          <CollabTitle>
            Written by: &nbsp; {contributors.map((o, i) =>
            <Collaborators
              style={{color: customTheme.primary}}>{o.name} {i === contributors.length - 1 ? "" : ", "}</Collaborators>)}
          </CollabTitle>
          }
          <div>
            <ChapterContainer>
              <div id={"quill-inner-html"} dangerouslySetInnerHTML={{__html: html}}/>
              <div className="spacer" style={{clear: "both"}}/>
            </ChapterContainer>
          </div>

          {gallery.length > 0 &&
          <div style={{marginTop: 30}}>
            <HorizontalGallery gallery={gallery} onSelect={() => mediaDispatch.setViewGallery(formGallery(gallery))}/>
            <Button onClick={() => mediaDispatch.setViewGallery(formGallery(gallery))} basic
                    style={{margin: "10px 0 0 6px"}}>Show all photos</Button>
          </div>
          }

          <Divider style={{width: '80%', margin: '80px auto'}}/>
        </div>
      )
    }
    return <div>Loading....</div>
  }

  // Either write it into a read only container or convert it to html
  // https://stackoverflow.com/questions/39733120/how-to-read-in-existing-data-into-quill-js
  // https://www.npmjs.com/package/quill-delta-to-html
  // https://github.com/gtgalone/react-quilljs

  //const converter = new QuillDeltaToHtmlConverter(deltaOps, cfg);

  return (
    <>
      <StickySubNav id={"chapter-sticky"} style={width <= CONSTANTS.navCollapse ? {top: 80, left: 0} : {}}>
        <ChaptersMenu/>
      </StickySubNav>
      <ChaptersContainer id={"scroll-chapter-content"} empty={page.chapters.length === 0}>
        {chaptersLoading && <div style={{textAlign: 'center', marginBottom: 12}}><Loader inline active/></div>}
        {!!chapterError && <Message error>{chapterError}</Message>}
        {(page.chapters.length === 0 && !chaptersLoading) ? !edit ? (
          <div style={{textAlign: "center"}}>
            <p style={{fontWeight: "bold"}}>
              This Everloom has no chapters.
            </p>
            <img src={ChapterImage} style={{maxHeight: 400, opacity: 0.6, marginTop: 60}}/>
          </div>
        ) : (
          <FlexBox align={"center"} justify={"center"} direction={"column"}>
            <img src={ChapterImage} style={{width: 120}}/>

            <FlexBox align={"center"} justify={"center"} direction={"column"} style={{maxWidth: 800, marginTop: 30}}>
              <h2>Tell Your Story</h2>
              <p style={{fontSize: 16, textAlign: 'center', lineHeight: 2}}>
                Create your first Chapter to begin creating your Everloom.
                Each Chapter includes a section for text and an album for photos, videos, or audio files to bring your
                stories to life.
              </p>
              <p style={{fontSize: 16, textAlign: 'center'}}>
                Learn more about writing Chapters in our <a target={'_blank'} href={process.env.REACT_APP_LANDING_URL + '/help'}>Help Center</a>.
              </p>
            </FlexBox>

            <Button primary style={{marginTop: 20}} onClick={() => history.push(`/${pageId}/chapters/manage`)}>
              Add First Chapter
            </Button>
          </FlexBox>
        ) : null}
        {sortByIndex(page.chapters).map(loadChapter)}
      </ChaptersContainer>
    </>
  )
}

export default Chapters

const ChapterContainer = styled.div`
  .ql-image {
    max-width: 340px !important;
    max-height: 200px !important;
    float: left;
    margin-right: 14px;
  }

  h2 {
    font-size: 16px;
  }

  line-height: 25px;
`

const CollabTitle = styled(FlexBox)`
  margin-bottom: 20px;
  font-size: 12px;
`

const Collaborators = styled.div`
`

const ChapterTitle = styled(FlexBox)`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;

  svg {
    display: none;
    margin-right: 10px;
    position: relative;
    top: 4px;
  }

  &:hover {
    cursor: pointer;

    svg {
      display: inline-block;
    }
  }
`

const ItemTitle = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-top: 30px;
`
