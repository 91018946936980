import React from 'react'
import {FlexBox} from "../styles/Containers.js";
import styled from "styled-components";
import HeirloomBrandLight from '@logo/themed/logo-col-light.svg'
import HeirloomBrand from '@logo/themed/logo-col.svg'

import useWindowSize from "../utils/dimensions.js";
import HeirloomImage from "../components/common/HeirloomImage.js";

const AuthLayout = ({children}) => {
    const {width} = useWindowSize();

    if (width < 920) {
        return (
            <FlexBox direction={'column'} justify={'center'} align={'center'} style={{minHeight: '100vh', paddingTop: 20, paddingBottom: 20}}>
                <HeirloomImage src={HeirloomBrand} style={{width: 200}}/>
                {children}
            </FlexBox>
        )
    }

    return (
        <FlexBox style={{flex: 1, height: '100vh'}}>
            <HeirloomAuthBox>
                <HeirloomImage src={HeirloomBrandLight}/>
            </HeirloomAuthBox>
            <AuthContainer>
                {children}
            </AuthContainer>
        </FlexBox>
    );
};

export default AuthLayout;

const AuthContainer = styled.div`
  min-height: 700px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const HeirloomAuthBox = styled.div`
  min-height: 700px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: ${props => props.theme.primary};
`;
