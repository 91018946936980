import React, { useEffect, useState } from "react"
import Screen from "../../layout/Screen"
import { useDispatch, useSelector } from "react-redux"
import Biography from "../../store/reducers/biography/actions.js"
import PermissionsService from "../../services/permissions.js"
import { FlexBox } from "../../styles/Containers.js"
import styled from "styled-components"
import { Button, Loader } from "semantic-ui-react"
import { PagesService } from "../../services/pages.js"
import { NoneText } from "../../styles/Text.js"
import Root from "../../store/reducers/root/actions.js"
import { Redirect, Route, Switch, useHistory } from "react-router-dom"
import { matchPath } from "react-router"
import { ProfileMenuItems } from "../../config/options.map.js"
import AddChapter from "../Chapters/AddChapter"
import Layout from "../../layout"
import SideBarMenu from "../../layout/SideBarMenu"
import { CONSTANTS } from "../../config/constants"
import useWindowSize from "../../utils/dimensions"
import ErrorBoundary from "../../components/ErrorBoundary"
import { useOnboarding, usePageScope } from "../../store/hooks/page.hooks.js"
import { Nux } from "../Onboarding/nux"
import PendingPermissions from "../../components/PendingPermissions.js"
import Auth from "../../store/reducers/auth/actions.js"

const MyProfile = ({ match }) => {
	const { width } = useWindowSize()
	const history = useHistory()
	const dispatch = useDispatch()
	const onboarding = useOnboarding()
	const root = new Root(useDispatch())
	const user = useSelector(Auth.user)

	const RootDispatch = new Root(dispatch)
	const BioDispatch = new Biography(dispatch)
	const { page, permissions, pageId, activePlan, basePath } = usePageScope()
	const [requesting, setRequesting] = useState(false)
	const canViewChapters = PermissionsService.checkPermissions(permissions, "canAccessChapters")

	useEffect(() => {
		const match = matchPath(window.location.pathname, {
			path: `${basePath}/:pageId/everloom/:slug`,
			exact: false,
			strict: false
		})
		if (match && match.params && match.params.slug) {
			const item = ProfileMenuItems.find(o => o.slug === match.params.slug)
			if (item) {
				BioDispatch.setProfileSection(item)
			}
		}
		if (!onboarding.intro) {
			root.setShowNux("intro")
		}

		const matchEv = matchPath(window.location.pathname, {
			path: `${basePath}/:pageId`,
			exact: false,
			strict: false
		})

		if (matchEv && matchEv.params.pageId) {
			if (matchEv.params.pageId.indexOf("ED") === -1) {
				console.log('Redirect')
				history.push("/")
			}
		}
	}, [])

	const requestAccess = () => {
		setRequesting(true)
		PagesService.requestAccessToPage(pageId).finally(() => {
			setRequesting(false)
			BioDispatch.patchLocalPage({
				accessRequested: true
			}, pageId)
			RootDispatch.setMessage({
				type: "success",
				message: "Access successfully requested!"
			})
		})
	}

	const showBriefMobile = ["chapters"]

	const currentViewSplit = history.location.pathname.split("/")
	let currentView = ""
	if (currentViewSplit.length >= 4) {
		currentView = currentViewSplit[3]
	}

	return (
		<Layout>
			<SideBarMenu>
				<Screen noPadding>
					<ErrorBoundary>
						{!!user.uid && <Nux/>}
						<HeirloomRouter hideBriefMobel={showBriefMobile.indexOf(currentView) === -1} width={width}>
							<div className={"heirloom-content"}>
								{canViewChapters &&
								<Switch>
									<Redirect exact path={`${match.url}`} to={`${match.url}/chapters`}/>
									{ProfileMenuItems.filter(o => {
										if (!activePlan.memoryAccess)
											return o.key !== "memories"
										return true
									}).filter(o => !!o.slug).map(o => {
										if (o.component) {
											return <Route exact path={`${match.url}/${o.slug}`} component={o.component}/>
										}
									})}

									<Route exact path={`${match.url}/chapters/manage`} component={AddChapter}/>
								</Switch>
								}
								{(!canViewChapters && page && !!permissions) &&
								<FlexBox align={"center"} justify={"center"} direction={"column"}>
									<h3>This person has limited your access</h3>
									<p>Request access to view their chapters</p>
									{!page?.accessRequested &&
									<Button primary onClick={requestAccess} loading={requesting}>Request Access</Button>}
									{page?.accessRequested && <NoneText> Access already requested</NoneText>}
								</FlexBox>
								}
								{!canViewChapters && (!page || !permissions) &&
								<Loader active/>
								}
							</div>
						</HeirloomRouter>
					</ErrorBoundary>
				</Screen>
			</SideBarMenu>
			<PendingPermissions/>
		</Layout>
	)
}

export default MyProfile

const HeirloomRouter = styled(FlexBox)`
	height: calc(100% - 40px);
	padding-bottom: 20px;
	min-height: 700px;

	.heirloom-content {
		flex: 1;
		max-width: calc(${props => props.width}px - ${props => props.width > CONSTANTS.collapsedSize ? (CONSTANTS.menuSize + 65) : 0}px)
	}

	.profile-details {
		margin-bottom: 20px !important;
	}

	@media only screen and (max-width: 700px) {
		.heirloom-content {
			padding-right: 0;
			width: 100%;
		}

		height: unset;
		flex-direction: column-reverse;
		.profile-details {
			${props => props.hideBriefMobel && { display: "none" }}
			margin: auto;
		}
	}
`
