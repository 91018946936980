import React, {useEffect, useState} from "react"
import {Button, Modal} from "semantic-ui-react"
import ModalLoader from "../common/ModalLoader"
import {NoneText} from "../../styles/Text"
import AccountPageSelector from "../../layout/AccountPageSelector"
import {useDispatch, useSelector} from "react-redux"
import Auth from "../../store/reducers/auth/actions"
import MediaService from "../../services/media"
import Root from "../../store/reducers/root/actions"
import Media from "../../store/reducers/media/actions"
import Biography from "../../store/reducers/biography/actions"
import {setDirty} from "../../utils/dirtyPages.js";
import {usePageScope} from "../../store/hooks/page.hooks.js";

const AddImageToPage = ({manageImage, close}) => {
  const root = new Root(useDispatch())
  const user = useSelector(Auth.user)
  const [selectedPage, setSelectedPage] = useState(null)
  const pages = user?.pageData
  const [loading, setLoading] = useState(false)
  const mediaReducer = new Media(useDispatch())
  const bioReducer = new Biography(useDispatch())
  const {permissions, pageId, page} = usePageScope()

  useEffect(() => {
    if (user && pages && !selectedPage) {
      setSelectedPage(pages[user.primaryPageId])
    }
  }, [user])

  const saveImageToPage = () => {
    setLoading(true)
    MediaService.saveToPage(selectedPage.pageId, manageImage)
      .then(async () => {
        setDirty(selectedPage.pageId);
        await updateImageGallery()
        close()
      })
      .catch(e => {
        if (e === "UPGRADE_ACCOUNT") {
          root.promptPaywall("GALLERY")
        } else {
          root.setMessage({
            type: "error",
            message: "Error saving image"
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const updateImageGallery = async () => {
    const newGallery = await bioReducer.fetchGallery(pageId)
    mediaReducer.setViewGallery(newGallery)

  }

  if (!manageImage) {
    return <div/>
  }

  return (
    <Modal size={"tiny"} closeIcon open onClose={close}>
      <Modal.Header>
        Add to Everloom
      </Modal.Header>
      <Modal.Content image>
        <ModalLoader active={loading}/>
        <Modal.Description>
          <p>
            Which Everloom would you like to add this to?
          </p>

          <AccountPageSelector customPopClass={"download-selector-pop"}
                               customTriggerClass={"download-selector"}
                               itemSelected={id => setSelectedPage(pages[id])}
                               forceValue={selectedPage}/>

          <p>
            <NoneText> Note: This file will count towards your storage usage.</NoneText>
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={saveImageToPage}>Add</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default AddImageToPage
