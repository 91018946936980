import React, {useEffect, useRef} from 'react'
// import WaveSurfer from "wavesurfer.js";
import Waveform from "react-audio-waveform"

const TEST_PEAKS = [0.04, 0.99, 0.54, 0.74, 0.76, 0.52, 0.79, 0.72, 0.83, 0.67, 0.88, 0.99, 0.95, 0.9399999999999999, 0.91, 0.82, 0.96, 0.91, 0.93, 0.93, 0.98, 0.99, 0.98, 0.99, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.98, 0.85, 0.82, 0.96, 0.99, 0.99, 0.99, 0.97, 0.97, 0.98, 1, 0.98, 0.98, 0.98, 0.98, 0.99, 0.99, 0.98, 0.98, 0.98, 0.99, 0.98, 0.99, 0.99, 0.98, 0.99, 0.9, 0.8, 0.91, 0.9, 0.88, 0.97, 0.98, 0.92, 0.98, 0.98, 0.99, 0.99, 0.98, 0.99, 0.99, 0.98, 0.98, 0.97, 0.98, 0.98, 0.98, 0.99, 0.99, 0.98, 0.99, 0.98, 0.99, 0.99, 0.98, 0.99, 0.98, 0.98, 0.99, 0.99, 0.98, 0.99, 0.99, 1, 0.99, 0.93, 0.96, 0.83, 0.9399999999999999, 0.98, 0]

console.log(TEST_PEAKS.length)
const PEAKS = [];

// created one
const AudioWaveform = ({data}) => {

  useEffect(() => {

  }, [data]);


  return <div>
  <Waveform
    barWidth={2}
    peaks={TEST_PEAKS}
    height={100}
    pos={0}
    duration={210}
    onClick={() => null}
    color="#676767"
    progressGradientColors={[[0, "#888"], [1, "#aaa"]]}
  /> </div>
};

// generated one
// const AudioWaveform = ({data}) => {
//   const wavesurfer = useRef(null);
//
//   useEffect(() => {
//
//     (async () => {
//
//
//       if (!data.Location) {
//         return;
//       }
//
//
//       wavesurfer.current = WaveSurfer.create({
//         container: wavesurfer.current,
//         waveColor: 'violet',
//         progressColor: 'purple'
//       });
//
//
//       wavesurfer.current?.loadBlob(data.Location);
//
//       wavesurfer.current?.on("ready", function () {
//       });
//
//     })();
//
//     return () => wavesurfer.current?.destroy();
//   }, [data]);
//
//
//   return <div style={{width: 200}} ref={wavesurfer}/>
// };

export default AudioWaveform;
