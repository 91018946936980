import React from 'react'
import Logo from "@logo/themed/logo-icon.svg";
import {useDispatch, useSelector} from "react-redux";
import Biography from "../store/reducers/biography/actions.js";
import {HoverableIcon, pageIdTitle} from "../styles/Text.js";
import PermissionsService from "../services/permissions.js";
import ChaptersMenu from "../pages/Chapters/ChaptersMenu.js";
import {ProfileMenuItems} from "../config/options.map.js";
import {ChevronBackOutline, Cog} from "react-ionicons";
import {Clickable} from "../styles/Containers.js";
import {useHistory} from "react-router-dom";
import useWindowSize from "../utils/dimensions.js";
import Root from "../store/reducers/root/actions.js";
import {CONSTANTS} from "../config/constants.js";
import {matchPath} from "react-router";
import HeirloomImage from "../components/common/HeirloomImage.js";
import {
	BottomNavContainer,
	LogoPosition,
	MetaContent,
	ProfileContent, SendPageContainer,
	SideNav
} from "../styles/Layout.js"
import ClipCopy from "../components/common/ClipCopy.js";
import styled from "styled-components";
import FormInput from "../components/common/FormInput.js";
import {PagesService} from "../services/pages.js";
import AccountPageSelector from "./AccountPageSelector.js";
import {usePageScope} from "../store/hooks/page.hooks.js";


const PageNav = ({}) => {
    const {width} = useWindowSize();
    const history = useHistory();
    const BiographyDispatch = new Biography(useDispatch())
    const {page, permissions, pageId, isMine, activePlan} = usePageScope()
    const canView = PermissionsService.checkPermissions(permissions, 'canAccessChapters');

    const canViewChapters = PermissionsService.checkPermissions(permissions, 'canAccessChapters');
    const canEdit = PermissionsService.checkPermissions(permissions, 'canEdit');
    const isBeneficiary = PermissionsService.checkPermissions(permissions, 'isOnlyBeneficiary')

    const sideNavOpen = useSelector(Root.navOpen);
    const rootDispatch = new Root(useDispatch())
    const [sendingPage, setSendingPage] = React.useState(false)
    const [sendEmail, setSendEmail] = React.useState('');

    const checkForActive = (itemKey) => {
        const match = matchPath(window.location.pathname, {
            path: "/:pageId/:location",
            exact: false,
            strict: false
        });
        return match && match.params && match.params.location === itemKey;
    }

    const selectMenuItem = (item) => {
        if (!disabled) {
            BiographyDispatch.setProfileSection(item);
            history.push(`/${pageId}/${item.slug}`);
            checkMenuCollapse();
        }
    }

    const checkMenuCollapse = () => {
        if (width < CONSTANTS.collapsedSize) {
            rootDispatch.toggleSideNav(false)
        }
    }

    const resetContext = (newPage) => history.push(`/${newPage}/chapters`)

    const sendMyPageId = () => {
        if (!sendEmail) return;
        setSendingPage(false);
        PagesService.sendCode(pageId, sendEmail).then(res => {
            rootDispatch.setMessage({
                type: 'success',
                message: 'Code sent!'
            })
        }).catch(e => {
            rootDispatch.setMessage({
                type: 'error',
                message: 'You must wait 1 minute before sending another email.'
            })
        });
    }


    const disabled = !canViewChapters;

    return (
        <SideNav open={sideNavOpen}>
            <ScrollableContainer>
                {width < CONSTANTS.collapsedSize &&
                <ChevronBackOutline className={'back-chev'} onClick={() => rootDispatch.toggleSideNav(false)}/>}
                <LogoPosition>
                    <HeirloomImage src={Logo}/>
                </LogoPosition>
                <MetaContent>
                    <div>{pageIdTitle}:
                        <HoverableIcon name={'paper plane'} onClick={() => setSendingPage(!sendingPage)}/>
                        <ClipCopy type={pageIdTitle} text={pageId}>
                            <HoverableIcon style={{marginLeft: 10}} name='copy'/>
                        </ClipCopy>
                        <span className={'meta-detail'}>{pageId}</span>
                    </div>
                    {!isMine && <div>Maintainer: <span className={'meta-detail'}> {page?.owner?.name}</span></div>}
                </MetaContent>
                <SendPageContainer active={sendingPage}>
                    <FormInput value={sendEmail}
                               onChange={(_, {value}) => setSendEmail(value)}
                               action={{
                                   icon: 'paper plane',
                                   onClick: sendMyPageId
                               }} formMargin={false} fluid containerStyle={{marginTop: 4}}
                               placeholder={'Enter email...'}/>
                </SendPageContainer>

                <AccountPageSelector itemSelected={resetContext}/>

                <div style={{flex: 1, marginTop: 30, marginBottom: 40}}>
                    <ProfileContent disabled={disabled}>
                        {ProfileMenuItems.filter(o => {
                            if (!activePlan.memoryAccess)
                                return o.key !== 'memories'
                            return true
                        }).filter(o => canEdit ? true : !o.canEdit).map(o => {
                            const active = checkForActive(o.key);
                            return (
                                <div className={'content-item'}
                                     key={o.key}
                                     onClick={() => selectMenuItem(o)}>
                                    {o.icon}
                                    {(active && canViewChapters) ?
                                        <span className={'active-item'}>{o.name}</span> : o.name}
                                </div>
                            )
                        })}
                    </ProfileContent>
                </div>

                {(checkForActive('chapters') && !disabled && canView) &&
                <div>
                    <ChaptersMenu canEdit={canEdit} isSmall={width < CONSTANTS.collapsedSize}
                                  checkCollapse={checkMenuCollapse}/>
                </div>
                }
            </ScrollableContainer>


            {(canEdit || isBeneficiary) && <BottomNavContainer align={'center'} justify={'flex-end'}
                                            onClick={() => {
                                                history.push(`/${pageId}/account`);
                                                checkMenuCollapse();
                                            }}>
                <Clickable style={{color: 'white', display: 'flex', alignItems: 'center'}}>Manage page<Cog
                    width={'30px'} height={'30px'} color={'#fff'} style={{marginLeft: 10}}/></Clickable>
            </BottomNavContainer>
            }
        </SideNav>
    );
};

export default PageNav;

export const ScrollableContainer = styled.div`
  overflow-y: auto;
  max-height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
