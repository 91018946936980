import React, {useEffect, useState} from "react"
import PermissionsService from "../../services/permissions"
import {useDispatch, useSelector} from "react-redux"
import Biography from "../../store/reducers/biography/actions"
import {FlexBox} from "../../styles/Containers.js"
import {ActionButton} from "../../styles/Semantic"
import {Button, Confirm, Divider, Icon, Loader, Modal} from "semantic-ui-react"
import ImageDataForm from "./ImageDataForm"
import MediaService from "../../services/media"
import Media from "../../store/reducers/media/actions"
import styled from "styled-components"
import AddImageToPage from "./AddImageToPage.js"
import {collapseHeight} from "./styles"
import {usePageScope} from "../../store/hooks/page.hooks.js";
import {useQueryClient} from "react-query";
import firebase from "firebase/app";
import mime from 'mime-types'
import useWindowSize from "../../utils/dimensions.js";

const EditBar = ({onClose, imageData, children}) => {
  const {width} = useWindowSize()
  const isSmall = width < 881;
  const dispatch = useDispatch()
  const viewImage = useSelector(Media.viewImage)
  const biographyReducer = new Biography(dispatch)
  const mediaReducer = new Media(dispatch)
  const [edit, setEdit] = useState(false)
  const {permissions, pageId, page, isPrimary} = usePageScope()
  const canEdit = PermissionsService.checkPermissions(permissions, "canEdit")
  const readonly = !!imageData?.readonly || !canEdit || imageData?.tagData
  const [newImageData, setNewImageData] = useState(null)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [loading, setLoading] = useState(false)
  const [saveImage, setSaveImage] = useState(false)
  const isTaggedImage = !!imageData?.tagId
  const queryClient = useQueryClient()
  const [downloading, setDownloading] = useState(false)

  useEffect(() => {
    setNewImageData({...imageData})
  }, [imageData])

  const updateLocalGallery = (type) => {
    const i = viewImage.gallery.findIndex(o => o.Location === newImageData.Location)
    if (type === "UPDATE") {
      viewImage.gallery[i] = newImageData
      biographyReducer.patchLocalGallery(newImageData, page)
      mediaReducer.setViewImage(newImageData, viewImage.gallery)
      mediaReducer.setViewGallery(viewImage.gallery)
    } else if (type === "REMOVE") {
      viewImage.gallery.splice(i, 1)
      page.gallery = viewImage.gallery
      biographyReducer.patchLocalPage(page)
      mediaReducer.setViewImage(newImageData, viewImage.gallery)
      mediaReducer.setViewGallery(viewImage.gallery)
    } else if (type === "REMOVE_TAG") {
      viewImage.gallery.splice(i, 1)
      mediaReducer.setViewImage(null)
      queryClient.setQueryData(["user", "gallery", "tagged", pageId], oldData => {
        const index = oldData.findIndex(o => o.tagId === newImageData.tagId)
        oldData.splice(index, 1)
        mediaReducer.setViewGallery(oldData)
        return oldData
      })
    }
  }

  const updateImageData = () => {
    firebase.analytics().logEvent("update_image_data");
    setLoading(true)
    return MediaService.updatePageGallery(newImageData, pageId, newImageData.Location).then(() => {
      updateLocalGallery("UPDATE")
    }).finally(() => {
      setLoading(false)
      setEdit(false)
    })
  }

  const deleteImage = () => {
    firebase.analytics().logEvent("delete_image");
    setLoading(true)
    setConfirmDelete(false)
    MediaService.deleteImage(pageId, imageData).then(() => {
      biographyReducer.addToMediaSize(page, -imageData.sizing?.original || 0)
      updateLocalGallery("REMOVE")
      onClose()
    }).finally(() => {
      setLoading(false)
    })
  }

  const removeTag = () => {
    firebase.analytics().logEvent("image_tag_removed");
    setLoading(true)
    MediaService.removeTag(imageData.tagId)
      .then(() => {
        updateLocalGallery("REMOVE_TAG")
        setLoading(false)
        onClose()
      })
      .catch(err => setLoading(false))
  }

  const downloadImage = () => {
    if (downloading) return;
    setDownloading(true)
    MediaService.toDataUrl(imageData.Location).then((data) => {
      console.log(data)
      firebase.analytics().logEvent("download_image");
      const a = document.createElement("a")
      a.href = `data:${imageData.mimeType};base64,` + data
      a.target = "_blank"
      a.download = 'everloom-image.' + mime.extension(imageData.mimeType)
      a.click()
    }).finally(() => setDownloading(false))
  }

  const buttonProps = {
    size: "medium",
    auto: true
  }

  const mediaModal = () => (
    <Modal open={edit} size={"mini"}>
      <Modal.Header>Media Details</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <ImageDataForm data={newImageData} setData={setNewImageData}/>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={() => {
          setNewImageData({...imageData})
          setEdit(false)
        }}>
          Cancel
        </Button>
        <Button
          content="Save"
          primary
          loading={loading}
          onClick={updateImageData}
        />
      </Modal.Actions>
    </Modal>
  )

  const addedContent = () => (
    <>
      {loading && <Loader/>}

      <AddImageToPage manageImage={saveImage ? imageData : null} close={() => setSaveImage(false)}/>
      {mediaModal()}
      <Confirm
        content={"Are you sure you would like to delete this image?"}
        onConfirm={deleteImage}
        onCancel={() => setConfirmDelete(false)}
        open={confirmDelete}
      />
    </>
  )

  if (isSmall) {

    return (
      <div>
        <FlexBox justify={'space-evenly'}>
          {(imageData.fileId && canEdit) && <ActionButton  {...buttonProps} onClick={() => setConfirmDelete(true)}><Icon
            name={"trash"}/>Delete</ActionButton>}

          {!readonly &&
          <ActionButton {...buttonProps} onClick={() => setEdit(true)}><Icon name={"pencil"}/>Edit</ActionButton>}
          {isTaggedImage &&
          <ActionButton {...buttonProps} style={{marginLeft: 6}} onClick={removeTag}><Icon name={"remove"}/>Remove
            Tag</ActionButton>}

          {(imageData.fileId && !isPrimary) && <ActionButton  {...buttonProps} onClick={() => setSaveImage(true)}><Icon
            name={"bookmark outline"}/>Save</ActionButton>}
          <ActionButton  {...buttonProps} onClick={downloadImage}><Icon name={"download"}/>Download</ActionButton>
        </FlexBox>
        {children}
      </div>
    )

  }

  return (
    <div>
      {addedContent()}
      <FlexBox justify={"flex-end"} style={{marginTop: 20}}>
        {!readonly &&
        <ActionButton {...buttonProps} onClick={() => setEdit(true)}><Icon name={"pencil"}/>Edit</ActionButton>}
        {isTaggedImage &&
        <ActionButton {...buttonProps} style={{marginLeft: 6}} onClick={removeTag}><Icon name={"remove"}/>Remove
          Tag</ActionButton>}
      </FlexBox>
      {children}
      <ContentButtons>
        <Divider/>
        <FlexBox justify={"space-evenly"} align={"center"}>
          {(imageData.fileId && !isPrimary) && <div className={"btn-item"} onClick={() => setSaveImage(true)}>
            <Icon name={"bookmark outline"}/>
            Save
          </div>}
          <div className={"btn-item"} onClick={downloadImage}>
            <Icon name={"download"}/>
            Download
          </div>
        </FlexBox>
        <Divider/>
      </ContentButtons>
      {(imageData.fileId && canEdit) && <BottomRightButton>
        <ActionButton {...buttonProps} onClick={() => setConfirmDelete(true)}>
          <Icon name={"trash"}/>
          Delete
        </ActionButton>
      </BottomRightButton>}
    </div>
  )
}

export default EditBar

const BottomRightButton = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;

  @media only screen and (max-width: ${collapseHeight}px) {
    top: 32px;
    left: 20px;
  }
`

const ContentButtons = styled.div`
  margin-top: 40px;

  @media only screen and (max-width: ${collapseHeight}px) {
    display: none;
  }

  .btn-item {
    &:hover {
      color: ${props => props.theme.primary};
      cursor: pointer;
    }
  }
`
