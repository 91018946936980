import React, { useState } from "react"
import { Modal } from "semantic-ui-react"
import { useDispatch, useSelector } from "react-redux"
import Media from "../../store/reducers/media/actions"
import { lang } from "../../config/languages"
import Biography from "../../store/reducers/biography/actions"
import GalleryList from "../common/GalleryList"
import UploadGalleryImage from "./UploadGalleryImage.js"
import PermissionsService from "../../services/permissions.js";
import {usePageScope} from "../../store/hooks/page.hooks.js";

const GalleryModal = ({ close }) => {
	const dispatch = useDispatch()
	const MediaDispatch = new Media(dispatch)
	const gallery = useSelector(Media.viewGallery)
	const canSelectImage = useSelector(Media.isSelectable)
	const [selectedImages, setSelectedImages] = useState([])
	const { page, permissions } = usePageScope()
	const edit = PermissionsService.checkPermissions(permissions, "canEdit")

	const imageSelected = (image) => {
		MediaDispatch.setViewImage(image, gallery)
	}

	const multipleSelected = (image) => {
		MediaDispatch.setSelectedImage(image)
	}

	return (
		<Modal open onClose={close} size={"fullscreen"} closeIcon
					 dimmer={false}
					 className={"gallery-modal-class"}
					 mountNode={document.getElementById("gallery-modal")}>
			<Modal.Header>
				{lang.ownerAlbum.replace("{name}", page.brief.firstName)}
			</Modal.Header>
			<Modal.Content style={{ overflowY: "auto" }}>
				<GalleryList
					gallery={gallery}
					selected={selectedImages}
					imageSelected={o => canSelectImage ? multipleSelected(o) : imageSelected(o)}/>
			</Modal.Content>
		</Modal>
	)
}

export default GalleryModal
