import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Biography from "../../store/reducers/biography/actions.js"
import Auth from "../../store/reducers/auth/actions.js"
import Tree from "../../store/reducers/tree/actions.js"
import InlinePageLoader from "../../components/common/InlinePageLoader.js"
import Screen from "../../layout/Screen.js"
import { FlexBox } from "../../styles/Containers.js"
import SimpleCard from "../MyFamily/components/SimpleCard.js"
import { Button } from "semantic-ui-react"
import {usePageScope} from "../../store/hooks/page.hooks.js";
import DemoFamilyPage from "./demo.js";


const FamilyPage = ({}) => {
	const dispatch = useDispatch()
	const TreeDispatch = new Tree(dispatch)
	const BriefDispatch = new Biography(useDispatch())
	const myFamily = useSelector(Tree.family)
	const { page } = usePageScope()
	const user = useSelector(Auth.user)
	const [treeLoading, setTreeLoading] = useState(false)

	useEffect(() => {
		if (!myFamily.length && user && page && !treeLoading) {
			setTreeLoading(true)
			TreeDispatch.fetchFamily(page.pageId, true).finally(() => setTreeLoading(false))
		}
	}, [user, page])

	const showRelationship = ["PARENTS", "SIBLINGS", "ME", "CHILDREN", "SPOUSE"]
	const order = ["ME", "SIBLINGS", "SPOUSE", "CHILDREN", "PARENTS", "NEPHEWS/NIECE", "OTHER"]

	const noTitle = myFamily.findIndex(o => !o.title)
	if (noTitle > -1) {
		myFamily[noTitle].title = "Other"
	}

	return (
		<Screen style={{ backgroundColor: "white", padding: 12, borderRadius: 4 }}>
			<InlinePageLoader active={treeLoading}/>
			<div style={{ textAlign: "right" }}>
				<Button primary onClick={() => {
					BriefDispatch.setEditBrief(true)
					setTimeout(() => {
						const briefContainer = document.getElementById('brief-modal-content');
						briefContainer.scrollTop = briefContainer.scrollHeight;
					}, 250)
				}}>
					Manage Family
				</Button>
			</div>
			{!treeLoading && <>
				<div>
					{myFamily.sort((a, b) => order.indexOf(a.title.toUpperCase()) > order.indexOf(b.title.toUpperCase()) ? 1 : -1).map((o, i) => {
						return (
							<>
								<h2 style={{ textTransform: "capitalize", marginTop: 30 }}>{o.title === "Me" ? "Spouses" : o.title}</h2>
								<FlexBox wrap={"no-wrap"} style={{ overflowX: "auto" }}>
									{o.data.filter(o => o.data.pageCode !== page.pageId).map(p => (
											<div style={{ margin: "1rem" }}><SimpleCard
												showRelationship={showRelationship.indexOf(o.title?.toUpperCase()) > -1} data={p} index={i}/>
											</div>
										)
									)}
								</FlexBox>
							</>
						)
					})}
				</div>
			</>}
		</Screen>
	)
}

export default FamilyPage
