import {
	FETCHING_TAGGABLE,
	INITIAL_STATE,
	SET_ERROR,
	SET_LOADING,
	SET_PAGE_PERMISSIONS,
	SET_PAGE_TAGGABLE
} from "./initialState"
import { RESET_STATE } from "../common/reducer.class"

export const permissionsReducer = (state = INITIAL_STATE, action) => {
	const { payload, type, meta } = action

	switch (type) {
		case SET_LOADING:
			return {
				...state,
				loading: payload
			}
		case SET_ERROR:
			return {
				...state,
				error: payload
			}
		case SET_PAGE_PERMISSIONS: {
			return {
				...state,
				permissions: {
					[payload.pageId]: {
						permissions: payload.results.pagePermissions,
						permissionsRequests: payload.results.requests,
						permissionsPending: payload.results.pending
					}
				}
			}
		}
		case SET_PAGE_TAGGABLE:
			return {
				...state,
				fetchingTaggable: false,
				taggable: {
					...state.taggable,
					[payload.pageId]: payload.data
				}
			}
		case FETCHING_TAGGABLE: {
			return {
				...state,
				fetchingTaggable: true
			}
		}
		case RESET_STATE:
			return INITIAL_STATE
		default:
			return state
	}
}
