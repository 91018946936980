import React, {useEffect, useState} from 'react'
import {Button, Loader, Segment} from "semantic-ui-react";
import {AddCircle} from "react-ionicons";
import CreateCode from "./createCode.js";
import {useDispatch} from "react-redux";
import Root from "../store/reducers/root/actions.js";
import AdminService from "../services/admin.js";
import {FlexBox} from "../styles/Containers.js";

const GiftCodes = ({}) => {
  const [silentLoading, setSilentLoading] = useState(false);
  const [addSection, setAddSection] = useState(false);
  const [data, setData] = useState([]);
  const [loadingCode, setLoadingCode] = useState('');
  const root = new Root(useDispatch());

  useEffect(() => {
    loadAll();
  }, [])

  const loadAll = () => {
    setSilentLoading(true)
    AdminService.getAllCodes()
      .then(data => {
        setData(data);
      })
      .catch(e => {
        root.setMessage({
          type: 'error',
          message: e
        })
      })
      .finally(() => setSilentLoading(false))
  }

  const expire = (code) => {
    setLoadingCode(code);
    AdminService.expireCode(code)
      .then(() => {
        setLoadingCode('');
        loadAll();
      }).catch(e => {
      root.setMessage({
        type: 'error',
        message: e
      })
    })
  }

  return (
    <div style={{paddingBottom: 40}}>
      <Loader active={silentLoading}/>
      <h1>
        Gift Codes and Promotions
        <AddCircle style={{cursor: 'pointer', position: 'relative', top: 2}}
                   onClick={() => setAddSection(true)}/>
      </h1>
      <h4 style={{
        marginBottom: 50,
        opacity: 0.7
      }}>Create gift codes or promotional codes. Both essentially do the same thing as of now.</h4>
      <CreateCode isOpen={addSection} onClose={(reload) => {
        setAddSection(false)
        if (reload) {
          loadAll();
        }
      }}/>

      {data.sort((a, b) => a.createdAt < b.createdAt ? -1 : 1).map((o, i) => (
        <Segment style={{marginTop: 12}}>
          <FlexBox align={'center'} justify={'space-between'}>
            <div>
              <strong>{o.code}</strong> ({o.type}) ({o.issuer})
            </div>
            <div>
              {o.status === 'ACTIVE' ? <Button loading={loadingCode === o.code} primary onClick={() => expire(o.code)}>Expire</Button> : o.status}
            </div>
          </FlexBox>
        </Segment>
      ))}


    </div>
  );
};

export default GiftCodes;
