import API from "./api.js";

export default class TreeService {

    static base = 'tree';

    static getPageTree(pageId) {
        return API.get(`${TreeService.base}/${pageId}`)
    }

    static getFamily(pageId, soft) {
			return API.post(`${TreeService.base}/${pageId}/family`, {
				soft
			})
		}

    static searchMyTree(pageId, search) {
        return API.get(`${TreeService.base}/${pageId}/search?keyword=${search}`)
    }

    static getContactBook = (pageId) => {
        return API.get(`${TreeService.base}/${pageId}/contactBook`)
    }

    static updateContactBook(pageId, form) {
        return API.post(`${TreeService.base}/${pageId}/contactBook/update`, {...form})
    }

    static getMyFamily(soft) {
        return API.post(`${TreeService.base}/me/family`, {
        	soft
				})
    }
}
