import React, {useState} from 'react'
import MemoryFeedEditor from "./MemoryFeedEditor.js";
import EmptyFeed from "./EmptyFeed.js";
import {useDispatch, useSelector} from "react-redux";
import MemoriesReducer from "../../store/reducers/memories/actions.js";
import MemoryItem from "./MemoryItem.js";
import {handleActionResponse} from "../../utils/messageResponse.js";
import Root from "../../store/reducers/root/actions.js";
import Biography from "../../store/reducers/biography/actions.js";
import styled from "styled-components";
import {Button, Divider} from "semantic-ui-react";
import {FlexBox} from "../../styles/Containers.js";
import {usePageScope} from "../../store/hooks/page.hooks.js";

const MemoryFeed = ({isAdmin}) => {
    const [loading, setLoading] = useState('')
    const memories = useSelector(MemoriesReducer.memories);
    const memoriesDispatch = new MemoriesReducer(useDispatch())
    const rootDispatch = new Root(useDispatch())
    const {pageId} = usePageScope()
    const writeMem = useSelector(MemoriesReducer.isWritingMem)

    const removeItem = (item) => {
        setLoading(item.id);
        handleActionResponse(memoriesDispatch.declinePost(item.id, pageId), rootDispatch, 'Status updated', () => {
            setLoading('');
        })
    }


    return (
        <FlexBox direction={'column'} style={{height: '100%'}}>
            <MemoryFeedContainer>
                {memories.length === 0 && <EmptyFeed/>}
                {memories.map(o => <MemoryItem loading={loading === o.id} item={o} actions={isAdmin} feed
                                               remove={removeItem}/>)}
            </MemoryFeedContainer>

            <Divider/>

            {writeMem && <div style={{width: '100%'}}>
                <MemoryFeedEditor/>
            </div> }
        </FlexBox>
    );
};

export default MemoryFeed;

export const MemoryFeedContainer = styled.div`
  flex: 1;
  width: 100%;
  overflow-y: auto;
`;
