import { useQuery } from "react-query"
import { useCurrentUser } from "./user.hooks.js"
import PermissionsService from "../../services/permissions.js"
import Biography from "../reducers/biography/actions.js"
import { useSelector } from "react-redux"
import MediaService from "../../services/media.js"
import Auth from "../reducers/auth/actions.js"
import Notifications from "../reducers/notifications/actions.js"
import { PagesService } from "../../services/pages"


export const usePageScope = () => {
	return useSelector(Biography.biographyScope)
}

export const useNotificationsData = () => {
	return useSelector(Notifications.notifData)
}

export const useOnboarding = () => {
	return {
		...(useSelector(Auth.user)?.onboarding || {}),
		...(JSON.parse(localStorage.getItem("onboarding") || "{}"))
	}
}

export const useTaggable = () => {
	const user = useCurrentUser()
	const { permissions, pageId } = usePageScope()
	const canEdit = PermissionsService.checkPermissions(permissions, "canEdit")
	let fetchPage = user.myPages.indexOf(pageId) > -1 ? pageId : user.primaryPageId
	return useQuery(["user", "taggable", fetchPage], () => PermissionsService.getTaggable(fetchPage, false).then(res => {
		if (typeof res === "number") {
			return []
		}
		return res
	}), {
		enabled: canEdit && !!pageId,
		placeholderData: [],
		staleTime: 1000 * 60 * 30
	})
}

export const useTaggedGallery = (enabled = true) => {
	const { pageId } = usePageScope()
	return useQuery(["user", "gallery", "tagged", pageId], () =>
		MediaService.getTaggedGallery(pageId), {
		placeholderData: [],
		enabled,
		staleTime: 1000 * 60 * 30
	})
}


export const useDemoData = (isPublic, viewing, pin, setError, setPin, pid) => {
	return useQuery(["demo-data", viewing, pin], () => {
		if (isPublic) {
			return PagesService.fetchPageAsDemo(viewing, pin)
				.then(data => {
					if (!data || !data.length) {
						throw new Error("No pages")
					}
					const page = data[0].pageId
					localStorage.setItem("sharePage:" + page, JSON.stringify({
						pageId: page,
						pin,
						link: viewing
					}))
					return data
				}).catch(err => {
					localStorage.getItem(`sharePage:${pid}`)
					setPin('')
					setError("There was an error accessing this everloom.")
				})
		}
		return PagesService.fetchDemo().catch(err => {
			window.open("https://app.everloom.io")
		})
	}, {
		staleTime: Infinity,
		enabled: !isPublic || (!!viewing && pin.length === 4)
	})

}
