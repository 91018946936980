import React, { useEffect, useState } from "react"
import Screen from "../../layout/Screen"
import StepOne from "./components/StepOne.js"
import StepTwo from "./components/StepTwo.js"
import { Button, Message } from "semantic-ui-react"
import findGetParam from "../../utils/findGetParam.js"
import { useHistory } from "react-router-dom"
import { Clickable, FlexBox } from "../../styles/Containers.js"
import StepFour from "./components/StepFour.js"
import { useDispatch, useSelector } from "react-redux"
import OnboardingReducer from "../../store/reducers/onboarding/action.js"
import Auth from "../../store/reducers/auth/actions.js"
import SelectBillingMethod from "./components/SelectBillingMethod.js"

const Onboarding = ({}) => {
	const history = useHistory()
	const skippable = ["2"]
	const lastStep = "3"
	const data = useSelector(OnboardingReducer.formData)
	const onboarding = new OnboardingReducer(useDispatch())
	const errors = useSelector(OnboardingReducer.getErrors) || {}
	const user = useSelector(Auth.user)
	const [selectPayment, setSelectPayment] = useState(false)
	const [discount, setDiscount] = useState(null)

	useEffect(() => {
		onboarding.setOnboarding({
			plan: 0
		})
		if (!data.firstName && !data.lastName) {
			onboarding.setOnboarding({
				firstName: user.firstName,
				lastName: user.lastName
			})
		}
	}, [])

	let stepNum = findGetParam("step", true)
	if (!stepNum) {
		stepNum = 1
	}

	const prev = () => {
		if (stepNum === "1") {
			return
		}
		history.push({
			pathname: window.location.pathname,
			search: "?step=" + (+stepNum - 1)
		})
	}

	const next = () => {
		if (stepNum === lastStep) {
			return
		}
		history.push({
			pathname: window.location.pathname,
			search: "?step=" + (+stepNum + 1)
		})
	}

	const submitHeirloom = () => {
		if (!!data.plan && !data.paymentMethod && (!discount || discount.discount.discount !== 1)) {
			setSelectPayment(true)
			return
		}

		const errors = {}
		if (!data.firstName) {
			errors.firstName = true
			errors.global = "A first name and last name must be specified"
		}

		if (!data.lastName) {
			errors.lastName = true
			errors.global = "A first name and last name must be specified"
		}

		if (errors.lastName || errors.firstName) {
			onboarding.setFormErrors(errors)
			history.push({
				pathname: window.location.pathname,
				search: "?step=1"
			})
			return
		}

		if (isNaN(data.plan)) {
			onboarding.setFormErrors(errors)
			errors.global = "You must select a page type"
			history.push({
				pathname: window.location.pathname,
				search: "?step=3"
			})
			return
		}
		onboarding.setFormErrors(errors)

		onboarding.submitData(data).finally(() => {
			setTimeout(() => {
				history.push("/")
			}, 500)
		})

	}

	return (
		<Screen style={{ width: "100%" }}>

			{(!!errors && errors.global) && <Message negative>{errors.global}</Message>}

			{stepNum === "1" && <StepOne/>}

			{stepNum === "2" && <StepTwo/>}

			{/*{stepNum === "3" && <StepThree/>}*/}

			{stepNum === lastStep && <StepFour setGlobalDiscount={setDiscount}/>}

			<SelectBillingMethod close={() => setSelectPayment(false)} open={selectPayment}/>

			<div style={{ paddingBottom: 20 }}>

				{stepNum === lastStep &&
				<div style={{ textAlign: "center", marginTop: 20 }}>
					<FlexBox justify={"center"}>
						<Button style={{ marginTop: 6 }} size={"big"} onClick={submitHeirloom}
										primary>{!!data.plan ? "Purchase" : "Create"}</Button>
						{/*<Button size={'big'} style={{backgroundColor: customTheme.quaternary, marginTop: 6}}>*/}
						{/*  Redeem Heirloom*/}
						{/*</Button>*/}
					</FlexBox>
					<Clickable style={{ marginTop: 12 }} onClick={prev}>Back</Clickable>
				</div>
				}

				{(stepNum !== "1" && stepNum !== lastStep) && <Button style={{ float: "left" }} onClick={prev}>
					Back
				</Button>}

				{stepNum !== lastStep && <FlexBox justify={"flex-end"} style={{ marginTop: 20 }}>
					{skippable.indexOf(stepNum) > -1 && <Button onClick={next}>Skip</Button>}
					<Button primary onClick={next}>Next</Button>
				</FlexBox>}
			</div>
		</Screen>
	)
}

export default Onboarding
