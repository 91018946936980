import React, {useState} from "react"
import UploadImageContent from "./UploadImageModal"
import {Button} from "semantic-ui-react"
import UploadImageComponent from "../media/UploadImage"
import Media from "../../store/reducers/media/actions"
import {useDispatch} from "react-redux"
import firebase from "firebase";

const UploadGalleryImage = ({selectableState, onUpload, containerStyle, onImageData}) => {
  const MediaReducer = new Media(useDispatch())
  const [toBeUploaded, setToBeUploaded] = useState(null)

  return (
    <div style={containerStyle || {}}>
      <UploadImageContent content={toBeUploaded}
                          onUploadComplete={newGallery => {
                            firebase.analytics().logEvent("image_uploaded");
                            if (onUpload) {
                              onUpload(newGallery)
                            } else if (!onImageData) {
                              MediaReducer.setViewGallery(newGallery || [], selectableState)
                            }
                          }}
                          onImageData={onImageData}
                          onFinished={(uploaded) => setToBeUploaded(null)}/>

      <UploadImageComponent allowMultiple loaderPosition={"top"} useButton onUrl={base64 => setToBeUploaded(base64)}
                            type={"base64"}>
        <Button primary>
          Upload Media
        </Button>
      </UploadImageComponent>
    </div>
  )
}

export default UploadGalleryImage
