import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Segment, Sidebar } from "semantic-ui-react"
import styled from "styled-components"
import Notifications from "../store/reducers/notifications/actions.js"
import SmallNavigation from "./menus/SmallNavigation"
import NotificationsNav from "./menus/NotificationsNav"

const SideBarMenu = ({ children }) => {
	const visible = useSelector(Notifications.visible)

	useEffect(() => {
		if (visible) {
			document.getElementById("notification-sidebar-content").style.overflowY = "hidden"
		} else {
			document.getElementById("notification-sidebar-content").style.overflowY = "unset"
		}
	}, [visible])

	return (
		<Pushable as={Segment}>

			<NotificationsNav/>

			<SmallNavigation/>

			<Pusher id={"notification-sidebar-content"} dimmed={visible}>
				{children}
			</Pusher>
		</Pushable>
	)
}

export default SideBarMenu

const Pusher = styled(Sidebar.Pusher)`
	border: none !important;
	padding: 0 !important;
`

const Pushable = styled(Sidebar.Pushable)`
	box-shadow: none !important;
	border: none !important;
	padding: 0 !important;
	background-color: transparent !important;
	margin: 0 !important;

	.ui.visible.right.overlay.sidebar {
		z-index: 100000000;
	}
`
