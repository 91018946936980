import React, {useState} from 'react'
import PinInput from 'react-pin-input'

const PinEntry = ({pin, setPin}) => {

    return (
        <div>
					<PinInput
						length={4}
						initialValue={"·"}
						onChange={(value, index) => console.log(value, index)}
						style={{padding: '10px'}}
						inputStyle={{borderColor: 'white', borderRadius: 4, color: 'white'}}
						inputFocusStyle={{borderColor: 'white'}}
						onComplete={(value, index) => {
							setPin(value)
						}}
						type={"text"}
						autoSelect={true}
					/>
        </div>
    );
};

export default PinEntry;
