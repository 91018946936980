import React, {useEffect, useState} from "react"
import {Clickable, FlexBox, ScrollbarStyles, SettingsPage} from "../../styles/Containers.js"
import {FormSectionTitle} from "../../styles/Forms.js"
import {Button, Icon, Loader, Modal, Table} from "semantic-ui-react"
import {useDispatch, useSelector} from "react-redux"
import Auth from "../../store/reducers/auth/actions.js"
import styled from "styled-components"
import ModalLoader from "../../components/common/ModalLoader.js"
import Root from "../../store/reducers/root/actions.js"
import {HoverableIcon, NoneText} from "../../styles/Text.js"
import {LISTS} from "../../config/options.map.js"
import findGetParam from "../../utils/findGetParam.js"
import PageManagement from "../../components/PageManagement.js"
import CustomHybridTable from "../../components/common/CustomHybridTable"
import useWindowSize from "../../utils/dimensions"
import {useHistory} from "react-router-dom"
import AddPageInline from "../Profile/AddPageInline.js";
import {useOnboarding} from "../../store/hooks/page.hooks.js";
import {Nux} from "../Onboarding/nux";
import ErrorBoundary from "../../components/ErrorBoundary.js";

const MyPages = ({loading, tableOnly, containerStyles = {}, transfers = true}) => {
  const history = useHistory()
  const {height} = useWindowSize()
  const RootDispatch = new Root(useDispatch())
  const onboarding = useOnboarding()

  const user = useSelector(Auth.user)
  const pages = user?.pageData
  const [viewPage, setViewingPage] = useState(null)
  const [addPage, setAddPage] = useState(false)
  const [modalLoading] = useState(false)

  const pageStatus = (status) => {
    return <span style={{
      fontSize: 12,
      color: "red",
      textTransform: "capitalize"
    }}>({status.replace(new RegExp("_", "g"), " ").toLowerCase()})</span>
  }

  useEffect(() => {
    const upgrade = findGetParam("upgrade", true)
    if (upgrade === "current") {
      RootDispatch.setViewUpgrade(true)
      setViewingPage(null)
    }

    const act = findGetParam("act", true)
    if (act) {
      if (act === "add_page") {
        setAddPage(true)
        if (!onboarding.createNewEverloom) {
          RootDispatch.setShowNux('createNewEverloom')
        }
      }
    }

  }, [])

  const planTitle = (LISTS.data.payPlans || []).find(o => o.id === viewPage?.activePlan?.id)?.title || ""

  return (
    <SettingsPage actions={true}>
      <AddPageInline isOpen={addPage} close={() => setAddPage(false)}
                     pageCreated={(pageId) => history.push(`/${pageId}/chapters`)}/>
      <Modal open={!!viewPage} close={() => setViewingPage(null)} style={{maxWidth: 300}}>
        <ModalLoader active={modalLoading}/>
        <Modal.Header>
          <div>
            {viewPage?.firstName} {viewPage?.lastName}
          </div>
          <TierText>{planTitle}</TierText>
        </Modal.Header>
        <Modal.Content>
          <ManagePage direction={"column"} justify={"flex-end"}>
            <PageManagement close={() => setViewingPage(null)}
														transfers={transfers}
                            viewPage={viewPage}
                            render={(o, i) => (
                              <Button {...o.props} key={i} onClick={() => o.action()}>
                                {o.title}
                              </Button>
                            )}/>
          </ManagePage>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setViewingPage(null)}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
      <PagesContainer style={containerStyles}>
        {!tableOnly && <FormSectionTitle>
          My Everlooms
          <Button primary onClick={() => setAddPage(true)} size={"small"} icon>
            <Icon name={"add"}/>
            Add
          </Button>
        </FormSectionTitle>}
        <Loader active={loading}/>

        <CustomHybridTable
          data={Object.keys(pages || {})}
          titles={["Name", ""]}
          singleTitle={""}
          row={(p, i) =>
            <Table.Row>
              <Table.Cell onClick={() => setViewingPage(pages[p])}>
                {pages[p].firstName} {pages[p].lastName} {!!pages[p].isPrimary ? "(primary)" : pages[p].status ? pageStatus(pages[p].status) : ""}
              </Table.Cell>
              <Table.Cell style={{textAlign: "right"}}>
                <HoverableIcon onClick={() => setViewingPage(pages[p])} name={"setting"}/>
              </Table.Cell>
            </Table.Row>
          }
          singleRow={(p, i) =>
            <Table.Row>
              <Table.Cell>
                <Clickable onClick={() => setViewingPage(pages[p])}>
                  {pages[p].firstName} {pages[p].lastName} {!!pages[p].isPrimary ? "(primary)" : pages[p].status ? pageStatus(pages[p].status) : ""}
                </Clickable>
              </Table.Cell>
            </Table.Row>
          }
        />
      </PagesContainer>
      <Nux/>
    </SettingsPage>
  )
}

export default MyPages

const styles = {
  contentMargin: {
    marginTop: 12
  }
}

const ManagePage = styled(FlexBox)`
  .button {
    width: 100%;
    margin-top: 5px;
  }
`

const TierText = styled(NoneText)`
  line-height: 1;
  font-size: 12px;
  margin-top: 8px;
`
const PagesContainer = styled.div`
  ${ScrollbarStyles}
`
