import React, {useEffect, useState} from "react"
import {Button, Icon, Loader, Menu, Search, Segment, Table} from "semantic-ui-react"
import Tree from "../../store/reducers/tree/actions.js"
import {useDispatch, useSelector} from "react-redux"
import {Clickable, FlexBox, IconImage, ProfileCircle} from "../../styles/Containers.js"
import {useHistory} from "react-router-dom"
import {PagesService} from "../../services/pages.js"
import EditContact from "./EditContact.js"
import PermissionsService from "../../services/permissions.js"
import ToolTip from "../../components/common/ToolTip.js";
import ContactAccess from "./ContactAccess.js";
import styled from "styled-components";
import {useOnboarding, usePageScope} from "../../store/hooks/page.hooks.js";
import Root from "../../store/reducers/root/actions.js";
import DirectoryImage from "../../assets/icons/directory.svg";
import PermissionIcon from "../../assets/icons/permissions.svg";
import Info from "../../assets/icons/info.svg";

const ContactBook = ({}) => {
  const history = useHistory()
  const treeReducer = new Tree(useDispatch())
  const contactBook = useSelector(Tree.getContactBook)
  const {pageId, permissions, page, isMine, activePlan} = usePageScope()
  const [activeItem, setActiveItem] = useState("Family")
  const [loading, setLoading] = useState(false)
  const [editInfo, setEditInfo] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const RootDispatch = new Root(useDispatch())
  const onboarding = useOnboarding()
  const edit = PermissionsService.checkPermissions(permissions, "canEdit")

  useEffect(() => {
    if (!onboarding.directory) {
      RootDispatch.setShowNux('directory')
    }
  }, [])

  useEffect(() => {
    if (!contactBook) {
      loadData()
    }
  }, [contactBook])

  const loadData = () => {
    setLoading(true)
    treeReducer.fetchContactBook(pageId).finally(() => {
      setLoading(false)
    })
  }

  const contactSet = (contactBook || []).findIndex(o => o.pageId === pageId) > -1;
  const myContact = (contactBook || []).find(o => o.pageId === pageId)

  const hasAccess = activePlan.directoryAccess
  const editButtonVisible = edit && hasAccess && contactBook?.length > 0 && !contactSet;

  const me = (contactBook || []).find(o => o.pageId === pageId) || {};
  const others = (contactBook || []).filter(o => o.pageId !== pageId).sort((a, b) => {
    return a.lastName === b.lastName ? a.firstName > b.firstName ? 1 : -1 : a.lastName > b.lastName ? 1 : -1
  })

  return (
    <>
      <SecondNav pointing secondary>
        <Menu.Item
          name='Family'
          icon={<IconImage src={DirectoryImage} />}
          active={activeItem === "Family"}
          onClick={() => setActiveItem("Family")}
        />

        {edit && <ToolTip message={contactSet ? null : 'You must first set your info.'} disabled={!contactSet}
                          trigger={<Menu.Item
                            style={editButtonVisible ? {position: 'relative', top: 7} : {}}
                            name={'Access'}
                            icon={<IconImage src={PermissionIcon} />}
                            active={activeItem === "Access"}
                            onClick={() => contactSet ? setActiveItem('Access') : null}
                          />}/>}

        {editButtonVisible && <Menu.Menu position='right'>
          <Menu.Item>
            <Button primary onClick={() => setEditInfo(true)}>Set My Information</Button>
          </Menu.Item>
        </Menu.Menu>}

        <Menu.Menu position='right'>
          <Menu.Item
            name=''
            icon={<IconImage src={Info} />}
            onClick={() => {
              RootDispatch.setShowNux('directory')
            }}
          />
        </Menu.Menu>
      </SecondNav>


      <EditContact isOpen={editInfo} close={(reload) => {
        setEditInfo(false)
        if (reload) {
          loadData()
        }
      }} data={contactBook?.find(o => o.pageId === pageId)}/>

      {activeItem === 'Access' && <ContactAccess contact={myContact} access={myContact?.pageAccess || []}/>}

      {activeItem === 'Family' &&
      <>
        <Search
          icon={"search"}
          onSearchChange={(ev, {value}) => setSearchValue(value)}
          value={searchValue}
          placeholder={"Search"}
          showNoResults={false}
        />
        <Table singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Phone number</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Address</Table.HeaderCell>

            </Table.Row>
          </Table.Header>

          <Table.Body>
            {(!contactBook || !contactBook?.length) &&
            <Table.Row>
              <Table.Cell colspan={4}>
                <Segment placeholder>
                  <FlexBox align={'center'} justify={'center'} direction={'column'}>
                    {(edit && hasAccess) && <>
                      <img src={DirectoryImage} style={{marginTop: 20}}/>
                      <FlexBox align={"center"} justify={"center"} direction={"column"}
                               style={{marginTop: 30, marginBottom: 20}}>
                        <h2>
                          Stay in Touch with Loved Ones
                        </h2>
                        <p style={{fontSize: 16, textAlign: 'center', lineHeight: 2}}>
                          Start by adding your own contact information.
                          As you connect with more people on Everloom their information will be added to your Directory.
                        </p>
                        <p style={{fontSize: 16, textAlign: 'center'}}>
                          Learn more about the Directory in our <a target={'_blank'} href={process.env.REACT_APP_LANDING_URL + '/help'}>Help
                          Center</a>.
                        </p>
                      </FlexBox>
                    </>
                    }
                    {(edit && !hasAccess) &&
                    <Button primary onClick={() => history.push('/account?upgrade=current')}>
                      Upgrade To Gain Access</Button>
                    }
                    {(edit && hasAccess) &&
                    <Button primary onClick={() => setEditInfo(true)}>Add Contact Information</Button>}

                  </FlexBox>
                  <Loader active={loading}/>
                </Segment>
              </Table.Cell>
            </Table.Row>
            }
            {!(!contactBook || !contactBook?.length) && [me, ...others].filter(o => !searchValue ? true : o.firstName.toLowerCase().includes(searchValue.toLowerCase()) || o.lastName.toLowerCase().includes(searchValue.toLowerCase())).map(o => {
              return (
                <Table.Row>
                  <Table.Cell>
                    <FlexBox align={"center"} wrap={"no-wrap"}>
                      <ToolTip message={edit && o.pageId === pageId ? 'Edit contact information' : null}
                               trigger={<Icon onClick={() => setEditInfo(true)}
                                              style={{visibility: edit && o.pageId === pageId ? 'visible' : 'hidden'}}
                                              name={'edit'}/>}/>
                      <ProfileCircle src={o.image || IconImage}/>
                      <Clickable
                        onClick={() => o.pageId ? history.push(history.push(PagesService.navigatePageRoute(o.pageId))) : null}>{o.firstName} {o.lastName}</Clickable>
                    </FlexBox>
                  </Table.Cell>
                  <Table.Cell>
                    {o.phoneNumber ?
                      <Clickable><a href={"tel:" + o.phoneNumber}>{o.phoneNumber}</a></Clickable> : "-"}
                  </Table.Cell>
                  <Table.Cell>
                    {o.email ?
                      <Clickable><a href={"mailto:" + o.email}>{o.email}</a></Clickable> : "-"}
                  </Table.Cell>
                  <Table.Cell>
                    {o.address ? o.address : "-"}
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </>
      }
    </>
  )
}

export default ContactBook
const SecondNav = styled(Menu)`
  .active.item {
    border-color: ${props => props.theme.primary} !important;
  }

`
