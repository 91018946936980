import React, { useEffect, useState } from "react"
import { FlexBox, SettingsPage } from "../../../styles/Containers"
import ModalLoader from "../../../components/common/ModalLoader"
import { FormSectionTitle } from "../../../styles/Forms"
import { HoverableIcon, NoneTextInlineRegular } from "../../../styles/Text"
import { useQuery, useQueryClient } from "react-query"
import { usePageScope } from "../../../store/hooks/page.hooks"
import API from "../../../services/api"
import ClipCopy from "../../../components/common/ClipCopy"
import theme from "../../../styles/theme"
import styled from "styled-components"

const SharableEverloom = ({}) => {
	const client = useQueryClient()
	const { pageId } = usePageScope()
	const [isPinReset, setIsPinReset] = useState(false)
	const [currentLink, setCurrentLink] = useState("")
	const [currentPin, setCurrentPin] = useState("")
	const {
		data: linkData,
		isFetching
	} = useQuery(["user", pageId, "sharable", { shouldResetPin: isPinReset }], (ctx) => {
		const pinReset = ctx.queryKey[ctx.queryKey.length - 1].shouldResetPin
		return API.get(`pages/${pageId}/sharable?newPin=${pinReset ? "1" : "0"}`).then(data => {
			return data
		})
	}, {
		staleTime: Infinity
	})

	useEffect(() => {
		if (linkData) {
			setCurrentLink(`https://public.everloom.io/links/${linkData.code}`)
			setCurrentPin(linkData.pin)
		}
	}, [linkData])

	useEffect(() => {
		if (isPinReset) {
			client.refetchQueries(["user", pageId, "sharable", { shouldResetPin: isPinReset }]).then(() => {
				client.invalidateQueries(["user", pageId, "sharable", { shouldResetPin: false }]).then(() => {
					setIsPinReset(false)
				})
			})
		}
	}, [isPinReset])

	return (
		<SettingsPage>
			<ModalLoader active={isFetching}/>
			<FormSectionTitle flip={445}>
				<div>
					Sharable Link
					<div style={{ marginTop: 4 }}/>
					<NoneTextInlineRegular>
						Use the link and pin below to share this Everloom. Refresh the pin at any time to re-secure your link.
						<br/>
						Access: <b>READ ONLY</b>
						{/*<b>Regenerating the link will invalidate all previous links (only one may exist at a time). </b>*/}
					</NoneTextInlineRegular>
					<div style={{ marginBottom: 8 }}/>
				</div>
				{/*<Button style={{ minWidth: 160 }} primary onClick={() => regenerateLink()} size={"small"} icon>*/}
				{/*	{!!currentLink ? "Regenerate" : "Generate"} Link*/}
				{/*</Button>*/}
			</FormSectionTitle>
			{currentLink && <div>
				<ClipCopy text={currentLink}>
					<CopyContainer justify={"space-between"}>
						{currentLink}
						<HoverableIcon style={{ marginLeft: 10, color: theme.primary }} name='copy'/>
					</CopyContainer>
				</ClipCopy>
				<ClipCopy text={currentPin} sendMessage={false}>
					<CopyContainer justify={"space-between"} style={{ marginTop: 12 }}>
						<FlexBox>
							<b style={{ marginRight: 12 }}>Your Pin:</b>
							<span style={{ letterSpacing: "8px" }}>
							{currentPin}
							</span>
						</FlexBox>
						<HoverableIcon style={{ marginLeft: 10, color: theme.primary }} onClick={() => setIsPinReset(true)}
													 name='redo'/>
						<HoverableIcon style={{ marginLeft: 10, color: theme.primary }} name='copy'/>
					</CopyContainer>
				</ClipCopy>
			</div>}
		</SettingsPage>
	)
}

export default SharableEverloom

const CopyContainer = styled(FlexBox)`
	width: 100%;
	background-color: #f8f8f8;
	border-radius: 4px;
	padding: 6px;
	position: relative;
	top: 8px;
`
