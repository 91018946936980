import React, {useEffect, useState} from 'react'
import {LogoPosition, SideNav} from "../styles/Layout.js";
import {ScrollableContainer} from "./PageNav.js";
import {useDispatch, useSelector} from "react-redux";
import Root from "../store/reducers/root/actions.js";
import {CONSTANTS} from "../config/constants.js";
import {ArrowBack, ChevronBackOutline} from "react-ionicons";
import HeirloomImage from "../components/common/HeirloomImage.js";
import useWindowSize from "../utils/dimensions.js";
import Logo from "@logo/themed/logo-icon.svg";
import Stepper from "../components/common/Stepper.js";
import {MainSideBar} from "./index.js";
import findGetParam from "../utils/findGetParam.js";
import {useHistory} from "react-router-dom";
import Auth from "../store/reducers/auth/actions.js";
import styled from "styled-components";
import AuthService from "../services/auth.js";

const steps = [
    {title: 'Add name and photo'},
    {title: 'Add biography details'},
    // {title: 'Add family'},
    {title: 'Finalize'}
]

const OnboardingLayout = ({children}) => {
    const history = useHistory();
    const sideNavOpen = useSelector(Root.navOpen);
    const rootDispatch = new Root(useDispatch())
    const {width} = useWindowSize();
    const [stepNum, setStepNum] = useState(1);
    const tokenData = useSelector(Auth.tokenData)
    const userDispatch = new Auth(useDispatch())

    useEffect(() => {
        AuthService.setTokens(tokenData);
        const unsub = history.listen(() => {
            let step = findGetParam('step', true);
            if (!step) {
                step = 1;
            }
            setStepNum(step);
        })
        return () => unsub();
    }, [])

    const logout = () => {
        userDispatch.logout()
        history.push('/');
    }

    return (
        <MainSideBar menuOpen={sideNavOpen && width >= CONSTANTS.collapsedSize}>
            <SideNav open={sideNavOpen} id={'onboarding-layout'}>
                <ScrollableContainer>
                    {width < CONSTANTS.collapsedSize &&
                    <ChevronBackOutline className={'back-chev'} onClick={() => rootDispatch.toggleSideNav(false)}/>}
                    <LogoPosition>
                        <HeirloomImage src={Logo}/>
                    </LogoPosition>
                    <div style={{marginTop: 20}}>
                        <Stepper options={steps} activeIndex={+stepNum - 1} indexSelected={(index) => {
                            history.push({
                                pathname: window.location.pathname,
                                search: '?step=' + (+index + 1)
                            })
                        }}/>
                    </div>
                </ScrollableContainer>
                <LogoutBtn onClick={logout}>
                    <ArrowBack/>
                    Logout
                </LogoutBtn>

            </SideNav>
            {width < CONSTANTS.collapsedSize &&
            <Stepper options={steps} activeIndex={+stepNum - 1}
                     reduced
                     indexSelected={(index) => {
                         history.push({
                             pathname: window.location.pathname,
                             search: '?step=' + (+index + 1)
                         })
                     }}/>
            }
            <div id={'layout-navigation'} className={'inner-container'}>
                <div className={'content'}>
                    {children}
                </div>
            </div>

        </MainSideBar>
    );
};

export default OnboardingLayout;

const LogoutBtn = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: ${props => props.theme.quaternary};
  font-weight: 500;
  cursor: pointer;

  span {
    position: relative;
    top: 6px;
    right: 10px;

    svg {
      color: ${props => props.theme.quaternary};
    }
  }

`;
