import {
    INITIAL_STATE,
    PAGE_LOADING,
    SET_ACCOUNT_SECTION,
    SET_CHANGE_ACCOUNT,
    SET_CHAPTER_NAV_SECTION,
    SET_CONFETTI, SET_DIMENSIONS,
    SET_ERROR,
    SET_FETCHED,
    SET_LOADING, SET_MANAGE_IMAGE,
    SET_PROMPT_PAYWALL,
    SET_TRANSFER_MODAL,
    SET_VIEW_UPGRADE, SHOW_NUX,
    TOGGLE_SIDE_NAV
} from "./initialState";
import { RESET_STATE } from "../common/reducer.class"

export const rootReducer = (state = INITIAL_STATE, action) => {
    const {payload, type, meta} = action;

    switch (type) {
        case PAGE_LOADING:
            return {
                ...state,
                pageLoading: payload
            };
        case SET_LOADING:
            return {
                ...state,
                loading: payload
            };
        case SET_ERROR:
            return {
                ...state,
                error: payload
            };
        case SET_FETCHED:
            return {
                ...state,
                fetchedAuth: payload
            }
        case SET_CHANGE_ACCOUNT:
            return {
                ...state,
                changeAccount: payload
            }
        case TOGGLE_SIDE_NAV: {
            return {
                ...state,
                navOpen: payload
            }
        }
        case SET_PROMPT_PAYWALL: {
            return {
                ...state,
                payWallPrompt: payload
            }
        }
        case SET_CONFETTI: {
            return {
                ...state,
                showConfetti: payload
            }
        }
        case SET_VIEW_UPGRADE: {
            return {
                ...state,
                viewUpgrade: payload
            }
        }
        case SET_ACCOUNT_SECTION: {
            return {
                ...state,
                accountSection: payload
            }
        }
        case SET_MANAGE_IMAGE: {
            return {
                ...state,
                manageImage: payload
            }
        }
        case SET_TRANSFER_MODAL:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    transfer: payload
                }
            }
        case SET_CHAPTER_NAV_SECTION:
            return {
                ...state,
                chapterNavSection: payload
            }
        case SHOW_NUX: {
            return {
                ...state,
                nux: payload
            }
        }
        case SET_DIMENSIONS: {
            return {
                ...state,
                dimensions: payload
            }
        }
        case RESET_STATE:
            return INITIAL_STATE;
        default:
            return state
    }
};
