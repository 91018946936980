import React from 'react'
import {Dropdown} from "semantic-ui-react";
import {formStyles, InputLabel} from "../../styles/Forms.js";
import {mappedOptions} from "../../config/options.map.js";

const FormDropDown = ({
                        name,
                        placeholder = '',
                        label,
                        value,
                        onChange,
                        containerStyle,
                        inputStyle,
                        labelStyle,
                        options,
                        formMargin = true,
                        search = false,
                        multiple = false,
                        loading = false,
                        onSearchChange = () => {
                        },
                        searchInput = ['text'],
                        clearable = false,
                        fluid = false
                      }) => {

  let styles = {...containerStyle, width: 177}
  if (formMargin) {
    styles = {...styles, ...formStyles.inputSideMargin}
  }
  if (fluid) {
    styles.width = '100%'
  }
  return (
    <div style={styles}>
      {!!label && <InputLabel style={{...labelStyle}}>{label}</InputLabel>}
      <Dropdown search={search} closeOnChange fluid value={value} inputStyle={{...inputStyle}}
                searchInput={searchInput} clearable={clearable}
                multiple={multiple}
                defaultUpward={false} upward={false}
                placeholder={placeholder} name={name} selection options={options || mappedOptions(name)}
                onChange={onChange} loading={loading} onSearchChange={onSearchChange}/>
    </div>
  );
};

export default FormDropDown;
