import React, {useEffect, useState} from 'react'
import SearchBar from "../../../layout/Header/Search.js";
import {Link} from "react-router-dom";
import {usePageScope, useTaggable} from "../../../store/hooks/page.hooks.js";
import firebase from "firebase/app";
import {Button, Card, Icon, Menu, Message, Modal} from "semantic-ui-react";
import {FlexBox} from "../../../styles/Containers.js";
import ModalLoader from "../../../components/common/ModalLoader.js";
import BiographyService from "../../../services/biography.js";

const LinkMember = ({onSelected, currentMember}) => {
  const {pageId} = usePageScope()
  let {data: options} = useTaggable()
  const [showInfo, setShowInfo] = useState(false)
  const [selection, setSelection] = useState(null)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const pc = selection && !selection.message ? selection.pageCode : currentMember && currentMember.pageCode ? currentMember.pageCode : null

  useEffect(() => {
    if (pc && !loading) {
      setLoading(true)
      BiographyService.isValidPageCode(pageId, pc)
        .then(data => {
          setError(data.failed)
          if (data.failed) {
            onSelected({pageCode: ''})
            setSelection(null)
          } else {
            onSelected({pageCode: pc})
            setSelection({
              ...selection,
              ...data
            })
          }
        }).finally(() => setLoading(false))
    }
  }, [selection, currentMember])

  const info = () => (
    <Modal open={showInfo}>
      <Modal.Header>Family Connections</Modal.Header>
      <Modal.Content>
        <p>
          When you link to someone’s Everloom their name and photo will be added to your Family and it will create a
          link to their Everloom.
        </p>
        <p>
          We will also notify them that they've been added as a family member and will have the option to add you back.
        </p>
        <p>
          Learn more about Family Connections in our
          <a target={"_blank"} href={process.env.REACT_APP_LANDING_URL + '/help'}> Help Center</a>.
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={() => setShowInfo(false)} style={{margin: '0 auto 0 auto'}}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )


  return (
    <div>
      {error && <Message negative>{error}</Message>}
      <h3>Link to an Existing Everloom
        {info()}
        <Menu.Item
          style={{marginLeft: 6}}
          icon={'info circle'}
          onClick={() => setShowInfo(true)}
        />
      </h3>

      {loading ? <ModalLoader active/> : selection ?
        <Card style={{padding: 10}}>
          <Icon name={'close'} style={{
            position: 'absolute',
            right: 10,
            cursor: 'pointer'
          }} onClick={() => {
            setSelection(null)
            onSelected({pageCode: ""})
          }}/>
          <FlexBox align={'center'}>
            <img src={selection?.image} style={{width: 60, height: 60, borderRadius: 30, marginRight: 16}}/>
            <div>
              <h3 style={{marginBottom: 0}}>{selection?.message}</h3>
              <h6 style={{
                marginTop: 8,
                marginBottom: 0,
                opacity: 0.5
              }}>Owner:{selection.permissions?.ownerName?.firstName} {selection.permissions?.ownerName?.lastName} </h6>
            </div>
          </FlexBox>
          <h5>Relationship</h5>
          <div style={{textTransform: 'capitalize'}}>
            {currentMember.relation?.relationship}
          </div>
          <h5>Relationship Type</h5>
          <div style={{textTransform: 'capitalize'}}>
            {currentMember.relation?.relationshipType}
          </div>
        </Card> : null}

      {!selection &&
      <>
        <SearchBar
          placeholder={"Select Everloom"}
          fluid
          action={(id) => {
            firebase.analytics().logEvent("member_linked");
            setSelection({
              pageCode: id,
              ...options.find(o => o.pageCode === id),
              firstName: options.find(o => o.pageCode === id).name.split(' ')[0],
              lastName: options.find(o => o.pageCode === id).name.split(' ')[1]
            })
          }}/>
        <h4>Can't find someone?</h4>
        <p>You can only link people who have an Everloom account that you've connected with.</p>
        <p>
          Go to the <strong><Link to={`/${pageId}/account`}>Manage</Link></strong> tab to connect with family members
          and
          return to link their Everloom.
        </p>
      </>}
    </div>
  );
};

export default LinkMember;
