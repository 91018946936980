import {
    PAGE_LOADING,
    SET_ACCOUNT_SECTION,
    SET_CHANGE_ACCOUNT, SET_CHAPTER_NAV_SECTION, SET_CONFETTI, SET_DIMENSIONS,
    SET_ERROR,
    SET_FETCHED,
    SET_LOADING, SET_MANAGE_IMAGE, SET_PROMPT_PAYWALL,
    SET_TRANSFER_MODAL, SET_VIEW_UPGRADE, SHOW_NUX, TOGGLE_SIDE_NAV
} from "./initialState"
import BaseReducer from "../common/reducer.class"


class Root extends BaseReducer {

    setPageLoading = ({value}) => {
        this.dispatch({
            type: PAGE_LOADING,
            payload: value
        })
    }

    manageImage = (imageData) => {
        this.dispatch({
            type: SET_MANAGE_IMAGE,
            payload: imageData
        })
    }

    setMessage = (value) => {
        this.setError(value)
    }

    promptPaywall = (value) => {
        this.dispatch({
            type: SET_PROMPT_PAYWALL,
            payload: value
        })
    }

    toggleSideNav = (value) => {
        this.dispatch({
            type: TOGGLE_SIDE_NAV,
            payload: value
        })
    }

    setTransferPageModal = ({value}) => {
        this.dispatch({
            type: SET_TRANSFER_MODAL,
            payload: value
        })
    }

    setAccountSection = (value) => {
        this.dispatch({
            type: SET_ACCOUNT_SECTION,
            payload: value
        })
    }

    setViewUpgrade = (value) => {
        this.dispatch({
            type: SET_VIEW_UPGRADE,
            payload: value
        })
    }

    setConfetti = (duration) => {
        this.dispatch({
            type: SET_CONFETTI,
            payload: true
        })
        setTimeout(() => {
            this.dispatch({
                type: SET_CONFETTI,
                payload: false
            })
        }, duration);
    }

    setDataFetched = ({value}) => {
        this.dispatch({
            type: SET_FETCHED,
            payload: value
        })
    }

    setChangeAccount = (val = true) => {
        this.dispatch({
            type: SET_CHANGE_ACCOUNT,
            payload: val
        })
    }

    setChapterNavSection = (value) => {
        this.dispatch({
            type: SET_CHAPTER_NAV_SECTION,
            payload: value
        })
    }

    setShowNux = (value) => {
        this.dispatch({
            type: SHOW_NUX,
            payload: value
        })
    }

    setDimensions = (value) => {
        this.dispatch({
            type: SET_DIMENSIONS,
            payload: value
        })
    }

    constructor(dispatch) {
        super({
            dispatch, setErrorConst: SET_ERROR, setLoadingConst: SET_LOADING,
            type: "root"
        })
    }

    static savedDimensions = state => state.root.dimensions;
    static pageLoading = state => state.root.pageLoading;
    static isFetched = state => state.root.fetchedAuth;
    static changingAccount = state => state.root.changeAccount;
    static modals = state => state.root.modals;
    static accountSection = state => state.root.accountSection;
    static navOpen = state => state.root.navOpen;
    static chapterNavSection = state => state.root.chapterNavSection
    static payWallPrompt = state => state.root.payWallPrompt
    static showConfetti = state => state.root.showConfetti
    static viewUpgrade = state => state.root.viewUpgrade;
    static manageImage = state => state.root.manageImage;
    static getLang = state => state.root.language;
    static showNux = state => state.root.nux
    static outerNode = state => {
        return document.getElementById('edit-image-container') || document.body
    }

}

export default Root
