import React from "react"
import { Button, Modal } from "semantic-ui-react"
import FormInput from "../../../components/common/FormInput.js"
import { WhiteIconButton } from "../../../styles/Text.js"
import { validateEmail } from "../../../utils/validateEmail.js"
import { PagesService } from "../../../services/pages.js"
import ConfirmationModal from "../../../layout/Modals/confirm.js"
import firebase from "firebase";

const TransferOwnership = ({ transferOwnership, setTransferOwnership, transferSuccess }) => {
	const [transferEmail, setTransferEmail] = React.useState("")
	const [confirmation, setConfirmation] = React.useState(false)
	const [loading, setLoading] = React.useState(false)
	const [emailError, setEmailError] = React.useState(false)
	const [error, setError] = React.useState("")

	const transferPage = () => {
		setLoading(true)
		PagesService.transferPage(transferOwnership.pageId, {
			email: transferEmail
		})
			.then(() => {
				firebase.analytics().logEvent("page_transferred");
				cancel()
				return transferSuccess()
			})
			.catch(e => {
				setConfirmation(false)
				setError(e)
			})
			.finally(() => setLoading(false))
	}

	const validate = () => {
		if (validateEmail(transferEmail)) {
			setConfirmation(true)
		} else {
			setEmailError(true)
		}
	}

	const cancel = () => {
		setConfirmation(false)
		setTransferOwnership(null)
		setTransferEmail("")
		setEmailError(false)
		setError("")
	}

	return (
		<div>
			<ConfirmationModal
				size={"mini"}
				onAccept={transferPage}
				onDecline={cancel}
				open={!!confirmation}
				loading={loading}
				body={`Are you sure you would like to transfer ownership of ${transferOwnership?.brief?.firstName} ${transferOwnership?.brief?.lastName}'s page to ${transferEmail}?`}
				text={{
					no: "Cancel",
					yes: "Yes"
				}}/>

			<Modal open={!!transferOwnership} size={"mini"}>
				<Modal.Header>
					<div style={{ flex: 1 }}>Transfer Ownership</div>
					{!!error && <div style={{ color: "red", fontWeight: "400", fontSize: 14 }}>{error}</div>}
				</Modal.Header>
				<Modal.Content>
					<p>Enter the email of the person you would like to transfer this page to.</p>
					<FormInput error={emailError ? "Invalid email specified" : null} fluid
										 onChange={(ev, { value }) => setTransferEmail(value)} value={transferEmail}
										 placeholder={"Email"} formMargin={false}/>
				</Modal.Content>
				<Modal.Actions>
					<Button color='black' onClick={cancel} disabled={loading}>
						Cancel
					</Button>
					<WhiteIconButton
						content="Confirm"
						labelPosition='right'
						icon='checkmark'
						onClick={() => validate(false)}
						positive
					/>
				</Modal.Actions>
			</Modal>
		</div>
	)
}

export default TransferOwnership
