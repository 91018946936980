import {
    INITIAL_STATE,
    SET_ERROR, SET_FORM,
    SET_LOADING,
    SET_FORM_ERRORS
} from "./initialState";
import { RESET_STATE } from "../common/reducer.class"

export const onboardingReducer = (state = INITIAL_STATE, action) => {
    const {payload, type, meta} = action;

    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                loading: payload
            };
        case SET_ERROR:
            return {
                ...state,
                error: payload
            };
        case SET_FORM:
            return {
                ...state,
                form: {
                    ...state.form,
                    ...payload
                }
            }
        case SET_FORM_ERRORS: {
            return {
                ...state,
                formErrors: payload
            }
        }
        case RESET_STATE:
            return INITIAL_STATE;
        default:
            return state
    }
};
