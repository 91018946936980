import { PATCH_USER, SET_EMAIL_SETTINGS, SET_ERROR, SET_LOADING, SET_USER } from "./initialState"
import BaseReducer from "../common/reducer.class"
import { getAllReducers } from "../../index.js"
import NotificationService from "../../../services/notifications.js"
import { RESET_STATE } from "../common/reducer.class"


class Auth extends BaseReducer {

	constructor(dispatch) {
		super({
			dispatch, setErrorConst: SET_ERROR, setLoadingConst: SET_LOADING,
			type: "auth"
		})
	}

	setUser = (props) => {
		this.dispatch({
			type: SET_USER,
			payload: props
		})
	}

	patchUserLocal = props => {
		this.dispatch({
			type: PATCH_USER,
			payload: props
		})
	}

	updateNotificationSettings = ({ checked, key }, pastSettings) => {
		if (pastSettings.indexOf(key) > -1 && checked === false) {
			pastSettings.splice(pastSettings.indexOf(key), 1)
		} else if (pastSettings.indexOf(key) === -1 && checked === true) {
			pastSettings.push(key)
		}
		this.dispatch({
			type: SET_EMAIL_SETTINGS,
			payload: pastSettings
		})
		return NotificationService.updatePreferences(key).then(res => {
			this.dispatch({
				type: SET_EMAIL_SETTINGS,
				payload: res
			})
		}).catch(err => {
			this.setError(err)
		})
	}

	logout = () => {
		localStorage.clear()
		const reducers = getAllReducers(this.dispatch)
		for (const r in reducers) {
			reducers[r].dispatch({ type: RESET_STATE })
		}
		this.dispatch({
			type: SET_USER,
			payload: null
		})
	}

	static user = state => state.auth.user
	static isAuthenticated = state => !!localStorage.getItem("@heirloom:tokens") && JSON.parse(localStorage.getItem("@heirloom:tokens")).token
	static tokenData = state => ({
		token: state.auth.token,
		refresh: state.auth.refresh
	})
}

export default Auth
