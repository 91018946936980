import React, { useState } from "react"
import { FlexBox } from "../../styles/Containers"
import Pill from "../common/Pill"
import FormDatePicker from "../common/FormDatePicker"
import FormLocationPicker from "../common/FormLocationPicker"
import { Form, TextArea } from "semantic-ui-react"
import TagManager from "../../pages/Chapters/ChapterTags"
import { ActionRoundButton } from "../../styles/Semantic.js"
import { Add } from "react-ionicons"
import firebase from "firebase/app";

const ImageDataForm = ({ data, setData }) => {
	const [openTags, setOpenTags] = useState(false)

	return (
		<div>
			<TagManager currentTags={(data.tags || [])} setOpenTags={setOpenTags} openTags={openTags}
									setTags={tags => setData({ ...data, tags })}/>

			<FormDatePicker formMargin={false} placeholder={"Date of picture..."}
											value={data.date}
											onChange={(_, { value }) => setData({ ...data, date: value })}
											containerStyle={{ width: "100%" }}/>
			<FormLocationPicker
				setWidth={276}
				containerStyle={{ width: "100%", marginTop: 6 }}
				formMargin={false} placeholder={"Add location"}
				value={data.place || ""}
				onChange={(_, { value }) => setData({ ...data, place: value })}/>
			<Form>
				<TextArea
					onChange={(_, { value }) => setData({ ...data, description: value })}
					style={{ width: "100%", resize: "none", marginTop: 6 }}
					placeholder='Caption'
					value={data.description}/>
			</Form>


			<FlexBox align={'center'}>
				<strong style={{marginRight: 16}}>Tag People</strong>
				<ActionRoundButton primary onClick={() => {
					firebase.analytics().logEvent("viewing_media_tag_manager");
					setOpenTags(true)
				}}>
					<Add />
				</ActionRoundButton>
			</FlexBox>


			<FlexBox style={{ width: "100%", marginTop: 12 }}>
				{((data.tags || []).map((p, i) => (
					<Pill {...p} cleared={() => {
						const tags = (data.tags || [])
						tags.splice(i, 1)
						setData({ ...data, tags })
					}}>{p.name}</Pill>
				)))}
			</FlexBox>

		</div>
	)
}

export default ImageDataForm
