import { INITIAL_STATE, SET_CONTACT_BOOK, SET_ERROR, SET_LOADING, SET_MY_FAMILY, SET_MY_TREE } from "./initialState"
import { RESET_STATE } from "../common/reducer.class"

export const treeReducer = (state = INITIAL_STATE, action) => {
	const { payload, type, meta } = action

	switch (type) {
		case SET_LOADING:
			return {
				...state,
				loading: payload
			}
		case SET_ERROR:
			return {
				...state,
				error: payload
			}
		case SET_MY_TREE:
			return {
				...state,
				myTree: {
					...state.myTree,
					[payload.pageId]: payload.data
				}
			}
		case SET_MY_FAMILY: {
			return {
				...state,
				family: {
					...state.family,
					[payload.pageId]: payload.data
				}
			}
		}

		case SET_CONTACT_BOOK:
			if (!payload.data || typeof payload.data !== "object") {
				return state
			}
			return {
				...state,
				contactBook: {
					...state.contactBook,
					[payload.pageId]: payload.data
				}
			}
		case RESET_STATE:
			return INITIAL_STATE
		default:
			return state
	}
}
