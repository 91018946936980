import React from "react"
import styled from "styled-components"
import { FlexBox } from "../../styles/Containers.js"
import { VisibleViewTypes } from "../../components/common/GalleryList.js"
import MediaService from "../../services/media.js"
import { CloseOutline } from "react-ionicons"
import Media from "../../store/reducers/media/actions"
import { useDispatch } from "react-redux"
import {Button} from "semantic-ui-react";
import GalleryIcon from '../../assets/icons/album.svg'

const NewGallery = ({ width, gallery, removeImageIndex, setAddMedia }) => {
	const MediaDispatch = new Media(useDispatch())
	return (
		<>
			<FlexBox justify={'space-between'} align={'flex-end'} style={{marginTop: 30}}>
				<div>
					<h4>Chapter Album</h4>
				</div>
				{!!setAddMedia && <Button primary onClick={() => setAddMedia('GALLERY')}>
					Add to Album
				</Button>}
			</FlexBox>
			<GalleryContainer width={width}>
				{gallery.length === 0 && <CenterTitle>
					<img src={GalleryIcon} style={{width: 100}} />
				</CenterTitle>}
				<FlexBox style={{ padding: gallery.length === 0 ? 0 : 10 }} align={"center"}>
					{gallery.map((img, index) => {
						const extension = MediaService.getType(img)
						return (
							<GalleryView style={{ marginTop: 20 }} key={index + "-img-gal"}>
								<RemoveImage onClick={() => {
									removeImageIndex(index)
								}}/>
								<VisibleViewTypes clicked={() => MediaDispatch.setViewImage(gallery[index], [])} data={img}
																	extension={extension}/>
							</GalleryView>
						)
					})}

				</FlexBox>
			</GalleryContainer>
		</>
	)
}

export default NewGallery

const RemoveImage = styled(CloseOutline)`
	position: absolute;
	top: -8px;
	background-color: rgba(255, 255, 255, 0.9);
	border-radius: 20px;
	border: thin solid #999999;
	cursor: pointer;

	svg {
		fill: #999999;
	}
`

const GalleryView = styled.div`
	position: relative;

	img {
		margin-left: 10px;
		margin-right: 10px;
		height: 170px;
	}
`

export const GalleryContainer = styled.div`
	border: 1px solid #ccc;
	height: 200px;
	width: 100%;
	margin-top: ${props => props.width > 771 ? 4 : props.width > 447 ? 40 : 60}px;
	overflow-x: auto;
	position: relative;
	opacity: ${props => props.light ? 0.5 : 1};

`

export const CenterTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	opacity: 0.6;
`
