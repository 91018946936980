import React from 'react'
import {formStyles, InputLabel} from "../../styles/Forms.js";
import DatePicker from "react-date-picker";
import * as moment from "moment";
import styled from "styled-components";
import {Icon} from "semantic-ui-react";
import {CONSTANTS} from "../../config/constants.js";
import {NoneTextInline} from "../../styles/Text.js";

const FormDatePicker = ({
                            name,
                            placeholder = '',
                            label,
                            value,
                            onChange,
                            containerStyle,
                            inputStyle,
                            labelStyle,
                            isForm,
                            formMargin = true,
                            viewOnly
                        }) => {

    let styles = {...containerStyle}
    if (formMargin) {
        styles = {...styles, ...formStyles.inputSideMargin}
    }
    let date = value && moment(value, CONSTANTS.dateFormat).isValid() ? moment(value, CONSTANTS.dateFormat).toDate() : null;

    if (!date && viewOnly) {
        return <div />
    }
    return (
        <div>
            {viewOnly && <DatePickerContainer style={styles} isForm>
                <div>{date ? moment(date).format('MMM, DD YYYY') : <NoneTextInline>None</NoneTextInline>}</div>
            </DatePickerContainer>
            }
            {!viewOnly && <DatePickerContainer style={styles} isForm>
                {!!label && <InputLabel style={{...labelStyle}}>{label}</InputLabel>}
                <DatePicker
                    onChange={date => onChange(null, {name, value: moment(date).format(CONSTANTS.dateFormat)})}
                    value={date}
                    placeholder={placeholder}
                    clearIcon={null}
                    dayPlaceholder={'DD'}
                    monthPlaceholder={'MM'}
                    yearPlaceholder={'YYYY'}
                    calendarIcon={<Icon name={'calendar'} style={{opacity: 0.8}}/>}
                    name={name} inputStyle={{...inputStyle}}
                />
            </DatePickerContainer>}
        </div>
    );
};

export default FormDatePicker;

const DatePickerContainer = styled.div`
  margin-top: 8px;
  min-width: 177px;

  .react-date-picker {
    height: 38px;
    min-width: 177px;
    ${props => props.isForm && `
        margin-top: 5px;
    `}
  }

  .react-date-picker__wrapper {
    border-radius: 0.28571429rem;
    border: 1px solid rgba(34, 36, 38, 0.15);
    color: rgba(0, 0, 0, 0.87);
  }


  span {
    line-height: 38px;
  }

  .react-date-picker__inputGroup {
    input {
      min-width: 40px;
      text-align: center;
      ${props => props.isForm && `
  border: none !important;
  height: 17px;
`}
    }
  }

`;
