import React, {useEffect, useState} from "react"
import findGetParam from "../../utils/findGetParam.js"
import LogoLoader from "../../components/common/LogoLoader.js"
import styled from "styled-components"
import {FlexBox} from "../../styles/Containers.js"
import AuthService from "../../services/auth.js"
import {MailIcon} from "./VerifyEmail.js"

let attemptedVerification = false
let success = false
const RunEmailVerification = ({}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [failed, setFailed] = useState(false)

  const emailCode = findGetParam("verification", true)
  const user = findGetParam("user", true)

  useEffect(() => {
    if (emailCode && user && !attemptedVerification) {
      runCheck()
    } else {
      setFailed(true)
    }
  }, [emailCode, user])

  const runCheck = async () => {
    setIsLoading(true)
    try {
      await AuthService.verifyEmail(user, emailCode)
    } catch (e) {
      if (!success) {
        setFailed(true)
      }
    }
    setIsLoading(false)
  }

  return (
    <div style={{padding: 20}}>

      {!isLoading ?
        failed ?
          <div style={{textAlign: "center", marginTop: 50}}>
            <MailIcon/>
            <h2>There was an error verifying your email!</h2>
          </div>
          :
          <div style={{textAlign: "center", marginTop: 50}}>
            <MailIcon/>
            <h2>Thank you for verifying your email!</h2>
            <h4>You can now close this tab</h4>
          </div>

        :
        <div style={{textAlign: "center", marginTop: 50}}>
          <LogoLoader alt/>
          <h4>
            Verifying email...
          </h4>
        </div>}
    </div>
  )
}

export default RunEmailVerification

const CenterContainer = styled(FlexBox)`
  height: 100%;
`
