import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { FlexBox, SettingsPage } from "../../styles/Containers.js"
import { Checkbox, Grid } from "semantic-ui-react"
import { mappedOptions } from "../../config/options.map.js"
import Auth from "../../store/reducers/auth/actions.js"
import { FormSectionTitle } from "../../styles/Forms"

const NotificationsAccount = ({}) => {

	const AuthDispatch = new Auth(useDispatch())
	const settings = useSelector(Auth.user)?.notificationPermissions || []

	const updateEmailPreference = (checked, key) => {
		AuthDispatch.updateNotificationSettings({ checked, key }, settings)
	}
	return (
		<SettingsPage>
			<FormSectionTitle>
				Email Preferences
			</FormSectionTitle>
			<Grid columns={1} style={styles.contentMargin}>
				{mappedOptions("notifications").map(notif => (
					<Grid.Row>
						<Grid.Column>
							<FlexBox justify={"space-between"}>
								{notif.name}

								<Checkbox toggle key={notif.key}
													checked={settings.indexOf(notif.key) > -1}
													onChange={(_, { checked }) => updateEmailPreference(checked, notif.key)}/>
							</FlexBox>
						</Grid.Column>
					</Grid.Row>
				))}
			</Grid>
		</SettingsPage>
	)
}

export default NotificationsAccount

const styles = {
	contentMargin: {
		marginTop: 12
	}
}
