import styled from "styled-components"
import { Icon, Loader, Progress, Radio, Segment, Table } from "semantic-ui-react"
import { ScrollbarStyles } from "./Containers"
import { CONSTANTS } from "../config/constants"

export const ImageProgress = styled(Progress)`
	z-index: 100000;
	width: 100%;
	border-radius: .28571429rem !important;

	.bar {
		border-radius: .28571429rem !important;
	}

	margin: ${props => props.top ? "0" : "0em 0em 2.5em"} !important;

	${props => props.button ? `
    position: absolute !important;
    opacity: 0.7;
    width: 100% !important;
    .bar {
      height: 36px !important;
    }
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  ` : {
		bottom: "-22",
		position: "absolute"
	}
	}
`

export const ContainerProgress = styled(Progress)`
	position: absolute !important;
	top: 0;
	bottom: 0;
	margin: 0 !important;
	border-radius: 0 !important;
	right: 0;
	left: 0;

	.bar {
		height: 100% !important;
		opacity: 0.7 !important;
	}
`

export const InfoIcon = styled(Icon)`
	background: gainsboro;
`

export const CustomSegment = styled(Segment)`
	transition: height 0.15s ease-out;

	${props => props.expanded ? {
		height: 100
	} : {
		height: 49
	}}
	overflow: hidden;

	&:hover {
		cursor: pointer;
		background-color: ${props => props.theme.tertiary};
	}
`

export const DarkLoader = styled(Loader)`
	&:before {
		border-color: ${props => props.theme.primary} !important;
	}
`

export const SelectableSegment = styled(Segment)`
	cursor: pointer;

	&:hover {
		border: thin solid ${props => props.theme.quaternary};
	}
`
export const SemanticChip = styled.div`
	user-select: none;
	display: inline-block;
	vertical-align: top;
	white-space: normal;
	font-size: 1em;
	padding: .35714286em .78571429em;
	margin: .14285714rem .28571429rem .14285714rem 0;
	box-shadow: 0 0 0 1px rgb(34 36 38 / 15%) inset;
	font-weight: 700;
	border: 0 solid transparent;
	border-radius: .28571429rem;
	background-color: #e8e8e8;
	line-height: 1;
	transition: background .1s ease;

	i {
		opacity: 0.4;
		cursor: pointer;
	}
`

export const CustomTable = styled(Table)`
	border: none !important;

	thead, tbody {
		display: block;
	}

	table, tr {
		width: 100%; /* Optional */
		display: inline-table;
	}

	tbody td, thead th {
		width: 33.33%; /* Optional */
	}

	tbody {
		max-height: 400px;
		overflow: auto;
		${ScrollbarStyles}
	}

	th {
		background-color: transparent !important;
	}
`

export const ActionSegment = styled(Segment)`
	&:hover {
		border: thin solid ${props => props.theme.primary};
		cursor: pointer;
	}
`

export const ActionButton = styled.div`
	background-color: white;
	border-radius: 4px;
	font-size: 12px;
	display: flex;
	align-items: center;
	justify-content: center;

	border: thin solid #E5E5E5;

	color: ${props => props.warn ? "red" : "black"};

	padding: 6px 12px 6px 12px;

	${props => props.auto ? { width: "fit-content" } : {}}
	&:hover {
		border-color: ${props => props.theme.primary};
		cursor: pointer;
	}

	i {
		margin-right: 6px;
	}

	${props => props.size === "medium" ? { minHeight: 34 } : {}}
`

export const ActionRoundButton = styled.div`
	background-color: ${props => props.primary ? props.theme.primary : "white"};
	width: 30px;
	height: 30px;
	border-radius: 30px;
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
	margin-right: 10px;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&:hover {
		opacity: 0.6;
	}

	svg {
		color: ${props => props.primary ? "white" : "black"};
	}
`

export const ActionIcon = styled(Icon)`
	opacity: .95;

	&:hover {
		cursor: pointer;
		opacity: 0.7;
	}
`

export const EditProfileModalStyles = (height, width) => ({
	width: CONSTANTS.toSmallToEdit,
	maxHeight: height - 24,
	maxWidth: '100%'
})

export const CustomToggle = ({ ...props }) => {
	return <RadioContainer><Radio {...props} toggle/> </RadioContainer>
}

export const RadioContainer = styled.div`

	.ui.toggle.checkbox input:checked ~ .box:before, .ui.toggle.checkbox input:checked ~ label:before {
		background-color: ${props => props.theme.primary} !important;
	}

	.ui.checkbox label:before {
		max-width: 40px;
	}

	.ui.checkbox .box:before, .ui.checkbox label:before {
		background: ${props => props.theme.alert} !important;
	}

	.ui.toggle.checkbox input:checked ~ .box:after, .ui.toggle.checkbox input:checked ~ label:after {
		left: 20px;
	}
`
