import * as React from "react"
import Settings from "./Settings.js"
import MyPages from "./MyPages.js"
import Screen from "../../layout/Screen"
import { useDispatch, useSelector } from "react-redux"
import Permissions from "./Permissions.js"
import NotificationsAccount from "./Notifications.js"
import { Grid } from "semantic-ui-react"
import Billing from "./Billing.js"
import Root from "../../store/reducers/root/actions.js"
import GiftPage from "./GiftPage.js"
import styled from "styled-components"
import PermissionsService from "../../services/permissions.js"
import BeneficiarySection from "./pageSettings/BeneficiarySection.js"
import BeneficiaryRequests from "./pageSettings/BeneficiaryRequests.js"
import Auth from "../../store/reducers/auth/actions.js"
import PageSettings from "./pageSettings"
import DeleteAccount from "./DeleteAccount"
import ReferralsTable from "./Referrals.js"
import {useEffect, useState} from "react";
import {usePageScope} from "../../store/hooks/page.hooks.js";

const AccountComponent = ({ pageSpecific }) => {
	const dispatch = useDispatch()
	const RootDispatch = new Root(dispatch)
	const [error, setError] = useState(null)
	const [success, setSuccess] = useState(null)
	const [loading, setLoading] = useState(false)
	const { page, permissions } = usePageScope()
	const user = useSelector(Auth.user)
	const canEdit = PermissionsService.checkPermissions(permissions, "canEdit")
	const isBeneficiary = PermissionsService.checkPermissions(permissions, "isOnlyBeneficiary")

	useEffect(() => {
		if (error) {
			RootDispatch.setMessage({
				type: "error",
				message: error
			})
			setError(null)
		}
		if (success) {
			RootDispatch.setMessage({
				type: "success",
				message: success
			})
			setSuccess(null)
		}
	}, [error, success])

	if ((!canEdit && !isBeneficiary) && pageSpecific) {
		return (
			<AccountScreen>
				<Screen/>
			</AccountScreen>
		)
	}

	if ((isBeneficiary && !canEdit) && pageSpecific) {
		return (
			<AccountScreen>
				<div style={{ height: 20 }}/>
				<Permissions/>
				<BeneficiarySection/>
			</AccountScreen>
		)
	}

	return (
		<AccountScreen>
			<Screen>
				{pageSpecific && <PageSettings/>}
				{!pageSpecific &&
				<>
					<Grid stackable doubling columns={2}>
						<Grid.Column style={{ paddingLeft: 0 }}>
							<Settings setSuccess={setSuccess} setError={setError} setLoading={setLoading}/>
							<ReferralsTable />
						</Grid.Column>
						<Grid.Column>
							<GiftPage/>
							<MyPages transfers={false} loading={loading} setLoading={setLoading}/>
							{user?.beneficiaryRequested.length > 0 && <BeneficiaryRequests/>}
							<NotificationsAccount/>
							<Billing/>
						</Grid.Column>
					</Grid>
					<DeleteAccount/>
				</>
				}
			</Screen>
		</AccountScreen>
	)
}

export default AccountComponent

const AccountScreen = styled.div`
	@media only screen and (max-width: 700px) {
		.page-screen {
			padding: 0 !important;
		}

		.gift-settings {
			margin-top: 0;
		}

		.billing-settings {
			padding: 20px;
		}
	}
`
