import styled from "styled-components"
import {FlexBox} from "../../styles/Containers"

export const collapseHeight = 880

export const MediaViewerDimmer = styled.div`
  position: fixed;
  background-color: rgb(0, 0, 0, 0.9);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 320px 60px 60px;

  iframe {
    border: none;
    flex: 1;
    height: 100%;
    width: 100%;
  }

  video {
    width: 85%;
  }

  img, video, iframe {
    max-height: calc(100vh * 0.8);
    max-width: calc(100vw - 240px);
  }

  .media-container {
    height: calc(100vh * 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (min-width: 540px) and (max-width: ${collapseHeight}px) {
    padding: 30px 16px 320px 16px ;
    .media-container {
      height: calc(100vh - 360px);
    }

    img, video, iframe {
      max-height: calc(100vh - 360px);
      max-width: calc(100vw - 320px);
    }
  }

  @media only screen and (max-width: ${539}px) {
    padding: 16px 16px 320px 16px ;
    img, video, iframe {
      max-height: calc(100vh - 360px);
      max-width: 100%
    }
  }

`

export const DetailContainer = styled.div`
  width: 240px;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999999;
  @media only screen and (max-width: ${collapseHeight}px) {
    width: 100%;
    left: 0;
    height: 240px;
    right: 0;
    margin-top: auto;
    margin-bottom: 0;
    overflow: auto;
  }
`

export const DetailsBox = styled.div`
  padding: 12px;
  margin: auto;
  background-color: white;
  border-radius: 4px;
  height: 100%;
  width: 240px;

  @media only screen and (max-width: ${collapseHeight}px) {
    width: 100%;
  }

  .ui.loader:after {
    border-color: #000 transparent transparent !important;
  }
`

export const ImageViewerContainer = styled.div`
  display: flex;
`

export const ChapterDetailsTitle = styled(FlexBox)`
  justify-content: space-evenly;
  width: calc(100vw - 240px);
  max-height: 30px;
  height: 30px;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  color: white;
  margin-bottom: 20px;

  position: relative;

  @media only screen and (max-width: ${collapseHeight}px) {
    width: calc(100vw * 0.8);
  }
`

export const ActionArrows = styled.div`
  svg {
    color: white;
  }

  svg:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`

export const CloseImg = styled.div`
  color: white;
  position: absolute;
  right: 15px;

  img {
    cursor: pointer;
  }
`
