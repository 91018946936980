import React from 'react'
import styled from "styled-components";
import {Link, useHistory} from "react-router-dom";

const AuthTabs = ({}) => {
  const history = useHistory()

  const isLogin = history.location.pathname === '/login'

  return (
    <TabContainer>
      <div style={isLogin ? {backgroundColor: '#eeeeee', fontWeight: 'bold'} : {}}>
        <Link to={'/login'}>Sign In</Link>
      </div>
      <div style={!isLogin ? {backgroundColor: '#eeeeee', fontWeight: 'bold'} : {}}>
        <Link to={'/sign-up'}>Join Free</Link>
      </div>
    </TabContainer>
  );
};

export default AuthTabs;

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 40px;

  div:hover {
    background-color: #eeeeee;
    cursor: pointer;
  }

  div {
    width: 100%;
    height: 40px;
  }

  a {
    width: 100%;
    height: 40px;
    color: unset;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
