import {
	INITIAL_STATE,
	PATCH_USER,
	SET_EMAIL_SETTINGS,
	SET_ERROR,
	SET_LOADING,
	SET_TOKENS,
	SET_USER
} from "./initialState"
import { RESET_STATE } from "../common/reducer.class"

export const authReducer = (state = INITIAL_STATE, action) => {
    const {payload, type, meta} = action

    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                loading: payload
            }
        case SET_ERROR:
            return {
                ...state,
                error: payload
            }
        case SET_TOKENS:
            return {
                ...state,
                refresh: payload.refreshToken,
                token: payload.token
            }
        case PATCH_USER: {
            return {
                ...state,
                user: {
                    ...state.user,
                    ...payload
                }
            }
        }
        case SET_EMAIL_SETTINGS: {
            return {
                ...state,
                user: {
                    ...state.user,
                    notificationPermissions: payload
                }
            }
        }

        case SET_USER:
            if (!payload) {
                return {
                    ...state,
                    user: null
                }
            }
            const  {value, fetched} = payload;
            const st = {...state};
            if (!fetched) {
				st.token = value.token
				st.refresh = value.refreshToken
				st.user = value.user
            } else {
            	st.user = value
			}
            return st
        case RESET_STATE:
            return INITIAL_STATE;
        default:
            return state
    }
}
