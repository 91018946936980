import React from 'react'
import {Message} from "semantic-ui-react";

const MessageData = ({error, success, message, containerStyle}) => {

    const showableMessage = (message) => {
        if (!message) {
            return false;
        }
        if (typeof message === 'string') {
            if (message.includes('{}') || message.includes('TypeError')) {
                return null
            } else {
                return message;
            }
        } else {
            console.log(error.message)
            return JSON.stringify(error.message)
        }
    }

    return (
        <div style={containerStyle}>
            {!!showableMessage(error?.message) && <Message negative>
                {!!error.header &&
                <Message.Header>{typeof error.header === 'string' ? error.header : JSON.stringify(error.header)}</Message.Header>}
                <p>
                    {showableMessage(error.message)}
                </p>
            </Message>
            }

            {!!showableMessage(success?.message) && <Message positive>
                {!!success.header &&
                <Message.Header>{typeof success.header === 'string' ? success.header : JSON.stringify(success.header)}</Message.Header>}
                <p>
                    {showableMessage(success.message)}
                </p>
            </Message>
            }

            {!!showableMessage(message?.message) && <Message>
                {!!message.header &&
                <Message.Header>{typeof message.header === 'string' ? message.header : JSON.stringify(message.header)}</Message.Header>}
                <p>
                    {showableMessage(message.message)}
                </p>
            </Message>
            }
        </div>
    );
};

export default MessageData;
