import API from "./api.js";

export default class NotificationService {
  static base = 'notifications'
  static connection_open = false;

  static notificationData = {}

  static updatePreferences(key) {
    return API.put(NotificationService.base + "/settings/" + key + '/toggle');
  }

  static loadMyNotifications(pageId, lastKey = null, totalCount = 0) {
    if (!pageId) return new Promise((r, rj) => rj());
    return API.post(`${NotificationService.base}/${pageId}/list`, {lastKey, totalCount});
  }

  static clearNotification(pageId) {
    return API.post(`${NotificationService.base}/${pageId}/clear`);
  }

}
