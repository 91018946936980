import React, {useEffect, useState} from "react"
import {Button, Modal} from "semantic-ui-react"
import {WhiteIconButton} from "../../../styles/Text.js"
import NotificationService from "../../../services/notifications.js"
import BiographyService from "../../../services/biography.js"
import customTheme from "../../../styles/theme.js"
import findGetParam from "../../../utils/findGetParam.js"
import GenericRelativeForm from "./GenericRelativeForm.js"
import AddPageInline from "../AddPageInline.js"
import {useDispatch, useSelector} from "react-redux"
import Biography from "../../../store/reducers/biography/actions.js"
import LinkMember from "./LinkMember.js";
import {usePageScope} from "../../../store/hooks/page.hooks.js";
import firebase from "firebase/app";
import InviteUser from "../../Account/pageSettings/InviteUser.js";

const typeMap = {
  "parents": "Parent",
  "children": "Child",
  "siblings": "Sibling",
  "spouse": "Spouse"
}

const AddRelativeModal = ({
                            type,
                            edit,
                            setClose,
                            isOpen,
                            saveForm,
                            relatives,
                            reduced,
                          }) => {
  const bioDispatch = new Biography(useDispatch())
  const [invite, setInvite] = useState(false)
  const [relativeForm, setRelativeForm] = useState({})
  const [errors, setErrors] = useState({})
  const [subMessage, setSubMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [linkingMember, setLinkingMember] = useState(false);
  const pageCreation = useSelector(Biography.openPageCreation)
  const [notFound, setNotFound] = useState(false)
  const {pageId} = usePageScope()

  useEffect(() => {
    const newUid = new Date().getTime().toString().slice(-5)
    if (!!edit) {
      if (!edit.uid) {
        edit.uid = newUid;
      }
      if (edit.uid !== relativeForm.uid) {
        setRelativeForm({...edit})
      }
    }
    const param = findGetParam("action")

    if (NotificationService.mediumActionData && param && typeof param === "string" && NotificationService.mediumActionData[param]) {
      setLoading(true)
      const notifData = NotificationService.mediumActionData[param].data;
      const pageCode = NotificationService.mediumActionData[param].data.pageId
      BiographyService.isValidPageCode(pageId, pageCode)
        .then(data => {
          if (data === 404 || data.failed) {
            setNotFound(true)
            setRelativeForm({
              ...relativeForm,
              firstName: notifData.name.split(' ')[0],
              lastName: notifData.name.split(' ')[1],
              uid: newUid,
            })
            return;
          }
          setRelativeForm({
            ...relativeForm,
            ...data,
            uid: newUid,
            pageCode
          })
        })
        .finally(() => setLoading(false))
    } else if (!edit) {
      setRelativeForm({uid: newUid})
    }
  }, [edit])

  const isDisabled = () => {
    const base = !relativeForm.firstName || !relativeForm.lastName || !relativeForm.relation?.relationshipType
    if (type === "spouse") {
      return base || !relativeForm.relation?.from
    } else {
      return base || !relativeForm.relation?.relationship
    }
  }

  const updateForm = (_, {name, value}) => {
    if (name.includes("relation")) {
      const prop = name.split(".")
      setRelativeForm({
        ...relativeForm,
        [prop[0]]: {
          ...(relativeForm.relation || {}),
          [prop[1]]: value
        }
      })
    } else {
      setRelativeForm({
        ...relativeForm,
        [name]: value
      })
    }
  }

  const addRelative = () => {
    const rf = {...relativeForm}
    if (rf.pageCode) {
      setLoading(true)
      BiographyService.isValidPageCode(pageId, rf.pageCode)
        .then(data => {
          if (data.failed) {
            delete rf.pageCode
          }
        }).catch(e => {
        delete rf.pageCode
      }).finally(() => {
        firebase.analytics().logEvent("member_added", {
          linked: !!rf.pageCode
        });
        if (!rf.pageCode) {
          setInvite(true);
        } else {
          finalizeAdd(rf)
        }
      })
    } else {
      setInvite(true)
    }
  }

  const finalizeAdd = (rf) => {
    setInvite(false);
    setLoading(false)
    setRelativeForm({})
    setLinkingMember(false)
    saveForm(rf)
  }

  const close = () => {
    setRelativeForm({})
    setLinkingMember(false)
    setClose()
  }

  const codeNotFound = () => {
    return (
      <Modal open={notFound}>
        <Modal.Header>Oops, there was an error</Modal.Header>
        <Modal.Content>
          This users Everloom can not be found, or you must first connect with them.

          To connect with them go to she Share section of your tab and send an invitation.
        </Modal.Content>

        <Modal.Actions>
          <Button onClick={() => setNotFound(false)} primary>Close</Button>
        </Modal.Actions>
      </Modal>
    )
  }

  return (
    <Modal
      style={{maxWidth: 607}}
      onClose={close}
      closeIcon
      open={isOpen}>
      <InviteUser
        prompt={'Let your relative know you\'ve added them to your Everloom'}
        inlineClose inviting={invite} edit={false} close={() => {
        finalizeAdd({...relativeForm})
      }}/>
      {codeNotFound()}
      <AddPageInline isOpen={pageCreation} close={() => bioDispatch.setOpenPageCreation(false)}
                     pageCreated={pageCode => {
                       updateForm(null, {name: "pageCode", value: pageCode})
                       bioDispatch.setOpenPageCreation(false)
                     }}/>
      <Modal.Header>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <div>
            {!!edit ? "Edit Family Information" : "Add a " + typeMap[type]}
          </div>
          <div style={{fontSize: 16, color: customTheme.primary}}>
            {subMessage}
          </div>
        </div>
      </Modal.Header>
      <Modal.Content style={{maxHeight: 400, overflowY: "scroll"}}>

        {linkingMember ? <LinkMember currentMember={relativeForm}
                                     onSelected={pageData => {
                                       setRelativeForm({
                                         ...relativeForm,
                                         ...pageData
                                       })
                                     }}/>

          : <GenericRelativeForm onChange={updateForm} data={relativeForm} errors={errors}
                                 updateForm={setRelativeForm} type={type}
                                 edit={edit}
                                 reduced={reduced}
                                 relatives={relatives}/>}

      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => linkingMember ? setLinkingMember(false) : close()} basic style={{float: 'left'}}>
          {linkingMember ? 'Back' : 'Cancel'}
        </Button>

        <WhiteIconButton
          disabled={isDisabled()}
          content={linkingMember ? 'Done' : 'Next'}
          primary
          onClick={() => linkingMember ? addRelative() : setLinkingMember(true)}
          loading={loading}
        />
      </Modal.Actions>
    </Modal>
  )
}

export default AddRelativeModal
