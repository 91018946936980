import React from "react"
import { Button, Divider, Icon, Loader, Menu, Modal, Sidebar } from "semantic-ui-react"
import { FlexBox } from "../../styles/Containers"
import * as moment from "moment"
import { CONSTANTS } from "../../config/constants"
import styled from "styled-components"
import { LISTS } from "../../config/options.map"
import NotificationService from "../../services/notifications"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Biography from "../../store/reducers/biography/actions"
import Notifications from "../../store/reducers/notifications/actions"
import Auth from "../../store/reducers/auth/actions"
import {usePageScope} from "../../store/hooks/page.hooks.js";

const NotificationsNav = ({}) => {
	const history = useHistory()
	const NotificationDispatch = new Notifications(useDispatch())
	const briefDispatch = new Biography(useDispatch())
	const loading = useSelector(Notifications.isLoading)
	const notifData = useSelector(Notifications.notifData)
	const { pageId, page } = usePageScope()
	const visible = useSelector(Notifications.visible)
	const user = useSelector(Auth.user)

	const loadMore = (props) => {
		if (user && user.myPages.indexOf(pageId) > -1) {
			NotificationDispatch.loadNextPage(notifData, pageId)
		}
	}

	const runNotificationActions = (notification) => {
		const actionType = notification.type
		NotificationService.notificationData = notification
		console.log(notification)
		switch (actionType) {
			case "INVITE_FAMILY":
				history.push(`${history.location.pathname}?action=INVITE_FAMILY`)
				briefDispatch.setEditBrief('family')
				break
			case "ACCESS_REQUESTED":
				history.push(`/${notification.data.pageId}/account?action=ACCESS_REQUESTED`)
				break
			case "FOLLOW_UPDATE":
				history.push(`/${notification.data.pageId}/chapters`)
				NotificationService.notificationData = null
				break
			case "TAG":
				if (notification.data.tagType === "IMAGE") {
					history.push(`/${notification.data.taggedPage}/gallery?action=TAG`)
				}
				break
			case "BENEFICIARY_STATUS_REQUESTED":
				history.push(`/account`)
				break
		}
		NotificationDispatch.setNotificationsVisible(false, false, pageId)
	}

	const processMessage = (message) => {
		if (message.includes("make you their beneficiary.")) {
			message = message.replace("beneficiary", "<span class=\"clickable\">beneficiary</span>")
		} else if (message.includes("be able to see")) {
			message = message.replace("be able to see", "<span class=\"clickable\">be able to see</span>")
		} else if (message.includes("their Everloom")) {
			message = message.replace("their Everloom", `<span class="clickable">their Everloom</span>`)
		} else {
			for (let type of (LISTS.data?.relationTypes || [])) {
				if (message.includes("as your " + LISTS.data.relativeAsDescriptor[type])) {
					message = message.replace("as your " + LISTS.data.relativeAsDescriptor[type], `<span class="clickable"> as your ${LISTS.data.relativeAsDescriptor[type]}</span>`)
				}
			}
		}
		return message
	}

	const getNotificationIcon = (type) => {
		switch (type) {
			case "RELATION_CLAIMED":
			case "INVITE_FAMILY":
			case "NEW_BENEFICIARY":
			case "BENEFICIARY_STATUS_REQUESTED":
				return "code branch"
			case "ACCESS_REQUESTED":
				return "eye"
			case "COLLABORATION":
			case "FOLLOW_UPDATE":
				return "edit outline"

		}
	}

	const notifArray = (notifData?.list || []).sort((a, b) => moment(a.createdAt).unix() < moment(b.createdAt).unix() ? 1 : -1)
	return (
		<Sidebar
			as={Menu}
			animation={"overlay"}
			direction={"right"}
			icon='labeled'
			vertical
			visible={visible}
			width='wide'
		>
			<NotificationContainer>
				<CloseIcon name={"close"}
									 onClick={() => NotificationDispatch.setNotificationsVisible(false, false, pageId)}/>
				<MenuHeader className={"top-header"}>Notifications</MenuHeader>
				<Divider/>
				<MenuHeader><strong>{page?.brief?.firstName || ""} {page?.brief?.lastName || ""}</strong></MenuHeader>
				<Divider style={{ marginBottom: 0 }}/>

				<div>
					{notifArray.map((o, i) => (
						<NotificationCard
							key={i}
							i={i}
							seen={o.seen}
							onClick={() => o.action?.buttons?.length ? {} : runNotificationActions(o)}
						>
							<FlexBox>
								<NotificationIcon name={getNotificationIcon(o.type)}/>
								<div style={{ flex: 1 }}>
									<div dangerouslySetInnerHTML={{ __html: processMessage(o.message) }}/>
									<DateValue>{moment(o.createdAt).format(CONSTANTS.prettyDate)} at {moment(o.createdAt).format("HH:mm a")}</DateValue>
								</div>
							</FlexBox>
						</NotificationCard>
					))}
					{(notifData && notifData.totalCount > notifArray.length) ?
						<div style={{ padding: 10 }}><Button onClick={loadMore} fluid basic>Load more</Button></div> : < div/>}
					{loading ? <Loader active={true} inline/> : <div/>}
				</div>
			</NotificationContainer>
		</Sidebar>
	)
}

export default NotificationsNav

const NotificationContainer = styled.div`

	.top-header {
		height: 40px;
		line-height: 60px;
	}
`

const DateValue = styled.div`
	font-size: 12px;
	margin-top: 12px;
	font-weight: 500;
	opacity: 0.5;
`

const MenuHeader = styled(Modal.Header)`
	text-align: left;
	color: ${props => props.theme.primary};
	padding: 0 20px;
`

const NotificationCard = styled.div`
	text-align: left;
	padding: 35px 50px 35px 20px;
	border-top: ${props => props.i === 0 ? 0 : 1}px solid rgba(34, 36, 38, .15);

	background-color: ${props => props.seen ? "transparent" : "#f2f9ff"};

	.clickable {
		color: dodgerblue;
		text-decoration: underline;
	}

	.header {
		text-align: left;
		text-transform: capitalize;
	}

	.link {
		text-decoration: underline;
		color: dodgerblue;
	}

	&:hover {
		background-color: ${props => !props.seen ? "transparent" : "#f2f9ff"};
	}

	cursor: pointer;
`

const CloseIcon = styled(Icon)`
	color: ${props => props.theme.custom};
	position: absolute;
	right: 10px;
	top: 20px;
	opacity: 0.5 !important;
	cursor: pointer;
`

const NotificationIcon = styled(Icon)`
	margin-right: 14px !important;
	font-size: 20px !important;
	opacity: 0.5 !important;

`
