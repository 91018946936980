import BaseReducer from "../common/reducer.class"
import AuthService from "../../../services/auth"
import { SET_MY_REFERRALS } from "./initialState"
import { SET_ERROR, SET_LOADING } from "./initialState"

export class UserReducer extends BaseReducer {

	constructor(dispatch) {
		super({
			dispatch, setErrorConst: SET_ERROR, setLoadingConst: SET_LOADING,
			type: "auth"
		})
	}

	getMyReferrals = () => {
		return AuthService.getMyReferrals().then(res => {
			this.dispatch({
				type: SET_MY_REFERRALS,
				payload: res
			})
		}).catch(err => {
			this.setError(err)
		})
	}
	static myReferrals = state => state.user.referrals || { list: [], code: null }
}
