import API from "./api.js";
import {LISTS} from "../config/options.map.js";

export default class ConfigService {

    static getConfig() {
        return API.get('config').then(data => {
            LISTS.data = data.lists;
            console.log(LISTS)
        })
    }
}
