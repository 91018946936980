import { FETCHING_TAGGABLE, SET_ERROR, SET_LOADING, SET_PAGE_PERMISSIONS, SET_PAGE_TAGGABLE } from "./initialState"
import BaseReducer from "../common/reducer.class"
import PermissionsService from "../../../services/permissions.js"


const isFetching = {
	taggable: false
}

class PermissionsReducer extends BaseReducer {

	constructor(dispatch) {
		super({
			dispatch, setErrorConst: SET_ERROR, setLoadingConst: SET_LOADING,
			type: "permissions"
		})
	}

	fetchTaggable = async (pageId, init = false) => {
		console.log('USING DEPRECATED TAGGABLE')
		if (isFetching.taggable) return
		this.dispatch({
			type: FETCHING_TAGGABLE
		})
		isFetching.taggable = true
		return PermissionsService.getTaggable(pageId, init).then(result => {
			this.dispatch({
				type: SET_PAGE_TAGGABLE,
				payload: {
					data: result == 404 ? [] : result,
					pageId
				}
			})
		}).catch(err => {
			this.setError(err)
		}).finally(() => {
			isFetching.taggable = false
			this.setLoading({ value: false })
			if (init) {
				return this.fetchTaggable(pageId, false)
			}
		})
	}


	fetchPermissions = async (pageId) => {
		return PermissionsService.getPermissions(pageId).then(results => {
			this.dispatch({
				type: SET_PAGE_PERMISSIONS,
				payload: {
					results,
					pageId
				}
			})
		})
	}

	static myTaggable = state => state.permissions.taggable[state.biography.selectedPage || state.auth.user.primaryPageId] || []
	static taggableKeys = state => Object.keys(state.permissions.taggable || {})
	static fetchingTaggable = state => state.permissions.fetchingTaggable
}

export default PermissionsReducer
