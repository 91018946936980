import React from "react"
import { FlexBox } from "../../styles/Containers"
import { Grid, Placeholder } from "semantic-ui-react"
import "./index.scss"

const ProfileDetailsSkeleton = ({ style = {} }) => {

	return (
		<FlexBox direction={"column"} wrap={"unset"} align={"center"} className={"inner-brief placeholder-content"}
						 style={style}>
			<div style={{ width: "100%" }}>
				<Placeholder className={"top-placeholder"}>
					<Placeholder.Image className={"top-image"} square style={{ width: 100, height: 100 }}/>
					<Placeholder.Line className={"line1"}/>
					<Placeholder.Line className={"line1"}/>
					<Placeholder.Line className={"line1"}/>
				</Placeholder>
			</div>

			<Grid columns={2} style={{ width: "100%", margin: "20px 0" }} className={"center-placeholder"}>
				<Grid.Row>
					<Grid.Column style={{ padding: 0 }}>
						<Placeholder>
							<Placeholder.Header image/>
						</Placeholder>
					</Grid.Column>
					<Grid.Column style={{ padding: 0 }}>
						<Placeholder>
							<Placeholder.Header image/>
						</Placeholder>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column style={{ padding: 0 }}>
						<Placeholder>
							<Placeholder.Header image/>
						</Placeholder>
					</Grid.Column>
					<Grid.Column style={{ padding: 0 }}>
						<Placeholder>
							<Placeholder.Header image/>
						</Placeholder>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<Grid columns={1} style={{ width: "100%", margin: "20px 0" }}>
				<Grid.Column style={{ padding: 0 }}>
					<Placeholder>
						<Placeholder.Header image>
							<Placeholder.Line/>
							<Placeholder.Line/>
						</Placeholder.Header>
						<Placeholder.Paragraph>
							<Placeholder.Line fluid/>
							<Placeholder.Line fluid/>
						</Placeholder.Paragraph>
					</Placeholder>
				</Grid.Column>
			</Grid>
		</FlexBox>
	)
}

export default ProfileDetailsSkeleton
