import React from "react"
import { Button, Form, Icon, Modal } from "semantic-ui-react"
import { NoneText, NoneTextInline, WhiteIconButton } from "../../styles/Text.js"
import SearchUser from "../../components/common/SearchUser.js"
import { lang } from "../../config/languages"
import FormInput from "../../components/common/FormInput.js"
import { FlexBox } from "../../styles/Containers.js"
import { SemanticChip } from "../../styles/Semantic.js"

const TagManager = ({ openTags, setOpenTags, currentTags = [], setTags, mountNode }) => {
	const [newTags, setNewTags] = React.useState([])
	const [textTag, setTextTag] = React.useState({ name: "" })

	React.useEffect(() => {
		setNewTags([
			...currentTags
		])
	}, [openTags])

	const setUpdateTags = (value, props) => {
		const noDupes = []
		const arr = [
			...newTags,
			...(value || [])
		]
		for (const a of arr) {
			if (noDupes.findIndex(o => o.pageCode === a.pageCode) === -1 || !a.pageCode) {
				noDupes.push(a)
			}
		}
		setNewTags(noDupes)
	}

	const addTextTag = (props) => {
		setNewTags([
			...newTags,
			props
		])
		setTextTag({ name: "" })
	}

	const submit = () => {
		setTags(newTags)
		setOpenTags(false)
	}

	const removeTextTag = (i) => {
		const texts = newTags.filter(o => !o.pageCode)
		const pages = newTags.filter(o => !!o.pageCode)
		texts.splice(i, 1)

		setNewTags([
			...pages,
			...texts
		])
	}

	const excludedCodes = () => {
		return currentTags.filter(o => o.pageCode).map(o => o.pageCode)
	}

	return (
		<Modal
			size={"mini"}
			onClose={() => setOpenTags(false)}
			onOpen={() => setOpenTags(true)}
			mountNode={mountNode}
			open={openTags}>
			<Modal.Header>{lang.addChapterTag}</Modal.Header>
			<Modal.Content>
				<h4 dangerouslySetInnerHTML={{ __html: lang.chapterTagDescription }} style={{ textAlign: "center" }}/>
				<Modal.Description>
					<SearchUser users={newTags.filter(o => !!o.pageCode)} excludeCodes={excludedCodes()} fluid
											onChange={setUpdateTags}/>
					<div style={{ marginTop: 8, opacity: 0.5, fontStyle: "italic", marginBottom: 8, textAlign: "center" }}>
						Or
					</div>
					<FlexBox style={{ marginBottom: 12 }}>
						{newTags.filter(o => !o.pageCode).map((o, i) => (
							<SemanticChip onClick={() => removeTextTag(i)}>{o.name} <Icon name={"times"}/></SemanticChip>
						))}
					</FlexBox>
					<Form onSubmit={() => addTextTag(textTag)}>
						<FormInput placeholder={"Name"} value={textTag.name} fluid formMargin={false}
											 onChange={(_, { value }) => setTextTag({ name: value })}/>
						<NoneTextInline>Press enter to add next tag</NoneTextInline>
					</Form>
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={() => setOpenTags(false)}>
					Cancel
				</Button>
				<WhiteIconButton
					content="Save"
					labelPosition='right'
					icon='checkmark'
					onClick={submit}
					positive
				/>
			</Modal.Actions>
		</Modal>
	)
}

export default TagManager
