import React from 'react'
import styled from "styled-components";
import { ScrollbarStyles } from "../styles/Containers"

const Screen = ({children, padding = true, style = {}, fluid, center}) => {

  if (fluid) {
    style.width = '100%';
  }
  return (
    <MainScreen center={center} padding={padding} style={style} className={'page-screen'}>
      {children}
    </MainScreen>
  );
};

export default Screen;

const MainScreen = styled.div`
  min-height: 700px;
  max-width: 1600px;
  ${props => props.center ? {margin: 'auto'} : {marginRight: 'auto'}};
  padding: ${props => props.padding ? 12 : 0}px;
  height: fit-content;
`
