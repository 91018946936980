import React from 'react'
import {useSelector} from "react-redux";
import Root from "../../store/reducers/root/actions.js";
import PaywallModals from "./paywalls";
import PendingPages from "../../pages/PendingPages.js";
import Upgrade from "../../pages/Account/Upgrade.js";
import ManageImage from "../../components/media/ManageImage.js";
import ImageViewer from "../../components/ImageViewer"

const ModalView = ({}) => {
    const upgrade = useSelector(Root.viewUpgrade);

    return (
        <div>
            <PaywallModals/>
            <PendingPages/>
            <ManageImage />
            <Upgrade isOpen={upgrade}/>
            <ImageViewer />
        </div>
    );
};

export default ModalView;
