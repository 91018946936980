import React from "react"
import { Message } from "semantic-ui-react"

const ErrorComponent = ({ message, header }) => {

	if (!message) {
		return null
	}

	return (
		<Message negative>
			{!!header && <Message.Header>{header}</Message.Header>}
			<p>{message}</p>
		</Message>
	)
}

export default ErrorComponent
