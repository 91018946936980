import React from 'react'
import Logo from '@logo/themed/logo-loader-1.svg';
import LogoDark from '@logo/themed/logo-loader-2.svg';
import HeirloomImage from "./HeirloomImage.js";

const LogoLoader = ({active = true, alt = false}) => {

    return (
        <div className={'logo'}>
          {active && <HeirloomImage style={{width: 100, height: 100}} src={alt ? LogoDark : Logo}/>}
        </div>
    );
};

export default LogoLoader;
