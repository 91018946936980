import React, {useEffect, useRef, useState} from 'react'
import {Button, Icon, Message, Modal, Segment} from "semantic-ui-react";
import {FlexBox} from "../../styles/Containers.js";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.scss';
import Auth from "../../store/reducers/auth/actions.js";
import ModalLoader from "../../components/common/ModalLoader.js";
import Root from "../../store/reducers/root/actions.js";
import {LISTS} from "../../config/options.map.js";
import {MyPaymentMethods} from "./Billing.js";
import BillingService from "../../services/billing.js";
import useWindowSize from "../../utils/dimensions.js";
import {NoneTextInline} from "../../styles/Text.js";
import EnterGiftCode from "../../components/common/EnterGiftCode.js";
import {usePageScope} from "../../store/hooks/page.hooks.js";
import firebase from "firebase";

export const PlanInfo = ({activePlan, choosePlan, applyGiftCode, usingCode, discount}) => {
  const {width} = useWindowSize();

  const listedPlans = [...JSON.parse(JSON.stringify(LISTS.data.payPlans))]
  const currentSpent = listedPlans.find(o => o.id === activePlan.id)?.cost || 0;

  for (let i = 0; i < listedPlans.length; i++) {
    if (discount && discount.discount && discount.discount.plans.indexOf(listedPlans[i].id) > -1) {
      listedPlans[i].cost = listedPlans[i].cost - ((+discount.discount.discount || 0) * listedPlans[i].cost);
      if (listedPlans[i].cost < 0) {
        listedPlans[i].cost = 0;
      }
    } else {
      if (listedPlans[i].id > activePlan.id) {
        listedPlans[i].cost = listedPlans[i].cost - currentSpent;
      } else {
        listedPlans[i].cost = ''
      }
    }
  }
  const flipView = width < 560

  const BuyPage = ({o}) => (
    <Button
      style={!flipView ? {width: 120} : {width: '-webkit-fill-available', marginTop: 12}}
      disabled={activePlan.id === o.id || activePlan.id > o.id}
      onClick={() => choosePlan(o.id)}
      primary={activePlan.id !== o.id}>{activePlan.id === o.id ? 'Active' : 'Purchase'}</Button>
  );
  return (
    <>
      {(listedPlans || []).map(o => (
        <Segment>
          <FlexBox justify={'space-between'} align={'center'} flip={flipView}>
            <div>
              <FlexBox align={'center'}>
                <h3 style={{margin: '0 10px 3px 0'}}>{o.title}</h3>
              </FlexBox>
            </div>
            <FlexBox align={'center'} justify={'flex-end'}>
              {(!!o.cost || o.cost === 0) && <PriceTag>${o.cost}</PriceTag>}
              {!flipView && <BuyPage o={o}/>}
            </FlexBox>
          </FlexBox>
          {flipView && <BuyPage o={o}/>}
        </Segment>
      ))}
      <NoneTextInline>OR</NoneTextInline>
      <FlexBox style={{marginTop: 8, position: 'relative'}} align={'center'}>
        <EnterGiftCode usingCode={usingCode} applyGiftCode={code => applyGiftCode(code)}
                       clearGiftCode={() => applyGiftCode(null)}/>
      </FlexBox>

      <a style={{margin: '40px 0 10px 0', display: 'block', cursor: 'pointer'}}>See Pricing +
        Feature
        comparison <Icon name={'external alternate'}/></a>
    </>
  )
}


const Upgrade = ({isOpen}) => {
  const rootDispatch = new Root(useDispatch())
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(false);
  const {activePlan, pageId} = usePageScope()
  const [allowSwipes, setAllowSwipes] = useState('')
  const [plan, setPlan] = useState(null)
  const user = useSelector(Auth.user);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [useGiftCode, setUseGiftCode] = useState(null);


  const isFree = useGiftCode?.discount?.discount?.discount === 1

  const close = () => {
    rootDispatch.setViewUpgrade(false);
  }

  let swiper = useRef()

  useEffect(() => {
    setError(null);
    setUseGiftCode(null)
    if (isOpen) {
      firebase.analytics().logEvent("clicked_upgrade_account");
    }
  }, [isOpen])

  useEffect(() => {
    switch (allowSwipes) {
      case 'NEXT':
        swiper.current.slideNext();
        break;
      case 'PREV':
        swiper.current.slidePrev();
    }
    setAllowSwipes('');
  }, [allowSwipes])

  const choosePlan = (plan) => {
    setAllowSwipes('NEXT');
    setPlan(plan);
  }

  const applyGiftCode = (code) => {
    setUseGiftCode(code);
  }

  const backToChoosePlan = () => {
    setAllowSwipes('PREV');
    setPlan(null)
  }

  const actions = () => {
    const i = swiper.current?.activeIndex || 0;
    if (i === 0) {
      return (
        <Button basic color='blue' onClick={close}>
          Maybe later
        </Button>
      )
    } else if (i === 1) {
      return (<>
        <Button basic color='blue' onClick={backToChoosePlan} style={{float: 'left'}}>
          Back
        </Button>
        <Button disabled={(!paymentMethod && !isFree) || !plan} primary onClick={submit}>
          Buy now
        </Button>
      </>)
    }
  }

  const submit = () => {
    setSubmitting(true);
    BillingService.upgradeAccount(pageId, plan, paymentMethod, useGiftCode?.discount?.code).then(res => {
      rootDispatch.setConfetti(5000);
      setError(null);
      setSubmitting(false)
      setTimeout(() => {
        window.open('/', '_self');
      }, 3000);
      close();
      rootDispatch.setMessage({
        type: 'success',
        message: 'Page successfully upgraded!'
      })
    }).catch(err => {
      setError(err);
      setSubmitting(false)
    })
  }

  if (!user) return <div/>

  return (
    <Modal open={isOpen} closeIcon onClose={close} size={'small'}>
      <Modal.Header>Upgrade Your Everloom</Modal.Header>
      <ModalLoader active={submitting}/>
      <ModalContent style={{paddingBottom: 0}}>
        {!!error && <Message negative>
          {error}
        </Message>}
        <Swiper
          ref={swiper}
          allowSlideNext={!!allowSwipes}
          allowSlidePrev={!!allowSwipes}
          onSwiper={sw => {
            swiper.current = sw;
          }}
          slidesPerView={1}>
          <SwiperSlide>
            {/*<Image src={UpgradeImage} style={{height: 150, margin: '0 40px 0 40px'}}/>*/}
            <Modal.Description style={{padding: 20, flex: 1}}>

              <PlanInfo applyGiftCode={giftCode => applyGiftCode(giftCode)} usingCode={!!useGiftCode}
                        discount={useGiftCode?.discount}
                        activePlan={activePlan} choosePlan={choosePlan}/>
            </Modal.Description>
          </SwiperSlide>
          <SwiperSlide>
            <BillingOptionsFlex>
              <div style={{textAlign: 'center', maxWidth: 500, flex: 1}}>
                <h3>Select A Payment Method</h3>
                <MyPaymentMethods billing={user.billing || []} actionable
                                  onClick={o => setPaymentMethod(o.id)}
                                  active={paymentMethod}
                                  customClass={'upgrade-selector'}/>
              </div>
            </BillingOptionsFlex>
          </SwiperSlide>
        </Swiper>
      </ModalContent>
      <Modal.Actions>
        {actions()}
      </Modal.Actions>
    </Modal>
  );
};

export default Upgrade;

export const BillingOptionsFlex = styled(FlexBox)`
  flex: 1;
  justify-content: center;

  .upgrade-selector {
    cursor: pointer;

    &:hover {
      border: thin solid ${props => props.theme.quaternary};
    }

    i {
      top: -8px !important;
    }
  }
`

const PriceTag = styled.div`
  font-size: 24px;
  margin-right: 30px;
  color: ${props => props.theme.quaternary};
  font-weight: bold;
`;

export const ModalContent = styled(Modal.Content)`
  .swiper-container {
    width: 100%;

    .swiper-slide {
      width: 100% !important;
      display: flex;
    }
  }
`;

const BillingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
