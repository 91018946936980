export const handleActionResponse = (prom, root, status, fin = () => null) => {
    prom
        .then(r => {
            root.setMessage({
                type: 'success',
                message: status
            })
        })
        .catch(e => {
            root.setMessage({
                type: 'error',
                message: e
            })
        })
        .finally(() => fin())
}
