import React from "react"
import {RelativePillClose} from "../../styles/Text.js"
import {CloseOutline} from "react-ionicons"
import {PagesService} from "../../services/pages.js"
import {useHistory} from "react-router-dom"
import styled from "styled-components";
import LogoSvg from '../../assets/logos/themed/logo-primary-col.svg'

const Pill = ({pageCode, children, cleared, style = {}, onClick, type}) => {
  const history = useHistory()

  const nav = (e) => {
    e.preventDefault()
    if (onClick) {
      onClick(pageCode)
    } else {
      history.push(PagesService.navigatePageRoute(pageCode))
    }
  }

  return (
    <RelativePillClose closeable={!!cleared} style={style} type={type}>
      {cleared && <CloseOutline onClick={cleared}/>}
      {(pageCode || onClick) ? <a onClick={nav}>{children}</a> : children}
      {pageCode && <Logo src={LogoSvg}/>}
    </RelativePillClose>
  )
}

export default Pill

const Logo = styled.img`
  width: 10px;
`
