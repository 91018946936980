import React, {useState} from 'react'
import {PopupContainer} from "./Header/MenuPopover.js";
import {List} from "semantic-ui-react";
import {FlexBox, PopupListItem, ProfileIcon, ScrollbarStyles} from "../styles/Containers.js"
import IconImage from '@images/image-not-found.png';
import PlusSign from '@images/plus-sign.png';
import {ChevronDown} from "react-ionicons";
import {useDispatch, useSelector} from "react-redux";
import Auth from "../store/reducers/auth/actions.js";
import {PageSelector} from "../styles/Layout.js";
import styled from "styled-components";
import {useHistory} from "react-router-dom"
import {useOnboarding, usePageScope} from "../store/hooks/page.hooks.js";
import firebase from "firebase/app";
import Root from "../store/reducers/root/actions.js";

const AccountPageSelector = ({itemSelected, forceValue, customTriggerClass = '', customPopClass = ''}) => {
  const user = useSelector(Auth.user)
  const history = useHistory()
  const {page} = usePageScope();
  const pages = user?.pageData
  const [selectPage, setSelectPage] = useState(false);

  const createPage = () => {
    firebase.analytics().logEvent("app_page_dropdown");
    history.push('/account?act=add_page')
  }

  const keys = Object.keys(pages || {}).sort((a, b) => a === user?.primaryPageId ? -1 : 1);

  return (
    <>
      {((forceValue || page) && keys.length >= 1) &&
      <div>
        <PopupContainer
          className={`menu-popup account-page-selector-popup ${customPopClass}`}
          content={() => (
            <PopupList relaxed>
              {keys.map(o => {
                const pageData = pages[o];
                return (<PopupListItem noMargin style={{height: 60}}>
                  <FlexBox onClick={() => {
                    setSelectPage(false)
                    itemSelected(o)
                    firebase.analytics().logEvent("selected_page", {
                      isPrimary: o === user.primaryPageId,
                      navType: 'AccountPageSelector'
                    });
                  }} align={'center'}>
                    <ProfileIcon src={pageData?.image || IconImage}/>
                    <List.Header>{pageData.firstName} {pageData.lastName}</List.Header>
                  </FlexBox>
                </PopupListItem>)
              })}
              <PopupListItem noMargin style={{height: 60}}>
                <FlexBox onClick={createPage}>
                  <ProfileIcon src={PlusSign}/>
                  <FlexBox direction={'column'}>
                    <List.Header>Add Everloom</List.Header>
                    <p style={{fontSize: 12}}>Create additional Everloom</p>
                  </FlexBox>
                </FlexBox>
              </PopupListItem>
            </PopupList>
          )}
          eventsEnabled={true}
          on='click'
          onClose={() => setSelectPage(false)}
          onOpen={() => setSelectPage(true)}
          open={selectPage}
          position={'bottom center'}
          trigger={
            <PageSelector align={'center'} justify={'space-between'} className={`${customTriggerClass}`}>
              {forceValue ? `${forceValue.firstName} ${forceValue.lastName}` : `${page.brief.firstName} ${page.brief.lastName}`}
              <ChevronDown/>
            </PageSelector>
          }/>
      </div>
      }
    </>
  );
};

export default AccountPageSelector;

const PopupList = styled(List)`
  max-height: 400px;
  overflow-y: auto;
  padding: 12px !important;
  ${ScrollbarStyles}
`
