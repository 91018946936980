import React, { useState } from "react"
import { FlexBox, SettingsPage } from "../../../styles/Containers.js"
import { Button } from "semantic-ui-react"
import { useDispatch, useSelector } from "react-redux"
import Auth from "../../../store/reducers/auth/actions.js"
import Root from "../../../store/reducers/root/actions.js"
import AuthService from "../../../services/auth.js"
import ModalLoader from "../../../components/common/ModalLoader.js"
import { NoneText } from "../../../styles/Text.js"
import { FormSectionTitle } from "../../../styles/Forms"
import { OptionBox } from "./BeneficiarySection"
import firebase from "firebase/app";

const BeneficiaryRequests = ({}) => {
	const user = useSelector(Auth.user)
	const root = new Root(useDispatch())
	const auth = new Auth(useDispatch())
	const [loading, setLoading] = useState(false)

	const declineRequest = (pageCode) => {
		firebase.analytics().logEvent("executor_declined");
		setLoading(true)
		AuthService.declineBeneficiaryRequest(pageCode)
			.then(() => {
				root.setMessage({
					type: "success",
					message: "Action completed"
				})
				auth.patchUserLocal({
					beneficiaryRequested: user.beneficiaryRequested.filter(o => o.pageCode !== pageCode)
				})
			})
			.catch(() => {
				root.setMessage({
					type: "error",
					message: "An unexpected error occurred"
				})
			}).finally(() => setLoading(false))
	}

	const acceptRequest = (pageCode) => {
		firebase.analytics().logEvent("executor_accepted");
		setLoading(true)
		AuthService.acceptBeneficiaryRequest(pageCode)
			.then(() => {
				root.setMessage({
					type: "success",
					message: "Action completed"
				})
				auth.patchUserLocal({
					beneficiaryRequested: user.beneficiaryRequested.map(o => {
						if (o.pageCode === pageCode) {
							return {
								...o,
								accepted: true
							}
						}
						return o
					})
				})
			})
			.catch(() => {
				root.setMessage({
					type: "error",
					message: "An unexpected error occurred"
				})
			}).finally(() => setLoading(false))
	}

	return (
		<SettingsPage>
			<FormSectionTitle>
				Executor Status
			</FormSectionTitle>
			<p style={{marginTop: 12}}>
				You are an Executor for the following Everlooms.
			</p>
			<ModalLoader active={loading}/>

			<div style={{marginTop: 20}} />

			{
				user.beneficiaryRequested.map(o => (
					<OptionBox>
						<FlexBox justify={"space-between"} align={"center"}>
							<div>{o.name}</div>
							{!o.accepted && <div>
								<Button color={"red"} onClick={() => declineRequest(o.pageCode)}>Decline</Button>
								<Button primary onClick={() => acceptRequest(o.pageCode)}>Accept</Button>
							</div>}
							{o.accepted && <NoneText>Active</NoneText>}
						</FlexBox>
					</OptionBox>
				))
			}
		</SettingsPage>
	)
}

export default BeneficiaryRequests
