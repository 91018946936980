import {validateEmail} from "./validateEmail"

export const validateAuthForm = (form) => {
  const failedValidation = {}

  for (const prop in form) {
    switch (prop) {
      case "email":
        if (!validateEmail(form[prop])) {
          failedValidation[prop] = "Invalid email";
        }
        break
      case "password":
        if (form[prop].length < 6) {
          failedValidation[prop] = "Password must be at least 6 characters";
        }
        break
      case "lastName":
      case "firstName":
        if (form[prop].length < 2 || form[prop].length > 15) {
          failedValidation[prop] = "Name must be between 2 and 15 chracters";
        }
        break
      case "repassword":
        if (form[prop] !== form.password) {
          failedValidation[prop] = "Passwords do not match";
        }
        break
    }
  }

  return failedValidation
}
