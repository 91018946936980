import React, {useState} from 'react'
import FormInput from "./FormInput.js";
import {Button} from "semantic-ui-react";
import BillingService from "../../services/billing.js";
import styled from "styled-components";

const EnterGiftCode = ({usingCode, applyGiftCode, clearGiftCode}) => {
  const [giftCode, setGiftCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [codeStatus, setCodeStatus] = useState('')

  const applyCode = () => {
    setLoading(true);
    BillingService.getCodePromo(giftCode)
      .then(discount => {
        setCodeStatus('VALID')
        applyGiftCode(discount)
      })
      .catch((e) => setCodeStatus('INVALID'))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <FormInput value={giftCode} onChange={(_, {value}) => setGiftCode(value)} placeholder={'Enter Gift or Promo Code'}
                 formMargin={false} fluid/>
      {!usingCode &&
      <Button primary loading={loading} style={{marginLeft: 6}} disabled={!giftCode} onClick={applyCode}>Apply</Button>}
      {usingCode && <Button style={{marginLeft: 6}} onClick={() => {
        setCodeStatus('');
        setGiftCode('')
        applyGiftCode('')
        clearGiftCode('');
      }}>Clear</Button>}
      {!!codeStatus && <CodeStatus
        style={{color: codeStatus === 'VALID' ? 'green' : 'red'}}>{codeStatus === 'VALID' ? 'Code is Valid!' : 'Code is invalid'}</CodeStatus>}
    </>
  );
};

export default EnterGiftCode;

const CodeStatus = styled.strong`
  position: absolute;
  bottom: -24px;
  left: 2px;
`;
