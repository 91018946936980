export const RESET_STATE = "RESET_STATE"

class BaseReducer {
	constructor(props) {
		this.dispatch = props.dispatch
		this.setLoadingConst = props.setLoadingConst
		this.setErrorConst = props.setErrorConst
		this.type = props.type
	}

	setLoading = ({ value }) => {
		this.dispatch({
			type: this.setLoadingConst,
			payload: value
		})
	}

	setError = (props) => {
		this.dispatch({
			type: this.setErrorConst,
			payload: props
		})
	}

	getLoading = state => state[this.type].loading
	getError = state => state[this.type].error
}

export default BaseReducer
