import endpoints from "../config/endpoints"
import axios from "axios"
import {reduxStore} from "../index.js";
import {SET_TOKENS} from "../store/reducers/auth/initialState.js";
import AuthService from "./auth.js";

const API = axios.create({
  baseURL: endpoints.base
})

API.interceptors.request.use((config) => {
  const token = AuthService.tokens().token;
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`
  }
  return config
}, (error) => {
  // Do something with request error
  return Promise.reject(error)
})

API.interceptors.response.use((response) => {
  const value = response && response.data && response.data.data && (Object.keys(response.data.data).length >= 0 || response.data.data.length >= 0) ? response.data.data : response.data.message || "Success!"
  return Promise.resolve(value)
}, (error) => {
	console.log('Failed')
  if (error?.response?.status === 401 && error.response.data.message === 'invalid_user') {
  	console.error('Invalid user found')
    localStorage.clear();
    return Promise.reject()
  }
  const refresh = null //AuthService.tokens().refreshToken;
	if (error?.response?.status === 401 && refresh) {
    axios.get(`${endpoints.base}user/refresh`, {
      headers: {
        Authorization: `Bearer ${refresh}`
      }
    }).catch(e => {
      return Promise.reject(error?.response?.data?.message || "Unexpected error occurred")
    }).then(res => {
      reduxStore.dispatch({
        type: SET_TOKENS,
        payload: res.data.data
      })
      if (res && res.data.success) {
        AuthService.setTokens(res.data.data);
      }
      const token = AuthService.tokens().token;
      error.config.headers["Authorization"] = `Bearer ${token}`
      return API.request(error.config)
    })
  } else {
    switch (error?.response?.status) {
      case 429:
        return Promise.reject('Too many requests made.');
      case 404:
        return Promise.resolve(404);
      case 401:
        localStorage.clear();
				console.error('401, logout')
				// window.open('/login', '_self');
        return Promise.reject()
      default:
        return Promise.reject(error?.response?.data?.message || "Unexpected error occurred")
    }
  }
})

export async function DoubleFetch(func) {



}

export default API
