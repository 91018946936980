import React, { useRef, useState } from "react"
import ImageNotFound from "@images/image-not-found.png"
import { Placeholder } from "semantic-ui-react"
import styled from "styled-components"

const HeirloomImage = ({ src, style = {}, onClick, onError, onLoad = () => null, loadable = true }) => {
	const ref = useRef()
	let [loaded, setLoaded] = useState(false)

	const imgError = () => {
		ref.current.onError = ""
		ref.current.src = ImageNotFound
		ref.current.style.border = "thin solid black"
		if (onError)
			onError()
		return true
	}

	const visibilityStyles = loaded ? {
		visibility: "visible"
	} : {
		visibility: "hidden",
		position: "absolute"
	}

	return (
		<>
			<ImageLoader visible={!loaded && loadable} style={{ ...style }}>
				<Placeholder.Image />
			</ImageLoader>
			<img ref={ref} src={src || ImageNotFound} style={{ ...style, ...visibilityStyles }}
					 onLoad={() => {
						 onLoad()
						 setLoaded(true)
					 }} onClick={onClick} onError={imgError}/>
		</>
	)
}

export default HeirloomImage

const ImageLoader = styled(Placeholder)`
	${props => !props.visible ? `
	display: none !important` : {}};
	border-radius: 6px;
	width: 100%;
	height: 100%;
`
