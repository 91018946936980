import React, {useState} from 'react'
import {FlexBox, SettingsPage} from "../../../styles/Containers.js";
import ModalLoader from "../../../components/common/ModalLoader.js";
import {FormSectionTitle} from "../../../styles/Forms.js";
import {NoneTextRegular} from "../../../styles/Text.js";
import AcceptPermissions from "./AcceptPermissions.js";
import styled from "styled-components";
import {Button, Divider} from "semantic-ui-react";
import {usePageScope} from "../../../store/hooks/page.hooks.js";
import {useAlterPermissions} from "../../../store/hooks/permissions.hooks.js";

const PermissionRequests = ({requests, containerStyle = {}}) => {
  const [acceptInvite, setAcceptInvite] = useState(-1);
  const {pageId} = usePageScope()
  const [loading, setLoading] = useState(false)
  const alterPermission = useAlterPermissions(pageId, setLoading)

  const declineInvite = (request) => {
    alterPermission.mutateAsync({
      data: {
        pageId: request.pageId
      },
      type: 'decline'
    })
  }

  const acceptUserInvite = (permissions) => {
    const request = requests[acceptInvite]
    const access = permissions;
    setAcceptInvite(-1);
    for (const page in access) {
      const {BRIEF, WRITE, READ} = access[page].permissions;
      if (!BRIEF && !WRITE && !READ) {
        delete access[page]
      }
    }
    alterPermission.mutateAsync({
      data: {
        pageId: request.pageId,
        access
      },
      type: 'accept'
    })
  }


  return (
    <SettingsPage style={containerStyle}>
      <ModalLoader active={loading}/>
      <FormSectionTitle>
        Everloom Invites
      </FormSectionTitle>
      <NoneTextRegular>People who have requested access to your Everloom</NoneTextRegular>

      {acceptInvite > -1 && <AcceptPermissions acceptUserInvite={acceptUserInvite} data={requests[acceptInvite]}
                                               setAcceptInvite={setAcceptInvite}/>}

      <div style={{marginTop: 30}}/>
      {requests.map((o, i) => (
        <>
          <PermissionItem key={'pr-' + i} align={'center'}>
            <strong style={{flex: 1}}>{o.issuingEmail}</strong>
            <FlexBox justify={'flex-end'} align={'center'}>
              <Button basic color={'red'} onClick={() => declineInvite(o)}>
                Decline
              </Button>
              <Button primary onClick={() => setAcceptInvite(i)}>
                Accept
              </Button>
            </FlexBox>
          </PermissionItem>
          {requests.length > 1 && <PermissionDivider/>}
        </>
      ))
      }
    </SettingsPage>
  );
};

export default PermissionRequests;

const PermissionItem = styled(FlexBox)`

`

const PermissionDivider = styled(Divider)`
  margin: 12px 0 12px 0 !important;
  opacity: 0.5;
`
