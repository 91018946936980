import API from "./api";
import {CONSTANTS} from "../config/constants.js";

export default class MediaService {

    static base = 'media';

    static fetchPageGallery = (pageId) => {
        return API.get(`${MediaService.base}/${pageId}/gallery`);
    }

    static deleteImage = (pageId, imageData) => {
        return API.put(`${MediaService.base}/${pageId}/gallery/delete`, {imageData});
    }

    static saveToPage = (pageId, imageData) => {
        return API.put(`${MediaService.base}/${pageId}/gallery/save-to-page`, imageData);
    }

    static getType = (imageData) => {
        const possibleTypes = [...CONSTANTS.acceptedImages, ...CONSTANTS.acceptedMedia, ...CONSTANTS.acceptedVideos];
        let extension = '';
        if (imageData.base64) {
            const mimeSlice = imageData.base64.slice(5, imageData.base64.indexOf(';'));
            extension = MediaService.getExtension(mimeSlice);
        } else if (imageData.Location) {
            for (const type of possibleTypes) {
                if (imageData.Location.indexOf(type) > -1) {
                    extension = type;
                }
            }
        } else {
            extension = '.png';
        }

        return extension;
    }

    static uploadImage = (file, imageData, pageId, loadingCallback = () => {
    }, type = 'GALLERY') => {
        const data = new FormData();
        data.append('file', file[0]);
        data.append('description', imageData.description);
        data.append('place', imageData.place);
        data.append('date', imageData.date);
        data.append('type', type);
        data.append('tags', JSON.stringify(imageData.tags));
        return API.put(`/media/${pageId}/gallery`, data, {
            onUploadProgress: loadingCallback,
            headers: {'Content-Type': 'multipart/form-data'}
        })
    }

    static updatePageGallery = (imageData, pageId, location, type = 'GALLERY') => {
        return API.post(`${MediaService.base}/${pageId}/gallery`, {
            description: imageData.description,
            tags: imageData.tags,
            place: imageData.place,
            date: imageData.date,
            type,
            location,
        })
    }

    static getMyBucket = () => {
        return API.get(`${MediaService.base}/bucket`);
    }

    static getTaggedGallery = (pageId) => {
        return API.get(`${MediaService.base}/${pageId}/tagged`);
    }

    static getImageObject = (tagId) => {
        return API.get(`${MediaService.base}/${tagId}/tag`);
    }

    static removeTag = (tagId) => {
        return API.delete(`${MediaService.base}/${tagId}/tag`);
    }


    static getExtension = (mime) => {
        switch (mime) {
            case 'image/jpeg':
                return '.jpeg';
            case 'image/png':
                return '.png';
            case 'image/jpg':
                return '.jpg';
            case 'application/pdf':
                return '.pdf';
            case 'video/quicktime':
                return '.mov';
            case 'audio/x-mpeg-3':
            case 'audio/mpeg3':
            case 'audio/mpeg':
                return '.mp3'
            case 'video/mp4':
            case 'application/x-mpegURL':
                return '.mp4'
            case 'audio/x-m4a':
                return '.m4a'
            default:
                return '.png';
        }
    }

    static dataURLtoFile = (dataurl, filename) => {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type: mime});
    }

    static toDataUrl = async (url) => {
        return (await API.post("/media/download", {
            url: url
        })).uri
    }
}
