export const INITIAL_STATE = {
	loading: false,
	error: null,
	user: null,
	token: null,
	refresh: null
}

const SET_LOADING = "set_loading"
const SET_ERROR = "set_error"
const SET_USER = "set_user"
const SET_TOKENS = "SET_TOKENS"
const SET_EMAIL_SETTINGS = "SET_EMAIL_SETTINGS"
const PATCH_USER = "PATCH_USER"

export {
	SET_LOADING,
	SET_ERROR,
	SET_USER,
	SET_TOKENS,
	SET_EMAIL_SETTINGS,
	PATCH_USER,
}
