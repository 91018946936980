import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import Root from "../store/reducers/root/actions.js";
import Notifications from "../store/reducers/notifications/actions.js";
import styled from "styled-components";
import ReactDOM from "react-dom"

const MessageManager = ({}) => {
    const dispatch = useDispatch();
    const RootDispatch = new Root(dispatch)
    const NotifDispatch = new Notifications(dispatch)
    const rootError = useSelector(RootDispatch.getError)
    const notifError = useSelector(NotifDispatch.getError);

    const hide = () => {
        RootDispatch.setMessage(null);
        NotifDispatch.setError(null);
    }

    const type = rootError ? rootError.type : 'error';

    const elem = (
        <CustomMessage active={rootError || notifError} type={type} onClick={hide}>
            <div className={'error-title'}>{type}</div>
            <div
                className={'error-message'}>{(rootError && rootError.message && typeof rootError.message === 'string') ? rootError.message : notifError || 'An unexpected error occurred'}</div>
        </CustomMessage>
    );

    React.useEffect(() => {
        if (rootError || notifError) {
            setTimeout(() => {
                hide();
            }, 8000);
        }
    }, [rootError, notifError])

    if (!document.getElementById('message-root')) {
        return <div />
    }

    ReactDOM.render(elem, document.getElementById('message-root'))

    return <div/>
};

export default MessageManager;

export const CustomMessage = styled.div`
  width: 300px;
  border-radius: 0 10px 10px 0;
  height: auto;
  min-height: 60px;
  background-color: white;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);

  transform: translateX(${props => props.active ? 0 : -300}px);
  transition: all 0.1s ease-in;

  border-left: 8px solid ${props => props.type === 'error' ? props.theme.alert : '#44c45b'};

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;

  .error-title {
    color: #1c1c1c !important;
    text-transform: capitalize;
    font-weight: 500;
  }

  .error-message {
    font-size: 12px;
    opacity: 0.7;
  }
`;
