import React, {useEffect, useState} from 'react'
import {Button, Modal} from "semantic-ui-react";
import styled from "styled-components";
import {useDispatch} from "react-redux";
import AdminService from "../services/admin.js";
import ModalLoader from "../components/common/ModalLoader.js";
import {FlexBox} from "../styles/Containers.js";
import FormInput from "../components/common/FormInput.js";
import {LISTS} from "../config/options.map.js";
import Root from "../store/reducers/root/actions.js";

const CreateCode = ({isOpen, onClose}) => {
  const [codeData, setCodeData] = useState({})
  const [loading, setLoading] = useState(false);
  const root = new Root(useDispatch())

  const mappedAmounts = (LISTS.data.payPlans || []).map(o => ({title: o.title, cost: o.cost, id: o.id}));

  const onChange = (_, {value, name}) => {
    setCodeData({
      ...codeData,
      [name]: value
    })
  }

  const handlePlanClick = (id) => {
    const arr = codeData.plans || [];
    if (arr.indexOf(id) > -1) {
      arr.splice(arr.indexOf(id), 1);
    } else {
      arr.push(id);
    }
    setCodeData({
      ...codeData,
      plans: arr
    })
  }

  useEffect(() => {
    setCodeData({});
    setLoading(false);
  }, [isOpen])

  const submit = () => {

    if (valid()) {
        setLoading(true);
        AdminService.createCode(codeData)
          .then(() => {
            root.setMessage({
              type: 'success',
              message: 'Code created'
            })
            onClose(true);
          })
          .catch(e => {
            root.setMessage({
              type: 'error',
              message: e
            })
          })
          .finally(() => setLoading(false))
    }

  }

  console.log(codeData)

  const valid = () => {
    return codeData.discount && !isNaN(codeData.discount) && codeData.plans?.length;
  }

  return (
    <Modal closeIcon onClose={onClose} open={isOpen}>
      <Modal.Header>Create Code</Modal.Header>
      <ModalLoader active={loading}/>
      <Modal.Content>
        <Modal.Description>Create your code</Modal.Description>

        <Modal.Description>
          <h4>Select type</h4>

          <FlexBox>
            <SelectableChip active={codeData.type === 'PROMOTIONAL'}
                            onClick={() => onChange(null, {name: 'type', value: 'PROMOTIONAL'})}
                            style={{marginLeft: 0}}>PROMOTIONAL</SelectableChip>
            <SelectableChip active={codeData.type === 'GIFT'}
                            onClick={() => onChange(null, {name: 'type', value: 'GIFT'})}>GIFT</SelectableChip>
          </FlexBox>

          <h4>Select discount</h4>
          <h6>Select a number from 0-100 for the discount you would like to apply to each tier.</h6>

          <FormInput value={codeData.discount} placeholder={'Enter percentage discount...'} onChange={onChange} name={'discount'}/>

          <h4>Applicable tiers</h4>
          <FlexBox>
            {mappedAmounts.map(o => (
              <SelectableChip active={(codeData.plans || []).indexOf(o.id) > -1}
                              onClick={() => handlePlanClick(o.id)}
                              style={{marginLeft: 0}}>{o.title}: ${o.cost}</SelectableChip>
            ))
            }
          </FlexBox>

        </Modal.Description>
      </Modal.Content>

      <Modal.Actions>
        <Button>
          Discard
        </Button>
        <Button primary disabled={!valid()} onClick={submit}>
          Create
        </Button>
      </Modal.Actions>

    </Modal>
  );
};

export default CreateCode;

export const SelectableChip = styled.div`

  margin: 10px;
  padding: 6px 10px 6px 10px;
  background-color: ${props => props.active ? props.theme.quaternary : 'transparent'};
  border: 2px solid ${props => props.theme.quaternary};
  border-radius: 30px;
  cursor: pointer;
`;
