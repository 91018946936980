import React, {useEffect, useState} from "react"
import {Button, Dropdown, Loader, Menu} from "semantic-ui-react"
import MemoriesReducer from "../../store/reducers/memories/actions.js"
import {useDispatch, useSelector} from "react-redux"
import {usePageScope} from "../../store/hooks/page.hooks.js";
import {IconImage} from "../../styles/Containers.js";
import PermissionIcon from '../../assets/icons/permissions.svg'
import MemoriesIcon from '../../assets/icons/memories.svg'
import PendingIcon from '../../assets/icons/hourglass.svg'
import Biography from "../../store/reducers/biography/actions.js";
import {CONSTANTS} from "../../config/constants.js";
import useWindowSize from "../../utils/dimensions.js";

const SelectionMenu = ({visibleSection, setVisibleSection, isAdmin}) => {
  const {width} = useWindowSize()
  const small = width <= CONSTANTS.hideSearch;
  const memoryReducer = new MemoriesReducer(useDispatch())
  const [loading, setLoading] = useState(false)
  const {pageId, page} = usePageScope()
  const settings = useSelector(MemoriesReducer.settings)

  useEffect(() => {
    setLoading(true)
    console.log(page.feed)
    memoryReducer.loadMemoryData(pageId, page.feed).finally(() => setLoading(false))
  }, [])

  const writeButton = !loading && ((settings.status === "LOCKED" || page.isDemo) ? <div>Locked</div> :
    <Button onClick={() => memoryReducer.setWriteMemory(true)} primary size={"tiny"}>Write Memory</Button>)

  const dropMenuProps =  {
    onClick: (_, {value}) => setVisibleSection(value)
  }

  return (
    <div style={{position: "relative"}}>
      {small ? (
        <Dropdown style={{minWidth: 100, marginBottom: 20}} text={visibleSection} onChange={console.log}>
          <Dropdown.Menu>
            <Dropdown.Item {...dropMenuProps} text='Memories' value={'Memories'}/>
            <Dropdown.Item {...dropMenuProps} text='Pending' value={'Pending'}/>
            <Dropdown.Item {...dropMenuProps} text='Settings' value={'Settings'}/>
          </Dropdown.Menu>
        </Dropdown>
      ) : <Menu pointing secondary>
				{isAdmin && <>
          <Menu.Item
            name='Memories'
            icon={<IconImage src={MemoriesIcon} />}
            active={visibleSection === "Memories"}
            onClick={() => setVisibleSection("Memories")}
          />
          <Menu.Item
            name='Pending'
            icon={<IconImage src={PendingIcon} />}
            active={visibleSection === "Pending"}
            onClick={() => setVisibleSection("Pending")}
          />

          <Menu.Item
            name='Settings'
            icon={<IconImage src={PermissionIcon} />}
            active={visibleSection === "Settings"}
            onClick={() => setVisibleSection("Settings")}
          />
        </>}
        <Menu.Menu position='right'>
          <Menu.Item>
            {writeButton}
          </Menu.Item>
        </Menu.Menu>
      </Menu>}
      <Loader style={{left: "95%"}} active={loading}/>
    </div>
  )
}

export default SelectionMenu
