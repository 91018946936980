import API from "./api.js"
import { LISTS } from "../config/options.map.js"

export class PagesService {
	static BASE = "pages"

	static navigatePageRoute = (pageId) => {
		return `/${pageId}/chapters`
	}

	static submitOnboarding(data) {
		return API.post(`${PagesService.BASE}/me/onboarding`, data)
	}

	static transferPage(pageId, body) {
		return API.post(`${PagesService.BASE}/${pageId}/transfer/request`, body)
	}

	static cancelMyTransfer(pageId) {
		return API.post(`${PagesService.BASE}/${pageId}/transfer/cancel-me`)
	}

	static cancelPageTransfer(pageId) {
		return API.post(`${PagesService.BASE}/${pageId}/transfer/cancel`, {})
	}

	static acceptPageTransfer(pageId, isPrimary) {
		return API.post(`${PagesService.BASE}/${pageId}/transfer/accept`, { isPrimary })
	}

	static getPage(pageId, soft) {
		return API.post(`${PagesService.BASE}/${pageId}`, {
			soft
		})
	}

	static getPages() {
		return API.get(PagesService.BASE + "/me/pages")
	}

	static createNewPage(data) {
		return API.post(PagesService.BASE + "/me/pages", data)
	}

	static requestAccessToPage(pageId) {
		return API.get(`${PagesService.BASE}/${pageId}/request`)
	}

	static setFollowingPage = (pageId) => {
		return API.post(`${PagesService.BASE}/${pageId}/follow`, {})
	}

	static sendCode(pageId, email) {
		return API.post(`${PagesService.BASE}/${pageId}/send-code`, { email })
	}

	static setBeneficiary(selected, removedEmails, pageId) {
		return API.post(`${PagesService.BASE}/${pageId}/beneficiary`, { emailList: selected, removedEmails })
	}

	static getPageData(pageId) {
		return API.get(`${PagesService.BASE}/${pageId}/data`)
	}

	static getPageChapters(pageId) {
		return API.get(`${PagesService.BASE}/${pageId}/chapters`)
	}

	static fetchDemo() {
		return API.get(`public/demos`).then(data => {
			return data;
		})
	}

	static fetchPageAsDemo(key, pin) {
		return API.get(`public/profile/${key}?pin=${pin}`).then(data => {
			return data;
		})
	}

	static getStatsFromContext(pageData) {
		const getRelativeCount = (relations) => {
			let count = 0
			for (let j of LISTS.data.relationTypes || []) {
				count += relations[j].length
			}
			return count
		}
		return {
			relation: {
				relationship: ""
			},
			image: pageData.brief.image,
			pageCode: pageData.pageId,
			firstName: pageData.brief.firstName,
			lastName: pageData.brief.lastName,
			totalMedia: (pageData.gallery || []).length,
			totalChapters: (pageData.chapters || []).length,
			totalRelatives: getRelativeCount(pageData.relations || {}),
			chaptersDiscussed: Array.from(new Set((pageData.chapters || []).map(o => o.chapterTitle))),
			totalContributors: (pageData.contributors || []).length,
			lastUpdated: pageData.updatedAt,
			ownerName: pageData.owner.name
		}
	}
}
