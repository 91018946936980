import React from 'react'
import FormInput from "../FormInput.js";
import {FlexBox} from "../../../styles/Containers.js";
import {Dropdown} from "semantic-ui-react";
import styled from "styled-components";
import {COUNTRY_OPTIONS} from "../../../config/countriesData.js";
import useWindowSize from "../../../utils/dimensions.js";

const CountrySelector = ({onChange, value, style = {}, width = '175px'}) => (
  <CountryDropdown
    placeholder='Select Country'
    fluid
    search
    style={style}
    selection
    name={'country'}
    value={value}
    onChange={onChange}
    width={width}
    options={COUNTRY_OPTIONS}
  />
)

const BillingInfo = ({setBillingDetails, billingDetails}) => {
  const {width} = useWindowSize();
  const update = (_, {value, name}) => {
    setBillingDetails({
      ...billingDetails,
      billing_details: {
        ...billingDetails.billing_details,
        address: {
          ...billingDetails.billing_details.address,
          [name]: value
        }
      }
    })
  }

  const flipSize = 440;
  const containerWidth = 'calc(100% - 12px)'
  const shouldFlip = width <= flipSize;

  return (
    <div style={{width: '100%', maxWidth: 382}}>
      <FormInput onChange={(_, {value}) => {
        setBillingDetails({
          ...billingDetails,
          billing_details: {
            ...billingDetails.billing_details,
            name: value
          }
        })
      }} name={'name'} value={billingDetails.billing_details.name} placeholder={'Full name'} fluid/>
      <FormInput onChange={update} name={'line1'} value={billingDetails.billing_details.address.line1}
                 placeholder={'Address 1'} fluid/>
      <FlexBox flip={flipSize}>
        <FormInput onChange={update} name={'line2'} value={billingDetails.billing_details.address.line2}
                   placeholder={'Address 2'} containerStyle={shouldFlip && {width: containerWidth}} fluid={shouldFlip} />
        <FormInput onChange={update} name={'city'} value={billingDetails.billing_details.address.city}
                   placeholder={'City'} inputStyle={{marginLeft: shouldFlip ? 0 : 3}}
                   containerStyle={shouldFlip && {width: containerWidth}} fluid={shouldFlip}/>
      </FlexBox>
      <FlexBox flip={flipSize}>
        <CountrySelector value={billingDetails.billing_details.address.country} onChange={update}
                         width={shouldFlip ? containerWidth : 175}/>
        {/*<FormInput onChange={update} name={'country'} value={billingDetails.billing_details.address.country}*/}
        {/*           placeholder={'Country'}/>*/}
        <FormInput onChange={update} name={'state'} value={billingDetails.billing_details.address.state}
                   placeholder={'State/Province'} inputStyle={{marginLeft: shouldFlip ? 0 : 3}}
                   containerStyle={shouldFlip && {width: containerWidth}} fluid={shouldFlip}/>
      </FlexBox>
    </div>
  );
};

export default BillingInfo;

const CountryDropdown = styled(Dropdown)`
  min-height: 0 !important;
  height: 38px;
  padding: 0 !important;
  width: ${props => props.width} !important;

  margin-top: 3px;
  margin-left: 12px;

  input {
    padding-left: 10px !important;
  }

  .menu {
    max-height: 180px !important;

    .item {
      .text {
        line-height: 20px !important;
      }
    }
  }

  .search {
    min-height: 0 !important;
    height: 38px;
    padding: 0 0 0 10px !important;
    width: 177px !important;
  }

  .text {
    line-height: 38px;
    padding-left: 10px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 130px;
  }

`;
