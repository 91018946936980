import API from "./api.js"
import AuthService from "./auth.js"
import { jsPDF } from "jspdf"
import * as JSZip from "jszip"
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html"
import { saveAs } from "file-saver"
import Handlebars from "handlebars"
import { ChapterTemplate } from "../static/chapterTemplate.js"
import toPlaintext from 'quill-delta-to-plaintext';

export default class BiographyService {

	static base = "biography"

	static submitBiographyUpdates(data) {
		return API.post(`${AuthService.base}/${data.pageId}/brief`, data)
	}

	static getFamilyPageData(pageId) {
		return API.get(AuthService.base + `/${pageId}/family-data`)
	}

	static deleteChapter(chapterId, pageId) {
		return API.delete(`${AuthService.base}/${pageId}/chapter/${chapterId}`)
	}

	static manageChapter(pageId, data) {
		return API.post(`${AuthService.base}/${pageId}/chapter`, data)
	}

	static isValidPageCode(pageId, relativeId) {
		return API.get(AuthService.base + `/${pageId}/${relativeId}/is-valid-id`)
	}

	static getTaggedChapters(pageId) {
		return API.get(`${AuthService.base}/${pageId}/tagged`)
	}

	static changeChapterStatus(pageId, chapterId, status) {
		return API.post(`${AuthService.base}/${pageId}/chapter-status`, {
			chapterId,
			status
		})
	}

	static updateChapterOrder = (pageId, sortedChapters) => {
		return API.post(`${AuthService.base}/${pageId}/chapter-sort`, {
			sortedChapters
		})
	}

	static setDeceased = (deceasedDate, pageId) => {
		return API.post(`${AuthService.base}/${pageId}/brief/deceased`, {
			deceasedDate
		})
	}

	// TODO: Properly determine type of content before downloading it
	static downloadPage = async (pageId, page, loadingCallback) => {
		const signatures = {
			JVBERi0: "pdf",
			R0lGODdh: "gif",
			R0lGODlh: "gif",
			iVBORw0KGgo: "png"
		}
		const name = page.brief.firstName + " " + page.brief.lastName
		let percent = 15
		await new Promise(async (resolve) => {
				const zip = new JSZip()
				let i = 0

				for (const chapter of page.chapters) {
					i += 1
					const fileUUID = Math.floor(1000 + Math.random() * 9000)
					const title = chapter.chapterTitle + "-" + fileUUID
					const pdf = new jsPDF("p", "pt", "a4")
					for (const op of chapter.operations) {
						if (op.insert && op.insert.image) {
							delete op.insert.image
						}
					}

					const folder = zip.folder(name + "-" + title)
					if ((chapter.gallery || []).length > 0) {
						const gall = folder.folder("gallery")
						for (const img of chapter.gallery || []) {
							await new Promise(resolve => {
								setTimeout(async () => {
									const galUUID = Math.floor(1000 + Math.random() * 9000)
									const foundImage = (await API.post("/media/download", {
										url: img.Location
									})).uri

									const extension = (b64) => {
										for (let s in signatures) {
											if (b64.indexOf(s) === 0) {
												return signatures[s]
											}
										}
										return "png"
									}

									gall.file(title + "-gallery-" + galUUID + "." + extension(foundImage), foundImage, { base64: true })
									resolve()
								}, 600)
							})
						}
					}

					let text = toPlaintext(chapter.operations);
					folder.file(title + ".txt", text)

					percent = ((i - 1) / page.chapters.length) * 100
					if (percent < 15) {
						percent = 15
					}
					if (percent > 75) {
						percent = 75
					}
					loadingCallback(percent)
				}

				const gallery = page.gallery || []
				const galleryFolder = zip.folder("Gallery")
				let j = 0
				let galPercent = 0
				for (const img of gallery) {
					j += 1
					await new Promise(resolve => {
						setTimeout(async () => {
							const galUUID = Math.floor(1000 + Math.random() * 9000)
							const foundImage = (await API.post("/media/download", {
								url: img.Location
							})).uri

							const extension = (b64) => {
								for (let s in signatures) {
									if (b64.indexOf(s) === 0) {
										return signatures[s]
									}
								}
								return "png"
							}

							galleryFolder.file(name + "-gallery-" + galUUID + "." + extension(foundImage), foundImage, { base64: true })
							resolve()
						}, 600)
					})

					galPercent = (j / gallery.length) * 25
					loadingCallback(percent + galPercent)
				}

				zip.generateAsync({ type: "blob" }).then(function(content) {
					saveAs(content, name + "-everloom.zip")
					resolve()
				})
			}
		)
	}
}
