import React, {useState} from "react"
import {Divider, Grid, Modal} from "semantic-ui-react"
import {useDispatch, useSelector} from "react-redux"
import Biography from "../../store/reducers/biography/actions.js"
import moment from "moment"
import {CONSTANTS} from "../../config/constants.js"
import MaleImageHolder from "../../assets/icons/male.png"
import FemaleImageHolder from "../../assets/icons/female.png"
import EditProfile from "./EditProfile.js"
import findGetParam from "../../utils/findGetParam.js"
import {EditItemCircle, FlexBox, ScrollbarStyles} from "../../styles/Containers.js"
import PermissionsService from "../../services/permissions.js"
import {Notifications, NotificationsOff} from "react-ionicons"
import customTheme from "../../styles/theme.js"
import HeirloomImage from "../../components/common/HeirloomImage.js"
import {Link, useHistory} from "react-router-dom"
import {LISTS} from "../../config/options.map.js"
import useWindowSize from "../../utils/dimensions.js"
import {MetaContent, SendPageContainer} from "../../styles/Layout.js"
import AccountPageSelector from "../../layout/AccountPageSelector.js"
import Root from "../../store/reducers/root/actions"
import FormInput from "../../components/common/FormInput"
import {PagesService} from "../../services/pages"
import ProfileDetailsSkeleton from "../../components/skeletons/ProfileDetailsSkeleton.js"
import {EditProfileModalStyles} from "../../styles/Semantic"
import styled from "styled-components"
import {ChipBtn} from "../../styles/Forms.js";
import {useOnboarding, usePageScope} from "../../store/hooks/page.hooks.js";

const BriefFields = [
  {
    title: "Birth name",
    field: "birthname"
  },
  {
    title: "Birthday",
    field: "born"
  },
  {
    title: "Deceased",
    field: "deceased",
    hide: true
  },
  {
    title: "Birthplace",
    field: "birthplace"
  }
]

const ProfileDetails = () => {
  const history = useHistory()
  const {height} = useWindowSize()
  const rootDispatch = new Root(useDispatch())
  const onboarding = useOnboarding()
  const briefDispatch = new Biography(useDispatch())
  const editBrief = useSelector(Biography.editBrief)
  const [sendEmail, setSendEmail] = useState("")
  const [sendingPage, setSendingPage] = useState(false)
  const {page, permissions, pageId, isMine, subscribed, basePath} = usePageScope()
  const canEdit = PermissionsService.checkPermissions(permissions, "canEdit")
  const canView = PermissionsService.checkPermissions(permissions, "canAccessChapters")
  const brief = {...(page?.brief || {})}
  const validBrief = Object.keys(brief).length > 0
  if (validBrief) {
    brief.name = brief.firstName + " " + brief.lastName
    brief.born = brief.birthday ? (moment(page.brief.birthday, CONSTANTS.dateFormat).format(CONSTANTS.prettyDate) + ` (${moment().diff(moment(page.brief.birthday, CONSTANTS.dateFormat).format(), "years")})`) : "-"
    const deceasedDif = moment(page.brief.deceased, CONSTANTS.dateFormat).diff(moment(page.brief.birthday, CONSTANTS.dateFormat).format(), "years")
    brief.deceased = brief.deceased ? (moment(page.brief.deceased, CONSTANTS.dateFormat).format(CONSTANTS.prettyDate) + (!isNaN(+deceasedDif) ? ` (${deceasedDif})` : "")) : "-"
  }


  const toggleFollowing = () => {
    briefDispatch.followPage(pageId, !subscribed)
  }

  React.useEffect(() => {
    const data = findGetParam("onboarding", true)
    if (data) {
      briefDispatch.setEditBrief("ONBOARD")
    }
  }, [])

  const getProp = (propName) => {
    if (propName === "birthname") {
      return (brief.birthName || brief.firstName) + (brief.middleName ? ` ${brief.middleName}` : "") + (` ${brief.maidenName || brief.lastName || ""}`) + (brief.suffix ? ` ${brief.suffix}` : '')
    } else {
      return brief[propName] || ""
    }
  }

  const getRelation = (prop) => {
    if (prop.relation && prop.relation.relationshipType === "divorced") {
      return `${prop.firstName} ${prop.lastName}${prop.relation.to ? ` (${moment(prop.relation.from, CONSTANTS.dateFormat).format("YYYY")}-${moment(prop.relation.to, CONSTANTS.dateFormat).format("YYYY")})` : ""}`
    } else {
      return `${prop.firstName} ${prop.lastName}`
    }
  }

  const filteredFields = BriefFields.filter(o => !((!getProp(o.field) || getProp(o.field) === "-") && o.hide))
  const myFamilyTypes = page ? (LISTS.data.relationTypes || []).filter(o => page.relations[o]?.length > 0) : []

  const maxHeight = height - CONSTANTS.navHeight - 90

  const resetContext = (newPage) => history.push(`${basePath}/${newPage}/chapters`)

  const sendMyPageId = () => {
    if (!sendEmail) return
    setSendingPage(false)
    PagesService.sendCode(pageId, sendEmail).then(res => {
      rootDispatch.setMessage({
        type: "success",
        message: "Code sent!"
      })
    }).catch(e => {
      rootDispatch.setMessage({
        type: "error",
        message: "You must wait 1 minute before sending another email."
      })
    })
  }

  const getMinWidth = (text, fontSize) => {
    const test = document.getElementById("test")
    if (!test) return 0
    test.innerText = text
    test.style.fontSize = fontSize
    return (test.clientWidth + 1)
  }

  const openBrief = () => (
    <Modal id={"brief-modal"} open={!!editBrief} closeOnDimmerClick={false}
           onClose={() => editBrief === "ONBOARD" ? {} : briefDispatch.setEditBrief(false)}
           style={EditProfileModalStyles(height, 0)}>
      <Modal.Header>{'Edit '} <span style={{textTransform: 'capitalize'}}>{editBrief}</span></Modal.Header>
      <EditProfile type={editBrief} close={() => briefDispatch.setEditBrief(false)}/>
    </Modal>
  )

  const sendCode = () => (
    <SendPageContainer active={sendingPage}>
      <FormInput value={sendEmail}
                 onChange={(_, {value}) => setSendEmail(value)}
                 action={{
                   icon: "paper plane",
                   onClick: sendMyPageId
                 }} formMargin={false} fluid containerStyle={{marginTop: 4}}
                 placeholder={"Enter email..."}/>
    </SendPageContainer>
  )

  if (!page) {
    return (
      <BriefContainer style={{maxHeight, height: maxHeight}}>
        <ProfileDetailsSkeleton style={{maxHeight, height: maxHeight}}/>
      </BriefContainer>
    )
  }

  const smallHeight = height <= 750

  const ImageHolder = brief?.gender === 'female' ? FemaleImageHolder : MaleImageHolder

  return (
    <>
      <BriefContainer style={{maxHeight, height: maxHeight}}>
        <SizeTester id={"test"}/>
        {openBrief()}
        <ContentFlex direction={"column"} wrap={"unset"} className={"inner-brief"}
                     style={{...{maxHeight, height: maxHeight}, ...(smallHeight ? {overflowY: "auto"} : {})}}>
          <div style={{flex: 1}}>
            {(canView && !isMine) &&
            <EditItemCircle style={{top: 5}} onClick={() => toggleFollowing()}>
              {!subscribed && <NotificationsOff width={14} color={"white"}/>}
              {subscribed && <Notifications width={14} color={customTheme.quaternary}/>}
            </EditItemCircle>}

            <FlexBox align={"center"} justify={"center"}>
              <HeirloomImage src={brief?.image || ImageHolder} style={{width: 100, height: 100, borderRadius: 50}}/>
            </FlexBox>

            <AccountPageSelector itemSelected={resetContext}/>

            <MetaContent>
              <FlexBox align={'center'} justify={'space-between'}>
                <strong style={{marginLeft: 6, marginTop: 6, fontSize: 14}}>Biography</strong>
                {canEdit && <ChipBtn onClick={() => briefDispatch.setEditBrief('bio')}
                                     style={{
                                       backgroundColor: customTheme.quaternary,
                                       color: 'white'
                                     }}><strong>Edit</strong></ChipBtn>}
              </FlexBox>
            </MetaContent>

            {sendCode()}

            <div style={{marginTop: 12}}/>


            <Grid columns={2} style={{margin: 0}}>
              {validBrief && filteredFields
                .map((field, i) => (
                  <Grid.Column style={{padding: 5}}>
                    <DataBlock index={i}>
                      <div>{field.title}</div>
                      <strong>{getProp(field.field)}</strong>
                    </DataBlock>
                  </Grid.Column>
                ))}
            </Grid>

            <Divider/>
          </div>

          <FamilyContainer style={smallHeight ? {overflow: "hidden", minHeight: 400} : {}}>

            <FlexBox align={'center'} justify={'space-between'} style={{marginBottom: 12}}>
              <strong style={{marginTop: 6, fontSize: 14}}>Family</strong>
              {canEdit && <ChipBtn onClick={() => {
                briefDispatch.setEditBrief('family')
                if (!onboarding.addFamily) {
                  rootDispatch.setShowNux('addFamily')
                }
              }}
                                   style={{
                                     backgroundColor: customTheme.quaternary,
                                     color: 'white'
                                   }}><strong>Edit</strong></ChipBtn>}
            </FlexBox>

            {validBrief && myFamilyTypes.map((relation, i) => (
              <RelativeSection>
                <div className={"relative-title"}>{relation}</div>
                <div className={"relative-content"}>
                  {
                    page.relations[relation].map((relative, i) => relative.pageCode ? (
                      <Link to={`${basePath}/${relative.pageCode}/chapters`} style={{
                        width: getMinWidth(getRelation(relative), 10),
                        color: customTheme.primary
                      }}>{getRelation(relative)} {i !== page.relations[relation].length - 1 ? ", " : ""}</Link>
                    ) : getRelation(relative) + ((i !== page.relations[relation].length - 1) ? ", " : ""))
                  }
                </div>
              </RelativeSection>
            ))
            }
          </FamilyContainer>
        </ContentFlex>
      </BriefContainer>
    </>
  )
}

export default ProfileDetails

const FamilyContainer = styled.div`
  overflow-y: auto;
  max-height: 100%;
  flex: 1;
  width: 100%;
  ${ScrollbarStyles}
`

const DataBlock = styled.div`
  font-size: 11px;
  background-color: #FBFBFB;
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 4px;
`

const BottomButton = styled.div`
  text-align: center;
  color: ${props => props.theme.quaternary};
  height: 40px;
  line-height: 38px;
  font-weight: bold;
  border-radius: 0 0 6px 6px;
  cursor: pointer;
  background-color: white;
  border-top: 1px solid rgba(34, 36, 38, .15);
  width: 260px;

  &:hover {
    color: ${props => props.theme.quaternary};
  }
`

const BriefContainer = styled.div`
  overflow: hidden;
  width: 260px;
  min-width: 260px;
  position: relative;
  background-color: white;
  border-radius: 6px;
  min-height: 700px;
  .inner-brief {
    padding: 14px;
  }
`

const RelativeSection = styled.div`
  .relative-title {
    font-size: 12px;
    font-weight: bold;
    text-transform: capitalize;
  }

  .relative-content {
    font-size: 11px;
  }

  margin-bottom: 12px;

  a:hover {
    color: ${props => props.theme.quaternary} !important;
  }
`

const SizeTester = styled.div`
  position: absolute;
  visibility: hidden;
  height: auto;
  width: auto;
  white-space: nowrap;
`

const ContentFlex = styled(FlexBox)`
  ${ScrollbarStyles}
`
