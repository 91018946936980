import React, { useState } from "react"
import { FlexBox, SettingsPage } from "../../styles/Containers.js"
import { useDispatch, useSelector } from "react-redux"
import { FormSectionTitle } from "../../styles/Forms.js"
import { Button, Confirm, Icon, Segment } from "semantic-ui-react"
import AddBillingMethod from "./BillingMethodModal.js"
import { SelectableSegment } from "../../styles/Semantic.js"
import customTheme from "../../styles/theme.js"
import Root from "../../store/reducers/root/actions.js"
import ModalLoader from "../../components/common/ModalLoader.js"
import Auth from "../../store/reducers/auth/actions.js"
import BillingService from "../../services/billing.js"
import AuthService from "../../services/auth.js"

export const MyPaymentMethods = ({ billing, actionable, onClick, customClass, active }) => {
	const [addMethod, setAddMethod] = useState(false)
	const [confirmDelete, setConfirmDelete] = useState(false)
	const [loading, setLoading] = useState(false)
	const RootDispatch = new Root(useDispatch())
	const AuthDispatch = new Auth(useDispatch())

	const deleteMethod = () => {
		setLoading(true)
		BillingService.deletePaymentMethod(confirmDelete.id).then(() => {
			AuthService.getMe(true).then(async user => {
				AuthDispatch.setUser({ value: user, fetched: true })
			}).finally(() => {
				setConfirmDelete(null)
				setLoading(false)
			})
			RootDispatch.setMessage({
				type: "success",
				message: "Payment method removed"
			})
		}).catch(e => {
			setLoading(false)
			RootDispatch.setMessage({
				type: "error",
				message: "Error removing payment method"
			})
		})
	}

	return (
		<>
			<Confirm
				content={<>
					<p style={{ padding: "20px 20px 10px 20px" }}>
						Are you sure you would like to remove this payment method?
					</p>
					<ModalLoader active={loading}/>
				</>}
				confirmButton={"Confirm"}
				onCancel={() => setConfirmDelete(false)}
				onConfirm={deleteMethod}
				open={!!confirmDelete}
			/>
			<AddBillingMethod isOpen={addMethod} close={() => setAddMethod(false)}/>
			{billing.map(o => (
				<Segment className={customClass} onClick={() => onClick ? onClick(o) : {}}
								 style={active === o.id ? { border: "thin solid " + customTheme.quaternary } : {}}>
					<FlexBox align={"center"}>
						<Icon name={"credit card"}
									style={{ fontSize: 40, marginRight: 20, position: "relative", top: 12 }}/>
						<FlexBox>
							<strong style={{ textTransform: "uppercase", marginRight: 20 }}>{o.card.brand} </strong>
							**** {o.card.last4}
							<div style={{ textAlign: "center", flex: 1 }}>exp: {o.card.exp_month}/{o.card.exp_year}</div>
						</FlexBox>
						{!actionable && <Icon name={"trash"} onClick={() => setConfirmDelete(o)}
																	style={{ cursor: "pointer" }}/>}
					</FlexBox>
				</Segment>
			))}
			<SelectableSegment onClick={() => setAddMethod(true)}>
				<Icon name={"add circle"} style={{ marginRight: 20 }}/>
				Add payment method
			</SelectableSegment>
		</>
	)
}

const Billing = ({}) => {
	const user = useSelector(Auth.user)

	if (!user?.stripeCustomer || !user.billing || !user.billing.length) {
		return <div/>
	}

	return (
		<SettingsPage style={{ minHeight: 140 }} className={"billing-settings"}>
			<FormSectionTitle>
				Payment methods
			</FormSectionTitle>

			<div style={{ marginTop: 20 }}>
				<MyPaymentMethods billing={user.billing}/>
			</div>
		</SettingsPage>
	)
}

export default Billing
