import React from 'react'
import {Button, Form, Header, Icon, Input, Message, Modal} from "semantic-ui-react";
import AvatarEditor from 'react-avatar-editor'
import ImageHolder from '@images/image-not-found.png';
import {useDropzone} from "react-dropzone";
import {FlexBox} from "../../styles/Containers.js";
import MediaService from "../../services/media.js";
import {useSelector} from "react-redux";
import Biography from "../../store/reducers/biography/actions.js";
import {lang} from "../../config/languages";
import {usePageScope} from "../../store/hooks/page.hooks.js";

function MyDropzone({active, onDrop}) {
  const {getRootProps, getInputProps, rejectedFiles, isDragActive} = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png'
  })

  const fileButtonProps = {
    primary: true,
    fluid: true,
    size: "large",
    content: 'Pick Image'
  }

  return (
    <Form.Field>
      <div {...getRootProps()}>
        <input {...getInputProps()} multiple={false} maxLength="256"/>
        <Button {...fileButtonProps} />

      </div>
      {rejectedFiles?.length ?
        <Message negative>
          Only image files will be accepted
        </Message> : ''
      }
    </Form.Field>
  )
}


const SetProfilePicture = ({onUrl, style, img, onBase64}) => {
  const [acceptedFiles, setAcceptedFiles] = React.useState([]);
  const [scale, setScale] = React.useState(1.0);
  const [visible, setVisible] = React.useState(false);
  const [error, setError] = React.useState('')
  const image = (acceptedFiles.length > 0) ? acceptedFiles[0] : (img || ImageHolder)
  const [loading, setLoading] = React.useState(false)
  const {pageId} = usePageScope()

  const editor = React.useRef();
  const width = 260
  const borderRadius = (width / 2)
  const height = width

  const close = () => {
    setVisible(false);
    setAcceptedFiles([]);
    setScale(1);
    setError(false);
  }

  const onDrop = (files) => {
    setAcceptedFiles(files);
  }

  const alterScale = (_, {value}) => {
    setScale(parseFloat(value))
  }

  const getUrlForNewImage = () => {
    try {
      if (editor) {
        const ed = editor.current
        const canvasScaled = ed.getImageScaledToCanvas()
        const picture = canvasScaled.toDataURL('image/jpeg');
        if (onBase64) {
          onBase64(picture);
          close();
          return;
        }
        const file = MediaService.dataURLtoFile(picture, 'test');
        const fileWithExt = MediaService.dataURLtoFile(picture, new Date().getTime() + MediaService.getExtension(file.type));
        const totalBytes = fileWithExt.size;
        const size = totalBytes / 1000000;
        if (size > 10) {
          setError('Max file size is 10 MB');
          return;
        }
        setLoading(true);
        MediaService.uploadImage([fileWithExt], {description: '', tags: []}, pageId, () => {
        })
          .finally(() => {
            setLoading(false)
          }).then(res => {
          onUrl(res.Location);
          close();
        }).catch(err => setError(err))
      }
    } catch (e) {
      console.log(e)
      setError('Invalid or corrupt image file');
    }
  }

  const avatarProps = {
    image, width, height,
    border: 0, scale, rotate: 0,
    color: [0, 0, 0, 0.7],
    ref: editor,
		borderRadius: 500
  }

  return (
    <div>
      <Modal open={visible} size={'mini'} centered={true} close={close}>
        <Header style={{height: 55}}>
          Your Everloom Photo
          <Button style={{fontSize: '1.6rem', backgroundColor: "white", marginRight: 0}} icon="close"
                  floated="right" onClick={close} data-test='modal-button-close'/>
        </Header>
        <Modal.Content scrolling={true}>
          <h4 style={{textAlign: 'center'}}>{lang['uploadPhoto']}</h4>
          <FlexBox align={'center'} justify={'center'} style={{marginBottom: 12}}>
            <AvatarEditor {...avatarProps}/>
          </FlexBox>
          {acceptedFiles.length === 0
            ? (
              <MyDropzone active={acceptedFiles.length} onDrop={onDrop}/>
            )
            : (
              <div>
                <Form.Field>
                  <Input fluid type="range" step={0.01} min={1.0} max={2.0} name="scale"
                         value={scale} onChange={alterScale}/>
                </Form.Field>
                <Form.Field>
                  <Button loading={loading} primary fluid
                          onClick={getUrlForNewImage}>Save</Button>
                </Form.Field>
              </div>
            )
          }
          {error && <Message negative>
            {error}
          </Message>
          }
        </Modal.Content>
      </Modal>
      <Button primary style={style} icon labelPosition='left' fluid onClick={() => setVisible(true)}>
        <Icon name='cloud upload' style={{color: 'white'}}/>
        <div style={{marginLeft: 12}}>
          Upload Photo
        </div>
      </Button>
    </div>
  );
};

export default SetProfilePicture;

