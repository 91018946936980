import API from "./api.js";

export default class MemoriesService {

    static BASE = 'memories';

    static postMemory = (data, pageId) => {
        return API.put(`${MemoriesService.BASE}/${pageId}/post`, data)
    }

    static getMemories = (pageId) => {
        return API.get(`${MemoriesService.BASE}/${pageId}/feed`)
    }

    static approvePost = (postId, pageId) => {
        return API.post(`${MemoriesService.BASE}/${pageId}/approve`, {postId})
    }

    static declinePost = (postId, pageId) => {
        return API.post(`${MemoriesService.BASE}/${pageId}/decline`, {postId})
    }

    static updateMemorySettings = (settings, pageId) => {
        return API.post(`${MemoriesService.BASE}/${pageId}/settings`, {settings})
    }
}
