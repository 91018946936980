import styled from "styled-components"
import { FlexBox } from "./Containers"

const FormSectionTitle = styled(FlexBox)`
	font-size: 18px;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	flex-wrap: unset;

	.ui.icon.button>.icon, .ui.icon.buttons .button>.icon {
		margin-right: 10px !important;
	}

	.ui.button {
		box-shadow: 0px 4px 5px rgba(17, 131, 121, 0.25);
	}
`

const FormSectionDescription = styled.div`
	line-height: 26px;
	font-size: 14px;

	@media only screen and (max-width: 450px) {
		font-size: 12px;
	}
`

const InputLabel = styled.div`
	text-transform: capitalize;
`

const formStyles = {
	inputSideMargin: {
		marginLeft: 10,
		marginTop: 4
	}
}

const ChipBtn = styled.button`
	min-width: 45px;
	width: auto;
	margin-right: 2px;
	height: 20px;
	border: none;
	font-size: 10px;
	border-radius: 4px;

	&:hover {
		border: thin solid rgba(0, 0, 0, 0.3);
		cursor: pointer;
	}

	&:active, &:focus {
		outline: none;
	}

	background-color: #F8F8FA;
`

export {
	FormSectionDescription,
	FormSectionTitle,
	InputLabel,
	formStyles,
	ChipBtn
}
