import React, {useState} from 'react'
import MediaService from "../../services/media";
import {ContainerProgress, ImageProgress} from "../../styles/Semantic";
import customTheme from "../../styles/theme";
import Dropzone from "react-dropzone";
import {DropzoneSection} from "../../styles/Containers.js";
import {Icon} from "semantic-ui-react";
import {useDispatch} from "react-redux";
import Root from "../../store/reducers/root/actions.js";
import {CONSTANTS} from "../../config/constants.js";
import {usePageScope} from "../../store/hooks/page.hooks.js";
import mime from 'mime-types'

const UploadImageComponent = ({
                                allowMultiple, onData,
                                type, useButton, isIcon, uploadedNew = () => null,
                                children, onUrl, style = {}, percent
                              }) => {
  const rootDispatch = new Root(useDispatch())
  const [uploadProgress, setUploadProgress] = useState(-1);
  const {pageId, activePlan} = usePageScope()

  const getAcceptList = () => {
    let extensions = [];
    if (activePlan.imageOnly) {
      for (let ext of CONSTANTS.acceptedImages) {
        extensions = CONSTANTS.acceptedImages
      }
    } else {
      for (let ext of [...CONSTANTS.acceptedImages, ...CONSTANTS.acceptedVideos, ...CONSTANTS.acceptedMedia]) {
        extensions = [...CONSTANTS.acceptedImages, ...CONSTANTS.acceptedVideos, ...CONSTANTS.acceptedMedia]
      }
    }
    return extensions
  }

  const getBase64 = async (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    })
  }

  const sendAllBase64 = async (picture) => {
    const pics = [];
    for (const pic of picture) {
      const data = await getBase64(pic)
      pics.push(data);
    }
    onUrl(pics);
  }

  const onDrop = (picture) => {
    if (type === 'base64') {
      if (allowMultiple) {
        sendAllBase64(picture)
      } else {
        getBase64(picture[0]).then(data => onUrl(data));
      }
      return;
    }
    uploadInternal(picture)
  }

  const uploadInternal = (picture) => {
    MediaService.uploadImage(picture, {description: '', tags: []}, pageId, (loading) => {
      if (loading.loaded && loading.total && loading.total !== 0) {
        setUploadProgress((loading.loaded / loading.total) * 100);
      } else {
        setUploadProgress(0);
      }
    }).finally(() => {
      setUploadProgress(-1);
    }).then(res => {
      console.log('Upload internal')
      if (onUrl) {
        console.log('Done onUrl')
        onUrl(res.Location)
      }
      if (onData) {
        console.log('Done onData')
        onData(res)
      }
      rootDispatch.setMessage({
        type: 'success',
        message: "Upload successfully complete!"
      })
      uploadedNew()
    }).catch(err => rootDispatch.setMessage({
      type: 'success',
      message: err
    }))
  }


  if (useButton) {
    return (
      <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles)}
                accept={getAcceptList()}
                style={{backgroundColor: 'red', height: '100%'}}>
        {({getRootProps, getInputProps}) => (
          <div style={{...style}}>
            <input {...getInputProps()}/>
            <div {...getRootProps()} style={{position: 'relative'}}>
              {(uploadProgress > -1 || percent > -1) &&
              <ImageProgress button color={'green'} percent={uploadProgress || percent} indicating/>}
              {children}
            </div>
          </div>
        )}
      </Dropzone>
    )
  }
  return (
    <div style={{...style}}>
      <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles)}
                accept={getAcceptList()}
                style={{backgroundColor: 'red', height: '100%'}}>
        {({getRootProps, getInputProps}) => (
          <section style={{...style, position: 'relative', opacity: 1}}>
            <DropzoneSection isIcon={isIcon} {...getRootProps()} style={{textAlign: 'center'}}>
              <input {...getInputProps()} />
              <Icon style={{fontSize: isIcon ? 30 : 80}} name="cloud upload"/>
              {!isIcon && <p>Drag 'n' drop some files here, or click to select files</p>}
            </DropzoneSection>
            {(uploadProgress > -1 || percent > -1) &&
            <ContainerProgress color={customTheme.primary} percent={uploadProgress || percent} indicating/>}
          </section>
        )}
      </Dropzone>
    </div>
  );
};

export default UploadImageComponent;
