import React from 'react'
import LogoLoader from "./LogoLoader.js";
import styled from "styled-components";

const InlinePageLoader = ({active = true}) => {
  if (!active) {
    return <div/>
  }
  return (
    <LoaderContainer>
      <LogoLoader alt/>
    </LoaderContainer>
  );
};

export default InlinePageLoader;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
`
