import {
	INITIAL_STATE,
	SET_ERROR,
	SET_LOADING_INNER,
	SET_NOTIF_DATA,
	SET_NOTIFS_VISIBLE,
	SET_SETTINGS
} from "./initialState"
import { RESET_STATE } from "../common/reducer.class"

export const notificationsReducer = (state = INITIAL_STATE, action) => {
	const { payload, type, meta } = action

	switch (type) {
		case SET_LOADING_INNER:
			return {
				...state,
				innerLoading: payload
			}
		case SET_ERROR:
			return {
				...state,
				error: payload
			}
		case SET_SETTINGS:
			return {
				...state,
				settings: payload
			}
		case SET_NOTIFS_VISIBLE:
			return {
				...state,
				visible: payload
			}
		case SET_NOTIF_DATA:
			return {
				...state,
				error: null,
				notifications: payload
			}
		case RESET_STATE:
			return INITIAL_STATE
		default:
			return state
	}
}
