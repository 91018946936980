import React from 'react'
import useInterval from "../utils/useInterval";

const Countdown = ({done, time, label = ''}) => {
    const [value, setValue] = React.useState(time);
    useInterval(() => {
        if (value === 0) {
            done();
        } else {
            setValue(value - 1);
        }
    }, [1000]);

    return (
        <div style={{color: 'white'}}>
            {label}{value}
        </div>
    );
};

export default Countdown;
