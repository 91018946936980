import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Root from "./store/reducers/root/actions"
import Auth from "./store/reducers/auth/actions"
import { AuthedRoutes, OnboardingRoutes, Routes, VerifyRoutes } from "./Routes"
import { PageLoader } from "./styles/Containers.js"
import ConfigService from "./services/config.js"
import LogoLoader from "./components/common/LogoLoader.js"
import AuthLayout from "./layout/AuthLayout.js"
import { Route, Switch, useHistory } from "react-router-dom"
import useWindowSize from "./utils/dimensions.js"
import { CONSTANTS } from "./config/constants.js"
import MessageManager from "./components/MessageManager.js"
import AdminLayout from "./layout/AdminLayout.js"
import Confetti from "react-confetti"
import OnboardingLayout from "./layout/Onboarding.js"
import { languages } from "./config/languages"
import AuthService from "./services/auth.js"
import ModalView from "./layout/Modals"
import findGetParam from "./utils/findGetParam"
import { QueryClient, QueryClientProvider } from "react-query"
import firebase from "firebase/app"
import { useOnboarding } from "./store/hooks/page.hooks.js"
import RunEmailVerification from "./pages/Auth/RunEmailVerification.js"
import Demo from "./pages/Profile/Demo.js"
import useDimensions from "./utils/getDimensions.js"
import { DefaultToast, ToastProvider } from "react-toast-notifications"
import { isDemoDomain } from "./utils/isDemo"

const AppContainer = () => {
	useDimensions()
	const dispatch = useDispatch()
	const history = useHistory()
	const RootDispatch = new Root(dispatch)
	const AuthDispatch = new Auth(dispatch)
	const onboarding = useOnboarding()
	const loading = useSelector(RootDispatch.getLoading)
	const isAuthenticated = useSelector(Auth.isAuthenticated)
	const user = useSelector(Auth.user)
	const showConfetti = useSelector(Root.showConfetti)
	let pageLoading = useSelector(Root.pageLoading)
	const { width, height } = useWindowSize()
	const language = useSelector(Root.getLang)
	const tokenData = useSelector(Auth.tokenData)
	const isAdmin = (user?.roles || []).indexOf("admin") > -1
	let isOnboarded
	let isVerified = user?.emailVerified
	languages.value = language
	const isDemo = isDemoDomain

	if (!isAuthenticated) {
		isVerified = false
		isOnboarded = false
	}

	if (user && !user.primaryPageId) {
		isOnboarded = false
	} else if (user && user.primaryPageId) {
		isOnboarded = true
	}

	useEffect(() => {
		history.listen((location, action) => {
			firebase.analytics().setCurrentScreen(history.pathname)
		})
	}, [])

	useEffect(() => {
		if (width <= CONSTANTS.collapsedSize) {
			RootDispatch.toggleSideNav(false)
		} else {
			RootDispatch.toggleSideNav(true)
		}
	}, [width, history.location.pathname])

	useEffect(() => {
		console.log("v1.0.0")
		ConfigService.getConfig()
		RootDispatch.setError(null)
		RootDispatch.setDataFetched({ value: false })

		const referral = findGetParam("referral", true)
		if (referral) {
			(async () => {
				AuthService.referralInfo = {
					code: referral
				}
				try {
					const name = await AuthService.getReferralCodeName(referral)
					AuthService.referralInfo = {
						code: referral,
						name
					}
				} catch (e) {
				}
			})()
		}
	}, [])

	useEffect(() => {
		if (isAuthenticated && isAdmin) {
			history.push(`/admin/chapter-sections`)
		} else if (isAuthenticated && !user && tokenData) {
			AuthService.setTokens(tokenData)
			RootDispatch.setPageLoading({ value: true })
			AuthService.getMe(true).then(async user => {
				AuthDispatch.setUser({ value: user, fetched: true })
			}).finally(() => RootDispatch.setPageLoading({ value: false }))
		} else {
			RootDispatch.setDataFetched({ value: false })
		}
		if (isAuthenticated && user) {
			firebase.analytics().setUserProperties({
				firstName: user.firstName,
				lastName: user.lastName
			})
			firebase.analytics().setUserId(user.uid)
		}
	}, [isAuthenticated, user])

	useEffect(() => {
		if (isAuthenticated) {
			if (isDemo) {
				localStorage.clear()
				firebase.auth().signOut()
				return
			}
			const sessions = localStorage.getItem("sessions") || 0
			if (+sessions === 3 && !onboarding.createEverloom) {
				RootDispatch.setShowNux("createEverloom")
			} else if (onboarding.createEverloom) {
				localStorage.removeItem("sessions")
			} else {
				localStorage.setItem("sessions", (+sessions + 1).toString())
			}
		}
	}, [isAuthenticated])

	const pageLoader = (
		<PageLoader>
			<LogoLoader active/>
		</PageLoader>
	)

	// if (width < CONSTANTS.toSmallToEdit) {
	//   return <MobileDisabled/>
	// }

	if (loading || isVerified === undefined || isOnboarded === undefined) {
		return pageLoader
	}
	let content

	if (window.location.href.includes("verify-email")) {
		return <Route exact path="/verify-email" component={RunEmailVerification}/>
	}

	if (isDemo) {
		content = (
			<Switch>
				<Route path="**" component={Demo}/>
			</Switch>
		)
	} else if (isAuthenticated) {
		if (!user) {
			return pageLoader
		}
		if (isAdmin) {
			content = <AdminLayout/>
		} else if (isVerified && isOnboarded) {
			content = <AuthedRoutes/>
		} else if (isVerified) {
			content = (
				<OnboardingLayout>
					<OnboardingRoutes/>
				</OnboardingLayout>
			)
		} else {
			content = (
				<AuthLayout>
					<VerifyRoutes/>
				</AuthLayout>
			)
		}

	} else {
		content = (
			<AuthLayout>
				<Routes/>
			</AuthLayout>
		)
	}

	return (
		<div className="App">
			{pageLoading && (
				<PageLoader>
					<LogoLoader active/>
				</PageLoader>
			)}
			<div style={pageLoading ? { display: "none" } : {}}>
				<ModalView/>
				<div id={"gallery-modal"}/>
				<MessageManager/>
				{content}
			</div>
			{showConfetti && <Confetti
				className={"confetti-class"}
				width={width}
				height={height}
			/>}
		</div>
	)
}

const queryClient = new QueryClient()

export const MyCustomToast = ({ children, ...props }) => {
	return (
		<DefaultToast {...props}>
			<div style={{cursor: 'pointer'}} onClick={() => props.callback ? props.callback() : null} dangerouslySetInnerHTML={{__html: children}} />
		</DefaultToast>
	)
}

const App = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<ToastProvider components={{ Toast: MyCustomToast }}>
				{AppContainer()}
			</ToastProvider>
		</QueryClientProvider>
	)
}

export default App
