import React, {useState} from 'react'
import GalleryModal from "./GalleryModal"
import { useDispatch, useSelector } from "react-redux"
import Media from "../../store/reducers/media/actions"
import ViewImage from "./ViewImage"

const ImageViewer = ({}) => {
	const dispatch = useDispatch();
	const MediaDispatch = new Media(dispatch);
	const galleryModalOpen = useSelector(Media.viewGallery)
	const viewImage = useSelector(Media.viewImage)

    return (
        <div>
					{galleryModalOpen && <GalleryModal close={() => MediaDispatch.setViewGallery(null)} />}
					{viewImage && <ViewImage close={() => MediaDispatch.setViewImage(null, [])} />}
        </div>
    );
};

export default ImageViewer;
