import React, {useEffect} from 'react'
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import FormInput from "../FormInput.js";
import styled from "styled-components";

const PaymentMethod = ({setBillingDetails, billingDetails}) => {
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        if (stripe && elements && !billingDetails.stripeEnabled) {
            setBillingDetails({
                ...billingDetails,
                stripeEnabled: true
            })
        }
    }, [stripe, elements])

    const updateForm = async () => {
        if (!stripe || !elements) {
            return;
        }
        const cardElement = elements.getElement(CardElement);
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        const token = await stripe.createToken(cardElement)
        if (error || !token || !token.token) {
            // console.log('[error]', error);
        } else {
            setBillingDetails({
                ...billingDetails,
                billing_details: {
                    ...billingDetails.billing_details,
                    address: {
                        ...billingDetails.billing_details.address,
                        postal_code: paymentMethod.billing_details.address.postal_code
                    }
                },
                id: token.token.id
            })
            // console.log('[PaymentMethod]', paymentMethod);
        }
    };

    return (
        <PaymentContainer>
            <FormInput formMargin={false} placeholder={'Name on card'} fluid containerStyle={{marginBottom: 12}}/>
            <CardElement onChange={updateForm}/>
        </PaymentContainer>
    );
};

export default PaymentMethod;

const PaymentContainer = styled.div`
  max-width: 350px;
  width: 100%;

  .StripeElement {
    border: thin solid #e2e2e2;
    padding: 10px;
    border-radius: 4px;
  }
`;
