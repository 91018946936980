export const INITIAL_STATE = {
	loading: false,
	error: null,
	data: {}
	// feed: [],
	// pending: [],
	// settings: {}
}

const SET_LOADING = "set_loading"
const SET_ERROR = "set_error"
const SET_MEMORY_DATA = "SET_MEMORY_DATA"
const SET_MEMORY_SETTINGS = "SET_MEMORY_SETTINGS"
const SET_WRITE = "SET_WRITE"

export {
	SET_LOADING,
	SET_ERROR,
	SET_MEMORY_DATA,
	SET_MEMORY_SETTINGS,
	SET_WRITE
}
