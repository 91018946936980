export const INITIAL_STATE = {
	loading: false,
	error: null,
	data: {
		myImages: []
	},
	viewingImage: null,
	selectedGallery: null,
	selectable: false,
	selectedImageData: null
}

const SET_LOADING = "set_loading"
const SET_ERROR = "set_error"
const SET_IMAGES = "set_images"
const GET_IMAGES = "get_images"
const SET_VIEWING_IMAGE = "SET_VIEWING_IMAGE"
const SET_VIEWING_GALLERY = "SET_VIEWING_GALLERY"
const SET_SELECTED_IMAGE = 'SET_SELECTED_IMAGE'

export {
	SET_LOADING,
	SET_ERROR,
	SET_IMAGES,
	GET_IMAGES,
	SET_VIEWING_IMAGE,
	SET_VIEWING_GALLERY,
	SET_SELECTED_IMAGE
}
