import * as React from "react"
import {Redirect, Route, Switch} from "react-router-dom"
import RunEmailVerification from "./pages/Auth/RunEmailVerification.js"
import VerifyEmail from "./pages/Auth/VerifyEmail.js"
import RedirectHome from "./components/RedirectHome"
import FamilyPage from "./pages/FamilyPage"
import Screen from "./layout/Screen"
import SideBarMenu from "./layout/SideBarMenu"
import AccountComponent from "./pages/Account"
import MyProfile from "./pages/Profile"
import NotFound from "./components/NotFound"
import LoginComponent from "./pages/Auth/Login"
import ForgotComponent from "./pages/Auth/Forgot"
import SignUpComponent from "./pages/Auth/Signup"
import Onboarding from "./pages/Onboarding"
import HomeLayout from "./layout/HomeLayout"

const DemoRoutes = () => {
  return (
    <>
      <Switch>
        <Route exact path="/" component={RedirectHome}/>
				<Route exact path="/demo" component={RedirectHome}/>
				<Route exact path="/demo/home" component={FamilyPage}/>
        <Route exact path={"/demo/not-found/:type"} component={NotFound}/>
        <Route path="/demo/:pageId" component={MyProfile}/>
      </Switch>
    </>)
}

const AuthedRoutes = () => {
  return (
    <>
      <Switch>
        <Route exact path="/" component={RedirectHome}/>
        <Route exact path="/home" component={FamilyPage}/>
        <Route exact path="/account">
          <SideBarMenu>
            <HomeLayout>
              <Screen>
                <AccountComponent pageSpecific={false}/>
              </Screen>
            </HomeLayout>
          </SideBarMenu>
        </Route>
        <Route exact path={"/not-found/:type"} component={NotFound}/>
        <Route path="/:pageId" component={MyProfile}/>
			</Switch>
    </>)
}
const VerifyRoutes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <VerifyEmail/>
      </Route>
      <Route exact path="/verify">
        <VerifyEmail/>
      </Route>
      <Route exact path="/verify-email">
        <RunEmailVerification/>
      </Route>
      <Redirect to={"/"}/>
    </Switch>
  )
}

const OnboardingRoutes = () => {
  return (
    <Switch>
      <Route path="/onboarding" component={Onboarding}/>
      <Redirect to={"/onboarding?step=1"} from={"/"}/>
      <Redirect to={"/onboarding?step=1"} from={"/onboarding"}/>
    </Switch>
  )
}

const Routes = () => {
  return (
    <Switch>
      {/*<Route exact path="/">*/}
      {/*	<h3>Coming soon</h3>*/}
      {/*</Route>*/}
      <Route exact path="/">
        <Redirect to="/sign-up"/>
      </Route>
      <Route exact path="/login">
        <LoginComponent/>
      </Route>
      <Route exact path="/verify-email">
        <RunEmailVerification/>
      </Route>
      <Route exact path="/forgot-password">
        <ForgotComponent/>
      </Route>
      <Route exact path="/forgot-password/set-new">
        <ForgotComponent setting/>
      </Route>
      <Route exact path="/sign-up">
        <SignUpComponent/>
      </Route>
      <Redirect to={"/"}/>
    </Switch>
  )
}
const unAuthedRoutes = ["sign-up", "login", "forgot-password"]
export {
  Routes,
  AuthedRoutes,
  unAuthedRoutes,
  VerifyRoutes,
  OnboardingRoutes,
  DemoRoutes
}
