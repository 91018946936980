import { SET_MY_REFERRALS, SET_ERROR, SET_LOADING, INITIAL_STATE } from "./initialState"
import { RESET_STATE } from "../common/reducer.class"


export const userReducer = (state = INITIAL_STATE, action) => {
	const {payload, type, meta} = action

	switch (type) {
		case SET_LOADING:
			return {
				...state,
				loading: payload
			}
		case SET_ERROR:
			return {
				...state,
				error: payload
			}
		case SET_MY_REFERRALS: {
			return {
				...state,
				referrals: payload
			}
		}
		case RESET_STATE:
			return INITIAL_STATE;
		default:
			return state
	}
}
