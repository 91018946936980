import React, { useState } from "react"
import { Clickable, FlexBox, SettingsPage } from "../../styles/Containers.js"
import { FormSectionTitle } from "../../styles/Forms.js"
import { useSelector } from "react-redux"
import Auth from "../../store/reducers/auth/actions.js"
import { Button, Grid, Message, Modal } from "semantic-ui-react"
import FormInput from "../../components/common/FormInput.js"
import AuthService from "../../services/auth.js"
import PasswordInput from "../../components/common/PasswordInput"

const Settings = ({ setError, setSuccess, setLoading, loading, error }) => {
	const user = useSelector(Auth.user)
	const [resetPass, setResetPass] = useState(false)
	const [passwordData, setPasswordData] = useState({
		old: "",
		new: "",
		re: ""
	})
	const onChange = (event, { value, name }) => setPasswordData({ ...passwordData, [name]: value })

	const updatePass = () => {
		setError("")
		setSuccess("")
		if (passwordData.new !== passwordData.re) {
			setError("Passwords do not match")
			return
		}
		setLoading(true)
		AuthService.updatePass({
			password: passwordData.new,
			oldPassword: passwordData.old,
			update: true
		}).then(() => {
			setSuccess("Password successfully updated")
			setResetPass(false)
		})
			.catch(() => setError("There was an error updating your password"))
			.finally(() => setLoading(false))
	}

	const resetPasswordModal = () => (<Modal open={resetPass} close={() => setResetPass(false)} style={{ maxWidth: 400 }}>
		<Modal.Header>Change Password</Modal.Header>
		<Modal.Content>
			{!!error && <Message negative>{error}</Message>}

			<FlexBox direction={"column"}>

				<PasswordInput onChange={onChange} value={passwordData.old} placeholder={"Old password"}
											 name={"old"} containerStyle={{ width: "100%", marginTop: 4 }}
											 formMargin={false}/>

				<PasswordInput onChange={onChange} value={passwordData.new} placeholder={"New password"}
											 name={"new"} containerStyle={{ width: "100%", marginTop: 4 }}
											 formMargin={false} showHint/>

				<PasswordInput onChange={onChange} value={passwordData.re}
											 name={"re"} containerStyle={{ width: "100%", marginTop: 4 }}
											 formMargin={false} isReEnter/>
			</FlexBox>
		</Modal.Content>
		<Modal.Actions>

			<Button onClick={() => {
				setSuccess(null)
				setError(null)
				setResetPass(false)
			}}>Cancel</Button>
			<Button loading={loading} primary
							disabled={!passwordData.re || !passwordData.new || !passwordData.old || loading}
							onClick={updatePass}>Update</Button>
		</Modal.Actions>
	</Modal>)


	return (
		<SettingsPage>
			<div>
				<FormSectionTitle>
					Account
				</FormSectionTitle>

				<Grid columns={1} style={styles.contentMargin}>
					<Grid.Row>
						<Grid.Column>
							<FlexBox justify={"space-between"}>
								<div>Email</div>
								<strong>{user?.email}</strong>
							</FlexBox>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<FlexBox justify={"space-between"}>
								<div>Name</div>
								<strong>{user?.firstName} {user?.lastName}</strong>
							</FlexBox>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<FlexBox justify={"space-between"} align={"center"}>
								<div style={{ display: "flex", alignItems: "center" }}>Password</div>
								<Clickable active onClick={() => setResetPass(true)}>Change</Clickable>
							</FlexBox>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				{resetPasswordModal()}
			</div>
		</SettingsPage>
	)
}

export default Settings

const styles = {
	contentMargin: {
		marginTop: 12
	}
}
