import React from 'react'
import {Form} from "semantic-ui-react";
import {formStyles} from "../../styles/Forms.js";

const FormRadio = ({
                       name,
                       label,
                       value,
                       onChange,
                       containerStyle,
                       formMargin = true,
                       checked,
                       key
                   }) => {

    let styles = {...containerStyle}
    if (formMargin) {
        styles = {...styles, ...formStyles.inputSideMargin}
    }
    return (
        <div style={styles}>
            <Form.Field>
                <Form.Radio
                    label={label}
                    name={name}
                    value={value}
                    checked={checked}
                    primary
                    onChange={onChange}
                />
            </Form.Field>
        </div>
    );
};

export default FormRadio;
