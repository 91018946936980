import React, {useEffect, useState} from 'react'
import {Button, Header, Icon, Image, Loader, Segment} from "semantic-ui-react";
import SkeletonText from '@images/skeleton-text.png';
import {useDispatch} from "react-redux";
import AddChapterSection from "./AddChapterSection.js";
import Edit from "@images/icons/edit.svg";
import {AddCircle, CloseOutline} from "react-ionicons";
import debounce from 'lodash.debounce';
import Root from "../store/reducers/root/actions.js";
import AdminService from "../services/admin.js";
import {ActivityDot, FlexBox} from "../styles/Containers.js";
import PositionClicker from "../components/common/PositionClicker.js";
import HeirloomImage from "../components/common/HeirloomImage.js";

const AdminChapterSections = ({}) => {
  const [loadingSections, setLoadingSections] = useState(false);
  const [silentLoading, setSilentLoading] = useState(false);
  const [addSection, setAddSection] = useState(false);
  const [sectionData, setSectionData] = useState([]);
  const [editingData, setEditingData] = useState(null)
  const rootDispatch = new Root(useDispatch());

  const debouncedSave = debounce((sort) => {
    AdminService.updateChapterOrder(sort.sort((a, b) => a.index < b.index ? -1 : 1).map(o => o.id))
      .catch(e => {
        rootDispatch.setMessage({
          type: 'error',
          message: 'Error updating order'
        })
      })
  }, 1000);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = (silent = false) => {
    if (silent) {
      setSilentLoading(true)
    } else {
      setLoadingSections(true)
    }
    AdminService.getChapterSection().then(data => {
      setSectionData(data);
    }).catch(() => {
      rootDispatch.setMessage({
        type: 'error',
        message: 'There was an error loading content'
      });
    }).finally(() => {
      setSilentLoading(false);
      setLoadingSections(false);
    })
  }

  const deleteSection = (i) => {
    AdminService.deleteChapterSection(sectionData[i].id).then(() => {
      loadData(true);
      rootDispatch.setMessage({
        type: 'success',
        message: 'Section deleted'
      })
    }).catch(() => {
      rootDispatch.setMessage({
        type: 'error',
        message: 'Error deleting section'
      })
    })
  }

  const toggleActiveState = (i) => {
    AdminService.toggleChapterStatus(sectionData[i].id, !sectionData[i].active).then(() => {
      loadData(true);
      rootDispatch.setMessage({
        type: 'success',
        message: 'Section deleted'
      })
    }).catch(() => {
      rootDispatch.setMessage({
        type: 'error',
        message: 'Error deleting section'
      })
    })
  }

  const reorderValues = (dir, index) => {
    const sort = [...sectionData];
    if (index === 0 && dir === 'up') {
      return;
    }
    if (index === sectionData.length - 1 && dir === 'down') {
      return;
    }
    const i1 = sort.findIndex(o => o.index === index);
    const i2 = sort.findIndex(o => o.index === (dir === 'down' ? index + 1 : index - 1));

    if (i1 === -1 || i2 === -1) {
      return;
    }

    sort[i1].index = (dir === 'down' ? index + 1 : index - 1);
    sort[i2].index = index;
    setSectionData(sort);
    debouncedSave(sort)
  }

  return (
    <div style={{paddingBottom: 40}}>
      <Loader active={silentLoading}/>
      <h1>
        Chapter sections <AddCircle style={{cursor: 'pointer', position: 'relative', top: 2}}
                                    onClick={() => setAddSection(true)}/>
      </h1>
      <h4 style={{
        marginBottom: 50,
        opacity: 0.7
      }}>Add pre defined chapter types that potentially have prefilled chapter data and specified tips.</h4>
      <AddChapterSection editingData={editingData} sections={sectionData} isOpen={addSection} close={(reload) => {
        setEditingData(null);
        if (reload) {
          loadData(true);
        }
        setAddSection(false)
      }}/>

      {sectionData.length === 0 && <Segment loading={loadingSections}>
        {loadingSections && <Image src={SkeletonText}/>}
      </Segment>}

      {sectionData.sort((a, b) => a.index < b.index ? -1 : 1).map((o, i) => (
        <Segment style={{marginTop: 12}}>
          <FlexBox align={'center'}>
            <PositionClicker styles={{width: 100, flex: 0}} onClick={dir => reorderValues(dir, i)}/>
            <FlexBox justify={'space-between'} style={{marginLeft: 20}}>
              <div>
                {o.chapterTitle}
              </div>
              <div>
                <HeirloomImage style={{cursor: 'pointer'}}
                               onClick={() => {
                                 setAddSection(true)
                                 setEditingData({...o})
                               }} src={Edit}/>
                <CloseOutline style={{marginLeft: 10, cursor: 'pointer'}}
                              onClick={() => deleteSection(i)}/>
                <ActivityDot style={{marginLeft: 10}} active={o.active}
                             onClick={() => toggleActiveState(i)}/>
              </div>
            </FlexBox>
          </FlexBox>
        </Segment>
      ))
      }

      {(!loadingSections && sectionData.length === 0) && <Segment placeholder>
        <Header icon>
          <Icon name='database'/>
          No pre-defined sections exist for chapters
        </Header>
        <Button primary onClick={() => setAddSection(true)}>Add Section</Button>
      </Segment>}
    </div>
  );
};

export default AdminChapterSections;
