import React from 'react'
import {Modal} from "semantic-ui-react";
import {useDispatch, useSelector} from "react-redux";
import OnboardingReducer from "../../../store/reducers/onboarding/action.js";
import {BillingOptionsFlex} from "../../Account/Upgrade.js";
import {MyPaymentMethods} from "../../Account/Billing.js";
import Auth from "../../../store/reducers/auth/actions.js";

const SelectBillingMethod = ({close, open}) => {
    const data = useSelector(OnboardingReducer.formData)
    const onboarding = new OnboardingReducer(useDispatch());
    const user = useSelector(Auth.user)

    return (
        <Modal open={open} onClose={close} closeIcon size={'small'}>
            <Modal.Header>Select A Payment Method</Modal.Header>
            <Modal.Content>
              <Modal.Description style={{paddingBottom: 20}}>Link a credit card to purchase your Everloom. This billing information will be linked to your account.</Modal.Description>
                <BillingOptionsFlex>
                    <div style={{textAlign: 'center', maxWidth: 500, flex: 1}}>
                        <MyPaymentMethods billing={user.billing || []} actionable
                                          onClick={o => {
                                              onboarding.formChange(null, {name: 'paymentMethod', value: o.id});
                                              close();
                                          }}
                                          active={data.paymentMethod}
                                          customClass={'upgrade-selector'}/>
                    </div>
                </BillingOptionsFlex>
            </Modal.Content>
            <Modal.Actions>

            </Modal.Actions>
        </Modal>
    );
};

export default SelectBillingMethod;
