export const INITIAL_STATE = {
	loading: false,
	error: null,
	myTree: {},
	contactBook: {},
	family: {}
}

const SET_LOADING = "set_loading"
const SET_ERROR = "set_error"
const SET_MY_TREE = "SET_MY_TREE"
const SET_CONTACT_BOOK = "SET_CONTACT_BOOK"
const SET_MY_FAMILY = "SET_MY_FAMILY"

export {
	SET_LOADING,
	SET_ERROR,
	SET_MY_TREE,
	SET_CONTACT_BOOK,
	SET_MY_FAMILY
}
