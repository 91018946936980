import React, {useEffect, useState} from "react"
import {mappedOptions} from "../../config/options.map.js"
import {useDispatch, useSelector} from "react-redux"
import {Button, Dropdown, Icon, Menu} from "semantic-ui-react"
import {FlexBox, IconImage, IconImageSmall} from "../../styles/Containers.js"
import {QuillDeltaToHtmlConverter} from "quill-delta-to-html"
import Root from "../../store/reducers/root/actions.js"
import styled from "styled-components"
import {EllipsisHorizontal} from "react-ionicons"
import {HoverableMenu, HoverMenuActiveStyles} from "../../styles/Layout"
import PermissionsService from "../../services/permissions"
import findGetParam from "../../utils/findGetParam"
import {useHistory} from "react-router-dom"
import useWindowSize from "../../utils/dimensions"
import {CONSTANTS} from "../../config/constants"
import {ActionRoundButton} from "../../styles/Semantic"
import SortChapters from "./SortChapters"
import {sortByIndex} from "../../utils/sortIndex"
import {usePageScope} from "../../store/hooks/page.hooks.js";
import EditImage from '../../assets/icons/edit.svg'
import EditImageLight from '../../assets/icons/edit-light.svg'
import ChapterImage from '../../assets/icons/chapter.svg'

export const formContentChapterList = (page, pageId) => {
  const chapterList = []
  const validChapterTypes = [...mappedOptions("chapterData").filter(o => o.active)]
  for (const chapter of page?.chapters) {
    const ch = {
      ...chapter,
      title: chapter.chapterTitle || validChapterTypes.find(o => o.id === chapter.chapterTitleType)?.text,
      id: `chapter-${pageId}-${chapter.chapterId}`,
      did: `${encodeURIComponent(chapter.chapterTitle)}`,
      type: "id",
      sections: [],
      chapterId: chapter.chapterId
    }
    const doc = document.createElement("div")
    const converter = new QuillDeltaToHtmlConverter(chapter.operations, {})
    doc.innerHTML = converter.convert()
    const subTags = doc.getElementsByTagName("h2")
    for (let i = 0; i < subTags.length; i++) {
      const text = subTags[i].innerText
      ch.sections.push({
        title: text,
        id: `chapter-${pageId}-${chapter.chapterId}`,
        did: `${encodeURIComponent(chapter.chapterTitle)}`,
        index: i,
        value: "h2",
        type: "index",
        chapterId: chapter.chapterId + "" + i
      })
    }
    chapterList.push(ch)
  }
  return sortByIndex(chapterList)
}

const ChaptersMenu = () => {
  const {width} = useWindowSize()
  const history = useHistory()
  const RootDispatch = new Root(useDispatch())
  const activeItem = useSelector(state => {
    // const urlParams = findGetParam("ch", true)
    // if (urlParams) {
    //   return encodeURIComponent(urlParams)
    // }
    return Root.chapterNavSection(state)
  })
  const {page, pageId, permissions} = usePageScope()

  const [formChapterList, setFormChapterList] = useState([])
  const [manageChapters, setManageChapters] = useState(false)

  const canEdit = PermissionsService.checkPermissions(permissions, "canEdit") && width >= CONSTANTS.toSmallToEdit

  useEffect(() => {
    document.getElementById("body-content")?.scrollTo(0, 0)
    if (page && page.chapters && (pageId === history.location.pathname.split("/")[1] || (pageId === history.location.pathname.split("/")[2] && 'demo' === history.location.pathname.split("/")[1]))) {
      const data = formContentChapterList(page, pageId)
      setFormChapterList(data)
      if (!activeItem && data.length) {
        RootDispatch.setChapterNavSection(data[0].did)
        history.push({
          pathname: history.location.pathname,
          search: "?ch=" + data[0].did
        })
      } else if (data.length && activeItem !== data[0].did && data.find(o => o.did === activeItem)) {
        handleItemClick(data.find(o => o.did === activeItem))
      }
    }
  }, [page, page.chapters])

  const handleItemClick = (chapter) => {
    RootDispatch.setChapterNavSection(chapter.chapterId)
    if (chapter.type === "id" || chapter.type === "gallery") {
      document.getElementById(chapter.id)?.scrollIntoView({block: "start", behavior: "smooth"})
    }
    if (chapter.type === "index") {
      document.getElementById(chapter.id)?.getElementsByTagName("h2")[chapter.index]?.scrollIntoView({
        block: "center",
        behavior: "smooth"
      })
    }

    history.push({
      pathname: history.location.pathname,
      search: "?ch=" + chapter.did
    })
  }

  const hoverMenu = (
    <HoverableMenu style={canEdit ? {} : {right: -10}}>
      {formChapterList.map((ch, i) => (
        <div onClick={() => handleItemClick(ch)}>{ch.title}</div>
      ))}
    </HoverableMenu>
  )

  const Options = (
    <FlexBox style={{flex: 0}} align={"center"} justify={"flex-end"} wrap={"unset"}>
      <MoreOption>
        <ActionRoundButton><EllipsisHorizontal/></ActionRoundButton>
        {hoverMenu}
      </MoreOption>
      {canEdit &&
      <Dropdown icon={null} direction={'left'} text='Dropdown'
                options={[
                  {
                    key: 'add',
                    icon: <IconImageSmall src={ChapterImage} style={{top: 7, position: 'relative'}} /> ,
                    text: 'Add Chapter',
                    onClick: () => history.push(`/${pageId}/chapters/manage`)
                  },
                  {key: 'edit',
                    icon: <IconImageSmall src={EditImage} style={{top: 6, position: 'relative'}} /> ,
                    text: 'Manage Chapters', onClick: () => setManageChapters(true)}
                ]}
                trigger={<Button style={{minWidth: 110}} size={"tiny"} primary>
                  <IconImageSmall src={EditImageLight} /> Chapters
                </Button>}/>
      }
    </FlexBox>
  )

  if (width <= CONSTANTS.hideSearch) {
    const selectedItem = formChapterList.find(o => o.did === activeItem)
    return (selectedItem || formChapterList.length) ? (
      <FlexBox style={{flex: 0}} align={"center"} justify={"flex-end"} wrap={"unset"}>
        <MoreOption>
          <Button style={{minWidth: 110}} size={"tiny"} icon secondary>
            {selectedItem ? selectedItem.title : formChapterList.length ? formChapterList[0].title : ''}
            <Icon name={"angle down"}/>
          </Button>
          {hoverMenu}
        </MoreOption>
      </FlexBox>
    ) : null;
  }

  return (
    <ChapterMenuNav justify={"space-between"} align={"center"}>
      {manageChapters && <SortChapters chapters={formChapterList} setClose={() => setManageChapters(false)}/>}
      <FlexBox wrap={"unset"} style={{overflow: "hidden", flex: 1, height: 38}}>
        {formChapterList.map((ch, i) => (
          <MenuItem active={activeItem === ch.did} key={ch.chapterId} onClick={() => handleItemClick(ch)}>
            <div className={"ch-title"}>{ch.title}</div>
            {ch.sections.length > 0 && <HoverableMenu>
              {ch.sections.map(o => (
                <div onClick={() => handleItemClick(ch)}>{o.title}</div>
              ))
              }
            </HoverableMenu>}
            <div className={"ch-active"}/>
          </MenuItem>
        ))}
      </FlexBox>
      {Options}
    </ChapterMenuNav>
  )
}

export default ChaptersMenu

const MoreOption = styled.div`

  ${HoverMenuActiveStyles}

`

const ChapterMenuNav = styled(FlexBox)`
  border-bottom: 2px solid #E5E9F3;
`

export const MenuItem = styled.div`
  position: relative;

  ${HoverMenuActiveStyles}
  .ch-title {
    padding-right: 20px;
    padding-left: 20px;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    line-height: 38px;

    &:hover {
      color: ${props => props.theme.quaternary};
    }
  }

  .ch-active {
    visibility: ${props => props.active ? "visible" : "hidden"};
    height: 2px;
    left: 0;
    right: 0;
    position: absolute;
    background-color: ${props => props.theme.primary};
    bottom: 0;
  }
`
