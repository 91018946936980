import React, {useEffect, useState} from 'react'
import {Button, Divider, Modal} from "semantic-ui-react";
import {FlexBox} from "../../../styles/Containers.js";
import AccessDropdown from "./AccessDropdown.js";
import {useSelector} from "react-redux";
import Auth from "../../../store/reducers/auth/actions.js";
import styled from "styled-components";
import useWindowSize from "../../../utils/dimensions.js";

const AcceptPermissions = ({data, setAcceptInvite, acceptUserInvite}) => {
  const user = useSelector(Auth.user);
  const {height} = useWindowSize()
  const [permissionData, setPermissionData] = useState({})

  useEffect(() => {
    const permissions = {}
    for (const page in user.pageData) {
      permissions[page] = {
        pageId: page,
        permissions: page === user.primaryPageId ? {
          BRIEF: true,
          READ: true
        } : {
          BRIEF: false,
          WRITE: false,
          READ: false
        }
      }
    }

    setPermissionData(permissions)

  }, [data]);

  return (
    <Modal style={{maxHeight: height - 24}} onClose={() => setAcceptInvite(-1)} open={!!data} size={'small'} closeIcon>
      <Modal.Header>Accept Invite</Modal.Header>
      <Modal.Content>
        <p>
          By accepting this request you will give this user access to the following Everlooms.
        </p>
        <div style={{marginTop: 30}}/>

        <PermissionItem justify={'space-between'} style={{marginBottom: 16}}>
          <strong>Everloom Title</strong>
          <strong style={{minWidth: 100}}>Access</strong>
        </PermissionItem>

        {user.myPages.map(o => {
          const pageData = user.pageData[o];
          return <>
            <PermissionItem justify={'space-between'}>
              <FlexBox direction={'column'}>
                {pageData.firstName} {pageData.lastName}
              </FlexBox>
              <AccessDropdown disabled={user.primaryPageId === o} permissions={permissionData[o]?.permissions || {}}
                              setPermissions={perms => setPermissionData({
                                ...permissionData,
                                [o]: {
                                  ...permissionData[o],
                                  permissions: perms
                                }
                              })}/>
            </PermissionItem>
            <PermissionDivider/>
          </>
        })}
        <p>You can change these permissions at any time from their respective Everlooms.</p>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={() => acceptUserInvite(permissionData)}>
          Accept
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AcceptPermissions;

const PermissionItem = styled(FlexBox)`

`

const PermissionDivider = styled(Divider)`
  margin: 12px 0 12px 0 !important;
  opacity: 0.5;
`
