import React from 'react'
import {lang} from "../../config/languages";
import {usePageScope} from "../../store/hooks/page.hooks.js";
import {FlexBox} from "../../styles/Containers.js";
import MemoriesImage from "../../assets/icons/memories.svg";

const EmptyFeed = ({pending}) => {

  const {page, isMine} = usePageScope()

  const text = pending ? lang.noPendingMemories :
    isMine ? 'Memories people share about you will appear here' : `Memories people share about ${page?.brief?.firstName} will appear here`

  return (
    <FlexBox align={'center'} justify={'center'} direction={'column'}>
      <img src={MemoriesImage} style={{marginTop: 20}}/>

      <FlexBox align={"center"} justify={"center"} direction={"column"} style={{maxWidth: 800, marginTop: 30}}>
        <h2>
          Collect Family Memories
        </h2>
        <p style={{fontSize: 16, textAlign: 'center', lineHeight: 2}}>
          When you enable Memories relatives can submit their own stories to an Everloom. You’ll have the chance to
          review them before displaying it on an Everloom.
        </p>
        <p style={{fontSize: 16, textAlign: 'center'}}>
          Learn more about adding Memories in our <a target={'_blank'} href={process.env.REACT_APP_LANDING_URL + '/help'}>Help
          Center</a>.
        </p>
      </FlexBox>
    </FlexBox>
  );
};

export default EmptyFeed;
