import React from 'react'
import styled from "styled-components";

const Stepper = ({options, activeIndex, indexSelected, reduced}) => {
  let opts = [...options];

  if (reduced) {
    return (
      <ReducedDiv>
        <VisibleBox active={activeIndex}>
          {opts.map((o, i) => (
            <StepBox onClick={() => indexSelected(i)}>
              <StepCircle active={activeIndex === i}/>
              <StepTitle active={activeIndex === i}>{o.title}</StepTitle>
              {i !== (options.length - 1) && <LineSeparator/>}
            </StepBox>
          ))}
        </VisibleBox>
      </ReducedDiv>
    )
  }

  return (
    <div>
      {opts.map((o, i) => (
        <StepBox onClick={() => indexSelected(i)}>
          <StepCircle active={activeIndex === i}/>
          <StepTitle active={activeIndex === i}>{o.title}</StepTitle>
          {i !== (options.length - 1) && <LineSeparator/>}
        </StepBox>
      ))}
    </div>
  );
};

export default Stepper;


const VisibleBox = styled.div`

  transform: translateY(${props => {
    switch (props.active) {
      case 0:
      case 1:
        return 0;
      case 2:
      case 3:
        return -150
        
    }
  }}px);
  
`

const ReducedDiv = styled.div`
  overflow: hidden;
  height: 185px;
  background-color: ${props => props.theme.primary};
  padding: 20px;
  color: white;
  width: 100%;
`;


const LineSeparator = styled.div`
  position: absolute;
  height: 50px;
  bottom: 0;
  left: 14px;
  width: 1px;
  background-color: white;
`

const StepBox = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  position: relative;
  height: 84px;
  margin-bottom: 4px;
`;

export const StepCircle = styled.div`
  margin-right: 20px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  transition: border 0.15s linear;
  border: ${props => props.active ? `10px solid ${props.dark ? props.theme.primary : 'white'}` : `thin solid ${props.dark ? props.theme.primary : 'white'}`};
  cursor: pointer;
`;

const StepTitle = styled.div`
  font-weight: ${props => props.active ? 600 : 400};
  line-height: 30px;
`;
