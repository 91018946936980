import {
	CHAPTERS_LOADING,
	INITIAL_STATE,
	PATCH_LOCAL_BRIEF,
	PATCH_LOCAL_PAGE,
	SET_CHAPTERS,
	SET_CHAPTERS_ERROR,
	SET_CURRENT_PAGE,
	SET_EDIT_BRIEF,
	SET_ERROR,
	SET_FAMILY_DATA,
	SET_LOADING,
	SET_PAGE_CREATION,
	SET_PAGE_DATA,
	SET_PAGE_GALLERY,
	SET_PROFILE_SELECTION,
	SET_TAGGED_CHAPTERS,
	SET_TAGGED_GALLERY,
	UPDATE_CHAPTER_ORDER,
	UPDATE_CHAPTER_STATUS,
	UPDATE_PAGE_DATA
} from "./initialState"
import { RESET_STATE } from "../common/reducer.class"

export const biographyReducer = (state = INITIAL_STATE, action) => {
	const { payload, type, meta } = action

	switch (type) {
		case SET_LOADING:
			return {
				...state,
				loading: payload
			}
		case SET_ERROR:
			return {
				...state,
				error: payload
			}
		case SET_CURRENT_PAGE:
			return {
				...state,
				selectedPage: payload
			}
		case SET_PROFILE_SELECTION: {
			return {
				...state,
				pageSelection: payload
			}
		}
		case SET_PAGE_CREATION:
			return {
				...state,
				openPageCreation: payload
			}
		case UPDATE_CHAPTER_STATUS: {
			const currentPage = payload.pageId
			const page = state.pageData[currentPage]
			const chapterIndex = page.chapters.findIndex(o => o.chapterId === payload.chapterId)
			page.chapters[chapterIndex].status = payload.status
			return {
				...state,
				pageData: {
					...state.pageData,
					[currentPage]: {
						...state.pageData[currentPage],
						page
					}
				}
			}
		}
		case SET_FAMILY_DATA: {
			const currentPage = state.selectedPage
			return {
				...state,
				familyPageData: {
					...state.familyPageData,
					[currentPage]: payload
				}
			}
		}
		case PATCH_LOCAL_PAGE: {
			const currentPage = state.selectedPage
			return {
				...state,
				pageData: {
					...state.pageData,
					[currentPage]: {
						...state.pageData[currentPage],
						...payload
					}
				}
			}
		}
		case PATCH_LOCAL_BRIEF: {
			const currentPage = state.selectedPage
			return {
				...state,
				pageData: {
					...state.pageData,
					[currentPage]: {
						...state.pageData[currentPage],
						brief: {
							...state.pageData[currentPage].brief,
							...payload
						}
					}
				}
			}
		}
		case UPDATE_CHAPTER_ORDER: {
			const currentPage = state.selectedPage
			const page = state.pageData[currentPage]
			page.chapters = payload.chapters
			return {
				...state,
				pageData: {
					...state.pageData,
					[currentPage]: page
				}
			}
		}
		case SET_TAGGED_GALLERY: {
			const currentPage = state.selectedPage
			return {
				...state,
				tagged: {
					...state.tagged,
					[currentPage]: {
						...(state[currentPage]?.tagged || {}),
						gallery: payload
					}
				}
			}
		}
		case SET_TAGGED_CHAPTERS: {
			const currentPage = state.selectedPage
			console.log(currentPage)
			return {
				...state,
				tagged: {
					...state.tagged,
					[currentPage]: {
						...(state.tagged[currentPage] || {}),
						chapters: payload
					}
				}
			}
		}
		case SET_PAGE_GALLERY: {
			const currentPage = state.selectedPage
			const page = state.pageData[currentPage]
			page.gallery = payload.gallery || []
			return {
				...state,
				pageData: {
					...state.pageData,
					[currentPage]: page
				}
			}
		}
		case SET_EDIT_BRIEF: {
			return {
				...state,
				editBrief: payload
			}
		}
		case UPDATE_PAGE_DATA: {
			const existingPage = state.pageData[payload.page.pageId]
			if (existingPage) {
				payload.page = {
					...payload.page,
					chaptersLoading: existingPage.chaptersLoading,
					chapters: existingPage.chapters,
					chaptersError: existingPage.chaptersError
				}
			}
			return {
				...state,
				pageData: {
					...state.pageData,
					[payload.page.pageId]: {
						...(state.pageData[payload.page.pageId] || {}),
						...payload.page
					}
				},
				pagePermissions: {
					...state.pagePermissions,
					[payload.page.pageId]: payload.permissions
				}
			}
		}
		case SET_PAGE_DATA: {
			return {
				...state,
				pageData: {
					...state.pageData,
					[payload.page.pageId]: payload.page
				},
				pagePermissions: {
					...state.pagePermissions,
					[payload.page.pageId]: payload.permissions
				},
				selectedPage: payload.page.pageId
			}
		}
		case CHAPTERS_LOADING: {
			return {
				...state,
				pageData: {
					...state.pageData,
					[payload.pageId]: {
						...state.pageData[payload.pageId],
						chaptersLoading: true,
						chaptersError: null
					}
				}
			}
		}
		case SET_CHAPTERS: {
			return {
				...state,
				pageData: {
					...state.pageData,
					[payload.pageId]: {
						...state.pageData[payload.pageId],
						chaptersLoading: false,
						chapters: payload.value
					}
				}
			}
		}
		case SET_CHAPTERS_ERROR: {
			return {
				...state,
				pageData: {
					...state.pageData,
					[payload.pageId]: {
						...state.pageData[payload.pageId],
						chaptersLoading: false,
						chaptersError: payload.value
					}
				}
			}
		}
		case RESET_STATE:
			return INITIAL_STATE
		default:
			return state
	}
}
