import React from "react"
import { Form } from "semantic-ui-react"
import { formStyles, InputLabel } from "../../styles/Forms.js"
import { ErrorFieldText } from "../../styles/Text.js"
import useWindowSize from "../../utils/dimensions.js"
import { CONSTANTS } from "../../config/constants.js"
import styled from "styled-components"

const FormInput = ({
										 name,
										 placeholder = "",
										 label,
										 value,
										 onChange,
										 containerStyle,
										 inputStyle,
										 labelStyle,
										 icon,
										 fluid,
										 loading,
										 formMargin = true,
										 error,
										 readonly = false,
										 type = "text",
										 action = undefined,
										 required = false
									 }) => {
	const { width } = useWindowSize()

	let styles = { ...containerStyle }

	if (fluid === "mobile") {
		fluid = width < CONSTANTS.mobileSize
	}

	if (formMargin) {
		styles = { ...styles, ...formStyles.inputSideMargin }
	}

	if (fluid) {
		styles.flex = 1
		styles.width = '100%'
	}

	return (
		<div style={styles} className={"form-field-div"}>
			<Form.Field>
				{!!label && <InputLabel style={{ ...labelStyle }}>{label}</InputLabel>}
				<CustomFormInput name={name} placeholder={placeholder} value={value} onChange={onChange}
										style={{ ...inputStyle }} type={type} disabled={readonly}
										action={action} required={required}
										icon={icon} fluid={fluid} loading={loading} error={!!error}/>
				{!!error && <ErrorFieldText style={{ marginLeft: 5 }}>{error}</ErrorFieldText>}
			</Form.Field>
		</div>
	)
}

export default FormInput


const CustomFormInput = styled(Form.Input)`
	${props => props.required ? `
	.ui.input input::placeholder {
		content: " *";
	}
	` : ``}
`
