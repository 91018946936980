import {useEffect} from "react";
import Root from "../store/reducers/root/actions.js";
import {useDispatch, useSelector} from "react-redux";

export default function useDimensions() {
  const dispatch = useDispatch()
  const rootReducer = new Root(dispatch)

  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      rootReducer.setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return useSelector(Root.savedDimensions);
}
