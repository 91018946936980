import React, {useState} from "react"
import {FlexBox, SettingsPage} from "../../styles/Containers.js"
import {Button, Divider, Icon, Loader} from "semantic-ui-react"
import InviteUser from "./pageSettings/InviteUser.js"
import {FormSectionTitle} from "../../styles/Forms.js"
import {HoverLink, NoneTextRegular, Subtext} from "../../styles/Text.js"
import {lang} from "../../config/languages"
import AccessDropdown from "./components/AccessDropdown.js";
import styled from "styled-components";
import PermissionRequests from "./components/PermissionRequests.js";
import {Link} from "react-router-dom";
import {usePageScope} from "../../store/hooks/page.hooks.js";
import {useAlterPermissions, usePagePermissions} from "../../store/hooks/permissions.hooks.js";
import SharableEverloom from "./pageSettings/SharableEverloom"

const Permissions = () => {
  const [inviting, setInviting] = useState(false)
  const [editPermissions, setEditPermissions] = useState(null)
  const {pageId} = usePageScope()
  const [loadingTrigger, setLoadingTrigger] = useState(false)
  const {data: currentPermissions, isFetching: loading} = usePagePermissions(pageId)
  const alterPermission = useAlterPermissions(pageId, setLoadingTrigger)
  const [changeLoading, setChangeLoading] = useState(-1)

  const requests = currentPermissions?.permissionsRequests || []

  const formedPermissions = []

  for (const perm in currentPermissions) {
    if (perm !== 'permissionsRequests') {
      formedPermissions.push(...currentPermissions[perm])
    }
  }

  const updatePermissions = (permissions, user, index) => {
    setChangeLoading(index)
    alterPermission.mutateAsync({
      data: {
        value: user.email,
        permissions
      },
      type: 'update'
    })
      .finally(() => {
      setChangeLoading(-1);
    })
  }

  const onRevoke = (access, index) => {
    setChangeLoading(index)
    alterPermission.mutateAsync({
      data: {
        email: access.email,
        isPendingId: access.userId === 'PENDING_ID'
      },
      type: 'revoke'
    }).finally(() => {
      setChangeLoading(-1);
    })
  }

  return (
    <>
			<SharableEverloom />
			{requests.length > 0 && <PermissionRequests requests={requests}/>}
      <SettingsPage>
        <FormSectionTitle>
          Everloom Permissions
          <Button primary onClick={() => setInviting(true)} size={"small"} icon>
            <Icon name={"add"}/>
            Add
          </Button>
        </FormSectionTitle>
        <Subtext>{lang.heirloomPerms} <Loader active={loading && changeLoading === -1}/></Subtext>
        <NoneTextRegular>Manage Directory permissions in the <Link
          to={`/${pageId}/directory`}>Directory</Link> tab</NoneTextRegular>


        <InviteUser inviting={inviting || !!editPermissions} edit={editPermissions} close={() => {
          setInviting(false)
          setEditPermissions(null)
        }}/>

        {formedPermissions.length === 0 &&

        <FlexBox align={'center'} justify={'center'} direction={'column'}
                 style={{position: 'relative', top: 30, opacity: 0.5}}>
          <h3>No One Has Access</h3>
          <h6 style={{marginTop: 0}}>No one can see your Everloom. Invite a family member to get started.</h6>
        </FlexBox>
        }

        <div style={{marginTop: 30}}/>
        {formedPermissions.map((perm, i) => {
          const permissions = {}
          for (const p of perm.permissions) {
            permissions[p] = true;
            if (p === 'BENEFICIARY') {
              permissions['BRIEF'] = true;
              permissions['READ'] = true;
            }
          }
          return (
            <div key={'pl-' + i}>
              <PermissionItem justify={'space-between'}>
                <HoverLink onClick={() => setEditPermissions(perm)}>
                  <FlexBox direction={'column'} style={{opacity: perm.status === 'PENDING' ? 0.6 : 1}}>
                    <strong>{perm.email}</strong>
                    {perm.status === 'PENDING' ?
                      <div style={{fontSize: 10}}><Icon name={'hourglass outline'}/>Pending</div>
                      : <div style={{fontSize: 12}}>
                        {perm.owner.firstName} {perm.owner.lastName}
                      </div>}
                  </FlexBox>
                </HoverLink>
                <AccessDropdown permissions={permissions} setPermissions={perms => updatePermissions(perms, perm, i)}
                                onRevoke={() => onRevoke(perm, i)} loading={changeLoading === i}/>
              </PermissionItem>
              <PermissionDivider/>
            </div>
          )
        })
        }
      </SettingsPage>
    </>
  )
}

export default Permissions

const PermissionItem = styled(FlexBox)`

`

const PermissionDivider = styled(Divider)`
  margin: 12px 0 12px 0 !important;
  opacity: 0.5;
`
