import * as React from 'react'
import Logo from '../../../assets/logos/themed/logo-icon.svg'

const nuxData = {

  "intro": {
    footer: {
      title: 'If you need help you can visit our Help Center in the Account Menu.'
    },
    title: null,
    slides: [
      {
        topImage: Logo,
        title: 'Welcome to Everloom!',
        description: [
          'A space for you and your family to bring together ancestry, history, and media to be passed on for future generations.',
        ],
        button: {
          title: 'Get Started',
          type: 'dismiss'
        }
      }
    ]
  },
  "chapters": {
    slides: [
      {
        documentAlignment: '0px',
        descriptionAlignment: 'left',
        descriptions: [
          "Chapters help simplify the writing and reading of an Everloom by breaking your story into smaller sections.",
          "Each Chapter has its own album where you can add photos, videos, and audio recordings that are related to the chapter topic.",
          "As you create Chapters you can save them as drafts until you’re ready to publish them. Only you can see your Chapter drafts.",
        ]
      }
    ],
    title: 'Chapters',
    footer: {
      button: {
        title: "Close",
        type: "dismiss"
      },
      learnMore: process.env.REACT_APP_LANDING_URL + '/help'
    }
  },
  "gallery": {
    slides: [
      {
        documentAlignment: '0px',
        descriptionAlignment: 'left',
        descriptions: [
          "An Everloom Gallery is a collection of all the photos, videos, and audio recordings that you add to your Everloom.",
          "If someone tags your Everloom it will also be shown in the Gallery, as well as any media you save from other Everlooms.",
        ]
      }
    ],
    title: 'Gallery',
    footer: {
      button: {
        title: "Close",
        type: "dismiss"
      },
      learnMore: process.env.REACT_APP_LANDING_URL + '/help'
    }
  },
  "memories": {
    slides: [
      {
        documentAlignment: '0px',
        descriptionAlignment: 'left',
        descriptions: [
          "Everloom Memories lets your connections add their own personal stories about you to your Everloom.",
          "When your connections submit a memory you’ll have the chance to review it before displaying it on your Everloom.",
        ]
      }
    ],
    title: 'Memories',
    footer: {
      button: {
        title: "Close",
        type: "dismiss"
      },
      learnMore: process.env.REACT_APP_LANDING_URL + '/help'
    }
  },
  "directory": {
    slides: [
      {
        documentAlignment: '0px',
        descriptionAlignment: 'left',
        descriptions: [
          "The Directory lists all the contact information for the people you connect with on Everloom.",
          "Start by adding your own email, phone number, or address. As you connect with more people on Everloom their contact information will be added to your Directory.",
          "You can manage who can see your contact information in the Access section."
        ]
      }
    ],
    title: 'Directory',
    footer: {
      button: {
        title: "Close",
        type: "dismiss"
      },
      learnMore: process.env.REACT_APP_LANDING_URL + '/help'
    }
  },
  "manage": {
    slides: [
      {
        documentAlignment: '0px',
        descriptionAlignment: 'left',
        descriptions: [
          "Everloom is private by design. That means no one can see or search for your Everloom until you invite them to view it.",
          "In the Manage section of an Everloom you can control who can see an Everloom and invite others to collaborate on it with you.",
        ]
      }
    ],
    title: 'Managing Everlooms',
    footer: {
      button: {
        title: "Close",
        type: "dismiss"
      },
      learnMore: process.env.REACT_APP_LANDING_URL + '/help'
    }
  },
  "addFamily": {
    slides: [
      {
        documentAlignment: '0px',
        descriptionAlignment: 'left',
        descriptions: [
          "You can list immediate family members such as parents, siblings, spouses, and children on your Everloom.",
          "When you add a family member you can add them by name or by linking an existing Everloom you’ve already connected with.",
        ]
      }
    ],
    title: 'Adding Family Members',
    footer: {
      button: {
        title: "Close",
        type: "dismiss"
      },
      learnMore: process.env.REACT_APP_LANDING_URL + '/help'
    }
  },
  "createEverloom": {
    slides: [
      {
        documentAlignment: '0px',
        descriptionAlignment: 'left',
        descriptions: [
          "Did you know that you can make an Everloom for someone other than yourself?",
          "For example, you may want to make an Everloom for a family member who has passed away or you may want to create an Everloom and pass it on for someone else to complete.",
          "You can create additional Everlooms by using the name dropdown above the Biography section or under your account settings.",
          "You can even work on an Everloom together with another person by inviting them to collaborate on it with you."
        ]
      }
    ],
    title: 'Creating More Everlooms',
    footer: {
      button: {
        title: "Close",
        type: "dismiss"
      },
      learnMore: process.env.REACT_APP_LANDING_URL + '/help'
    }
  },
  "createNewEverloom": {
    slides: [
      {
        documentAlignment: '0px',
        descriptionAlignment: 'left',
        descriptions: [
          "Did you know that you can make an Everloom for someone other than yourself?",
          "For example, you may want to make an Everloom for a family member who has passed away or you may want to create an Everloom and pass it on for someone else to complete.",
          "You can even work on an Everloom together with another person by inviting them to collaborate on it with you."
        ]
      }
    ],
    title: 'Creating a New Everloom',
    footer: {
      button: {
        title: "Close",
        type: "dismiss"
      },
      learnMore: process.env.REACT_APP_LANDING_URL + '/help'
    }
  },
  "downloadMedia": {
    slides: [
      {
        documentAlignment: '0px',
        descriptionAlignment: 'left',
        descriptions: [
          "As you explore your connections on Everloom you may come across photos, videos, or audio recordings that you want to add to your own Everloom or download to your device.",
          "Clicking Save will add the file to the saved section in your Gallery and will count against your Everloom storage space.",
          "Clicking download will let you download the file to your device.",
        ]
      }
    ],
    title: 'Saving Media',
    footer: {
      button: {
        title: "Close",
        type: "dismiss"
      },
      learnMore: process.env.REACT_APP_LANDING_URL + '/help'
    }
  },
  "shareEverloom": {
    slides: [
      {
        documentAlignment: '0px',
        descriptionAlignment: 'left',
        descriptions: [
          "Congratulations on publishing your first Chapter!",
          "Everloom is private by design, which means that no one can see your Everloom until you’ve invited them to view it.",
          "You can invite people to view your Everloom in the Manage section.",
        ]
      }
    ],
    title: 'Share Your Everloom',
    footer: {
      button: {
        title: "Close",
        type: "dismiss"
      },
      learnMore: process.env.REACT_APP_LANDING_URL + '/help'
    }
  }
}

export default nuxData
