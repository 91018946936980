import {
	SET_ERROR,
	SET_IMAGES,
	SET_LOADING,
	SET_SELECTED_IMAGE,
	SET_VIEWING_GALLERY,
	SET_VIEWING_IMAGE
} from "./initialState"
import BaseReducer from "../common/reducer.class"


class Media extends BaseReducer {

	constructor(dispatch) {
		super({
			dispatch, setErrorConst: SET_ERROR, setLoadingConst: SET_LOADING,
			type: "media"
		})
	}

	setImages = (props) => {
		this.dispatch({
			type: SET_IMAGES,
			payload: props
		})
	}

	setViewImage = (imageData, gallery) => {
		this.dispatch({
			type: SET_VIEWING_IMAGE,
			payload: imageData ? {
				data: imageData,
				gallery: gallery || [imageData]
			} : null
		})
	}

	setViewGallery = (gallery, selectable = false) => {
		this.dispatch({
			type: SET_VIEWING_GALLERY,
			payload: {
				gallery, selectable
			}
		})
	}

	setSelectedImage = (image) => {
		this.dispatch({
			type: SET_SELECTED_IMAGE,
			payload: image
		})
	}

	static getImages = state => state.media.data[state.biography.selectedPage] || []

	static viewImage = state => state.media.viewingImage
	static viewGallery = state => state.media.selectedGallery
	static selectedImage = state => state.media.selectedImageData
	static isSelectable = state => state.media.selectable
}

export default Media
