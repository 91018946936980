export const EN = {
  uploadPhoto: 'This should be a picture that features only the person in this Everloom.',
  chapterPrompt: 'Select a Chapter Guide or choose Other/Custom to start with a blank template',
  tagFamily: 'Tag family members in this chapter',
  chapterTagDescription: 'Tag people who are in this media and it will be added to the Tagged album on their Everloom. Learn more about tagging <a href="https://www.everloom.io/help-media-tags" target="_blank">here</a>.',
  imageTagDescription: "Tag people who are in this media and it will be added to the Tagged album on their Everloom. Learn more about tagging <a href='https://www.everloom.io/help-media-tags' target='blank'>here</a>",
  addChapterTag: 'Add Tag',
  tipsPrompt: 'See tips for writing this chapter',
  noChapters: 'Add your first chapter to begin creating your Everloom.',
  ownerAlbum: '{name}\'s Album',
  taggedAlbum: '{name}\'s Tagged Album',
  noMentions: 'Chapters where {name} is tagged',
  noPendingMemories: 'There are no memories to review',
  heirloomPerms: 'Share and control who can see your Everloom',
  inviteUser: '{name} Everloom Invitation',
  giftPage: 'Thank you for giving the gift of Everloom. Please select which type of page you\'d like to gift. The recipient can later upgrade the page at their own cost.',
	afterLifePerm: 'Choose a family member to name as your Executor. Learn more about adding an Executor to your Everloom.'
}
