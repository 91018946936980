import React from "react"
import {useSelector} from "react-redux"
import Biography from "../../../store/reducers/biography/actions.js"
import {FamilyName, ManagePageCode, MappedFormType, WhatsTheirName} from "./Questions.js"
import {formStyles} from "../../../styles/Forms.js"
import {Form} from "semantic-ui-react"
import FormDatePicker from "../../../components/common/FormDatePicker.js"
import {FlexBox} from "../../../styles/Containers.js"
import {LISTS} from "../../../config/options.map.js"
import {ModalFormSection} from "../../../styles/Text"
import {usePageScope} from "../../../store/hooks/page.hooks.js";

export const formTextMappingList = () => ({
  siblings: {
    belongsTo: "Child of",
    mapType: "siblingRelationship",
    relationshipType: "relationshipType",
    options: {
      relatedTo: LISTS.data.relatedToMapping["siblings"]
    }
  },
  parents: {
    belongsTo: "Parent of",
    mapType: "parentRelationship",
    relationshipType: "relationshipType",
    options: {
      relatedTo: LISTS.data.relatedToMapping["parents"]
    }
  },
  children: {
    belongsTo: "Child of",
    mapType: "childRelationship",
    relationshipType: "relationshipType",
    options: {
      relatedTo: LISTS.data.relatedToMapping["children"]
    }
  },
  spouse: {
    belongsTo: "Parent of",
    mapType: null,
    relationshipType: "spouseRelationshipType",
    options: {
      relatedTo: LISTS.data.relatedToMapping["spouse"]
    }
  }
})

const GenericRelativeForm = ({
                               type,
                               data,
                               onChange,
                             }) => {
  const formTextMapping = formTextMappingList()

  const {
    firstName = "",
    lastName = "",
    pageCode = "",
    relation = {
      relationship: ""
    },
  } = data

  const relationshipType = formTextMapping[type].relationshipType
  const isSpouse = type === "spouse"

  const relationshipTypeData = <div style={{marginTop: 30}}>
    {formTextMapping[type].mapType &&
    <>
      <ModalFormSection style={styles.innerSectionMargin}>
        Relationship to you
      </ModalFormSection>
      <MappedFormType type={"relationship"} mapping={formTextMapping[type].mapType} relation={relation}
                      loadedType={type}
                      onChange={onChange}/>
    </>
    }
    <ModalFormSection style={styles.formSectionTitle}>
      {type === "spouse" ? "Type of relationship" : "Relationship status"}
    </ModalFormSection>
    <MappedFormType type={"relationshipType"} mapping={relationshipType} relation={relation}
                    loadedType={type}
                    onChange={onChange}/>
    {(isSpouse && relation.relationshipType) &&
    <div style={{margin: -formStyles.inputSideMargin}}>
      {relation.relationshipType === "divorced" ?
        <FlexBox>
          <FormDatePicker label={"Anniversary date"} name={`relation.from`} value={relation.from}
                          onChange={onChange}/>
          <FormDatePicker label={"Separation date"} name={`relation.to`} value={relation.to} onChange={onChange}/>
        </FlexBox> :
        <>
          <FormDatePicker label={"Anniversary Date"} name={`relation.from`} value={relation.from}
                          onChange={onChange}/>
        </>
      }
    </div>
    }
  </div>

    return (
      <Form>
        <FamilyName/>
        <WhatsTheirName firstName={firstName} lastName={lastName} onChange={onChange}/>
        {relationshipTypeData}
      </Form>
    )
}

export default GenericRelativeForm

const styles = {
  formSectionTitle: {
    marginBottom: 10,
    marginTop: 26
  },
  innerSectionMargin: {
    marginTop: 18,
    marginBottom: 10
  }
}
