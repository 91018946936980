import React, {useEffect, useState} from 'react'
import {Button, Image, Modal} from "semantic-ui-react";
import {useDispatch, useSelector} from "react-redux";
import Root from "../../store/reducers/root/actions.js";
import AccountPageSelector from "../../layout/AccountPageSelector.js";
import Biography from "../../store/reducers/biography/actions.js";
import Auth from "../../store/reducers/auth/actions.js";
import {NoneText} from "../../styles/Text.js";
import ModalLoader from "../common/ModalLoader.js";
import MediaService from "../../services/media.js";
import {usePageScope} from "../../store/hooks/page.hooks.js";

const ManageImage = ({}) => {
  const root = new Root(useDispatch());
  const manageImage = useSelector(Root.manageImage);
  const outerNode = useSelector(Root.outerNode)
  const {page, pageId} = usePageScope()
  const user = useSelector(Auth.user)
	const [loading, setLoading] = useState(false);


  const [downloadContent, setDownloadContent] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);
	const pages = user?.pageData;

  useEffect(() => {
    if (user && pages && !selectedPage) {
      setSelectedPage(pages[user.primaryPageId])
    }
  }, [user])

  const downloadImage = () => {
    const a = document.createElement('a');
    a.href = manageImage.Location || manageImage.base64;
    a.download = "everloom-image.png";
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    close();
  }

  const close = () => {
    setDownloadContent(false);
    root.manageImage(null)
  }

  const saveImageToPage = () => {
    setLoading(true)
    MediaService.saveToPage(selectedPage.pageId, manageImage)
      .then(() => {
        close();
      })
      .catch(e => {
        if (e === 'UPGRADE_ACCOUNT') {
          root.promptPaywall('GALLERY');
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  if (!manageImage) {
    return <div/>
  }

  const canAddImage = () => {
    if (!manageImage.Location) {
      return false;
    }
    return !(user.primaryPageId === pageId && !manageImage.tagData && user.myPages.length === 1);
  }

  return (
    <Modal size={'mini'} closeIcon open={manageImage} onClose={close} mountNode={outerNode}>
      <Modal.Header>Image actions</Modal.Header>
      <Modal.Content style={{marginTop: 0}}>
        <ModalLoader active={loading}/>
        <Modal open={downloadContent} closeIcon onClose={() => setDownloadContent(false)} mountNode={outerNode}>
          <Modal.Header>
            Download location
          </Modal.Header>
          <Modal.Content image>
            <ModalLoader active={loading}/>
            <Image src={manageImage.Location} size={'medium'}/>
            <Modal.Description>
              <p>
                <NoneText> Note: This image will count towards your data quota.</NoneText>
              </p>
              <p>
                Which page would you like to download this too?
              </p>

              <AccountPageSelector customPopClass={'download-selector-pop'}
                                   customTriggerClass={'download-selector'}
                                   itemSelected={id => setSelectedPage(pages[id])}
                                   forceValue={selectedPage}/>

            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={saveImageToPage}>Save</Button>
          </Modal.Actions>
        </Modal>
        <Button primary fluid onClick={downloadImage}>Download Image</Button>
        {canAddImage() &&
        <Button fluid style={{marginTop: 10}} onClick={setDownloadContent}> Add media to Page</Button>}
      </Modal.Content>

    </Modal>
  );
};

export default ManageImage;
