import React, {useEffect} from 'react'
import 'quill/dist/quill.snow.css';
import {CustomEditor} from "../../pages/Chapters/AddChapter.js";

export const defaultToolbar = [
    [{'font': []}],
    [{'header': [2, false]}],
    [{'color': []}, {'background': []}],
    ['bold', 'italic', 'underline'],
    ['link', 'blockquote', 'image'],
    [{list: 'ordered'}, {list: 'bullet'}],
    [{'indent': '-1'}, {'indent': '+1'}],
    [{'direction': 'rtl'}],
];

const QuillEditor = ({quill, quillRef, contentUpdate = () => null, QContainer}) => {

    useEffect(() => {
        if (quill) {
            quill.on('text-change', contentUpdate);
        }
        return () => {
            if (quill) {
                quill.off('text-change', contentUpdate);
            }
        };
    }, [quill]);

    const QuillContainer = QContainer || CustomEditor

    return (
        <QuillContainer style={{backgroundColor: 'white'}}>
            <div ref={quillRef}/>
        </QuillContainer>
    );
};

export default QuillEditor;
