import React, {useState} from "react"
import {AuthCardContainer, FlexBox} from "../../styles/Containers.js"
import {Button, Card, CardContent, Checkbox, Divider, Form, Grid} from "semantic-ui-react"
import {NoneText} from "../../styles/Text.js"
import {validateAuthForm} from "../../utils/validateAuthForm.js"
import AuthService from "../../services/auth.js"
import {useDispatch} from "react-redux"
import Auth from "../../store/reducers/auth/actions.js"
import ErrorComponent from "../../components/messages/Error.js"
import {formStyles} from "../../styles/Forms.js"
import FormInput from "../../components/common/FormInput.js"
import GoogleLoginButton from "./GoogleLogin.js"
import ModalLoader from "../../components/common/ModalLoader.js"
import styled from "styled-components"
import PasswordInput from "../../components/common/PasswordInput"
import firebase from "firebase/app";
import AuthTabs from "./AuthTabs.js";

const SignUpComponent = ({}) => {
  const AuthDispatch = new Auth(useDispatch())
  const [checkedOptions, setCheckedOptions] = useState({
    emails: false,
    terms: false
  })

  const [error, setError] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [invalidFields, setInvalidFields] = useState({})
  const [form, setForm] = useState({
    email: "",
    password: "",
    repassword: "",
    firstName: "",
    lastName: ""
  })
  const onChange = (event, {value, name}) => setForm({...form, [name]: value})

  const submit = () => {
    const invalids = validateAuthForm(form)
    setInvalidFields(invalids)
    setError("")
    if (Object.keys(invalids).length === 0) {
      setSubmitting(true)
      delete form.repassword
      form.checkedOptions = checkedOptions
      firebase.analytics().logEvent("sign_up");
      AuthService.register({
        ...form,
        referralCode: AuthService.referralInfo?.code
      }).then(async user => {
        AuthService.setTokens({
          token: user.token,
          refreshToken: user.refreshToken
        })
        AuthDispatch.setUser({value: user})
      })
        .catch(error => setError(error))
        .finally(() => setSubmitting(false))
    }
  }

  const createGoogleAccount = (token) => {
    firebase.analytics().logEvent("sign_up")
    setSubmitting(true)
    AuthService.registerGoogle(token, checkedOptions, AuthService.referralInfo?.code).then(async user => {
      AuthService.setTokens({
        token: user.token,
        refreshToken: user.refreshToken
      })
      AuthDispatch.setUser({value: user})
    })
      .catch(error => setError(error))
      .finally(() => setSubmitting(false))
  }

  return (
    <AuthCardContainer>
      <ErrorComponent message={error}/>
      <Card>
        <ModalLoader active={submitting}/>
        <AuthTabs/>
        <Card.Content header="Create an account"/>
        {AuthService.referralInfo?.name &&
        <ReferralContainer>Referral Code: {AuthService.referralInfo?.name}</ReferralContainer>}
        <Form onSubmit={submit}>
          <Card.Content style={{marginLeft: -formStyles.inputSideMargin.marginLeft, paddingTop: 0}}>
            <FlexBox flip={500}>
              <FormInput containerStyle={{flex: 1}} placeholder="First name" fluid name="firstName"
                         error={invalidFields.firstName}
                         autoComplete="username" onChange={onChange} value={form.firstName}/>
              <FormInput containerStyle={{flex: 1, marginRight: -formStyles.inputSideMargin.marginLeft}}
                         placeholder="Last name" fluid name="lastName"
                         error={invalidFields.lastName}
                         autoComplete="username" onChange={onChange} value={form.lastName}/>
            </FlexBox>
            <FormInput icon="user" iconPosition="left" placeholder="Email" fluid name="email"
                       error={invalidFields.email}
                       autoComplete="username" onChange={onChange} value={form.email}/>

            <PasswordInput onChange={onChange} value={form.password} error={!!invalidFields.password}/>
            <PasswordInput onChange={onChange} value={form.repassword} error={invalidFields.repassword} isReEnter
                           showHint={!!invalidFields.password}/>
          </Card.Content>

          <CardContent style={{textAlign: "left", paddingTop: 0}}>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column width={16} style={{fontSize: 12}}>
                  <FlexBox wrap={'unset'}>
                    <Checkbox style={styles.checkboxContainer} onChange={(_, {checked}) => {
                      setCheckedOptions({
                        ...checkedOptions,
                        email: checked
                      })
                    }}/>
                    <div>
                      I agree to receive occasional promotional and marketing emails.
                    </div>
                  </FlexBox>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{paddingTop: 0}}>
                <Grid.Column width={16} style={{fontSize: 12}}>
                  <FlexBox wrap={'unset'}>
                    <Checkbox style={styles.checkboxContainer} onChange={(_, {checked}) => {
                      setCheckedOptions({
                        ...checkedOptions,
                        terms: checked
                      })
                    }}/>
                    <div>
                      By creating an account, you agree to the <a target={"_blank"}
                                                                  href={process.env.REACT_APP_LANDING_URL + "/terms-of-service"}>Terms
                      of Service</a> and <a target={"_blank"}
                                            href={process.env.REACT_APP_LANDING_URL + "/privacy-policy"}>Privacy
                      Policy</a>
                    </div>
                  </FlexBox>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </CardContent>

          <Card.Content>
            <Button fluid primary disabled={submitting || !checkedOptions.terms} type="submit">
              Register
            </Button>

            <Divider/>

            <GoogleLoginButton disabled={!checkedOptions.terms} googleData={createGoogleAccount}/>
            {!checkedOptions.terms && <NoneText>Must accept terms of service and privacy policy first</NoneText>}

          </Card.Content>
        </Form>
      </Card>

      {/*<div className="sub-item-text">Have an account? <StyledLink to="/login">Log in</StyledLink></div>*/}
    </AuthCardContainer>
  )
}

export default SignUpComponent

const ReferralContainer = styled.div`
  text-align: center;
  font-weight: bold;
`

const styles = {
  checkboxContainer: {
    marginRight: 8, position: 'relative', top: 3
  }
}
