import React, { useEffect, useState } from "react"
import LogoLoader from "../../components/common/LogoLoader.js"
import { FlexBox, PageLoader } from "../../styles/Containers.js"
import AuthService from "../../services/auth.js"
import Auth from "../../store/reducers/auth/actions.js"
import { useDispatch } from "react-redux"
import { SET_CURRENT_PAGE, SET_PAGE_DATA } from "../../store/reducers/biography/initialState.js"
import { useDemoData, usePageScope } from "../../store/hooks/page.hooks.js"
import { DemoRoutes } from "../../Routes.js"
import { matchPath } from "react-router"
import { useHistory } from "react-router-dom"
import { isPublicDemo } from "../../utils/isDemo"
import PinEntry from "../../components/PinEntry"
import Lock from "../../assets/icons/lock.png"
import Logo from "../../assets/logos/themed/logo-row-light.svg"
import styled from "styled-components"

const Demo = ({}) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const [viewing, setViewing] = useState("")
	const [error, setError] = useState(null)
	const [pin, setPin] = useState("")
	const AuthDispatch = new Auth(dispatch)
	const isPublic = isPublicDemo

	const matchPage = matchPath(window.location.pathname, {
		path: "/demo/:pageId",
		exact: false,
		strict: false
	})
	const pid = matchPage?.params?.pageId
	const { data, isFetching } = useDemoData(isPublic, viewing, pin, setError, setPin, pid)


	const demoData = (data || []).map(o => ({
		...o,
		isDemo: true
	}))
	const { page, pageId } = usePageScope()

	useEffect(() => {
		const pid = matchPage?.params?.pageId
		const storage = localStorage.getItem(`sharePage:${pid}`)
		if (storage && pid && viewing) {
			const item = JSON.parse(storage)
			if (item.pin && item.pin.length === 4) {
				setPin(item.pin)
			}
		}

	}, [viewing])

	useEffect(() => {
		if (isPublic) {
			const match = matchPath(window.location.pathname, {
				path: "/links/:linkId",
				exact: false,
				strict: false
			})

			if (!match) {
				if (matchPage || !matchPage.params?.pageId) {
					const storage = localStorage.getItem(`sharePage:${pid}`)
					if (!storage) {
						window.open("https://app.everloom.io/" + pid + "/chapters")
					} else {
						const item = JSON.parse(storage)
						if (!item.link || !item.pin) {
							window.open("https://app.everloom.io/" + pid + "/chapters")
						} else {
							setViewing(item.link)
							setPin(item.pin)
						}
					}
				} else {
					setError("Everloom not found")
				}
			} else {
				setViewing(match?.params?.linkId)
			}
		}
	}, [isPublic])

	useEffect(() => {
		const updatePageValue = (newPage) => {
			dispatch({
				type: SET_CURRENT_PAGE,
				payload: newPage.pageId
			})

			dispatch({
				type: SET_PAGE_DATA,
				payload: { page: newPage, permissions: newPage.permissions }
			})
		}

		if (demoData && demoData.length && !page) {
			const setPage = demoData[0]
			AuthService.setTokens({})
			AuthDispatch.setUser({
				value: { myPages: demoData.map(o => o.pageId), primaryPageId: setPage.pageId },
				fetched: true
			})
			updatePageValue(setPage)
			history.push(`/demo/${setPage.pageId}/chapters`)
		} else if (page && matchPage?.params.pageId && pageId !== matchPage?.params.pageId) {
			const setPage = demoData.find(o => o.pageId === matchPage?.params.pageId)
			if (setPage) {
				updatePageValue(setPage)
			}
		}

	}, [demoData, demoData.length, matchPage?.params.pageId])

	return !!page ? (
		<DemoRoutes/>
	) : isPublic ? (
		<PageLoader>
			<LogoImg src={Logo}/>
			{(isFetching || pin.length === 4) ? <LogoLoader active/> : <FlexBox direction={"column"} align={"center"} justify={"center"}>
				<h4 style={{ color: "white" }}>Enter the correct pin to view this Everloom</h4>
				<img src={Lock} style={{ width: 80 }}/>
				{!!error && <h5 style={{ color: "black", letterSpacing: 4 }}>{error}</h5>}
				<PinEntry setPin={text => {
					setPin(text)
				}} pin={pin}/>
			</FlexBox>}
		</PageLoader>
	) : (
		<PageLoader>
			<LogoLoader active/>
		</PageLoader>
	)
}

export default Demo


const LogoImg = styled.img`
	width: 200px;
	position: absolute;
	top: 20px;
`
