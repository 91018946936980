import React, {useEffect, useState} from "react"
import ReactMarkdown from "react-markdown"
import {Button, Segment} from "semantic-ui-react"
import {ChevronBack, ChevronForward, Remove} from "react-ionicons"
import {FlexBox} from "../../styles/Containers.js";
import ErrorBoundary from "../ErrorBoundary.js";

const TipsModal = ({tips = [], viewTips, setViewTips}) => {
  const [tipIndex, setTipIndex] = useState(0)

  useEffect(() => {
    setTipIndex(0);
  }, [viewTips])

  if (!tips.length) {
    return <div />
  }


  return (
    <ErrorBoundary>
      <Segment style={{flex: 1}}>
        <div style={{position: "absolute", right: 12, zIndex: 99999, cursor: 'pointer'}} onClick={() => {
          localStorage.setItem("viewTips", "false")
          setViewTips(false)
        }}>
          <Remove/>
        </div>
        <h2>{tips[tipIndex].header}</h2>
        <div>
          <ReactMarkdown>{tips[tipIndex].subHeader}</ReactMarkdown>
        </div>

        <FlexBox align={'center'} justify={'space-between'} style={{marginTop: 20}}>
          <div>
            <strong>{tipIndex + 1} / {tips.length}</strong>
          </div>
          <div>
            <Button primary disabled={tipIndex === 0} onClick={() => setTipIndex(tipIndex - 1)}>
              <ChevronBack color={'#fff'}/>
            </Button>
            <Button primary disabled={tipIndex === tips.length - 1} onClick={() => setTipIndex(tipIndex + 1)}>
              <ChevronForward color={'#fff'}/>
            </Button>
          </div>
          <div style={{opacity: 0}}>
            <strong>{tipIndex + 1} / {tips.length}</strong>
          </div>
        </FlexBox>
      </Segment>
    </ErrorBoundary>
  )
}

export default TipsModal
