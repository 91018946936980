import React from 'react'
import FormDropDown from "./FormDropDown.js";
import {useSelector} from "react-redux";
import PermissionsReducer from "../../store/reducers/permissions/actions.js";
import styled from "styled-components";
import {useTaggable} from "../../store/hooks/page.hooks.js";

const SearchUser = ({onChange, users = [], excludeCodes = [], fluid}) => {
  let {data: taggable} = useTaggable()

  taggable = taggable.map(o => ({
    key: o.pageCode,
    text: o.name,
    value: {
      pageCode: o.pageCode,
      name: o.name
    },
  }))

  return (
    <SearchUserContainer style={fluid ? {width: '100%'} : {}}>
      <FormDropDown
        clearable={false}
        fluid={fluid}
        multiple={true}
        formMargin={false}
        closeOnChange={false}
        closeOnBlur={false}
        containerStyle={{marginRight: 10}}
        options={taggable.filter(o => excludeCodes.indexOf(o.key) === -1)}
        searchInput={['text', 'relationship', 'relativeId']}
        search={true} onChange={(_, props) => {
        onChange(props.value, props)
      }}
        placeholder={'Search user...'}
        value={users}/>
    </SearchUserContainer>
  );
};

export default SearchUser;

const SearchUserContainer = styled.div`
`;
