import { SET_CONTACT_BOOK, SET_ERROR, SET_LOADING, SET_MY_FAMILY, SET_MY_TREE } from "./initialState"
import BaseReducer from "../common/reducer.class"
import TreeService from "../../../services/tree.js"

const isFetching = {
	family: false
}

class Tree extends BaseReducer {

	constructor(dispatch) {
		super({
			dispatch, setErrorConst: SET_ERROR, setLoadingConst: SET_LOADING,
			type: "tree"
		})
	}


	fetchMyTree = async (pageId) => {
		return TreeService.getPageTree(pageId).then(res => {
			this.dispatch({
				type: SET_MY_TREE,
				payload: {
					data: res,
					pageId
				}
			})
		})
	}

	fetchContactBook = async (pageId) => {
		return TreeService.getContactBook(pageId).then(res => {
			this.dispatch({
				type: SET_CONTACT_BOOK,
				payload: {
					data: res,
					pageId
				}
			})
		})
	}

	fetchFamily = async (pageId, init = false) => {
		if (isFetching.family) return
		if (init) isFetching.family = true
		return TreeService.getFamily(pageId, init).then(data => {
			this.dispatch({
				type: SET_MY_FAMILY,
				payload: { data, pageId }
			})
		}).finally(() => {
			isFetching.family = false
			if (init) {
				this.fetchFamily(pageId, false)
			}
		})
	}

	static getMyTree = state => state.tree.myTree[state.biography.selectedPage] || {}
	static getContactBook = state => state.tree.contactBook[state.biography.selectedPage]
	static family = state => state.tree.family[state.biography.selectedPage] || []
	static loading = state => state.tree.loading
}

export default Tree
