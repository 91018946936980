export default class QuillWordcounter {
    constructor(quill, options) {
        this.quill = quill;
        this.options = options;
        this.container = document.querySelector(options.container);
        quill.on('text-change', this.update.bind(this));
        this.update();
    }

    calculate() {
        let text = this.quill.getText();

        if (this.options.unit === 'word') {
            text = text.trim();

            return text.length > 0 ? text.split(/\s+/).length : 0;
        } else {
            return text.length;
        }
    }

    update() {
        const length = this.calculate();
        let label = this.options.unit;
        const max = this.options.max;

        if (length !== 1) {
            label += 's';
        }

        if (this.container) {
            this.container.nonce = length;
            if (max) {
                if (length > max) {
                    this.container.style.color = 'red';
                } else {
                    this.container.style.color = 'black';
                }
                this.container.innerHTML = `${length} ${label}`;
            } else {
                this.container.innerHTML = `${length} ${label}`;
            }
        }
    }
}
