export const INITIAL_STATE = {
    loading: false,
    error: null,
    form: {
        relatives: {
            parents: [],
            siblings: [],
            spouse: [],
            children: [],
        },
    },
    formErrors: {}
};

const SET_LOADING = 'SET_LOADING_INNER';
const SET_ERROR = 'SET_ERROR';
const SET_FORM = 'SET_FORM';
const SET_FORM_ERRORS = 'SET_FORM_ERRORS'

export {
    SET_LOADING,
    SET_ERROR,
    SET_FORM,
    SET_FORM_ERRORS
}
