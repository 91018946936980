import styled from "styled-components"
import theme from "./theme.js"
import { List } from "semantic-ui-react"

export const ScrollbarStyles = `
	&::-webkit-scrollbar {
		width: 2px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: ${props => props.theme.secondary};
	}

	scrollbar-width: 2px;
	scrollbar-color: lightgrey ${props => props.theme.secondary};
`

export const ScrollbarStylesHorizontal = `
	&::-webkit-scrollbar {
		height: 2px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: ${props => props.theme.secondary};
	}

	scrollbar-height: 2px;
	scrollbar-color: lightgrey ${props => props.theme.secondary};
`
export const HideScroll = `
&::-webkit-scrollbar {
  display: none;
}
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
`


export const FlexBox = styled.div`
	${ScrollbarStylesHorizontal};
	display: flex;
	${props => {
		if (props.flip) {
			return `
          flex-direction: ${props.direction || "row"} !important;
          @media only screen and (max-width: ${props.flip}px) {
            flex-direction: ${!props.direction ? "column" : props.direction === "row" ? "column" : "row"} !important;
          }
      `
		}
		return `
      flex-direction: ${props.direction || "row"};
    `
	}};
	${props => {
		if (props.fluid) {
			return `width: 100%;`
		}
		return ""
	}}
	flex-wrap: ${props => props.wrap || "wrap"};
	justify-content: ${props => props.justify || "flex-start"};
	align-items: ${props => props.align || "flex-start"};
	flex: 1;
`

export const MediaMessageContainer = styled(`div`)`
	position: absolute;
	bottom: 0;
	min-width: 300px;
	z-index: 1000000;
`

export const CardActions = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 40px;
	border-top: thin solid #E8E8E8;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${props => props.theme.quaternary};
	font-weight: bold;
	text-align: center;

	div {
		cursor: pointer;
	}
`


export const DropzoneSection = styled(`section`)`
	border-radius: 11px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	cursor: pointer;
	height: 100%;
	width: 100%;


	&:focus, &:active {
		outline: none;
		border: ${props => !props.isIcon ? `3px solid ${theme.primary}` : "none"};
		border-radius: 11px;
	}
`

export const ImageBinderContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 12px;
	max-height: 500px;
	overflow-y: auto;

	div {
		position: relative;
		cursor: pointer;

		i.icon {
			position: absolute;
			color: white;
			top: 0;
			bottom: 0;
			right: 0;
			margin: auto;
			left: 0;
			z-index: 1000000000000000;
			font-size: 30px;
		}
	}

	.ui.active.dimmer {
		opacity: 0.7 !important;
		border-radius: 10px !important;
	}

	img {
		border-radius: 10px;
		margin-right: 6px;
		margin-left: 6px;
		height: 100px;
	}
`

export const ImageBucketContainer = styled.div`
	position: relative;
	box-shadow: 2px 2px 4px 0 rgb(0, 0, 0, 0.30);
	border-radius: 0 0 10px 10px;
	width: 100%;
	transition: height 0.15s ease-out;
	overflow: ${props => !props.visible ? "hidden" : "auto"};;
	height: ${props => !props.visible ? "0" : "300px"};
	top: -12px;
`

export const AuthCardContainer = styled("div")`
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-top: ${props => props.topOffset || 80}px;
	width: 100%;

	.sub-item-text {
		margin-top: 12px;
	}

	.agreement-div {
		text-align: left;
		margin-bottom: 16px;
	}

	.message {
		max-width: 500px !important;
		width: 80% !important;
	}

	.card {
		max-width: 500px !important;
		width: 80% !important;

		@media only screen and (max-width: 500px) {
			width: 90% !important;
		}

		.content {
			padding: 20px;

			.button {
				height: 50px;
				text-transform: uppercase;
			}

			text-align: center;

			.header {
				color: ${props => props.theme.main};
			}

			.input:first-child {
				margin-top: 0 !important;
			}

			.input {
				margin-top: 16px;
			}
		}
	}
`

export const PageLoader = styled.div`
	background-color: ${props => props.theme.primary};
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: 100000000000;

	display: flex;
	align-items: center;
	justify-content: center;

	.loader::before {
		border-color: ${props => props.theme.quaternary} !important;
	}
`

export const ProfilePicture = styled.div`
	height: 140px !important;
	width: 140px !important;
	overflow: hidden;
	border-radius: 70px !important;

	img {
		object-fit: cover;
		max-width: 100% !important;
		max-height: 100% !important;
	}
`

export const ToolTipContainer = styled.div`
	display: inline;
	position: relative;
	top: -2px;
	margin-left: 10px;
	z-index: 1000;
	cursor: pointer;

	.tooltip-message {
		z-index: 10000000;
		position: absolute;
		top: 0;
		left: 30px;
		min-width: 200px;
		max-width: 320px;
		height: auto;
		font-size: 12px;
		background: #e9e9e9;
		padding: 0 9px;
		border: thin solid rgba(0, 0, 0, 0.05);
		border-radius: 6px;

		&::after {
			content: " ";
			z-index: 0;
			position: absolute;
			top: 20%; /* At the bottom of the tooltip */
			left: -10px;
			margin-left: -10px;
			border-width: 10px;
			border-style: solid;
			border-color: transparent #e9e9e9 transparent transparent;
		}
	}
`

export const ChaptersContainer = styled.div`
	margin-top: 60px;
	background-color: ${props => props.empty ? 'transparent' : 'white'};
	padding: 12px;
	border-radius: 4px;
`

export const ChapterContentViewContainer = styled.div`
	background-color: white;
	border: 1px solid #D4D4D5;
	padding: 30px;
	border-radius: 6px;
`

export const SettingsPage = styled.div`
	min-height: 200px;
	max-width: 900px;
	background-color: white;
	border-radius: 6px;
	border: 1px solid #E8E8E8;
	margin-top: 16px;
	position: relative;
	padding: 20px;
`

export const Clickable = styled.div`
	color: ${props => props.active ? props.theme.quaternary : "black"};

	&:hover {
		color: ${props => props.theme.quaternary};
		text-decoration: underline;
		cursor: pointer;
	}
`

export const OuterGalleryContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
	justify-content: ${ props => props.small ? 'center' : 'flex-start'};
`


export const CustomGalleryElement = styled.div`
	height: 150px;
	width: 150px;
	border-radius: 8px;
	transition: all .25s ease-in-out;
	background-color: rgba(0, 0, 0, 0.9);
	margin: 4px;

	&:hover img {
		//filter: grayscale(0);
	}

	&:hover {
		border-color: coral;
		div {
			text-decoration: underline;
		}
	}

	img {
		height: 150px;
		width: 150px;
		border-radius: 8px;
		object-fit: cover;
		transition: all .25s ease-in-out;
	}

	p {
		margin: 5px 0;
		padding: 0;
		text-align: center;
		font-style: italic;
	}
`


export const GalleryContainer = styled.ul`
	display: flex;
	flex-wrap: wrap;
	padding-left: 0 !important;

	li {
		height: 100px;
		flex-grow: 1;
		margin: 10px;
		list-style-type: none;
	}

	img {
		max-height: 100%;
		min-width: 100%;
		object-fit: scale-down;
		vertical-align: bottom;
		max-width: 33%;
	}

	img.resize {
		width: auto; /* you can use % */
		height: auto;
	}

	li:last-child {
		flex-grow: 10;
	}

	.meta {
		text-transform: uppercase;
		padding-top: 2px;
	}
`

export const AddButton = styled.div`
	width: 24px;
	height: 24px;
	border-radius: 16px;
	background-color: ${props => props.theme.primary};
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 12px;
	cursor: pointer;

	&:hover {
		background-color: ${props => props.theme.quaternary};
	}

	svg {
		width: 16px;
		color: white;
	}
`

export const ActivityDot = styled.div`
	width: 18px;
	height: 18px;
	border-radius: 20px;
	background-color: ${props => props.active ? "#0ebf17" : props.theme.alert};
	display: inline-block;
	cursor: pointer;
`

export const HeirloomAvatarStyles = {
	width: 30,
	height: 30,
	borderRadius: 30
}


export const ProfileIcon = styled.img`
	width: 46px;
	height: 46px;
	border-radius: 30px;
	margin-right: 16px;
	margin-left: -7px;
`

export const ProfileCircle = styled.img`
	width: 40px;
	height: 40px;
	border-radius: 30px;
	margin-left: 10px;
	margin-right: 10px;
	cursor: pointer;
`

export const PopupListItem = styled(List.Item)`
	display: flex !important;
	height: 50px;
	align-items: center;

	i {
		color: black;
		margin-right: ${props => props.noMargin ? 0 : 20}px !important;
	}
	
	cursor: pointer;

	.header {
		line-height: 21px;
		color: black;
		font-weight: 500;
		&:hover {
		  text-decoration: underline;
		}
	}
`
export const EditItemCircle = styled.div`
	background-color: ${props => props.theme.primary};
	width: 34px;
	height: 34px;
	border-radius: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: ${props => props.inline ? "static" : "absolute"};
	right: 5px;
	top: 5px;
	cursor: pointer;

	img {
		width: 14px;
	}

	&:hover {
		opacity: 0.7;
	}
`

export const Fab = styled.div`
	width: 70px;
	height: 70px;
	border-radius: 40px;
	background-color: ${props => props.theme.primary};
	position: fixed;
	bottom: 20px;
	right: 20px;
	box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);

	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		cursor: pointer;
		opacity: 0.9;
	}

	i {
		font-size: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		left: 2px;
	}
`

export const FamilyType = styled.div`
	text-transform: capitalize;
	min-height: 50px;
	background-color: #FAFAFA;
	margin-bottom: 12px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 12px;
	border-radius: 4px;
	.item-title {
		font-weight: bold;
		font-size: 14px;
	}
`

export const IconImage = styled.img`
  width: 16px !important;
  height: 16px !important;
  margin-right: 8px !important;
`

export const IconImageSmall = styled.img`
  width: 14px !important;
  height: 14px !important;
  margin-right: 8px !important;
`
