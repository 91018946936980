import React, {useEffect, useState} from 'react'
import {useDispatch} from "react-redux";
import PermissionsService from "../../services/permissions.js";
import SelectionMenu from "./selectionMenu.js";
import MemoryFeed from "./MemoryFeed.js";
import PendingFeed from "./PendingFeed.js";
import MemorySettings from "./MemorySettings.js";
import {useOnboarding, usePageScope} from "../../store/hooks/page.hooks.js";
import Root from "../../store/reducers/root/actions.js";

const Memories = ({}) => {
  const [visibleSection, setVisibleSection] = useState('Memories')
  const {permissions, page} = usePageScope();
  const isAdmin = PermissionsService.checkPermissions(permissions, 'isAdmin') || (PermissionsService.checkPermissions(permissions, 'isOnlyBeneficiary') && !!page?.brief?.deceased);
  const RootDispatch = new Root(useDispatch())
  const onboarding = useOnboarding()

  useEffect(() => {
    if (!onboarding.memories) {
      RootDispatch.setShowNux('memories')
    }
  }, [])

  return (
    <div style={{height: '100%'}}>
      <SelectionMenu isAdmin={isAdmin} visibleSection={visibleSection} setVisibleSection={setVisibleSection}/>

      {visibleSection === 'Memories' && <MemoryFeed isAdmin={isAdmin}/>}
      {visibleSection === 'Pending' && <PendingFeed/>}
      {visibleSection === 'Settings' && <MemorySettings/>}

    </div>
  );
};

export default Memories;
