import React, {useEffect, useState} from "react"
import {Button, Image, Modal, Progress} from "semantic-ui-react"
import MediaService from "../../services/media"
import Biography from "../../store/reducers/biography/actions"
import {useDispatch} from "react-redux"
import Root from "../../store/reducers/root/actions"
import ImageDataForm from "./ImageDataForm"
import customTheme from "../../styles/theme"
import {usePageScope} from "../../store/hooks/page.hooks.js";
import {FlexBox} from "../../styles/Containers.js";
import {base64SizeMb} from "../../utils/fileToBase64.js";
import AudioSVG from '../../assets/icons/audio.svg'
import VideoSVG from '../../assets/icons/video.svg'

const defaultImageData = {
  description: "",
  date: "",
  location: "",
  tags: []
}

const UploadImageContent = ({content, onFinished, onUploadComplete, onImageData}) => {
  const rootReducer = new Root(useDispatch())
  const biographyReducer = new Biography(useDispatch())
  const [uploadProgress, setUploadProgress] = useState(-1)
  const [imageData, setImageData] = useState(null)
  const {pageId, page} = usePageScope()

  useEffect(() => {

    if (content && content.length > 0) {
      if (content.length > 1) {
        runBatchUpload()
      } else {
        setImageData({
          ...defaultImageData,
          base64: content[0]
        })
      }
    }

  }, [content])

  const runBatchUpload = async () => {
    let fileSize = 0;
    const imageData = [];
    setUploadProgress((100 / content.length) - 10)
    let i = 1
    for (const base64 of content) {
      try {
        const data = await runUpload({
          ...defaultImageData,
          base64
        }, true)
        fileSize += base64SizeMb(base64);
        imageData.push(data);
      } catch (e) {
        runFailure(e)
        if (e === "UPGRADE_ACCOUNT") {
          return
        }
      }
      setUploadProgress((i / content.length) * 100)
      i += 1
    }
    if (onImageData) {
      onImageData(imageData)
    }
    biographyReducer.fetchGallery(pageId)
      .then(onUploadComplete)
      .finally(() => {
        biographyReducer.addToMediaSize(page, fileSize)
        done(true)
      })
  }

  const runFailure = (e) => {
    console.log(e)
    if (e === "UPGRADE_ACCOUNT") {
      rootReducer.promptPaywall("GALLERY")
      done()
    } else {
      rootReducer.setMessage({
        type: "error",
        message: "Error uploading one of your images"
      })
    }
  }

  const runUpload = async (data, skipLoading = false) => {
    const file = MediaService.dataURLtoFile(data.base64, "test")
    const fileWithExt = MediaService.dataURLtoFile(data.base64, new Date().getTime() + MediaService.getExtension(file.type))
    delete data.base64
    return MediaService.uploadImage([fileWithExt], data, pageId, (loading) => {
      if (!skipLoading) {
        if (loading.loaded && loading.total && loading.total !== 0) {
          setUploadProgress((loading.loaded / loading.total) * 100)
        } else {
          setUploadProgress(1)
        }
      }
    })
  }

  const uploadImageWithData = () => {
    const fileSize = base64SizeMb(imageData.base64);
    setUploadProgress(33)
    runUpload(imageData)
      .then((data) => {
        if (onImageData) onImageData([data]);
        biographyReducer.fetchGallery(pageId).then(onUploadComplete)
          .finally(() => {
            biographyReducer.addToMediaSize(page, fileSize)
            setTimeout(() => {
              done(true)
            }, 500)
          })
      })
      .catch(runFailure)
  }

  const done = (uploaded) => {
    setUploadProgress(-1)
    setImageData(null)
    onFinished(uploaded)
  }

  if (uploadProgress > -1) {
    return (
      <Modal open size={"mini"}>
        <Modal.Header>Uploading...</Modal.Header>
        <Modal.Content>
          <Progress percent={uploadProgress} indicating color={customTheme.primary}/>
        </Modal.Content>
      </Modal>
    )
  }

  const isAudio = imageData?.base64?.indexOf('data:audio') > -1;
  const isVideo = imageData?.base64?.indexOf('data:video') > -1

  const iconProps = {
    style: {
      width: 100,
      height: 100
    }
  }

  if (imageData) {
    return (
      <Modal open>
        <Modal.Header>Upload Content</Modal.Header>
        <Modal.Content image>
          {(isAudio || isVideo) ?
            <FlexBox align={'center'} justify={'center'}>
              {isAudio ? <img src={AudioSVG} {...iconProps}/> : <img src={VideoSVG} {...iconProps} />}
            </FlexBox>
            : <Image size='medium' src={imageData.base64} wrapped/>
          }
          <Modal.Description>
            <ImageDataForm data={imageData} setData={setImageData}/>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={done}>
            Cancel
          </Button>
          <Button
            content="Upload"
            primary
            onClick={uploadImageWithData}
          />
        </Modal.Actions>
      </Modal>
    )
  }

  return (
    <div>
    </div>
  )
}

export default UploadImageContent
