import React, {useState} from 'react'
import {FlexBox} from "../../../styles/Containers.js";
import styled from "styled-components";
import OnboardingReducer from "../../../store/reducers/onboarding/action.js";
import {useDispatch, useSelector} from "react-redux";
import {StepCircle} from "../../../components/common/Stepper.js";
import useWindowSize from "../../../utils/dimensions.js";
import EnterGiftCode from "../../../components/common/EnterGiftCode.js";
import {LISTS} from "../../../config/options.map.js";
import customTheme from "../../../styles/theme.js";

const StepFour = ({setGlobalDiscount}) => {
  const onboarding = new OnboardingReducer(useDispatch());
  const data = useSelector(OnboardingReducer.formData)
  const {width} = useWindowSize();
  const [discount, setDiscount] = useState(null)

  const listedPlans = LISTS.data.payPlans ? [...(JSON.parse(JSON.stringify(LISTS.data.payPlans)) || [])] : []

  for (let i = 0; i < listedPlans.length; i++) {
    if (discount && discount.discount && discount.discount.plans.indexOf(listedPlans[i].id) > -1) {
      listedPlans[i].cost = listedPlans[i].cost - ((+discount.discount.discount || 0) * listedPlans[i].cost);
      listedPlans[i].updated = true;
      if (listedPlans[i].cost < 0) {
        listedPlans[i].cost = 0;
      }
    }
  }

  return (
    <>
      <FlexBox justify={width < 801 ? 'flex-start' : 'center'} direction={'row'} wrap={'none'}
               style={{overflowX: 'auto', padding: 12}}>
        {listedPlans.map(o => (
          <PlanCard onClick={() => {
            onboarding.formChange(null, {name: 'plan', value: o.id})
          }}>
            <div style={{flex: 1}}>
              <h2><strong>{o.title}</strong></h2>

              <h3 style={{
                textAlign: 'center',
                color: o.updated ? 'green' : customTheme.quaternary
              }}>${(+o.cost).toFixed(2)}</h3>

              <p style={{textAlign: 'center', fontSize: 12}}>
                One Time Fee Per Everloom
              </p>

              <h4>{o.description}</h4>

              <ul>
                {o.perks.map(item => (
                  <li>{item}</li>
                ))}
              </ul>
            </div>
            <div style={{width: '100%'}}>
              <StepCircle style={{margin: 'auto'}} dark active={data.plan === o.id}/>
            </div>
          </PlanCard>
        ))}

      </FlexBox>
      <FlexBox justify={'center'} align={'center'}
               style={{position: 'relative', maxWidth: 340, margin: '12px auto 30px auto'}}>
        <EnterGiftCode usingCode={!!data.giftCode}
                       applyGiftCode={code => {
                         if (code && code.discount) {
                           setDiscount(code.discount);
                           setGlobalDiscount(code.discount)
                           onboarding.formChange(null, {name: 'giftCode', value: code.discount.code})
                         }
                       }}
                       clearGiftCode={() => {
                         setDiscount(null);
                         setGlobalDiscount(null)
                         onboarding.formChange(null, {name: 'giftCode', value: null})
                       }}/>
      </FlexBox>
    </>
  );
};

export default StepFour;

const PlanCard = styled.div`
  width: 240px;
  min-height: 481px;
  border-radius: 4px;
  background-color: white;
  margin-top: 12px;
  border: thin solid lightgrey;
  padding: 20px 12px 20px 12px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;

  ul {
    margin-top: 20px;
  }

  li {
    line-height: 30px;
    margin-bottom: 10px;

  }

  h2, h4 {
    text-align: center;
    width: 100%;
  }
`;
