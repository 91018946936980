import React, {useState} from 'react'
import {Button, Confirm, Modal} from "semantic-ui-react";
import firebase from "firebase";
import BiographyService from "../services/biography.js";
import Root from "../store/reducers/root/actions.js";
import {useDispatch} from "react-redux";
import Biography from "../store/reducers/biography/actions.js";
import {usePageScope} from "../store/hooks/page.hooks.js";
import ModalLoader from "./common/ModalLoader.js";

const DeleteChapter = ({onCancel, onDone, chapter}) => {
  const RootDispatch = new Root(useDispatch())
  const [loading, setLoading] = React.useState(false)
  const BiographyDispatch = new Biography(useDispatch())
  const { pageId } = usePageScope()

	console.log(chapter)

  const runDelete = () => {

    firebase.analytics().logEvent("deleting_chapter");
    setLoading(true)
    BiographyService.deleteChapter(chapter.chapterId, pageId)
      .then(() => {
        BiographyDispatch.fetchChapters(pageId).finally(() => {
          setLoading(false)
          RootDispatch.setMessage({
            type: "success",
            message: "Chapter deleted"
          })
          onDone()
        })
      })
      .catch(e => {
        setLoading(false)
        RootDispatch.setMessage({
          type: "error",
          message: "There was an error deleting your chapter. Try again later or contact us for help."
        })
      })

  }

  return (
    <Modal size={'small'} open={!!chapter}>
      <Modal.Header>
        Delete Chapter
      </Modal.Header>
      <Modal.Content>
        <ModalLoader active={loading}/>
        Are you sure you would like to delete this chapter?
      </Modal.Content>

      <Modal.Actions>
        <Button primary onClick={onCancel}>Cancel</Button>
        <Button color={'red'} onClick={runDelete}>Delete</Button>
      </Modal.Actions>
    </Modal>
  )
};

export default DeleteChapter;
