import API from "./api.js";
import {mappedOptions} from "../config/options.map.js";

export default class PermissionsService {

  static base = 'permissions';

  static checkPermissions = (permissions, type) => {
    const options = mappedOptions('permissionAccess');
    if (!options || !permissions) {
      return false;
    }

    for (const p of permissions) {
      if (options[type]?.indexOf(p) > -1) {
        return true;
      }
    }
    return false;
  }

  static revokePermissions(data, pageId) {
    return API.post(`${PermissionsService.base}/${pageId}/revoke`, data)
  }

  static addPermissionToPage = (data, pageId) => {
    return API.put(`${PermissionsService.base}/${pageId}/add`, data)
  }

  static getPermissions = (pageId) => {
    return API.get(`${PermissionsService.base}/${pageId}/permissions`)
  }

  static getTaggable = (pageId, soft) => {
    return API.post(`pages/${pageId}/taggable`, {
      soft
    })
  }

  static declineInvite = (data) => {
    return API.post(`${PermissionsService.base}/invites/decline`, data)
  }

  static acceptInvite = (data) => {
    return API.post(`${PermissionsService.base}/invites/accept`, data)
  }
}
