import React from 'react'
import {GoogleLogin} from 'react-google-login';
import {Button, Icon} from "semantic-ui-react";
import customTheme from "../../styles/theme.js";
import Root from "../../store/reducers/root/actions.js";
import {useDispatch} from "react-redux";
import googleImg from '../../assets/images/icons/google.png'

const GoogleLoginButton = ({googleData, disabled}) => {
    const root = new Root(useDispatch());

    const responseGoogle = (response) => {
      console.log('Got google token')
        googleData(response.tokenId)
    }

    const responseFailed = (response) => {
        if (response.error !== 'popup_closed_by_user' && response.error !== "idpiframe_initialization_failed") {
            root.setMessage({
                type: 'error',
                message: 'There was an error accessing Google authentication'
            })
        } else {
          console.log(response)
        }
    }

    return (
        <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT}
            buttonText="Continue with Google"
            render={renderProps => (
                <Button labelPosition='left' icon fluid style={{backgroundColor: 'white', border: 'thin solid grey'}}
                        onClick={renderProps.onClick} disabled={renderProps.disabled}>
                  <img src={googleImg} style={googleIconStyle} />
                    Continue with Google
                </Button>
            )}
            disabled={disabled}
            onSuccess={responseGoogle}
            onFailure={responseFailed}
            cookiePolicy={'single_host_origin'}
        />
    );
};

export default GoogleLoginButton;

const googleIconStyle = {
  position: 'absolute',
  left: 20,
  top: 15,
  width: 20, height: 20
}
