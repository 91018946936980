import React, {useEffect, useState} from 'react'
import {Divider, Message} from "semantic-ui-react";
import {useSelector} from "react-redux";
import PermissionsReducer from "../../store/reducers/permissions/actions.js";
import styled from "styled-components";
import {FlexBox} from "../../styles/Containers.js";
import DirectoryAccessDropdown from "../Account/components/DirectoryAccessDropdown.js";
import TreeService from "../../services/tree.js";
import Biography from "../../store/reducers/biography/actions.js";
import ModalLoader from "../../components/common/ModalLoader.js";
import Auth from "../../store/reducers/auth/actions.js";
import Screen from "../../layout/Screen.js";
import {usePageScope, useTaggable} from "../../store/hooks/page.hooks.js";

const ContactAccess = ({access, contact}) => {
  const [newAccess, setNewAccess] = useState([]);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null);
  const {pageId} = usePageScope()
  const user = useSelector(Auth.user);

  useEffect(() => {
    setNewAccess(access || [])
  }, [])


  let {data: options} = useTaggable()

  const saveChanges = (newAccessLevels) => {
    setLoading(true);
    TreeService.updateContactBook(pageId, {
      ...contact, pageAccess: newAccessLevels
    }).catch(e => {
      console.log(1, e)
      setError(e);
    }).then(() => setLoading(false))
  }

  return (
    <Screen style={{backgroundColor: 'white', borderRadius: 6, minHeight: 300}}>
      <ModalLoader active={loading}/>
      {!!error && <Message error>{error}</Message>}
      {(options || []).filter(o => o.pageCode !== user.primaryPageId).map(o => {
        const permissions = newAccess.indexOf(o.pageCode) === -1 ? {DIRECTORY: false} : {DIRECTORY: true}
        return (
          <>
            <PermissionItem justify={'space-between'}>
              <strong>{o.name}</strong>
              <DirectoryAccessDropdown permissions={permissions}
                                       setPermissions={perms => {
                                         const accessLevels = [...newAccess]
                                         const i = accessLevels.indexOf(o.pageCode)
                                         if (perms.DIRECTORY === false) {
                                           if (i > -1) {
                                             accessLevels.splice(i, 1)
                                           }
                                         } else {
                                           if (i === -1) {
                                             accessLevels.push(o.pageCode);
                                           }
                                         }
                                         setNewAccess(accessLevels);
                                         saveChanges(accessLevels)
                                       }}/>
            </PermissionItem>
            <PermissionDivider/>
          </>
        )
      })
      }
    </Screen>
  );
};

export default ContactAccess;

const PermissionItem = styled(FlexBox)`

`

const PermissionDivider = styled(Divider)`
  margin: 12px 0 12px 0 !important;
  opacity: 0.5;
`
