import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import Media from "../../store/reducers/media/actions"
import {Loader, Message, Modal} from "semantic-ui-react"
import {CONSTANTS} from "../../config/constants"
import MediaService from "../../services/media"
import {
  ActionArrows,
  ChapterDetailsTitle,
  CloseImg,
  DetailContainer,
  DetailsBox,
  ImageViewerContainer,
  MediaViewerDimmer
} from "./styles"
import ImageEditor from "./ImageEditor"
import {useKeyPress} from "../../utils/useKeyPress"
import EditBar from "./EditBar"
import HeirloomImage from "../common/HeirloomImage.js"
import {ArrowBack, ArrowForward} from "react-ionicons"
import CloseIcon from '@images/icons/close.svg';
import {useOnboarding} from "../../store/hooks/page.hooks.js";
import Root from "../../store/reducers/root/actions.js";
import AudioWaveform from "./AudioWaveform.js";
import {getAudioType} from "../common/GalleryList.js";

const ViewImage = ({close}) => {
  const dispatch = useDispatch()
  const MediaDispatch = new Media(dispatch)
  const RootDispatch = new Root(dispatch)
  const viewImage = useSelector(Media.viewImage)
  const onboarding = useOnboarding()
  const imageData = viewImage.data
  const galleryTitle = viewImage.galleryTitle
  const queue = viewImage.gallery
  const extension = imageData ? MediaService.getType(imageData) : ""
  const [mediaLoadError, setMediaLoadError] = useState(null)
  const [videoLoadError, setVideoLoadError] = useState(null)
  const isImage = CONSTANTS.acceptedImages.indexOf(extension) > -1

  const goNext = useKeyPress("ArrowRight")
  const goLast = useKeyPress("ArrowLeft")

  useEffect(() => {
    if (!onboarding.downloadMedia) {
      RootDispatch.setShowNux('downloadMedia')
    }
    const currentIndex = getCurrentIndex()
    if (goNext) {
      if (currentIndex === queue.length - 1) {
        return
      }
      nextImage(currentIndex)
      MediaDispatch.setViewImage(queue[currentIndex + 1], queue)
    } else if (goLast) {
      if (currentIndex === 0) {
        return
      }
      lastImage(currentIndex)
    }
  }, [goNext, goLast])

  const nextImage = () => {
    MediaDispatch.setViewImage(queue[getCurrentIndex() + 1], queue)
  }

  const lastImage = () => {
    MediaDispatch.setViewImage(queue[getCurrentIndex() - 1], queue)
  }

  const closeImage = () => {
    close()
    setMediaLoadError(null)
    setVideoLoadError(null)
  }

  const getCurrentIndex = () => {
    return queue.findIndex(o => o.Location === imageData.Location)
  }

  const Details = () => (
    <ChapterDetailsTitle>
      <div>{galleryTitle || "Gallery"}</div>
      {queue.length ? <div>{getCurrentIndex() + 1}/{queue.length}</div> : <div/>}
      <ActionArrows>
        <ArrowBack onClick={() => lastImage()}/>
        <ArrowForward onClick={() => nextImage()}/>
      </ActionArrows>
      <CloseImg>
        <img src={CloseIcon} onClick={closeImage}/>
      </CloseImg>
    </ChapterDetailsTitle>
  )

  const renderMedia = () => {
    if (isImage) {
      return (
        <MediaViewerDimmer>
          {Details()}
          <div className={"media-container"}>
            <HeirloomImage onLoad={() => setMediaLoadError(false)} onError={() => setMediaLoadError(true)}
                           src={(imageData.base64 || imageData.Location)}/>
          </div>
        </MediaViewerDimmer>
      )
    } else if (CONSTANTS.acceptedMedia.indexOf(extension) > -1) {
      if (mediaLoadError === null) {
        setMediaLoadError(false)
      }
      return (
        <MediaViewerDimmer>
          {Details()}
          <div className={"media-container"}>
            <iframe src={imageData.base64 || imageData.Location}/>
          </div>
        </MediaViewerDimmer>
      )
    } else if (CONSTANTS.acceptedVideos.indexOf(extension) > -1) {
      const isAudio = getAudioType(imageData, extension)
      return (
        <MediaViewerDimmer>
          {Details()}
          <div className={"media-container"} style={{flexDirection: 'column'}}>
            {/*{isAudio && <AudioWaveform data={imageData}/>}*/}
            <video width="400" controls
                   onLoadedData={() => setMediaLoadError(false)}
                   onError={(e) => {
                     setVideoLoadError(true)
                   }}
                   onCanPlay={() => setVideoLoadError(false)}>
              <source src={imageData.base64 || imageData.Location} type={"video/mp4"}/>
              Your browser does not support HTML video.
            </video>
          </div>
        </MediaViewerDimmer>
      )
    }
  }

  const videoError = (videoLoadError === true && CONSTANTS.acceptedVideos.indexOf(extension) > -1)
  const mediaError = (mediaLoadError === true && CONSTANTS.acceptedVideos.indexOf(extension) === -1)

  return (
    <Modal open dimmer={false}
           className={"image-viewer-modal"}
           mountNode={document.getElementById("gallery-modal")}>
      <ImageViewerContainer>

        <div id={"render-media"}>
          {renderMedia()}
        </div>

        <DetailContainer>
          <DetailsBox id={"edit-image-container"}>
            <EditBar imageData={imageData} onClose={closeImage}>
              {(mediaError || videoError) ? <div>
                <Message negative style={{textAlign: "center"}}>
                  There was an error loading this media content
                </Message>
              </div> : mediaLoadError === null ? <Loader active/> : <ImageEditor/>}
            </EditBar>
          </DetailsBox>
        </DetailContainer>
      </ImageViewerContainer>
    </Modal>
  )
}

export default ViewImage
