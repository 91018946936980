import loggerMiddleware from "./middleware/logger"
import { applyMiddleware, combineReducers, compose, createStore } from "redux"
import monitorReducerEnhancer from "./enhancers/monitorReducer"
import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { rootReducer } from "./reducers/root"
import { authReducer } from "./reducers/auth"
import { mediaReducer } from "./reducers/media"
import { biographyReducer } from "./reducers/biography"
import { permissionsReducer } from "./reducers/permissions"
import { notificationsReducer } from "./reducers/notifications"
import Root from "./reducers/root/actions.js"
import Media from "./reducers/media/actions.js"
import Biography from "./reducers/biography/actions.js"
import PermissionsReducer from "./reducers/permissions/actions.js"
import Notifications from "./reducers/notifications/actions.js"
import Auth from "./reducers/auth/actions.js"
import Tree from "./reducers/tree/actions.js"
import { treeReducer } from "./reducers/tree"
import MemoriesReducer from "./reducers/memories/actions.js"
import { memoriesReducer } from "./reducers/memories"
import OnboardingReducer from "./reducers/onboarding/action.js"
import { onboardingReducer } from "./reducers/onboarding"
import { userReducer } from "./reducers/user"
import { UserReducer } from "./reducers/user/action"

const persistConfig = {
	key: "root",
	storage,
	whitelist: ["auth"]
}

export const getAllReducers = (dispatch) => {
	return {
		rt: new Root(dispatch),
		auth: new Auth(dispatch),
		media: new Media(dispatch),
		biography: new Biography(dispatch),
		permissions: new PermissionsReducer(dispatch),
		notifications: new Notifications(dispatch),
		tree: new Tree(dispatch),
		memories: new MemoriesReducer(dispatch),
		onboarding: new OnboardingReducer(dispatch),
		user: new UserReducer(dispatch)
	}
}

export const configureStore = () => {

	const middlewareEnhancer = applyMiddleware(loggerMiddleware)

	const composedEnhancers = compose(middlewareEnhancer, monitorReducerEnhancer)

	const reducers = combineReducers({
		root: rootReducer,
		auth: authReducer,
		media: mediaReducer,
		biography: biographyReducer,
		permissions: permissionsReducer,
		notifications: notificationsReducer,
		tree: treeReducer,
		memories: memoriesReducer,
		onboarding: onboardingReducer,
		user: userReducer
	})

	const persistedReducer = persistReducer(persistConfig, reducers)


	const store = createStore(persistedReducer, composedEnhancers)
	const persistor = persistStore(store)

	return { persistor, store }
}
window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
