import React, { useEffect, useState } from "react"
import { Button, List, Modal } from "semantic-ui-react"
import ModalLoader from "../../components/common/ModalLoader"
import { FlexBox } from "../../styles/Containers"
import { useDispatch } from "react-redux"
import Biography from "../../store/reducers/biography/actions"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import Root from "../../store/reducers/root/actions"
import { sortByIndex } from "../../utils/sortIndex"
import { ActionButton, CustomToggle } from "../../styles/Semantic.js"
import PositionClicker from "../../components/common/PositionClicker"
import debounce from "lodash.debounce"
import { usePageScope } from "../../store/hooks/page.hooks.js"
import DeleteChapter from "../../components/DeleteChapter.js"

const SortChapters = ({ setClose, chapters }) => {
	const [sorting, setSorting] = React.useState(false)
	const [loading, setLoading] = React.useState(false)
	const [deleteChapter, setDeleteChapter] = useState(false)
	const { pageId, activePlan } = usePageScope()
	const BiographyDispatch = new Biography(useDispatch())
	const RootDispatch = new Root(useDispatch())
	const history = useHistory()
	const [order, setOrder] = useState([])
	const [publishUpdates, setPublishUpdates] = useState([])

	useEffect(() => setOrder(chapters), [chapters])

	const updatedOrder = debounce(() => {
		RootDispatch.setMessage({
			type: "success",
			message: "Chapters updated"
		})
	}, 1500)

	const setPublishState = (chapterId, status) => {
		if (!activePlan.canPublish) {
			RootDispatch.promptPaywall("CHAPTER_PUBLISH")
		} else {
			const updatedStatus = publishUpdates.findIndex(o => o.chapterId === chapterId)
			const vals = [...publishUpdates]
			if (updatedStatus > -1) {
				vals.splice(updatedStatus, 1)
			} else {
				vals.push({
					pageId, chapterId, status
				})
			}
			setPublishUpdates(vals)
		}
	}

	const statusToggled = (ev, ch) => {
		if (ev.checked === true) {
			setPublishState(ch.chapterId, "PUBLISH")
		} else {
			setPublishState(ch.chapterId, "DRAFT")
		}
	}

	const doneChapterDelete = async () => {
		setDeleteChapter(null)
		setSorting(false)

	}

	const reorderValues = async (direction, i) => {
		const sort = [...order]

		for (let i = 0; i < sort.length; i++) {
			sort[i].index = i + 1
		}
		setOrder(sort)

		if (i === sort.length && direction === "down") {
			return
		}
		if (i === 0 && direction === "up") {
			return
		}
		const i1 = sort.findIndex(o => o.index === i)
		const i2 = sort.findIndex(o => o.index === (direction === "down" ? i + 1 : i - 1))

		if (i1 === -1 || i2 === -1) {
			return
		}

		sort[i1].index = (direction === "down" ? i + 1 : i - 1)
		sort[i2].index = i
		setOrder(sort)
	}

	const save = async () => {
		setLoading(true)
		await BiographyDispatch.updateOrder(order, pageId)
		updatedOrder()
		for (const page of publishUpdates) {
			await BiographyDispatch.setChapterStatus(page.pageId, page.chapterId, page.status)
		}
		setLoading(false)
		setClose(false)
	}

	return (
		<Modal open style={{ maxWidth: 550 }} closeIcon onClose={setClose}>
			<Modal.Header>
				Edit Chapters
			</Modal.Header>
			<Modal.Content>
				<DeleteChapter chapter={deleteChapter} onDone={doneChapterDelete} onCancel={() => setDeleteChapter(null)}/>
				<ModalLoader active={loading}/>
				<List style={{ marginTop: 0 }}>
					{
						sortByIndex(order).map((ch, i) => {
							const name = ch.title
							const updatedStatus = publishUpdates.find(o => o.chapterId === ch.chapterId)
							const status = !!updatedStatus ? updatedStatus.status : ch.status
							return (
								<DragItem>
									<FlexBox justify={"space-between"} align={"center"}>
										<FlexBox style={{ flex: 0.7, width: 121, whiteSpace: "nowrap" }} align={"center"} wrap={"unset"}>
											<PositionClicker styles={{ flex: 0, marginRight: 10 }} classNames={"hover-hide"}
																			 onClick={dir => reorderValues(dir, i + 1)}/>
											<strong style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{i + 1}. {name}</strong>
										</FlexBox>
										<StatusButton>
											<CustomToggle onChange={(ev, res) => statusToggled(res, ch)} checked={status === "PUBLISH"}/>
											{status === "PUBLISH" ? <span className={"pub"}>Published</span> :
												<span className={"unpub"}>Unpublished</span>}
										</StatusButton>
										<FlexBox wrap={"unset"} style={{ flex: 0 }}>
											<ActionButton style={{ marginRight: 4 }} onClick={() => {
												setSorting(false)
												history.push(`/${pageId}/chapters/manage/?chapterId=${ch.chapterId}`)
											}}>Edit</ActionButton>
											<ActionButton onClick={() => setDeleteChapter(ch)} warn>Delete</ActionButton>
										</FlexBox>
									</FlexBox>
								</DragItem>
							)
						})
					}
				</List>
			</Modal.Content>
			<Modal.Actions>
				<Button primary onClick={save} loading={loading}>
					Save Changes
				</Button>
			</Modal.Actions>
		</Modal>

	)
}

export default SortChapters

const DragItem = styled(List.Item)`
	min-height: 30px;
	background-color: #FAFAFA;
	display: flex;
	align-items: center;
	padding: 4px 4px 4px 10px !important;
	margin-bottom: 6px;

	i, svg {
		cursor: pointer;

		&:hover {
			opacity: 0.5;
		}
	}

	.hover-hide {
		visibility: hidden;
	}

	&:hover {
		.hover-hide {
			visibility: visible;
		}
	}
`

export const StatusButton = styled.span`
	font-weight: 500;
	font-size: 12px;
	margin-top: 6px;
	line-height: 30px;

	display: flex;
	align-items: center;

	.unpub {
		color: ${props => props.theme.alert};
	}

	.pub {
		color: ${props => props.theme.primary};
	}

	.pub, .unpub {
		min-width: 70px;
	}

	.ui.checkbox {
		margin-right: 10px;
	}
`

const EditButton = styled.span`
	font-weight: 500;
	margin-right: 20px;
	margin-left: 10px;

	&:hover {
		cursor: pointer;
		color: ${props => props.theme.quaternary}l
	}
`
