import API from "./api"

export default class AuthService {

	static base = "user"
	static referralInfo = null

	static tokens = () => {
		return JSON.parse(localStorage.getItem("@heirloom:tokens") || "{}")
	}

	static setTokens = (tokens) => {
		if (tokens && tokens.token && tokens.refreshToken) {
			localStorage.setItem("@heirloom:tokens", JSON.stringify(tokens))
		}
	}

	static clearTokenData = () => {
		localStorage.removeItem("@heirloom:tokens")
	}

	static setNuxViewed(data) {
		return API.post(AuthService.base + "/me/nux", {
			onboarding: data
		})
	}

	static getMyReferrals() {
		return API.get(AuthService.base + "/me/referrals")
	}

	static claimReferral() {
		return API.post(AuthService.base + "/me/referrals/claim")
	}

	static login(data) {
		return API.put(AuthService.base + "/login", data)
	}

	static loginGoogle(token) {
		return API.put(AuthService.base + "/login/google", { token })
	}

	static registerGoogle(token, checkedOptions, referralCode) {
		return API.post(AuthService.base + "/signup/google", { token, checkedOptions, referralCode })
	}

	static register(data) {
		return API.post(AuthService.base + "/signup", data)
	}

	static forgotPassword(data) {
		return API.get(AuthService.base + `/${data.email}/forgot`)
	}

	static updatePass(data) {
		const token = window.location.search.substr(6)
		return API.put(AuthService.base + "/reset-password", data, {
			headers: {
				Authorization: "Bearer " + token
			}
		})
	}

	static verifyEmail(userId, emailCode) {
		return API.post(AuthService.base + "/verify-email", {
			userId, emailCode
		})
	}

	static resendVerification() {
		return API.get(AuthService.base + "/resend-verify-email")
	}

	static getMe(refresh = false) {
		return API.get(AuthService.base + "/me?refresh=" + refresh)
	}

	static declineBeneficiaryRequest(pageId) {
		return API.post(AuthService.base + "/me/decline-beneficiary", {
			pageId
		})
	}

	static acceptBeneficiaryRequest(pageId) {
		return API.post(AuthService.base + "/me/accept-beneficiary", {
			pageId
		})
	}

	static deleteAccount() {
		return API.put(AuthService.base + "/me/delete-account", {})
	}

	static getReferralCodeName(code) {
		return API.get(AuthService.base + "/referral/" + code, {})
	}
}
