import React, {useRef, useState} from 'react'
import {FlexBox, HeirloomAvatarStyles} from "../../styles/Containers.js";
import HeirloomImage from "../../components/common/HeirloomImage.js";
import {PagesService} from "../../services/pages.js";
import * as moment from "moment";
import {CONSTANTS} from "../../config/constants.js";
import {Button} from "semantic-ui-react";
import {MemorySegment} from "./PendingFeed.js";
import {QuillDeltaToHtmlConverter} from "quill-delta-to-html";
import ModalLoader from "../../components/common/ModalLoader.js";

const MemoryItem = ({actions = false, item, accept, decline, loading, feed, remove}) => {
    const [expand, setExpand] = useState(false);
    const mainBody = useRef()

    const converter = new QuillDeltaToHtmlConverter(item.text?.ops);
    const html = converter.convert();

    const expanding = (e) => {
        setExpand(!expand);
    }

    if (!html) return <div/>
    return (
        <MemorySegment expand={expand}>
            <ModalLoader active={loading}/>
            <div className={'quill-content'} ref={mainBody} dangerouslySetInnerHTML={{__html: html}}/>
            {(mainBody && mainBody.current && mainBody.current.scrollHeight > 200 && !expand) &&
            <a onClick={expanding} style={{cursor: 'pointer'}}>...view more</a>}

            {expand && <a onClick={expanding} style={{cursor: 'pointer'}}>...view less</a>}

            <FlexBox style={{marginTop: 10}} justify={'space-between'} align={'flex-end'}>
                <FlexBox align={'center'} style={{fontSize: 10}}>
                    <HeirloomImage style={HeirloomAvatarStyles} src={item.poster.image}/>
                    <a href={PagesService.navigatePageRoute(item.poster.pageCode)} style={{
                        paddingLeft: 10,
                        paddingRight: 5
                    }}>{item.poster.firstName} {item.poster.lastName}</a>
                    | <span
                    style={{paddingLeft: 5}}>{feed ? moment.unix(+item.timePosted / 1000).format(CONSTANTS.prettyDate) : moment(item.createdAt).format(CONSTANTS.prettyDate)}</span>
                </FlexBox>
                {(actions && feed) &&
                <Button onClick={() => remove(item)} size={'mini'} basic color={'red'}>Remove</Button>
                }
                {(actions && !feed) && <div>
                    <Button onClick={() => decline(item)} size={'mini'} color={'red'}>Decline</Button>
                    <Button onClick={() => accept(item)} size={'mini'} primary>Approve</Button>
                </div>}
            </FlexBox>
        </MemorySegment>
    );
};

export default MemoryItem;
