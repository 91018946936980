import styled from "styled-components"
import { FlexBox } from "./Containers.js"
import { CONSTANTS } from "../config/constants.js"
import React from "react"

export const BottomNavContainer = styled(FlexBox)`
	margin-top: auto;
	position: fixed;
	bottom: 0;
	width: 260px;
	padding-right: 20px;
	height: 60px;
	left: 0;
	background-color: ${props => props.theme.primary};

	span {
		cursor: pointer;
	}
`

export const SideNav = styled.div`
	width: ${CONSTANTS.menuSize}px;
	height: 100%;
	position: fixed;
	top: 0;
	background-color: ${props => props.theme.secondary};
	overflow-x: hidden;
	z-index: 1000000000;
	color: white !important;
	padding: 16px 16px 70px 16px;
	overflow: hidden;

	transform: translateX(${props => props.open ? 0 : -CONSTANTS.menuSize}px);
	transition: all 0.2s linear;

	.back-chev {
		display: ${props => props.open ? "block" : "none"};
		position: absolute;
		cursor: pointer;

		svg {
			color: white;
		}

		&:hover {
			svg {
				color: ${props => props.theme.quaternary};
			}
		}
	}
`

export const LogoPosition = styled.div`
	display: flex;
	margin-top: 4px;
	margin-bottom: 16px;
	align-items: center;
	justify-content: center;

	img {
		height: 100px;
	}
`

export const MetaContent = styled.div`
	margin-top: 12px;
	cursor: pointer;
	font-size: 12px;

	&:hover {
		.meta-detail:first-child {
			opacity: 0.5;
		}
	}

	div {
		color: black;
	}

	.meta-detail {
		color: black;
		font-weight: bold;
		flex: 1;
		padding-left: 6px;
	}
`

export const ProfileContent = styled.div`
	margin-top: 20px;

	.content-item {
		opacity: ${props => props.disabled ? 0.5 : 1};
		align-items: center;
		display: flex;
		line-height: 20px;
		height: 40px;
		cursor: pointer;

		svg {
			margin-right: 9px;
			color: white;
			fill: white;
		}

		.active-item {
			border-bottom: 2px solid ${props => props.theme.quaternary};
		}
	}
`

export const PageSelector = styled(FlexBox)`
	min-height: 48px;
	width: 100%;
	border-radius: 4px;
	margin-top: 12px;
	font-weight: bold;
	padding-left: 12px;
	padding-right: 12px;

	background-color: ${props => props.theme.secondary} !important;
	color: white;

	span svg {
		color: white;
	}

	&:hover {
		background-color: white;
		cursor: pointer;
		border: thin solid ${props => props.theme.primary};
	}
`

export const SendPageContainer = styled.div`
	height: ${props => props.active ? 46 : 0}px;
	overflow: hidden;
	transition: 0.1s linear;
`

export const StickySubNav = styled.div`
	position: fixed;
	background-color: ${props => props.theme.background};
	top: 128px;
	height: 50px;
	padding-top: 12px;
	right: 20px;
	left: ${CONSTANTS.menuSize + 12 + 24}px;
	z-index: 500;
	max-width: 1280px;
`

export const HoverableMenu = ({ children, style = {} }) => (
	<HoverableMenuStyled style={style} className={"dropdown-content"}>{children}</HoverableMenuStyled>
)

export const HoverMenuActiveStyles = `
.dropdown-content {
	margin-right: 50px;
}
	.dropdown-content div:hover {
		background-color: #ddd;
		cursor: pointer;
	}

	&:hover .dropdown-content {
		display: block !important;
	}
`

const HoverableMenuStyled = styled.div`
	display: none;
	position: fixed;
	background-color: white;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 9999999999;
	border-radius: 4px;

	div {
		height: 30px;
		padding-left: 12px;
		display: flex;
		align-items: center;
	}

`
