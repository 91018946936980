import React, { useEffect } from "react"
import styled from "styled-components"
import { ProfileMenuItems } from "../config/options.map"
import { FlexBox, HideScroll } from "../styles/Containers.js"
import { Link, useHistory } from "react-router-dom"
import { matchPath } from "react-router"
import PermissionsService from "../services/permissions"
import { usePageScope } from "../store/hooks/page.hooks.js"
import { usePagePermissions } from "../store/hooks/permissions.hooks.js"
import { useToasts } from "react-toast-notifications"
import { isDemoDomain } from "../utils/isDemo"

let toast
const TopPageNav = ({}) => {
	const { pageId, permissions, activePlan, page, basePath } = usePageScope()
	const { data: currentPermissions } = usePagePermissions(pageId, page)
	const isDemo = isDemoDomain
	const { addToast, removeAllToasts } = useToasts()
	const history = useHistory()

	const match = matchPath(window.location.pathname, {
		path: `${basePath}/:pageId/:current`,
		exact: false,
		strict: false
	})

	const current = match?.params?.current

	useEffect(() => {
		if (currentPermissions && !toast) {
			const size = (currentPermissions.permissionsRequests || []).length
			if (size > 0) {
				removeAllToasts()
				toast = addToast(`You have ${size} connections requests.<br /> <b>Click here to manage them.</b>`, {
					appearance: "info", callback: () => {
						history.push(`${basePath}/${pageId}/share`)
						removeAllToasts()
					}
				})
			}
		}
	}, [currentPermissions])

	const hasPermissions = (gates, value) => {
		if (isDemo && value === "family") {
			return true
		}
		if (!gates || gates.length === 0) return true
		return gates.some(type => !!PermissionsService.checkPermissions(permissions, type))
	}

	const getPostFix = (key) => {

		switch (key) {
			case "share":
				const size = (currentPermissions.permissionsRequests || []).length
				return size > 0 && <MenuBlip>{size}</MenuBlip>
			default:
				return ""
		}
	}

	return (
		<TopNavContainer>
			<FlexBox wrap={"unset"}>
				{(ProfileMenuItems.filter(o => {
					if (!activePlan.memoryAccess)
						return o.key !== "memories"
					return true
				}).filter(o => o.key !== "biography").filter(o => hasPermissions(o.gate, o.slug))).map(o => (
					<MenuItem to={`${basePath}/${pageId}/${o.slug}`} active={current === o.slug}>
						{o.name} {getPostFix(o.key)}
						<ActiveContainer active={current === o.slug}/>
					</MenuItem>
				))
				}
			</FlexBox>
		</TopNavContainer>
	)
}

export default TopPageNav

const ActiveContainer = styled.div`
	visibility: ${props => props.active ? "unset" : "hidden"};
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 4px;
	background-color: ${props => props.theme.primary};
`

const TopNavContainer = styled.div`
	height: 40px;
	width: 100%;
	background-color: white;
	border-radius: 4px;
	padding: 0 6px 0 6px;
	overflow-x: auto;
	${HideScroll}
`
const MenuItem = styled(Link)`
	position: relative;
	min-width: 120px;
	height: 40px;
	color: ${props => props.theme.text};
	display: flex;
	align-items: center;
	justify-content: center;

		// background-color: ${props => props.active ? props.theme.quaternary : "transparent"};
	border-radius: 4px;
`

const MenuBlip = styled.div`
	position: relative;
	top: -5px;
	color: red;
	font-size: 12px;
	font-weight: bold;
`
