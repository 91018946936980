import React, { useState } from "react"
import styled from "styled-components"
import { Button, Modal } from "semantic-ui-react"
import MyPages from "./MyPages"
import ModalLoader from "../../components/common/ModalLoader.js"
import AuthService from "../../services/auth"
import Root from "../../store/reducers/root/actions"
import { useHistory } from "react-router-dom"
import Auth from "../../store/reducers/auth/actions"
import { useDispatch } from "react-redux"
import {useQueryClient} from "react-query";

const DeleteAccount = ({}) => {
	const history = useHistory()
	const dispatch = useDispatch()
	const userDispatch = new Auth(dispatch)
	const root = new Root(dispatch)
	const [promptDelete, setPromptDelete] = useState(false)
	const [loading, setLoading] = useState(false)
	const queryClient = useQueryClient()

	const deleteAccount = () => {
		setLoading(true)
		AuthService.deleteAccount()
			.then(() => {
				root.setMessage({
					type: "Success",
					message: "Account deleted"
				})
				queryClient.invalidateQueries()
				userDispatch.logout()
				history.push("/")
			}).catch(() => {
			root.setMessage({
				type: "Error",
				message: "We were unable to delete your account at this time."
			})
		}).finally(() => setLoading(false))
	}

	return (
		<div>
			<Modal open={promptDelete} closeIcon onClose={() => setPromptDelete(true)}>
				<ModalLoader active={loading}/>
				<Modal.Header>Delete Account</Modal.Header>
				<Modal.Content>
					Deleting your account is an irreversible action that will purge all account data, including associated
					Everlooms.
					<br/>
					<br/>
					This is a list of all the pages that will be deleted. We recommend transferring ownership or downloading the
					Everloom to avoid losing all your work.
					<MyPages tableOnly containerStyles={{ maxHeight: 200, overflow: "auto" }}/>
					<br/>
					<br/>
					<strong>Note: It may take up to 30 days to clear all your data from our database.</strong>
				</Modal.Content>
				<Modal.Actions>
					<Button basic color={"red"} onClick={deleteAccount}>
						Delete Account
					</Button>
					<Button primary onClick={() => setPromptDelete(false)}>
						Never mind
					</Button>
				</Modal.Actions>
			</Modal>
			<DA onClick={() => setPromptDelete(true)}>Delete Account</DA>
		</div>
	)
}

export default DeleteAccount

const DA = styled.div`
	width: 100%;
	text-align: center;
	margin-top: 40px;
	color: red;
	opacity: 0.5;
	font-weight: bold;

	&:hover {
		cursor: pointer;
		opacity: 1;
	}

`
