import {
  CHAPTERS_LOADING,
  DEFAULT_BIO,
  PATCH_LOCAL_BRIEF,
  PATCH_LOCAL_PAGE,
  SET_CHAPTERS,
  SET_CHAPTERS_ERROR,
  SET_CURRENT_PAGE,
  SET_EDIT_BRIEF,
  SET_ERROR,
  SET_LOADING,
  SET_PAGE_CREATION,
  SET_PAGE_DATA,
  SET_PAGE_GALLERY,
  SET_PROFILE_SELECTION,
  SET_TAGGED_CHAPTERS,
  SET_TAGGED_GALLERY,
  UPDATE_CHAPTER_ORDER,
  UPDATE_CHAPTER_STATUS,
  UPDATE_PAGE_DATA
} from "./initialState"
import BaseReducer from "../common/reducer.class"
import BiographyService from "../../../services/biography.js"
import Root from "../root/actions.js"
import MediaService from "../../../services/media.js"
import {PagesService} from "../../../services/pages.js"
import {getDirty} from "../../../utils/dirtyPages.js";


class Biography extends BaseReducer {
  fetching = {
    chapters: false
  }

  constructor(dispatch) {
    super({
      dispatch, setErrorConst: SET_ERROR, setLoadingConst: SET_LOADING,
      type: "biography"
    })
  }

  updateQueryContext = (newCode, history) => {
    history.push(`/${newCode}/chapters`)
  }

  addToMediaSize = (page, amount) => {
    console.log(`Gallery Size ${page.gallerySize} + ${amount}`)
    page.gallerySize += amount;
    this.patchLocalPage(page);
  }

  fetchChapters = (pageId) => {
    if (this.fetching.chapters === true) return
    this.fetching.chapters = true
    this.dispatch({
      type: CHAPTERS_LOADING,
      payload: {
        pageId, value: pageId
      }
    })
    return PagesService.getPageChapters(pageId).then(res => {
      this.dispatch({
        type: SET_CHAPTERS,
        payload: {
          value: res,
          pageId
        }
      })
      return res;
    }).catch(e => {
      console.log(111, e)
      this.dispatch({
        type: SET_CHAPTERS_ERROR,
        payload: {
          value: e,
          pageId
        }
      })
    }).finally(() => {
      this.fetching.chapters = false
    })
  }

  patchLocalGallery = (imageData, page) => {
    const i = page.gallery.findIndex(o => o.Location === imageData.Location)
    page.gallery[i] = imageData
    this.patchLocalPage(page)
  }

  patchLocalPage = data => {
    this.dispatch({
      type: PATCH_LOCAL_PAGE,
      payload: data
    })
  }

  patchLocalBio = data => {
    this.dispatch({
      type: PATCH_LOCAL_BRIEF,
      payload: data
    })
  }

  patchBiography = (data, pageId) => {
    return BiographyService.submitBiographyUpdates({
      ...data,
      pageId
    })
      .then(() => this.patchLocalBio(data.biography))
  }

  setProfileSection = (selection) => {
    this.dispatch({
      type: SET_PROFILE_SELECTION,
      payload: selection
    })
  }

  getPageContextData = async (pageId) => {
    const data = await PagesService.getPageData(pageId)
    this.patchLocalPage(data)
  }

  setCurrentPage = (pageId) => {
    this.dispatch({
      type: SET_CURRENT_PAGE,
      payload: pageId
    })
  }

  setPageContext = async (pageId, soft = false) => {
    const isDirty = getDirty(pageId);
    if (soft === true && isDirty) {
      soft = false;
    }
    this.dispatch({
      type: SET_CURRENT_PAGE,
      payload: pageId
    })
    return PagesService.getPage(pageId, soft).then(page => {
      if (page === 404) {
        throw new Error("404")
      }
      if (!page) {
        throw new Error("Error: 01")
      }
      this.dispatch({
        type: UPDATE_PAGE_DATA,
        payload: page
      })
      return page.page.pageId
    }).catch(e => {
      return e
    }).finally(() => {
      if (soft) {
        setTimeout(() => {
          this.refreshPageData(pageId)
        }, 600)
      }
    })
  }

  refreshPageData = (pageId) => {
    return PagesService.getPage(pageId, false).then(page => {
      this.dispatch({
        type: UPDATE_PAGE_DATA,
        payload: page
      })
    }).catch(e => {
      console.error("Error fetching updated data for ", pageId, ":", e)
    })
  }

  updateOrder = async (sortedChapters, pageId) => {
    const data = sortedChapters.map(o => ({
      chapterId: o.chapterId,
      index: o.index
    }))
    return BiographyService.updateChapterOrder(pageId, data)
      .then(result => this.dispatch({
        type: UPDATE_CHAPTER_ORDER,
        payload: {
          chapters: sortedChapters,
          pageId
        }
      }))
  }

  updateChapterStatus = (pageId, chapterId, status) => {
    this.dispatch({
      type: UPDATE_CHAPTER_STATUS,
      payload: {
        pageId,
        chapterId,
        status
      }
    })
  }

  setChapterStatus = async (pageId, chapterId, status) => {
    this.updateChapterStatus(pageId, chapterId, status)
    return BiographyService.changeChapterStatus(pageId, chapterId, status)
      .catch(err => {
        this.updateChapterStatus(pageId, chapterId, status === "DRAFT" ? "PUBLISHED" : "DRAFT")
        this.setError(err)
      })
  }

  fetchGallery = async (pageId) => {
    return MediaService.fetchPageGallery(pageId).then(result => {
      this.dispatch({
        type: SET_PAGE_GALLERY,
        payload: {
          gallery: result,
          pageId
        }
      })
      return result
    }).catch(err => {
      this.setError(err)
    })
  }

  fetchPages = async () => {
    const RootDispatch = new Root(this.dispatch)
    RootDispatch.setPageLoading({value: true})
    return PagesService.getPages().then(result => {
      this.dispatch({
        type: SET_PAGE_DATA,
        payload: result.pages.sort((a, b) => b.isPrimary ? 1 : -1)
      })
    }).catch(err => {
      this.setError(err)
    }).finally(() => {
      RootDispatch.setPageLoading({value: false})
    })
  }

  setPage = (pageId) => {
    this.dispatch({
      type: SET_CURRENT_PAGE,
      payload: pageId
    })
  }

  setOpenPageCreation = (isOpen) => {
    this.dispatch({
      type: SET_PAGE_CREATION,
      payload: isOpen
    })
  }

  getTaggedChapters = async (pageId) => {
    return BiographyService.getTaggedChapters(pageId)
      .then(data => {
        this.dispatch({
          type: SET_TAGGED_CHAPTERS,
          payload: data
        })
      })
  }

  getTaggedGallery = async (pageId) => {
    return MediaService.getTaggedGallery(pageId)
      .then(data => {
        this.dispatch({
          type: SET_TAGGED_GALLERY,
          payload: data.map(o => ({
            Location: o.location,
            tagData: o
          }))
        })
      })
  }

  setEditBrief = (value) => {
    this.dispatch({
      type: SET_EDIT_BRIEF,
      payload: value
    })
  }

  followPage = (pageId, value) => {
    this.patchLocalPage({
      followed: value
    })
    return PagesService.setFollowingPage(pageId)
  }

  static getProfileSelection = state => state.biography.pageSelection
  static getMyBriefBiography = state => state.biography.pageData[state.biography.selectedPage]?.brief || DEFAULT_BIO.brief
  static getRelatives = state => state.biography.pageData[state.biography.selectedPage]?.relations || DEFAULT_BIO.relatives
  static pages = state => state.biography.pages[state.biography.selectedPage] || []
  static openPageCreation = state => state.biography.openPageCreation
  static biographyScope = state => ({
    page: state.biography.pageData[state.biography.selectedPage],
    pageId: state.biography.pageData[state.biography.selectedPage]?.pageId,
    permissions: state.biography.pagePermissions[state.biography.selectedPage],
    isMine: state.auth.user?.uid === state.biography.pageData[state.biography.selectedPage]?.ownerId,
    subscribed: state.biography.pageData[state.biography.selectedPage]?.followed,
    activePlan: state.biography.pageData[state.biography.selectedPage]?.activePlan || {},
    isPrimary: state.biography.pageData[state.biography.selectedPage]?.pageId === state.auth.user?.primaryPageId,
    relatives: state.biography.pageData[state.biography.selectedPage]?.relations || DEFAULT_BIO.relatives,
    basePath: state.biography.pageData[state.biography.selectedPage]?.isDemo ? '/demo' : ''
  })
  static taggedGallery = state => state.biography.tagged[state.biography.selectedPage]?.gallery || []
  static taggedChapters = state => state.biography.tagged[state.biography.selectedPage]?.chapters || []
  static editBrief = state => state.biography.editBrief
  static familyPageData = state => state.biography.familyPageData[state.biography.selectedPage] || []

  static pageDataKeys = state => Object.keys(state.biography.pageData || {}) || []
  static primaryPage = state => state.biography.pageData[state.auth.user.primaryPageId] || null
}

export default Biography
