import React from "react"
import { FlexBox } from "../../styles/Containers.js"
import { ChevronDown, ChevronUp } from "react-ionicons"
import styled from "styled-components"

const PositionClicker = ({ abs, styles, onClick, classNames }) => {

	return (
		<FlexBox className={classNames} direction={"column"} style={abs ? { position: "absolute", ...styles } : styles}>
			<CaretButton onClick={() => onClick("up")}>
				<CaretUpStyled/>
			</CaretButton>
			<CaretButton onClick={() => onClick("down")}>
				<CaretDownStyled/>
			</CaretButton>
		</FlexBox>
	)
}

export default PositionClicker

const CaretButton = styled.div`
	width: 16px;
	height: 16px;
	background-color: white;
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	margin: 2px 0 2px 0;

	svg {
		width: 12px;
	}

	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		cursor: pointer;
		background-color: rgba(0, 0, 0, 0.1);
	}

`

const CaretUpStyled = styled(ChevronUp)`
	&:hover {
		opacity: 0.7;
	}

	cursor: pointer;
`

const CaretDownStyled = styled(ChevronDown)`
	&:hover {
		opacity: 0.7;
	}

	cursor: pointer;
`
