import API from "./api.js";

export default class AdminService {

  static base = 'admin'

  static getChapterSection() {
    return API.get(`${AdminService.base}/chapters/sections`)
  }

  static submitChapterSection(data) {
    return API.put(`${AdminService.base}/chapters/sections`, data)
  }

  static deleteChapterSection(id) {
    return API.delete(`${AdminService.base}/chapters/sections/${id}`)
  }

  static toggleChapterStatus(id, active) {
    return API.post(`${AdminService.base}/chapters/sections/${id}/status`, {
      active
    })
  }

  static updateChapterOrder(order) {
    return API.post(`${AdminService.base}/chapters/sections/order`, {
      order
    })
  }

  static createCode(codeData) {
    return API.put(`${AdminService.base}/gift/new-code`, codeData)
  }

  static getAllCodes() {
    return API.get(`${AdminService.base}/gift/codes`)
  }

  static expireCode(code) {
    return API.post(`${AdminService.base}/gift/expire`, {
      code
    })

  }
}
