import React from "react"
import {Modal} from "semantic-ui-react"
import EditProfile from "./EditProfile.js"
import useWindowSize from "../../utils/dimensions"
import {EditProfileModalStyles} from "../../styles/Semantic"
import Auth from "../../store/reducers/auth/actions.js";
import {useDispatch, useSelector} from "react-redux";

const AddPageInline = ({isOpen, pageCreated, close}) => {
  const {height} = useWindowSize()
  const AuthDispatch = new Auth(useDispatch())
  const user = useSelector(Auth.user);

  const pageWasCreated = (pageId, data) => {
    pageCreated(pageId)
    AuthDispatch.patchUserLocal({
      pageData: {
        ...user.pageData,
        [pageId]: {
          ...data,
          firstName: data.preferredName || data.birthName
        }
      },
      myPages: [...user.myPages, pageId]
    })
  }

  return (
    <Modal
      id={"brief-modal"}
      close={close}
      onClose={close}
      closeOnDimmerClick={false}
      closeIcon
      open={isOpen} style={EditProfileModalStyles(height, 0)}>
      <Modal.Header>
        Add Everloom
      </Modal.Header>
      <EditProfile newPage onCreation={pageWasCreated} close={close}/>
    </Modal>
  )
}

export default AddPageInline
