import React, {useRef, useState} from 'react'
import QuillEditor from "../../components/common/QuillEditor.js";
import {Button, Modal} from "semantic-ui-react";
import LockedFeed from "./LockedFeed.js";
import {useDispatch, useSelector} from "react-redux";
import Biography from "../../store/reducers/biography/actions.js";
import PermissionsService from "../../services/permissions.js";
import MemoriesReducer from "../../store/reducers/memories/actions.js";
import styled from "styled-components";
import Root from "../../store/reducers/root/actions.js";
import MemoriesService from "../../services/memories.js";
import {useQuill} from "react-quilljs";
import Quill from "quill";
import QuillWordcounter from "../../utils/quillWordCounter.js";
import {usePageScope} from "../../store/hooks/page.hooks.js";
import firebase from "firebase";

Quill.register('modules/counter', QuillWordcounter);

const MemoryFeedEditor = ({}) => {
    const dispatch = useDispatch();
    const rootDispatch = new Root(dispatch);
    const {pageId, permissions, page} = usePageScope()
    const isAdmin = PermissionsService.checkPermissions(permissions, 'isAdmin');
    const status = useSelector(MemoriesReducer.memoriesStatus);
    const locked = status === 'LOCKED';
    const [loading, setLoading] = useState(false);
    const wordMax = 750;
    const counterRef = useRef();
    const [memSubmitted, setMemSubmitted] = useState(false)

    const contentUpdate = (props) => {

    }

    const submitFeed = () => {
        const words = Number(counterRef.current.innerText.split('/')[0]);
        if (words > wordMax || words < 1) {
            return;
        }
        firebase.analytics().logEvent("memory_submitted");
        setLoading(true);
        MemoriesService.postMemory({
            text: quill.getContents()
        }, pageId)
            .then(() => {
                setMemSubmitted(true);
                quill.setContents([]);
            })
            .catch(e => {
                rootDispatch.setMessage({
                    type: 'error',
                    message: e
                })
            }).finally(() => {
            setLoading(false);
        })
    }

    const {quill, quillRef} = useQuill({
        modules: {
            toolbar: memoriesToolbar,
            counter: {
                container: '#counter',
                unit: 'word',
                max: wordMax
            }
        },
        theme: 'snow',
        placeholder: 'Leave a Memory'
    });

    return (
        <div>
            <Modal open={memSubmitted} onClose={() => setMemSubmitted(false)} closeIcon>
                <Modal.Header>Memory Submitted</Modal.Header>
                <Modal.Content>
                    Your memory has been submitted. Depending on an Everloom's settings the owner of the Everloom may need to review the Memory before it appears on their Everloom. The owner may also choose to decline a memory if they don't want it featured on their Everloom.
                    <br />
                    <br />
                    Learn more about Memories <a target={'_blank'} href={process.env.REACT_APP_LANDING_URL +'/help-memories'}>here</a>.
                </Modal.Content>
                <Modal.Actions>
                    <Button primary onClick={() => setMemSubmitted(false)}>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
            {(!locked || isAdmin) &&
            <>
                <QuillEditor quill={quill} quillRef={quillRef} contentUpdate={contentUpdate}
                             QContainer={MemoriesQuillContainer}/>
                <Button fluid loading={loading} disabled={loading} primary onClick={submitFeed}>Submit Memory</Button>
                <div style={{marginTop: 4, textAlign: 'center'}} id={'counter'} ref={counterRef}/>
            </>
            }
            {locked && <LockedFeed/>}
        </div>
    );
};

export default MemoryFeedEditor;

const memoriesToolbar = [
    ['bold', 'italic', 'underline'],
    ['link', 'blockquote'],
    [{list: 'ordered'}, {list: 'bullet'}],
    [{'indent': '-1'}, {'indent': '+1'}],
]

const MemoriesQuillContainer = styled.div`
  height: 250px;

  .ql-editor {
    height: 200px;
  }

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    max-height: 35vh !important;
  }

  .ql-container {
    height: unset !important;
  }

  .ql-snow .ql-editor img {
    max-width: 340px !important;
    max-height: 200px !important;

    float: left;
    margin-right: 14px;
  }
`;
