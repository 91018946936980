import React, {useEffect, useState} from "react"
import {Button, Icon, Modal, Table} from "semantic-ui-react"
import FormInput from "../../../components/common/FormInput.js"
import {FlexBox} from "../../../styles/Containers.js"
import MessageData from "../../../components/common/MessageData.js"
import findGetParam from "../../../utils/findGetParam.js"
import {useHistory} from "react-router-dom"
import ModalLoader from "../../../components/common/ModalLoader.js"
import {lang} from "../../../config/languages"
import NotificationService from "../../../services/notifications.js"
import HeirloomModal from "../../../components/common/HeirloomModal"
import useWindowSize from "../../../utils/dimensions.js"
import AccessDropdown from "../components/AccessDropdown.js";
import {usePageScope} from "../../../store/hooks/page.hooks.js";
import {useAlterPermissions} from "../../../store/hooks/permissions.hooks.js";

const defaultForm = {
  value: "",
  permissions: {
    BRIEF: true,
    READ: true
  },
  isFamily: false
}

const InviteUser = ({inviting, close, edit, prompt, inlineClose}) => {
  const {width} = useWindowSize()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [form, setForm] = useState(defaultForm)
  const [showSent, setShowSent] = useState(false)
  const history = useHistory()
  const {pageId, page} = usePageScope()
  const alterPermission = useAlterPermissions(pageId, setLoading)

  useEffect(() => {
    const action = findGetParam("action", true) === "add_user"
    const data = NotificationService.notificationData?.data
    if (action && data && data.requesterPageId) {
      setForm({
        ...form,
        isFamily: data.detail !== "user",
        value: data.requesterPageId
      })
    }
  }, [])

  const cancel = () => {
    close()
    setForm(defaultForm)
    setShowSent(false)
    history.push(history.location.pathname)
    NotificationService.notificationData = null
  }

  useEffect(() => {
    if (edit) {
      const ed = {...edit}
      ed.permissions = {}
      for (const perm of edit.permissions) {
        ed.permissions[perm] = true
      }
      setForm(ed)
    }
  }, [edit])


  useEffect(() => {
    if (inviting) {
      setForm({...defaultForm})
    }
  }, [inviting])

  const submit = () => {
    setErrors({})
    alterPermission.mutateAsync({
      data: form,
      type: 'update'
    })
      .then(() => {
        setShowSent(true);
        if (close) {
          cancel()
        }
      })
      .catch(err => {
        setErrors({global: err})
      })
  }

  const breakSizes = [770, 440]

  const smallBreak = width <= breakSizes[1]
  const actionStyle = smallBreak ? {marginTop: 6} : {};

  return (
    <HeirloomModal
      closeIcon={!inlineClose}
      onClose={close}
      size={'small'}
      open={inviting}>
      <ModalLoader active={loading}/>

      <Modal open={showSent} size={'small'}>
        <Modal.Content style={{textAlign: 'center'}}>
          <Icon name={'check circle outline'} style={{color: '#00ff00', fontSize: 60}}/>
          <h2>Success</h2>
          <p>
            {form.permissions.WRITE ?
              <>
                Your collaboration invitation has been sent. If they accept your invitation, you will be able to add
                them as a family member and they will be able to view your Everloom. They will also be able to edit your
                Everloom.
                Learn more about Everloom collaboration <a target={'_blank'}
                                                           href={process.env.REACT_APP_LANDING_URL + '/help-everloom-collaboration'}>here</a>.
              </>
              : <>
                Your permission request has been sent. If they accept, you will be able to add them as a family member,
                and they will be able to view your Everloom.
              </>}
          </p>

          <Button primary onClick={cancel}>
            Close
          </Button>
        </Modal.Content>
      </Modal>

      <Modal.Header>{page ? lang.inviteUser.replace("{name}", `${page.brief.firstName} ${page.brief.lastName}`) : ""}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {prompt && <p><strong>{prompt}</strong></p>}
          <p>
            Invite someone to view or collaborate on this Everloom. Learn more about managing the privacy of your <a
            href={process.env.REACT_APP_LANDING_URL + '/help-privacy-and-permissions'} target="_blank">Everloom
            here.</a>
          </p>
          <MessageData error={{message: errors.global}}/>

          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell> Email</Table.HeaderCell>
                <Table.HeaderCell collapsing style={{textAlign: 'right', paddingRight: 65}}>Access</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <FormInput formMargin={false} placeholder={"example@example.com"} value={form.value}
                             error={errors.value} readonly={edit}
                             containerStyle={{maxWidth: 340}} fluid
                             onChange={(_, props) => setForm({...form, value: props.value})}/>
                </Table.Cell>
                <Table.Cell style={{textAlign: 'right'}}>


                  <AccessDropdown permissions={form.permissions} setPermissions={perms => setForm({
                    ...form,
                    permissions: perms
                  })}/>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          {/*<FlexBox style={{cursor: 'pointer'}} align={"center"} wrap={"unset"}*/}
          {/*         onClick={() => setForm({...form, isFamily: !form.isFamily})}>*/}
          {/*  <Checkbox style={{marginRight: 10}} checked={form.isFamily}/>*/}
          {/*  Add my contact information to their directory*/}
          {/*</FlexBox>*/}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <FlexBox direction={smallBreak ? 'column-reverse' : 'row'} justify={'flex-end'}>
          {inlineClose && <Button style={actionStyle} fluid={smallBreak} onClick={cancel}>
            Skip
          </Button>}
          <Button style={actionStyle} fluid={smallBreak} primary onClick={submit}>
            Send Invite
          </Button>
        </FlexBox>
      </Modal.Actions>
    </HeirloomModal>
  )
}

export default InviteUser
