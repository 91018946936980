import React, {useState} from "react"
import {useDispatch} from "react-redux"
import {Clickable, ScrollbarStyles, SettingsPage} from "../../styles/Containers"
import {Button, Icon, Table} from "semantic-ui-react"
import {FormSectionTitle} from "../../styles/Forms"
import CustomHybridTable from "../../components/common/CustomHybridTable"
import styled from "styled-components"
import ClipCopy from "../../components/common/ClipCopy"
import {UserReducer} from "../../store/reducers/user/action"
import {useClaimReferral, useReferrals} from "../../store/hooks/settings.hooks.js";
import ModalLoader from "../../components/common/ModalLoader.js";
import firebase from "firebase/app";

const statusMap = {
  ACCOUNT_VERIFIED: {
    text: "Verified Account",
    icon: "wait"
  },
  PAGE_PAID: {
    text: "Upgraded Account",
    icon: "checkmark"
  }
}

const ReferralsTable = ({}) => {
  const dispatch = useDispatch()
  const user = new UserReducer(dispatch)
  const {data: referrals, isFetching} = useReferrals()
  const [loading, setLoading] = useState(false)
  const claimReferral = useClaimReferral();

  const isClaimable = referrals.list.filter(o => o.status === 'ACCOUNT_VERIFIED').length >= 10 || referrals.list.filter(o => o.status === 'PURCHASED') >= 3;
  const btnProps = isClaimable ? {color: 'green'} : {primary: true};

  const runReferralClaim = async () => {
    if (isClaimable) {
      firebase.analytics().logEvent("referral_claimed");
      setLoading(true);
      await claimReferral.mutateAsync(null);
      setLoading(false);
    } else {
      firebase.analytics().logEvent("referral_code_copied");
    }
  }

  return (
    <SettingsPage>
      <ModalLoader active={isFetching || loading}/>
      <PagesContainer>
        <FormSectionTitle>
          My Referrals
          <ClipCopy text={process.env.REACT_APP_APP_HOST + "/sign-up?referral=" + referrals.code}
                    type={"Referral code"}>
            <Button onClick={runReferralClaim} {...btnProps} size={"small"} icon disabled={!referrals.code}>
              <Icon name={isClaimable ? "check" : "add"}/>
              {isClaimable ? 'Claim Reward' : 'Copy Referral Code'}
            </Button>
          </ClipCopy>
        </FormSectionTitle>
        <CustomHybridTable
          data={referrals.list}
          titles={["Name", "Status"]}
          singleTitle={""}
          row={(p, i) =>
            <Table.Row>
              <Table.Cell>
                {p.name}
              </Table.Cell>
              <Table.Cell>
                <span style={{fontStyle: "italic", marginRight: 12}}>{statusMap[p.status].text}</span>
                <Icon name={statusMap[p.status].icon}/>
              </Table.Cell>
            </Table.Row>
          }
          singleRow={(p, i) =>
            <Table.Row>
              <Table.Cell>
                <Clickable>
                  {p.name}
                </Clickable>
              </Table.Cell>
            </Table.Row>
          }
        />
      </PagesContainer>
    </SettingsPage>
  )
}

export default ReferralsTable
const PagesContainer = styled.div`
  ${ScrollbarStyles}
`
