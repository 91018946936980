import {
  ApertureOutline,
  BookOutline,
  CashOutline,
  ChatboxOutline,
  LockClosedOutline,
  NewspaperOutline,
  PeopleOutline,
  PersonOutline
} from "react-ionicons"
import Chapters from "../pages/Chapters"
import Gallery from "../pages/Gallery"
import ContactBook from "../pages/Contacts"
import Memories from "../pages/Memories"
import ProfileContainer from "../pages/Profile/ProfileContainer"
import AccountComponent from "../pages/Account"
import React from "react"
import Permissions from "../pages/Account/Permissions.js";
import FamilyPageReduced from "../pages/FamilyPage/secondary.js";

export const mappedOptions = (name) => {
  if (!name) {
    return []
  }
  return LISTS.data[name] || OtherTypes[name] || []
}

export const LISTS = {
  data: {}
}

export const AccountLevelPages = ["account", "sign-up", "login", "forgot-password"]


export const OtherTypes = {
  siblingRelationship: [
    {
      key: "brother",
      value: "brother",
      text: "Brother",
      type: "siblings",
      alt: "siblings"
    },
    {
      key: "sister",
      value: "sister",
      text: "Sister",
      type: "siblings",
      alt: "siblings"
    }
  ],
  parentRelationship: [
    {
      key: "mother",
      value: "mother",
      text: "Mother",
      type: "parents",
      alt: "children"
    },
    {
      key: "father",
      value: "father",
      text: "Father",
      type: "parents",
      alt: "children"
    }
  ],
  childRelationship: [
    {
      key: "son",
      value: "son",
      text: "Son",
      type: "children",
      alt: "parents"
    },
    {
      key: "daughter",
      value: "daughter",
      text: "Daughter",
      type: "children",
      alt: "parents"
    }
  ]
}

export const SettingsMenuItems = [
  {
    name: "Profile",
    icon: <PersonOutline/>,
    key: "profile"
  },
  {
    name: "Pages",
    icon: <NewspaperOutline/>,
    key: "pages"
  },
  {
    name: "Permissions",
    icon: <LockClosedOutline/>,
    key: "permissions"
  },
  {
    name: "Billing",
    icon: <CashOutline/>,
    key: "billing"
  }

]

export const ProfileMenuItems = [
  {
    name: "Biography",
    icon: <PersonOutline/>,
    key: "biography",
    slug: "biography",
    component: ProfileContainer
  },
  {
    name: "Chapters",
    icon: <BookOutline/>,
    key: "chapters",
    slug: "chapters",
    component: Chapters
  },
  {
    name: "Gallery",
    icon: <ApertureOutline/>,
    key: "gallery",
    slug: "gallery",
    component: Gallery
  },
  {
    name: "Family",
    icon: <PersonOutline/>,
    key: "family",
    slug: "family",
    component: FamilyPageReduced,
    gate: ["isAdmin"]
  },
  {
    name: "Memories",
    icon: <ChatboxOutline/>,
    key: "memories",
    slug: "memories",
    component: Memories
  },
  {
    name: "Directory",
    icon: <PeopleOutline/>,
    key: "directory",
    slug: "directory",
    component: ContactBook,
    gate: ["isAdmin"]
  },
  {
    name: "Share Everloom",
    icon: <ChatboxOutline/>,
    key: "share",
    slug: "share",
    component: () => Permissions({pageSpecific: true}),
    gate: ["canEdit", "isBeneficiary"]
  },
  {
    name: "Settings",
    icon: <ChatboxOutline/>,
    key: "account",
    slug: "account",
    component: () => AccountComponent({pageSpecific: true}),
    gate: ["canEdit", "isBeneficiary"]
  }
]
