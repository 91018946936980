import React from "react"
import styled from "styled-components"
import {FlexBox} from "../../styles/Containers.js"
import {Button} from "semantic-ui-react"
import {useDispatch} from "react-redux"
import PermissionsService from "../../services/permissions"
import Root from "../../store/reducers/root/actions"
import {usePageScope} from "../../store/hooks/page.hooks.js";

const MediaLayoutUsage = ({}) => {
  const {page, permissions} = usePageScope()
  const canEdit = PermissionsService.checkPermissions(permissions, "canEdit")
  const RootDispatch = new Root(useDispatch())

  const isLastPlan = page?.activePlan?.id === 2

  if (!canEdit || !page) {
    return <div/>
  }

  const getSize = (mb, unit) => {
    if (mb < 1000) {
      if (unit) {
        return Number(mb).toFixed(2) + "MB"
      }
      return mb
    }
    if (unit) {
      return Number(mb / 1024).toFixed(2) + "GB"
    }
    return mb / 1024
  }

  const ratio = ((page.gallerySize || 1) / (page.activePlan.mediaDataSize|| 0)) * 100

  return (
    <Usage>
      <FlexBox wrap={"unset"} align={"center"} justify={isLastPlan ? "center" : 'flex-start'}>
        <div>
          <FlexBox wrap={"unset"} align={"center"} justify={isLastPlan ? "center" : 'flex-start'}>
            <h5 style={{marginBottom: 6}}>Everloom Storage</h5>
          </FlexBox>
          <FlexBox wrap={"unset"} align={"center"} justify={'center'}>
            <div style={{width: "fit-content"}}>
              <UsageText>
                <CustomProgress progress={ratio}>
                  <div className={"cp-loading"}/>
                </CustomProgress>
                {getSize((page.gallerySize || 0), true)} of {getSize(page.activePlan.mediaDataSize, true)} used</UsageText>
            </div>
          </FlexBox>
        </div>
        {!isLastPlan && <Button size={"small"} style={{marginLeft: 12, backgroundColor: "white"}}
                                onClick={() => RootDispatch.setViewUpgrade(true)}>
          Upgrade
        </Button>}
      </FlexBox>
    </Usage>
  )
}

export default MediaLayoutUsage

const UsageText = styled.div`
  font-size: 10px;
  color: black;
  margin-bottom: 8px;
`

const CustomProgress = styled.div`
  width: 140px;
  height: 6px;
  background-color: #D4D4D5;
  border-radius: 10px;

  .cp-loading {
    height: 6px;
    border-radius: 10px;
    background-color: ${props => props.theme.quaternary};
    width: ${props => props.progress}%;
  }
`

const Usage = styled.div`
  position: fixed;
  bottom: 20px;
  width: 260px;
`
