import React, {useEffect} from "react"
import {useSelector} from "react-redux";
import Auth from "../store/reducers/auth/actions.js";
import {useHistory} from "react-router-dom";
import {usePageScope} from "../store/hooks/page.hooks.js";

const RedirectHome = ({}) => {
  const user = useSelector(Auth.user)
  const history = useHistory()
  const {basePath} = usePageScope()

  useEffect(() => {
    history.push(`${basePath}/${user.primaryPageId}/chapters`)
  }, []);

  return (
    <div/>
  );
};

export default RedirectHome;
