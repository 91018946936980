import React from "react"
import { CustomTable } from "../../styles/Semantic"
import { Table } from "semantic-ui-react"
import useWindowSize from "../../utils/dimensions"

const CustomHybridTable = ({ data, row, singleRow, titles, singleTitle }) => {
	const {width} = useWindowSize();

	if (width < 768) {
		return (
			<CustomTable>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>{singleTitle}</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{data.map(singleRow)}
				</Table.Body>
			</CustomTable>
		)
	}

	return (
		<CustomTable>
			<Table.Header>
				<Table.Row>
					{titles.map(o => <Table.HeaderCell >{o}</Table.HeaderCell>)}
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{data.map(row)}
			</Table.Body>
		</CustomTable>
	)
}

export default CustomHybridTable
