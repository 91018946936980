export const INITIAL_STATE = {
	loading: false,
	error: null,
	referrals: {
		code: null,
		list: []
	}
}

const SET_LOADING = "set_loading"
const SET_ERROR = "set_error"
const SET_MY_REFERRALS = 'SET_MY_REFERRALS';

export {
	SET_LOADING,
	SET_ERROR,
	SET_MY_REFERRALS
}
