import React from "react"
import {CustomGalleryElement, FlexBox} from "../../styles/Containers.js"
import MediaService from "../../services/media.js"
import {CONSTANTS} from "../../config/constants.js"
import styled from "styled-components"
import DocOutline from "@images/icons/document-outline.svg"
import HeirloomImage from "./HeirloomImage.js"

import AudioSVG from '../../assets/icons/audio.svg'
import VideoSVG from '../../assets/icons/video.svg'

export const VisibleViewTypes = ({extension, data, clicked, isSelected}) => {
  return (
    <ImageContainer onClick={() => clicked(data)} isSelected={isSelected}>
      {(CONSTANTS.acceptedImages.indexOf(extension) > -1) ?
        <HeirloomImage src={data.Location || data.base64}/> :
        (CONSTANTS.acceptedVideos.indexOf(extension) > -1) ? getAudioType(data, extension) :
          <div style={{position: 'relative'}}>
            <HeirloomImage src={DocOutline} style={{opacity: 0}}/>
            <FlexBox align={'center'} justify={'center'} style={typeTextStyles}><strong>PDF</strong></FlexBox>
          </div>
      }
    </ImageContainer>
  )
}

export const getAudioType = (data, extension) => {
  let isAudio;
  if (data.mimeType) {
    isAudio = data.mimeType?.indexOf('audio') > -1;
  } else {
    // backup
    isAudio = CONSTANTS.audiExtensions.indexOf(extension) > -1
  }

  const img = isAudio ? AudioSVG : VideoSVG;
  return <MediaContainer align={'center'} justify={'center'}><img src={img}
                                                                  style={{maxHeight: '60%'}}/></MediaContainer>
}

const GalleryList = ({
                       gallery,
                       imageSelected,
                       selected = [],
                       galleryContainer = DefaultStyled,
                       style = {},
                       id = new Date().getTime()
                     }) => {
  const Gallery = galleryContainer
  return (
    <Gallery style={style} id={id}>
      {gallery.map((o, i) => {
        const extension = MediaService.getType(o)
        const isSelected = selected.findIndex(p => p.Location === o.Location) > -1
        return (
          <CustomGalleryElement style={{cursor: "pointer"}} key={"gall-" + i}>
            <VisibleViewTypes isSelected={isSelected} data={o} extension={extension}
                              clicked={imageSelected}/>
          </CustomGalleryElement>
        )
      })
      }
    </Gallery>
  )
}

export default GalleryList

const DefaultStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const ImageContainer = styled.span`
  overflow: hidden;

  img {
    border-style: inset;
    border: ${props => props.isSelected ? "5px solid " + props.theme.quaternary : "unset"};
  }
`

const FileView = styled.span`
  text-align: center;
  width: 100%;
  display: block;
  line-height: 80px;
  padding: 30px;

  i {
    font-size: 80px;
  }
`

const typeTextStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  color: 'white',
  fontSize: 16
}


const MediaContainer = styled(FlexBox)`
  border-radius: 4px;
  background-color: #ebebeb;
  width: 150px;
  height: 150px;

  img {
    width: auto;
    height: 60px;
  }
`
