import {
    SET_ERROR,
    SET_LOADING,
    SET_LOADING_INNER,
    SET_NOTIF_DATA,
    SET_NOTIFS_VISIBLE,
    SET_SETTINGS
} from "./initialState"
import BaseReducer from "../common/reducer.class"
import NotificationService from "../../../services/notifications.js";


class Notifications extends BaseReducer {

    constructor(dispatch) {
        super({
            dispatch, setErrorConst: SET_ERROR, setLoadingConst: SET_LOADING,
            type: "notifications"
        })
    }

    loadNextPage = (notifData, pageId) => {
        this.dispatch({
            type: SET_LOADING_INNER,
            payload: true
        })
        NotificationService.loadMyNotifications(pageId, notifData.lastKey, notifData.totalCount)
            .then(data => {
                const newData = {
                    list: [...notifData.list, ...data.list],
                    totalCount: notifData.totalCount,
                    lastKey: data.lastKey
                }
                this.setError(null)
                this.dispatch({
                    type: SET_NOTIF_DATA,
                    payload: newData
                })
            })
            .catch(e => this.setError(e))
            .finally(() => this.dispatch({
                type: SET_LOADING_INNER,
                payload: false
            }))
    }

    setNotificationsVisible = (openValue, silentLoad, pageId) => {
        this.dispatch({
            type: SET_NOTIFS_VISIBLE,
            payload: silentLoad === true ? false : openValue
        })
        if (openValue === false) {
            return;
        }
        this.dispatch({
            type: SET_LOADING_INNER,
            payload: true
        })
       if (silentLoad === true ? false : openValue) {
           NotificationService.clearNotification(pageId)
       }
        NotificationService.loadMyNotifications(pageId)
            .then(data => {
                this.setError(null)
                this.dispatch({
                    type: SET_NOTIF_DATA,
                    payload: data
                })
            })
            .catch(e => this.setError(e))
            .finally(() => this.dispatch({
                type: SET_LOADING_INNER,
                payload: false
            }))
    }

    setNotifLoading = (val) => {
        this.dispatch({
            type: SET_LOADING_INNER,
            payload: val
        })
    }

    static getSettings = state => state.notifications.settings;
    static visible = state => state.notifications.visible;
    static notifData = state => state.notifications.notifications;
    static isLoading = state => state.notifications.innerLoading;
    static notificationCount = state => state.notifications.notifications ? (state.notifications.notifications[state.biography.selectedPage] || []).filter(o => o.seen === 0).length : 0;
}

export default Notifications
