import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Biography from "../store/reducers/biography/actions.js"
import Root from "../store/reducers/root/actions.js"
import Auth from "../store/reducers/auth/actions.js"
import BiographyService from "../services/biography.js"
import { PagesService } from "../services/pages.js"
import AuthService from "../services/auth.js"
import TransferOwnership from "../pages/Account/components/TransferOwnership.js"
import ModalLoader from "./common/ModalLoader.js"
import ManageBeneficiary from "../pages/Account/pageSettings/ManageBeneficiary.js"
import PermissionsService from "../services/permissions.js"
import { Modal, Progress } from "semantic-ui-react"
import {usePageScope} from "../store/hooks/page.hooks.js";

const PageManagement = ({ viewPage, close = () => null, render, transfers }) => {
	const RootDispatch = new Root(useDispatch())
	const AuthDispatch = new Auth(useDispatch())
	const BioDispatch = new Biography(useDispatch())
	const [modalLoading, setModalLoading] = useState(false)
	const [beneficiary, setBeneficiary] = useState(false)
	const { pageId, page, permissions } = usePageScope()
	const user = useSelector(Auth.user)
	const primaryPage = user?.primaryPageId
	const [transferOwnershipModal, setTransferOwnershipModal] = useState(null)
	const [downloading, setDownloading] = useState(-1)

	if (!render) {
		render = (opt, i) => (
			<div key={i} onClick={() => opt.action()}>{opt.title}</div>
		)
	}

	const refetch = () => {
		return AuthService.getMe(true).then(async user => {
			AuthDispatch.setUser({ value: user, fetched: true })
		}).finally(() => setTransferOwnershipModal(false))
	}

	const cancelTransfer = () => {
		setModalLoading(true)
		PagesService.cancelMyTransfer(viewPage.pageId).finally(() => {
			refetch().finally(() => {
				setModalLoading(false)
				close()
			})
		})
	}

	const setAndUpgradePage = (viewPage) => {
		setModalLoading(true)
		BioDispatch.setPageContext(viewPage, true).finally(() => {
			RootDispatch.setViewUpgrade(true)
			setModalLoading(false)
			close()
		})
	}

	const download = async (selectedPage) => {
		setDownloading(5)
		if (selectedPage !== pageId) {
			await BioDispatch.setPageContext(selectedPage)
		}
		BiographyService.downloadPage(selectedPage, page, (percentage) => {
			setDownloading(percentage)
		}).finally(() => setDownloading(-1))
	}

	const transferSuccess = () => {
		return refetch().finally(() => {
			close()
			setModalLoading(false)
		})
	}

	const options = [
		{
			title: "Upgrade Page",
			action: () => setAndUpgradePage(viewPage.pageId),
			props: { primary: true },
			permission: "canEdit"
		}
	]

	if ((viewPage?.pageId !== primaryPage && viewPage?.status !== "PENDING_TRANSFER") && transfers) {
		options.push({
			title: "Transfer Ownership",
			action: () => viewPage ? setTransferOwnershipModal(viewPage) : () => null,
			props: { color: "black" },
			permission: "isAdmin"
		})
	}

	options.push({
		title: "Download Everloom",
		action: () => download(viewPage?.pageId),
		permission: "isAdmin"
	},)


	if (viewPage?.status === "PENDING_TRANSFER") {
		options.push({
			title: "Cancel Transfer",
			action: () => cancelTransfer(),
			props: { color: "red" }
		})
	}


	return (
		<div>
			<Modal size={"mini"} open={downloading > -1}>
				<Modal.Header>Downloading</Modal.Header>
				<Modal.Content>
					Downloading your Everloom into a ZIP file. This may take several minutes...
				</Modal.Content>
				<Modal.Content>
					<Progress percent={downloading} indicating/>
				</Modal.Content>
			</Modal>
			<ModalLoader active={modalLoading}/>
			<ManageBeneficiary isOpen={beneficiary} close={() => setBeneficiary(false)}/>
			<TransferOwnership transferSuccess={transferSuccess}
												 transferOwnership={transferOwnershipModal}
												 setTransferOwnership={setTransferOwnershipModal}/>
			{/*{options.filter(o => PermissionsService.checkPermissions(permissions, o.permission)).map(render)}*/}
			{options.map(render)}
		</div>
	)
}

export default PageManagement
