export const INITIAL_STATE = {
    loading: false,
    error: null,
    settings: [],
    visible: false,
    notifications: [],
    innerLoading: false
};

const SET_LOADING_INNER = 'SET_LOADING_INNER';
const SET_ERROR = 'SET_ERROR';
const SET_SETTINGS = 'SET_SETTINGS';
const SET_NOTIFS_VISIBLE = 'SET_NOTIFS_VISIBLE';
const SET_NOTIF_DATA = 'SET_NOTIF_DATA';
const SET_LOADING = 'SET_LOADING'

export {
    SET_LOADING_INNER,
    SET_ERROR,
    SET_SETTINGS,
    SET_NOTIFS_VISIBLE,
    SET_NOTIF_DATA,
    SET_LOADING
}
