import { SET_ERROR, SET_LOADING, SET_MEMORY_DATA, SET_MEMORY_SETTINGS, SET_WRITE } from "./initialState"
import BaseReducer from "../common/reducer.class"
import MemoriesService from "../../../services/memories.js"


class MemoriesReducer extends BaseReducer {

	constructor(dispatch) {
		super({
			dispatch, setErrorConst: SET_ERROR, setLoadingConst: SET_LOADING,
			type: "memories"
		})
	}

	resetMemories = () => {
		this.dispatch({
			type: "RESET",
			payload: null
		})
	}

	setWriteMemory = (value) => {
		this.dispatch({
			type: SET_WRITE,
			payload: value
		})
	}

	updateSettings = (newSettings, oldSettings, pageId) => {
		this.dispatch({
			type: SET_MEMORY_SETTINGS,
			payload: {
				data: newSettings,
				pageId
			}
		})
		return MemoriesService.updateMemorySettings(newSettings, pageId).catch(e => {
			this.dispatch({
				type: SET_MEMORY_SETTINGS,
				payload: {
					data: oldSettings,
					pageId
				}
			})
		})
	}

	loadMemoryData = (pageId, feed) => {
		if (feed !== null && feed !== undefined) {
			return new Promise(resolve => {
				this.dispatch({
					type: SET_MEMORY_DATA,
					payload: {
						data: feed,
						pageId
					}
				})
				resolve()
			})
		}
		return MemoriesService.getMemories(pageId).then(data => {
			this.dispatch({
				type: SET_MEMORY_DATA,
				payload: {
					data,
					pageId
				}
			})
		})
	}

	approvePost = (postId, pageId) => {
		return MemoriesService.approvePost(postId, pageId).then(data => {
			this.dispatch({
				type: SET_MEMORY_DATA,
				payload: {
					data,
					pageId
				}
			})
		})
	}

	declinePost = (postId, pageId) => {
		return MemoriesService.declinePost(postId, pageId).then(data => {
			this.dispatch({
				type: SET_MEMORY_DATA,
				payload: {
					data,
					pageId
				}
			})
		})
	}


	static memories = state => state.memories.data[state.biography.selectedPage]?.feed || []
	static pendingMemories = state => state.memories.data[state.biography.selectedPage]?.pending || []
	static memoriesStatus = state => state.memories.data[state.biography.selectedPage]?.settings?.status
	static settings = state => state.memories.data[state.biography.selectedPage]?.settings || {}
	static isWritingMem = state => state.memories.writingMemory;

}

export default MemoriesReducer
