import React from "react"
import { Icon, Menu, Sidebar } from "semantic-ui-react"
import { Link, useHistory } from "react-router-dom"
import Logo from "../../assets/logos/themed/logo-row-dark.svg"
import { ProfileMenuItems } from "../../config/options.map"
import { useDispatch, useSelector } from "react-redux"
import Root from "../../store/reducers/root/actions"
import styled from "styled-components"
import Biography from "../../store/reducers/biography/actions"
import AccountPageSelector from "../AccountPageSelector"
import SearchBar from "../Header/Search"
import { CONSTANTS } from "../../config/constants"
import useWindowSize from "../../utils/dimensions.js"
import PermissionsService from "../../services/permissions"
import {usePageScope} from "../../store/hooks/page.hooks.js";

const SmallNavigation = ({}) => {
	const history = useHistory()
	const { width } = useWindowSize()
	const menuVisible = useSelector(Root.navOpen)
	const RootDispatch = new Root(useDispatch())
	const BriefDispatch = new Biography(useDispatch())
	const { permissions, pageId } = usePageScope()

	const hasPermissions = (gates) => {
		if (!gates || gates.length === 0) return true
		return gates.some(type => !!PermissionsService.checkPermissions(permissions, type))
	}


	return (
		<Sidebar
			as={Menu}
			animation='overlay'
			direction='left'
			vertical
			visible={menuVisible && width < CONSTANTS.navCollapse}>
			<LogoLayout>
				<a target={"_blank"} href={process.env.REACT_APP_LANDING_URL}>
					<img src={Logo}/>
				</a>
			</LogoLayout>
			<CloseIt name={"close"} onClick={() => RootDispatch.toggleSideNav(false)}/>
			<div style={{ padding: "0 12px 20px 12px" }}>
				<AccountPageSelector itemSelected={o => history.push(`/${o}/chapters`)}/>
				<div style={{ marginTop: 12 }}/>
				<SearchBar fluid/>

			</div>
			{ProfileMenuItems.filter(o => hasPermissions(o.gate)).map(o => (
				<Menu.Item
					key={o.key}
					as={Link}
					onClick={() => RootDispatch.toggleSideNav(false)}
					to={`/${pageId}/${o.slug}`}>
					{o.name}
				</Menu.Item>
			))}
		</Sidebar>
	)
}

export default SmallNavigation

const CloseIt = styled(Icon)`
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;

	&.icon:before {
		opacity: 0.3;
	}
`

const LogoLayout = styled.div`
	height: 150px;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		max-width: 150px;
	}
`
