import React, { useState } from "react"
import { FlexBox, SettingsPage } from "../../../styles/Containers.js"
import {Button, Confirm, Grid, Message, Modal, Popup, Progress} from "semantic-ui-react"
import { NoneTextInlineRegular } from "../../../styles/Text"
import { OptionBox } from "./BeneficiarySection"
import { FormSectionTitle } from "../../../styles/Forms"
import { useDispatch, useSelector } from "react-redux"
import Biography from "../../../store/reducers/biography/actions"
import Auth from "../../../store/reducers/auth/actions"
import TransferOwnership from "../components/TransferOwnership"
import { PagesService } from "../../../services/pages"
import BiographyService from "../../../services/biography"
import AuthService from "../../../services/auth"
import Root from "../../../store/reducers/root/actions"
import {usePageScope} from "../../../store/hooks/page.hooks.js";
import firebase from "firebase/app";

const ManagePageSettings = ({}) => {
	const AuthDispatch = new Auth(useDispatch())
	const BioDispatch = new Biography(useDispatch())
	const RootDispatch = new Root(useDispatch())

	const [loading, setLoading] = useState(false)
	const [transferOwnershipModal, setTransferOwnershipModal] = useState(null)
	const [downloading, setDownloading] = useState(-1)
	const [confirmDownload, setConfirmDownload] = useState(false)

	const { page, pageId, isMine } = usePageScope()
	const user = useSelector(Auth.user)
	const isPrimary = page.pageId === user.primaryPageId || page?.status === "PENDING_TRANSFER"
	const buttonWidth = 150

	const refetch = () => {
		return AuthService.getMe(true).then(async user => {
			AuthDispatch.setUser({ value: user, fetched: true })
		})
	}

	const cancelTransfer = () => {
		setLoading(true)
		PagesService.cancelMyTransfer(page.pageId).then(() => {
			BioDispatch.patchLocalPage({
				status: null
			})
		}).finally(() => {
			refetch().finally(() => {
				setLoading(false)
			})
		})
	}

	const transferSuccess = () => {
		setLoading(true)
		BioDispatch.patchLocalPage({
			status: "PENDING_TRANSFER"
		})
		return refetch().finally(() => {
			setLoading(false)
		})
	}

	const upgradeAccount = () => {
		RootDispatch.setViewUpgrade(true)
	}

	const downloadHeirloom = async () => {
		firebase.analytics().logEvent("download_everloom");
		setDownloading(5)
		const p = { ...page }
		if (!p.chapters.length) {
			p.chapters = await BioDispatch.fetchChapters(pageId);
		}
		setConfirmDownload(false)
		BiographyService.downloadPage(page.pageId, p, (percentage) => {
			setDownloading(percentage)
		}).finally(() => setDownloading(-1))
	}

	const downloadEvent = () => {
		return (
			<Modal size={"mini"} open={downloading > -1}>
				<Modal.Header>Downloading</Modal.Header>
				<Modal.Content>
					Downloading your Everloom into a ZIP file. This may take several minutes...
				</Modal.Content>
				<Modal.Content>
					<Progress percent={downloading} indicating/>
				</Modal.Content>
			</Modal>
		)
	}


	return (
		<SettingsPage>
			{downloadEvent()}
			<Confirm
				open={confirmDownload}
				content={'Would you like to download your Everloom? This may take several minutes.'}
				onCancel={() => setConfirmDownload(false)}
				onConfirm={downloadHeirloom}
			/>
			<FormSectionTitle flip={445}>
				<div>
					Settings
					<div style={{ marginBottom: 8 }}/>
				</div>
			</FormSectionTitle>

			<Grid>
				<Grid.Column style={{ marginTop: 20 }}>

					{page?.status === "PENDING_TRANSFER" && <Message warning>
						<FlexBox align={"center"} justify={"space-between"}>
							<div>
								This page is pending transfer
							</div>

							<Button onClick={cancelTransfer}>
								Cancel Transfer
							</Button>
						</FlexBox>
					</Message>}

					<OptionBox>
						<div>Upgrade your account</div>
						<Button onClick={upgradeAccount} style={{ width: buttonWidth }} secondary basic>Upgrade</Button>
					</OptionBox>
					<OptionBox>
						<div>Download Everloom</div>
						<Button onClick={() => setConfirmDownload(true)} style={{ width: buttonWidth }} secondary basic>Download</Button>
					</OptionBox>

					{isMine && <OptionBox>
						<div>Transfer Ownership</div>
						<Popup inverted
									 content={"Your primary page can not be transferred"}
									 disabled={!isPrimary}
									 trigger={<Button
										 onClick={() => isPrimary ? null : setTransferOwnershipModal(page)}
										 style={{ width: buttonWidth, opacity: isPrimary ? 0.5 : 1 }} secondary
										 basic>Transfer</Button>}/>
					</OptionBox>}
				</Grid.Column>
			</Grid>

			<TransferOwnership transferSuccess={transferSuccess}
												 transferOwnership={transferOwnershipModal}
												 setTransferOwnership={setTransferOwnershipModal}/>
		</SettingsPage>
	)
}

export default ManagePageSettings
