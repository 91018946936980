import { SET_ERROR, SET_FORM, SET_FORM_ERRORS, SET_LOADING } from "./initialState.js"
import BaseReducer from "../common/reducer.class.js"
import Root from "../root/actions.js"
import { PagesService } from "../../../services/pages.js"
import AuthService from "../../../services/auth.js"
import Auth from "../auth/actions.js"

export default class OnboardingReducer extends BaseReducer {
	constructor(dispatch) {
		super({
			dispatch, setErrorConst: SET_ERROR, setLoadingConst: SET_LOADING,
			type: "onboarding"
		})
	}

	setOnboarding = (data) => {
		this.dispatch({
			type: SET_FORM,
			payload: data
		})
	}

	formChange = (_, { value, name }) => {
		this.dispatch({
			type: SET_FORM,
			payload: {
				[name]: value
			}
		})
	}

	submitData = (data) => {
		const root = new Root(this.dispatch)
		const auth = new Auth(this.dispatch)
		root.setPageLoading({ value: true })
		return PagesService.submitOnboarding(data).then(() => {
			return AuthService.getMe(true).then(async user => {
				auth.setUser({ value: user, fetched: true })
			}).finally(() => {
				root.setPageLoading({ value: false })
			})
		}).catch(e => {
			this.setFormErrors({
				global: e
			})
			root.setPageLoading({ value: true })
		})
	}

	setFormErrors = (errors) => {
		this.dispatch({
			type: SET_FORM_ERRORS,
			payload: errors
		})
	}

	static formData = state => state.onboarding.form
	static getErrors = state => state.onboarding.formErrors
}
