export const INITIAL_STATE = {
	loading: false,
	error: null,
	pages: {},
	pageData: {},
	pagePermissions: {},
	openPageCreation: false,
	selectedPage: "",
	pageSelection: null,
	tagged: {},
	editBrief: false,
	familyPageData: {},
}

const SET_LOADING = "set_loading"
const SET_ERROR = "set_error"
const SET_PAGE_DATA = "SET_PAGE_DATA"
const SET_CURRENT_PAGE = "SET_CURRENT_PAGE"
const SET_PAGE_GALLERY = "SET_PAGE_GALLERY"
const UPDATE_CHAPTER_STATUS = "UPDATE_CHAPTER_STATUS"
const UPDATE_CHAPTER_ORDER = "UPDATE_CHAPTER_ORDER"
const SET_PAGE_CREATION = "SET_PAGE_CREATION"
const SET_PROFILE_SELECTION = "SET_PROFILE_SELECTION"
const SET_TAGGED_GALLERY = "SET_TAGGED_GALLERY"
const SET_TAGGED_CHAPTERS = "SET_TAGGED_CHAPTERS"
const SET_EDIT_BRIEF = "SET_EDIT_BRIEF"
const PATCH_LOCAL_BRIEF = "PATCH_LOCAL_BRIEF"
const PATCH_LOCAL_PAGE = "PATCH_LOCAL_PAGE"
const SET_FAMILY_DATA = "SET_FAMILY_DATA"
const UPDATE_PAGE_DATA = "UPDATE_PAGE_DATA"
const CHAPTERS_LOADING = "CHAPTERS_LOADING"
const SET_CHAPTERS = "SET_CHAPTERS"
const SET_CHAPTERS_ERROR = "SET_CHAPTERS_ERROR"

export {
	SET_LOADING,
	SET_ERROR,
	SET_PAGE_DATA,
	SET_CHAPTERS,
	SET_CHAPTERS_ERROR,
	UPDATE_PAGE_DATA,
	SET_CURRENT_PAGE,
	SET_PAGE_GALLERY,
	UPDATE_CHAPTER_STATUS,
	UPDATE_CHAPTER_ORDER,
	SET_PAGE_CREATION,
	SET_PROFILE_SELECTION,
	PATCH_LOCAL_PAGE,
	SET_TAGGED_GALLERY,
	SET_TAGGED_CHAPTERS,
	SET_EDIT_BRIEF,
	PATCH_LOCAL_BRIEF,
	SET_FAMILY_DATA,
	CHAPTERS_LOADING
}


export const DEFAULT_BIO = {
	brief: {
		pageId: "",
		image: "",
		publicName: "",
		firstName: "",
		lastName: "",
		middleName: "",
		maidenName: "",
		gender: "",
		suffix: "",
		birthday: "",
		birthplace: "",
		deceased: "",
		pronouns: "",
		about: ""
	},
	relatives: {
		parents: [],
		siblings: [],
		spouse: [],
		children: []
	}
}
