import React, {useEffect, useState} from "react"
import {Menu} from "semantic-ui-react"
import {useDispatch, useSelector} from "react-redux"
import Auth from "../../store/reducers/auth/actions.js"
import styled from "styled-components"
import Notifications from "../../store/reducers/notifications/actions.js"
import Root from "../../store/reducers/root/actions.js"
import MenuPopover from "./MenuPopover.js"
import PermissionsService from "../../services/permissions.js"
import SearchBar from "./Search.js"
import {FlexBox} from "../../styles/Containers.js"
import {CONSTANTS} from "../../config/constants"
import {MenuOutline} from "react-ionicons"
import useWindowSize from "../../utils/dimensions.js"
import {usePageScope} from "../../store/hooks/page.hooks.js";
import firebase from "firebase/app";
import 'firebase/database'
import {useCurrentUser} from "../../store/hooks/user.hooks.js";
import customTheme from "../../styles/theme.js";
import { isDemoDomain } from "../../utils/isDemo"

const HeaderComponent = ({fluid}) => {
  const {width} = useWindowSize()
  const dispatch = useDispatch()
  const user = useCurrentUser()
  const rootDispatch = new Root(dispatch)
  const NotificationDispatch = new Notifications(dispatch)
  const isAuthenticated = useSelector(Auth.isAuthenticated)
  const visible = useSelector(Notifications.visible)
  const {permissions, pageId} = usePageScope()
  const isAdmin = PermissionsService.checkPermissions(permissions, "isAdmin")
  const [unreadCount, setUnreadCount] = useState(0)

  useEffect(() => {
    const ref = firebase.database().ref(`notifications/${user.uid}/`);
    if (user.uid) {
      console.log('Init firebase watch', `notifications/${user.uid}`)
      ref.on('value', data => {
        let total = 0
        const values = data.val()
        for (const val in values) {
          total += values[val]
        }
        setUnreadCount(total)
        NotificationDispatch.setNotificationsVisible(true, true, pageId)
      })
    }

    return () => ref.off();
  }, [])

  return (
    <MenuContainer borderless menuOpen={true} fluid={fluid}>
      {(width <= CONSTANTS.navCollapse) &&
      <Menu.Menu position='left'>
        <HamburgerDiv onClick={() => rootDispatch.toggleSideNav(true)}>
          <MenuOutline color={"#000"}/>
        </HamburgerDiv>
      </Menu.Menu>
      }
      {(width > CONSTANTS.hideSearch) && <Menu.Menu position='left' style={isDemoDomain ? {width: '100%'} : {}}>
        <SearchBar/>
      </Menu.Menu>}
      <Menu.Menu position='right'>
        <FlexBox align={"center"}>
          {(isAuthenticated && isAdmin) &&
          <NotificationLabel>
            {unreadCount > 0 && <span>{unreadCount}</span>}
            <Menu.Item icon={"bell"}
                       onClick={() => NotificationDispatch.setNotificationsVisible(!visible, false, pageId)}/>
          </NotificationLabel>
          }

          {isAuthenticated &&
          <MenuPopover/>
          }
        </FlexBox>
      </Menu.Menu>
    </MenuContainer>
  )
}


export default HeaderComponent

const NotificationLabel = styled("div")`
  position: relative;
  background-color: ${props => props.active ? "red" : "white"} !important;
  border-radius: 30px;
  width: 41px;
  height: 40px;

  i {
    color: black;
  }

  span {
    position: absolute;
    color: #d00a0a;
    font-weight: bold;
    right: -3px;
    border: thin solid white;
    top: -4px;
    z-index: 10000;
    background-color: ${customTheme.background};
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const MenuContainer = styled(Menu)`
  ${props => props.fluid === true ?
          `
			width: 100%;
			margin-left: 12px !important;
		` :
          `
		width: calc(100% - ${CONSTANTS.menuSize + 12}px);
		margin-left: auto !important;
	`}
  z-index: 10000;
  max-width: 1600px !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  padding-top: 14px;

  @media only screen and (max-width: 560px) {
    margin-left: 0 !important;
  }
`

const HamburgerDiv = styled.div`
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    svg {
      color: ${props => props.theme.quaternary}
    }
  }
`
