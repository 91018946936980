export const CONSTANTS = {
	dateFormat: "MM/DD/YYYY",
	prettyDate: "MMM D, YYYY",
	menuSize: 260,
	collapsedSize: 1000,
	acceptedImages: [".jpg", ".jpeg", ".png"],
	acceptedVideos: [".mov", ".mp3", ".mp4", ".m4a"],
	audiExtensions: ['.mp3', '.m4a'],
	acceptedMedia: [".pdf"],
	addMimeTypes: ["video/x-m4v"],
	modalFullscreen: 767,
	mobileSize: 420,
	navHeight: 100,
	navCollapse: 786,
	hideSearch: 560,
	toSmallToEdit: 720
}

export const modalFull = (width, alt = "large") => {
	return width <= CONSTANTS.modalFullscreen ? "fullscreen" : alt
}
