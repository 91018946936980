import React from 'react'
import {useSelector} from "react-redux"
import Biography from "../../store/reducers/biography/actions"
import {DEFAULT_BIO} from "../../store/reducers/biography/initialState"
import ProfileDetails from "./ProfileDetails"
import {FlexBox} from "../../styles/Containers.js";
import useWindowSize from "../../utils/dimensions.js";
import {CONSTANTS} from "../../config/constants.js";

const ProfileContainer = ({}) => {
  const {width} = useWindowSize()
  const small = width <= CONSTANTS.hideSearch;

  const userProfile = useSelector(Biography.getMyBriefBiography)
  const info = userProfile || DEFAULT_BIO.brief

  if (!small) {
    return null;
  }

  return (
    <FlexBox dir={'column'} justify={'center'}>
      <ProfileDetails image={info.image} preferredName={info.publicName} firstName={info.firstName}
                      lastName={info.lastName} lastUpdated={info.lastUpdated}/>
    </FlexBox>
  );
};

export default ProfileContainer;
