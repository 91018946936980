import React from 'react'
import OnboardingReducer from "../../../store/reducers/onboarding/action.js";
import {useDispatch, useSelector} from "react-redux";
import {FlexBox} from "../../../styles/Containers.js";
import FormInput from "../../../components/common/FormInput.js";
import FormLocationPicker from "../../../components/common/FormLocationPicker.js";
import FormDatePicker from "../../../components/common/FormDatePicker.js";

const StepTwo = ({}) => {
    const onboarding = new OnboardingReducer(useDispatch());
    const data = useSelector(OnboardingReducer.formData)
    return (
        <div>
            <h1>Biography Details</h1>

            <div style={{marginTop: 20}}>
                <h3>Add your birthday</h3>
                <h5>Your birthday will be displayed in the biography section of your Everloom.</h5>
            </div>
            <FlexBox style={{marginLeft: -12, marginTop: 20}}>
                <FormDatePicker isForm value={data.birthday || ''} name='birthday'
                                placeholder='Birthday'
                                onChange={onboarding.formChange}/>
            </FlexBox>

            <div style={{marginTop: 40}}>
                <h3>Add your birthplace</h3>
                <h5>Typically, a person's birthplace is listed as the city in which they were born.</h5>
            </div>
            <FlexBox style={{marginTop: 20}}>
                <FormLocationPicker value={data.birthplace} name='birthplace'
                                    placeholder='Birthplace' formMargin={false}
                                    onChange={onboarding.formChange}/>
            </FlexBox>

        </div>
    );
};

export default StepTwo;
