import React from 'react'
import {InfoIcon} from "../../styles/Semantic.js";
import {Popup} from "semantic-ui-react";

const ToolTip = ({trigger, message, disabled, onClick, children, ...props}) => {

  if (!trigger && !children) {
    trigger = <InfoIcon onClick={onClick}
                        style={{cursor: 'pointer', backgroundColor: 'black', color: 'white', ...(props.style || {})}}
                        circular name='info' size='small'/>
  }

  return (
    <Popup {...props} inverted disabled={!message} content={message} trigger={
      <div style={{opacity: disabled ? 0.5 : 1, cursor: !message ? 'unset' : 'pointer'}}>
        {trigger || children}
      </div>
    }/>
  );
};

export default ToolTip;
