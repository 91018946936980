import React from "react"
import HeirloomImage from "../../../components/common/HeirloomImage.js"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { PagesService } from "../../../services/pages"
import { FlexBox } from "../../../styles/Containers.js"
import { NoneText } from "../../../styles/Text.js"

const SimpleCard = ({ data, index, showRelationship = true }) => {
	const canNavigate = !!data.data?.pageCode
	return (
		<FlexBox direction={'column'} align={'center'} justify={'center'} key={"fm-" + index}>
			<HeirloomImage style={{ width: 100, height: 100, borderRadius: 50 }} src={data.data?.pageData?.image}/>
			{canNavigate ? <Link to={PagesService.navigatePageRoute(data.data.pageCode)}>
					<Name canActivate={canNavigate}>{data.name}</Name>
				</Link> :
				<Name canActivate={canNavigate}>{data.name}</Name>}
			{showRelationship && <NoneText style={{textTransform: 'capitalize'}}>{data.data.relation?.relationship || data.data.relation?.relationshipType}</NoneText>}
		</FlexBox>
	)
}

export default SimpleCard

const Name = styled.h3`
	margin-top: 20px !important;
	text-align: center;
	margin-bottom: 0;

	&:hover {
		${props => props.canActivate ? `
			cursor: pointer;
			text-decoration: underline;
			color: blue;
		` : ``}
	}
`
