import {Button, Image, Modal} from "semantic-ui-react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.scss';
import React, {useEffect, useRef, useState} from "react";
import useWindowSize from "../../../utils/dimensions.js";
import Root from "../../../store/reducers/root/actions.js";
import AuthService from "../../../services/auth.js";
import {FlexBox} from "../../../styles/Containers.js";
import customTheme from "../../../styles/theme.js";
import nuxData from "./nuxData.js";
import {useOnboarding} from "../../../store/hooks/page.hooks.js";


export const Nux = () => {
  const {width} = useWindowSize();
  const root = new Root(useDispatch())
  const onboarding = useOnboarding()
  const selectedNux = useSelector(Root.showNux)
  const swiper = useRef()
  const [index, setIndex] = useState(0);
  const [swiperAction, setSwiperAction] = useState('');

  useEffect(() => {
    if (swiperAction) {
      switch (swiperAction) {
        case 'NEXT':
          swiper.current.slideNext()
          setSwiperAction('')
          break;
        case 'PREV':
          swiper.current.slidePrev()
          setSwiperAction('')
      }
    }
  }, [swiperAction])

  const close = () => {
    const newData = {
      ...onboarding,
      [selectedNux]: true
    }
    localStorage.setItem('onboarding', JSON.stringify(newData));
    AuthService.setNuxViewed(newData);
    root.setShowNux(null);
  }

  const swipeNext = () => setSwiperAction('NEXT')
  const swipePrev = () => setSwiperAction('PREV')

  const showImage = width >= 900;
  const total = 6;

  const data = nuxData[selectedNux]

  if (!data) {
    return null;
  }

  return (
    <Modal open onClose={close} closeIcon>
      {!!data.title && <Modal.Header>
        {data.title}
      </Modal.Header>}
      <Modal.Content>
        <Swiper
          onSlideChange={(props) => setIndex(props.activeIndex)}
          ref={swiper}
          allowSlideNext={data.slides.length > 0}
          allowSlidePrev={data.slides.length > 0}
          onSwiper={sw => {
            swiper.current = sw
          }}
          slidesPerView={1}>
          {data.slides.map(o => (<SwiperSlide>

            {o.topImage && <FlexBox align={'center'} justify={'center'}>
              <Image size={'tiny'} src={o.topImage}/>
            </FlexBox>}

            <div
              style={{textAlign: o.descriptionAlignment || 'center', margin: `12px ${o.documentAlignment || 'auto'}`}}>
              {!!o.title && <h2>{o.title}</h2>}
              {(o.descriptions || []).map(d => (
                <p style={{textAlign: o.descriptionAlignment || 'center', paddingTop: 20}}>
                  {d}
                </p>))}

              {!!o.button && <Button primary onClick={() => {
                if (o.button.type === 'dismiss')
                  close()
              }}>
                {o.button.title}
              </Button>}
            </div>
          </SwiperSlide>))}
        </Swiper>
      </Modal.Content>
      <Modal.Actions style={{minHeight: 65}}>

        {(!!data.footer && data.footer.title) && <div style={{textAlign: 'center', paddingTop: 10}}>
          {data.footer.title}
        </div>}

        {(!!data.footer && data.footer.button && data.footer.button.type === 'dismiss') &&
        <div style={{textAlign: 'center'}}>
          <Button primary onClick={close}>
            {data.footer.button.title}
          </Button>
        </div>
        }

        {(index > 0 && index <= total) && <FlexBox align={'center'} justify={'space-between'}>
          <strong>
            {index + 1} / {total}
          </strong>

          <FlexBox justify={'center'} align={'center'}>
            <Button primary style={{width: 120, marginRight: 6}} onClick={swipePrev}>
              Previous
            </Button>
            <Button primary style={{width: 120, marginLeft: 6}} onClick={swipeNext}>
              {index === total ? 'Complete' : 'Next'}
            </Button>
          </FlexBox>
          <div style={{opacity: 0}}>
            {index + 1} / {total}
          </div>
        </FlexBox>}
        {!!data.footer?.learnMore &&
        <div style={{textAlign: 'center', marginTop: 12}}>
          You can learn more in our <a target={'_blank'} href={data.footer.learnMore}>Help Center</a>
        </div>
        }
      </Modal.Actions>

    </Modal>
  )
}


const Skip = styled.span`
  color: ${customTheme.primary};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`
