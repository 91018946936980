import React, {useEffect, useState} from 'react'
import {Button} from "semantic-ui-react";
import {Cookies} from 'react-cookie-banner'
import moment from "moment";
import CookieIcon from '../assets/images/cookie.png'
import firebase from "firebase/app";
import "firebase/analytics"

const CookiesBanner = ({}) => {
  const cookies = new Cookies()
  const cookieTitle = 'user-has-accepted-cookies:v1'
  const [isActive, setIsActive] = useState(!!cookies.get(cookieTitle))

  useEffect(() => {
    if (isActive && cookies.get(cookieTitle) === 'true') {
      console.log('Analytics Enabled')
      firebase.analytics().setAnalyticsCollectionEnabled(true)
    } else {
      console.log('Analytics Disabled')
      firebase.analytics().setAnalyticsCollectionEnabled(false)
    }
  }, [isActive])

  const acceptCookies = () => {
    cookies.set(cookieTitle, 'true', {
      expires: moment().add(10, 'years').toDate(),
    })
    setIsActive(true)
  }

  const declineCookies = () => {
    cookies.set(cookieTitle, 'false', {
      expires: moment().add(1, 'month').toDate(),
    })
    setIsActive(true)
  }

  const CookieImage = () => {
    return (
      <img style={{width: 80, height: 80, marginBottom: 10}} src={CookieIcon}/>
    )
  }

  if (isActive) {
    return null
  }

  return (
    <div className={'react-cookie-banner'}>
      {CookieImage()}
      <div className={'cookie-message'}>
        <strong>Privacy</strong><br/>
        By clicking accept, you allow Everloom to use your data anonymously and disclose information within our
        <a>&nbsp;cookie policy</a>!
      </div>
      <div style={{width: '100%'}}>
        <Button onClick={acceptCookies} style={{margin: 4}} fluid primary className='button-close'>
          Accept
        </Button>
        <Button onClick={declineCookies} style={{margin: 4}} fluid color={'red'} basic className='button-close'>
          Decline
        </Button>
      </div>
    </div>
  );
};

export default CookiesBanner;
