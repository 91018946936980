import styled from "styled-components"
import {Link} from "react-router-dom"
import {Button, Divider, FormTextArea, Icon} from "semantic-ui-react"
import {FlexBox} from "./Containers.js"

export const SectionSubtext = styled.div`
  font-size: 16px;
`

export const H2Header = styled("h2")`
  text-align: center;

  &:hover {
    color: ${props => props.theme.main};
  }

  margin-top: 20px;
`

export const StyledLink = styled(Link)`
  color: ${props => props.theme.quaternary};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.secondary};
  }
`
export const HoverLink = styled(Link)`
  color: ${props => props.theme.dark};

  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;

    svg {
      color: ${props => props.theme.quaternary};
      fill: ${props => props.theme.quaternary};
    }

    color: ${props => props.theme.quaternary};
  }
`

export const SectionDivider = styled(Divider)`
  margin-top: 5px !important;
`

export const SectionTitle = styled.div`
  font-size: 24px;
  line-height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  text-transform: capitalize;

  img {
    cursor: pointer;
  }

  @media only screen and (max-width: 450px) {
    font-size: 18px;
  }
`

export const SectionText = styled.div`
  font-family: "Times New Roman", sans-serif;
  font-size: 14px;
`

export const RelativePill = styled.div`
  width: auto;
  min-width: 80px;
  color: ${props => props.theme.primary};
  background-color: #E7F3F2;
  border-radius: 30px;
  padding: 4px 14px 4px 14px;
  display: inline-block;
  margin: 4px;

  font-size: 10px;

  a {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`

export const RelativePillClose = styled(RelativePill)`
  position: relative;
  padding-left: 12px;
  display: flex;
  align-items: center;

  svg {
		margin-right: 6px;
    color: ${props => props.theme.primary};
    cursor: pointer;
    width: 16px;
  }
	
	img {
		margin-left: 12px;
	}

  a {
    cursor: pointer;
  }
`
export const WhiteIconButton = styled(Button)`
  i {
    color: white;
  }
`

export const ModalFormSection = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
`

export const ErrorFieldText = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 4px;
`

export const NoneText = styled.div`
  line-height: 3;
  opacity: 0.5;
  font-style: italic;
`

export const NoneTextInline = styled.span`
  opacity: 0.5;
  font-style: italic;
`

export const NoneTextInlineRegular = styled.span`
  opacity: 0.5;
  font-size: 12px;
`

export const NoneTextRegular = styled.div`
  opacity: 0.5;
  font-size: 12px;
`

export const Subtext = styled.div`
  opacity: 0.9;
  font-size: 12px;
`

export const pageIdTitle = "Everloom ID"

export const BoldText = styled.span`
  font-weight: bold;
  margin-left: 10px;
`

export const HoverableHome = styled(FlexBox)`
  color: ${props => props.theme.quaternary};
  font-weight: bold;
  cursor: pointer;

  span, svg {
    color: ${props => props.theme.quaternary};
  }

  &:hover {
    text-decoration: underline;
  }
`

export const CustomTextArea = styled(FormTextArea)`
  textarea {
    width: 100%;
    border: thin solid #e2e2e2;
    padding: 6px;
    height: 100px;
    margin-top: 6px;
    border-radius: 4px;
  }
`

export const HoverableIcon = styled(Icon)`
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`

export const RelativeLabel = styled.div`
  text-transform: capitalize;
  display: flex;
  align-items: center;
`

export const AddRelative = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 16px;
  background-color: ${props => props.theme.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.quaternary};
  }

  svg {
    width: 16px;
    color: white;
  }
`

export const Chip = styled.div`
  font-size: 8px;
  font-weight: bold;
  background-color: #E7F3F2;
  color: ${props => props.theme.primary};
  text-align: center;

  padding: 6px 12px 6px 12px;
  margin-right: 3px;
  margin-left: 3px;
  margin-top: 4px;

  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;


  ${props => props.active ? {backgroundColor: props.theme.primary, color: 'white'} : {}}
  &:hover {
    cursor: pointer;
    box-shadow: inset 0px 0px 0px 1px ${props => props.theme.quaternary};
  }
`

export const ChipLarge = styled.div`
  font-size: 14px;
  padding: 6px 12px;
  margin-right: 6px;
  margin-left: 5px;
  text-align: center;
  line-height: 22px;
  border-radius: 20px;
  color: ${props => props.theme.primary};
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 2px solid ${props => props.theme.primary};
  background-color: ${props => !props.border ? "transparent" : props.theme.quaternary};
`
