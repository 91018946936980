import React, {useContext} from 'react'
import {Modal} from "semantic-ui-react";
import {usePagePermissions} from "../store/hooks/permissions.hooks.js";
import {usePageScope} from "../store/hooks/page.hooks.js";
import AcceptPermissions from "../pages/Account/components/AcceptPermissions.js";
import {SettingsPage} from "../styles/Containers.js";
import PermissionRequests from "../pages/Account/components/PermissionRequests.js";

const PendingPermissions = ({}) => {
  const {pageId} = usePageScope()
  const {data: permissions} = usePagePermissions(pageId)

  const acceptUserInvite = () => {

  }

  const setAcceptInvite = () => {

  }

  return (
    <Modal open={(permissions?.newAccess || []).length > 0} size={'tiny'}>
      <Modal.Content style={{padding: 0}}>
        <PermissionRequests containerStyle={{marginTop: 0, border: 0}} requests={(permissions?.newAccess || [])}/>
      </Modal.Content>
    </Modal>
  );
};

export default PendingPermissions;
