import {ModalFormSection, pageIdTitle} from "../../../styles/Text.js"
import React, {useEffect, useState} from "react"
import {FormSectionDescription, formStyles} from "../../../styles/Forms.js"
import {FlexBox} from "../../../styles/Containers.js"
import FormInput from "../../../components/common/FormInput.js"
import {Form, Message} from "semantic-ui-react"
import {mappedOptions} from "../../../config/options.map.js"
import FormRadio from "../../../components/common/FormRadio.js"
import {useDebouncedEffect} from "../../../utils/debounceEffect.js"
import BiographyService from "../../../services/biography.js"
import FormCheckbox from "../../../components/common/FormCheckbox.js"
import styled from "styled-components"
import ModalLoader from "../../../components/common/ModalLoader.js";
import {usePageScope} from "../../../store/hooks/page.hooks.js";
import InviteUser from "../../Account/pageSettings/InviteUser.js";

export const PageCodeTitle = () => (
  <ModalFormSection style={{...styles.formSectionTitle, marginTop: 0}}>
    Link your relative by their {pageIdTitle}
  </ModalFormSection>
)

export const ManagePageCode = ({
                                 pageCode,
                                 onChange,
                                 currentPage,
                                 setCodeData = () => null,
                               }) => {
  const {pageId} = usePageScope()
  const [fetching, setFetching] = useState(false)
  const [message, setMessage] = useState(null)
  const [codeError, setCodeError] = useState(null)
  const [permissions, setPermissions] = useState(null)
  const [viewOptions, setViewOptions] = useState(true)

  useEffect(() => {
    if (pageCode && viewOptions) {
      setViewOptions(false)
    }
  }, [pageCode])

  useEffect(() => {
    if (pageCode === currentPage && currentPage !== "") {
      onChange(null, {name: "pageCode", value: ""})
      setMessage("ID can not be the ID of this page")
    } else if (pageCode === "") {
      setMessage("")
    }
  }, [pageCode])

  useDebouncedEffect(() => {
    if (pageCode) {
      setFetching(true)
      BiographyService.isValidPageCode(pageId, pageCode)
        .then(data => {
          setCodeError(data.failed)
          setMessage(data)
          setPermissions(data.permissions || {})
          setCodeData(data.firstName, data.lastName, data.permissions || {})
        })
        .finally(() => setFetching(false))
    }
  }, 350, [pageCode])

  if (fetching) {
    return <ModalLoader active={fetching}/>
  }

  if (!permissions) {
    return <div/>
  }

  return (
    <div style={{marginLeft: 0, marginTop: 0}}>
      {!!codeError && <Message error>{codeError}</Message>}
      <FlexBox align={'center'}>
        <img src={message?.image} style={{width: 60, height: 60, borderRadius: 30, marginRight: 16}}/>
        <div>
          <h3 style={{marginBottom: 0}}>{message?.message}</h3>
          <h6 style={{marginTop: 8, marginBottom: 0, opacity: 0.5}}>Owner:{permissions?.ownerName?.firstName} {permissions?.ownerName?.lastName} </h6>
        </div>
      </FlexBox>
    </div>
  )
}

export const RelativeFormTitle = ({style = {}}) => (
  <ModalFormSection style={{...styles.formSectionTitle, ...style}}>
    Let your relative know you've added them to your Everloom
  </ModalFormSection>
)

export const InviteUserDetails = ({email, onChange, errors, customMessage}) => {
  return (
    <div style={{marginLeft: -formStyles.inputSideMargin.marginLeft}}>
      <InviteUser
        prompt={'Let your relative know you\'ve added them to your Everloom'}
        inviting={true} edit={false} close={() => {
        // setInviting(false)
        // setEditPermissions(null)
      }}/>
    </div>
  )
}

export const FamilyName = () => (
  <ModalFormSection>
    What is your family members name?
  </ModalFormSection>
)

export const SpecificRelation = () => (
  <FormSectionDescription style={styles.innerSectionMargin}>
    What's your specific relation to this person?
  </FormSectionDescription>
)

export const WhatsTheirName = ({firstName, lastName, onChange}) => (
  <div style={{marginLeft: -formStyles.inputSideMargin.marginLeft}}>
    <FlexBox>
      <FormInput fluid={"mobile"} value={firstName} name='firstName' onChange={onChange} placeholder='First name'/>
      <FormInput fluid={"mobile"} value={lastName} name='lastName' onChange={onChange} placeholder='Last name'/>
    </FlexBox>
  </div>
)

export const MappedFormType = ({type, mapping, relation, onChange, loadedType = ""}) => (
  <div>
    <Form.Group>
      <FlexBox>
        {mappedOptions(mapping).filter(o => (o.exclude || []).indexOf(loadedType) === -1).map(relative => (
            <div key={relative.key + "-type"}>
              <FormRadio
                containerStyle={{minWidth: 80}}
                label={relative.text}
                name={`relation.${type}`}
                value={relative.value}
                checked={relation[type] === relative.value}
                onChange={onChange}
              />
            </div>
          )
        )}
      </FlexBox>
    </Form.Group>
  </div>
)

export const CheckedRelatives = ({options, onChange, checkedOptions}) => {

  const onUpdate = (_, {value}) => {
    const opts = [...checkedOptions]
    if (opts.indexOf(value) > -1) {
      opts.splice(opts.indexOf(value), 1)
    } else {
      opts.push(value)
    }
    onChange(opts)
  }

  return (<div>
      <Form.Group>
        <FlexBox>
          {options.map(relative => {
              return (
                <div key={relative.key + "-type"}>
                  <FormCheckbox
                    containerStyle={{minWidth: 80}}
                    label={relative.firstName + " " + relative.lastName}
                    value={relative.pageCode}
                    checked={checkedOptions.indexOf(relative.pageCode) > -1}
                    onChange={onUpdate}
                  />
                </div>
              )
            }
          )}
        </FlexBox>
      </Form.Group>
    </div>
  )
}


const styles = {
  formSectionTitle: {
    marginBottom: 10,
    marginTop: 26
  },
  innerSectionMargin: {
    marginTop: 18
  },
  foundName: {
    fontSize: 10,
    fontWeight: "bold",
    marginTop: 5
  }
}

const PermissionItem = styled.div`
  font-size: 12px;
  text-align: left;
  line-height: 20px;
  margin-left: 12px;
`
