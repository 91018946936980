import React, { useState } from "react"
import FormInput from "./FormInput"
import { Icon } from "semantic-ui-react"
import styled from "styled-components"

const PasswordInput = ({ showHint = false, isReEnter = false, onChange, value, error, placeholder, name, ...props }) => {
	const [viewIcon, setViewIcon] = useState(false)

	return (
		<>
			<FormInput
				{...props}
				icon={<Icon link name={viewIcon ? "eye slash" : "eye"} onClick={() => setViewIcon(!viewIcon)}/>}
				iconPosition="left" placeholder={placeholder || (isReEnter ? "Re-enter Password" : "Password")} fluid
				name={name || (isReEnter ? "repassword" : "password")}
				error={error} type={viewIcon ? "text" : "password"}
				autoComplete={"current-password"} onChange={onChange} value={value}/>
			{(showHint && !error) && <PasswordHint>
				Password must be between 6-15 characters.
			</PasswordHint>}
		</>
	)
}

export default PasswordInput

const PasswordHint = styled.span`
	opacity: 0.7;
	font-size: 12px;
	margin-top: 3px;
	margin-bottom: 6px;
	text-align: left !important;
`
