import React, {useEffect, useState} from "react"
import {Button, Card, Divider, Form, Loader} from "semantic-ui-react"
import {AuthCardContainer} from "../../styles/Containers.js"
import {StyledLink} from "../../styles/Text.js"
import {validateAuthForm} from "../../utils/validateAuthForm.js"
import AuthService from "../../services/auth.js"
import Auth from "../../store/reducers/auth/actions.js"
import {useDispatch} from "react-redux"
import {useHistory} from "react-router-dom"
import ErrorComponent from "../../components/messages/Error.js"
import SuccessComponent from "../../components/messages/Success.js"
import findGetParam from "../../utils/findGetParam.js"
import FormInput from "../../components/common/FormInput.js"
import {formStyles} from "../../styles/Forms.js"
import GoogleLoginButton from "./GoogleLogin.js"
import PasswordInput from "../../components/common/PasswordInput"
import firebase from "firebase";
import AuthTabs from "./AuthTabs.js";

const LoginComponent = ({}) => {
  const AuthDispatch = new Auth(useDispatch())
  const history = useHistory({forceRefresh: true})

  const [error, setError] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [invalidFields, setInvalidFields] = useState({})
  const [form, setForm] = useState({email: "", password: ""})
  const [message, setMessage] = useState("")
  const onChange = (event, {value, name}) => setForm({...form, [name]: value})

  useEffect(() => {
    const data = findGetParam("message")
    if (data && Object.keys(data).length) {
      setMessage(data)
    }

  }, [])

  const submit = () => {
    firebase.analytics().logEvent("logged_in");
    const invalids = validateAuthForm(form)
    setInvalidFields(invalids)
    setError("")
    setMessage("")
    if (Object.keys(invalids).length === 0) {
      setSubmitting(true)
      AuthService.login(form)
        .then(handleUser)
        .catch(error => setError(error))
        .finally(() => setSubmitting(false))
    }
  }

  const loginGoogle = (token) => {
    firebase.analytics().logEvent("logged_in");
    setSubmitting(true)
    AuthService.loginGoogle(token)
      .then(handleUser)
      .catch(error => {
        console.log(error)
        setError(error)
      })
      .finally(() => setSubmitting(false))
  }

  const handleUser = (userResponse) => {
    const user = userResponse
    AuthService.setTokens({
      token: user.token,
      refreshToken: user.refreshToken
    })
    firebase.analytics().setUserProperties({
      firstName: user.firstName,
      lastName: user.lastName,
    })
    firebase.analytics().setUserId(user.uid);
    firebase.analytics().logEvent("log_in");
    AuthDispatch.setUser({value: user})
    if (user.user.roles.indexOf("admin") > -1) {
      history.push(`/admin/chapter-sections`)
    } else if (user.user.primaryPageId) {
      history.push(`/${user.user.primaryPageId}`)
    } else {
      history.push(`/onboarding?step=1`)
    }
  }

  return (
    <AuthCardContainer>
      <ErrorComponent message={error}/>
      <SuccessComponent message={message}/>
      <Card>
        <Loader active={submitting}/>
        <AuthTabs/>
        <Card.Content header="Welcome Back" style={{paddingBottom: 0}}/>
        <Form onSubmit={submit}>
          <Card.Content style={{marginLeft: -formStyles.inputSideMargin.marginLeft}}>
            <FormInput icon="user" iconPosition="left" placeholder="Email" fluid name="email"
                       error={invalidFields.email}
                       autoComplete="username" onChange={onChange} value={form.email}/>
            <PasswordInput onChange={onChange} value={form.password} error={invalidFields.password}/>
            <div className="sub-item-text" style={{textAlign: 'left', paddingLeft: 12, fontSize: 12, marginTop: 5}}>
              <StyledLink to="/forgot-password" style={{fontSize: 12}}>Reset Password</StyledLink>
            </div>
          </Card.Content>

          <Card.Content style={{paddingTop: 0}}>
            <Button fluid primary disabled={submitting} type="submit">
              Log in
            </Button>
            <Divider/>
            <GoogleLoginButton googleData={loginGoogle}/>
          </Card.Content>
        </Form>
      </Card>

      {/*<div className="sub-item-text">Forgot password? <StyledLink to="/forgot-password">Reset It</StyledLink>*/}
      {/*</div>*/}
      {/*<div className="sub-item-text">Don't have an account? <StyledLink to="/sign-up">Sign up</StyledLink></div>*/}
    </AuthCardContainer>
  )
}

export default LoginComponent
