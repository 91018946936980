import React from 'react'
import {formStyles, InputLabel} from "../../styles/Forms.js";
import PlacesAutocomplete from 'react-places-autocomplete';
import FormInput from "./FormInput.js";
import styled from "styled-components";
import {NoneTextInline} from "../../styles/Text.js";

const FormLocationPicker = (props) => {
    const {
        name,
        label,
        value,
        onChange,
        containerStyle,
        labelStyle,
        placeholder,
        formMargin = true,
        viewOnly,
        setWidth = 300
    } = props

    const handleSelect = address => {
        onChange(null, {name, value: address})
    };

    let styles = {...containerStyle}
    if (formMargin) {
        styles = {...styles, ...formStyles.inputSideMargin}
    }

    const loc = value === 'undefined' ? null : value;

    if (viewOnly) {
        if (!loc) {
            return <div />
        }
        return (
            <div style={{...styles, marginLeft: 0}}>
                <div>{loc || <NoneTextInline>None</NoneTextInline>}</div>
            </div>
        )
    }

    return (
        <div style={styles} className={'location-field-div'}>
            {!!label && <InputLabel style={{...labelStyle}}>{label}</InputLabel>}
            <PlacesAutocomplete
                value={loc}
                onChange={(text) => onChange(null, {name, value: text})}
                onSelect={handleSelect}
                style={{width: setWidth}}
            >
                {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                    <div
                        style={{width: setWidth}}
                    >
                        <FormInput formMargin={formMargin} fluid  {...getInputProps({placeholder})} icon='location arrow' loading={loading}/>
                        <AutoCompleteDropdown hasLength={(suggestions || []).length > 0} setWidth={setWidth}>
                            {suggestions.map((suggestion, index) => {
                                const className = suggestion.active
                                    ? 'suggestion-item-isActive'
                                    : 'suggestion-item';
                                return (
                                    <div key={index + 'maps'}
                                         {...getSuggestionItemProps(suggestion, {
                                             className,
                                         })}
                                    >
                                        {suggestion.description}
                                    </div>
                                );
                            })}
                        </AutoCompleteDropdown>
                    </div>
                )}
            </PlacesAutocomplete>
        </div>
    );
};

export default FormLocationPicker;

const AutoCompleteDropdown = styled.div`
  max-width: calc(${props => props.setWidth}px - ${props => formStyles.inputSideMargin.marginLeft}px);
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  position: absolute;
  margin-left: ${props => formStyles.inputSideMargin.marginLeft}px;
  border: ${props => props.hasLength ? '1px solid rgba(34, 36, 38, 0.15)' : 'none'};
  z-index: 100000000;

  .suggestion-item {
    padding: 10px;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  }

  .suggestion-item-isActive {
    &:hover {
      padding: 10px;
      border-bottom: 1px solid rgba(34, 36, 38, 0.15);
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.03);
    }
  }
`
