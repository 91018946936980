import React, {useState} from 'react'
import {Button, Checkbox, Icon, Menu, Modal} from "semantic-ui-react";
import styled from "styled-components";
import {CustomGalleryElement, FlexBox, OuterGalleryContainer} from "../../styles/Containers.js";
import UploadGalleryImage from "../../components/ImageViewer/UploadGalleryImage.js";
import MediaService from "../../services/media.js";
import {VisibleViewTypes} from "../../components/common/GalleryList.js";
import {usePageScope} from "../../store/hooks/page.hooks.js";
import useWindowSize from "../../utils/dimensions.js";

const AddChapterMedia = ({isOpen, onClose}) => {
  const [activeItem, setActiveItem] = useState('Computer')
  const [selected, setSelected] = useState([]);
  const {page} = usePageScope()
  const [sessionDownloads, setSessionDownloads] = useState([]);
  const {height} = useWindowSize()

  const toggleImage = (imageData) => {
    const sel = [...selected];
    const i = sel.findIndex(o => o.Location === imageData.Location);
    if (i > -1) {
      sel.splice(i, 1);
    } else {
      sel.push(imageData)
    }
    setSelected(sel);
  }

  const renderList = (gallery) => {
    return gallery.map((o, i) => {
      const extension = MediaService.getType(o)
      return (
        <CustomGalleryElement style={{cursor: "pointer", marginRight: 20, position: 'relative'}}
                              key={"gall-" + i}
                              onClick={() => toggleImage(o)}>
          <CheckboxPos>
            <Checkbox checked={selected.findIndex(d => d.Location === o.Location) > -1}/>
          </CheckboxPos>
          <VisibleViewTypes data={o} extension={extension} clicked={() => null}/>
          <div style={{textAlign: 'center', marginTop: 4, fontWeight: 'bold'}}>{o.chapterTitle}</div>
        </CustomGalleryElement>
      )
    })
  }


  return (
    <Modal open={isOpen} onClose={() => onClose()} closeIcon style={{maxHeight: height - 24}}>
      <Modal.Header>Add Media</Modal.Header>
      <Modal.Content style={{maxHeight: 600, overflowY: 'auto'}}>
        <SecondNav pointing secondary>
          <Menu.Item
            name='From Computer'
            icon={'cloud download'}
            active={activeItem === "Computer"}
            onClick={() => setActiveItem("Computer")}
          />
          <Menu.Item
            name='From Everloom'
            icon={'world'}
            active={activeItem === "Everloom"}
            onClick={() => setActiveItem("Everloom")}
          />
        </SecondNav>

        <div style={{padding: 20}}>
          {(sessionDownloads.length === 0 && activeItem === 'Computer') &&
          <FlexBox align={'center'} justify={'center'} direction={'column'}>
            <Icon name={'cloud download'} size={'large'} style={{fontSize: 100, marginBottom: 20}}/>
            <UploadGalleryImage gallery={page.gallery} selectableState={false}
                                onImageData={data => setSessionDownloads([...sessionDownloads, ...data])}
            />
          </FlexBox>
          }

          <OuterGalleryContainer style={activeItem === 'Computer' ? {} : {opacity: 0, position: 'absolute', zIndex: -100}}>
            {renderList(sessionDownloads)}
          </OuterGalleryContainer>

          <OuterGalleryContainer style={activeItem === 'Everloom' ? {} : {opacity: 0, position: 'absolute', zIndex: -100}}>
            {renderList(page.gallery || [])}
          </OuterGalleryContainer>

        </div>
      </Modal.Content>
      <Modal.Actions>
        <FlexBox justify={'space-between'}>
          <UploadGalleryImage gallery={page.gallery} selectableState={false}
                              onImageData={data => setSessionDownloads([...sessionDownloads, ...data])}
                              containerStyle={{visibility: sessionDownloads.length === 0 ? 'hidden' : 'visible'}}
          />
          <div>
            <span style={{marginRight: 20}}>
            {selected.length} Photos Selected
            </span>

            <Button onClick={() => {
              onClose(selected)
              setSelected([])
            }} primary disabled={selected.length === 0}>
              Add to Chapter
            </Button>
          </div>
        </FlexBox>
      </Modal.Actions>
    </Modal>
  );
};

export default AddChapterMedia;

const SecondNav = styled(Menu)`
  .active.item {
    border-color: ${props => props.theme.primary} !important;
  }
`

const GalleryContainer = styled.div`
  //display: flex;
  //flex-wrap: wrap;
  //align-items: center;
  //justify-content: center;
`

const CheckboxPos = styled.div`
  position: absolute;
  padding: 4px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.3);
`
