import React, {useState} from 'react'
import {Icon, Segment} from "semantic-ui-react";

const LockedFeed = ({}) => {

    return (
        <Segment style={{backgroundColor: '#f6f7f8'}}>
            <Icon name={'lock'}/>
            Memories locked
        </Segment>
    );
};

export default LockedFeed;
