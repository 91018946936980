import React from 'react'
import Root from "../../../store/reducers/root/actions.js";
import {useDispatch, useSelector} from "react-redux";
import {Button, Modal} from "semantic-ui-react";
import {useHistory} from "react-router-dom";

const PaywallModals = ({}) => {
    const history = useHistory();
    const RootDispatch = new Root(useDispatch())
    const currentPaywall = useSelector(Root.payWallPrompt)

    const getText = () => {
        switch (currentPaywall) {
            case 'CHAPTER_WORD_COUNT':
                return 'Oops, looks like you have a higher word count than your plan allows. To raise the word count please upgrade your account.';
            case 'TOTAL_CHAPTERS':
                return 'Oops, looks like you have already reached your chapter limit. To raise the limit please upgrade your account.'
            case 'CHAPTER_PUBLISH':
                return 'Oops, publishing chapters is a premium feature. To make your chapters available to the rest of your family please upgrade!';
            case 'GALLERY':
                return 'This Everloom has run out of storage space. Select a smaller file to upload or upgrade your Everloom to get more storage space.';
            case 'DIRECTORY':
                return 'To get access to you and your family\'s directory, upgrade!';
            default:
                return 'Oops, looks like your plan doesn\'t allow for that! Upgrade now to enable access.';
        }
    }

    const getHeader = () => {
        switch (currentPaywall) {
            case 'GALLERY':
                return 'Out of Storage Space';
            default:
                return 'Restricted access';
        }
    }

    return (
        <Modal open={currentPaywall} size={'small'}>
            <Modal.Header>{getHeader()}</Modal.Header>
            <Modal.Content>
                {getText()}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => RootDispatch.promptPaywall(null)}>
                    Dismiss
                </Button>
                <Button primary onClick={() => {
                    history.push('/account?upgrade=current')
                    RootDispatch.promptPaywall(null)
                }}>
                    Upgrade
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default PaywallModals;
