import React from 'react'
import {Dimmer, Loader} from "semantic-ui-react";

const ModalLoader = ({active}) => {

    return (
        <Dimmer inverted active={active}>
            <Loader active={active}/>
        </Dimmer>
    );
};

export default ModalLoader;
