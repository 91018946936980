import "semantic-ui-css/semantic.min.css"
import "./index.scss"

import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { Provider } from "react-redux"
import { configureStore } from "./store"
import { BrowserRouter } from "react-router-dom"
import { ThemeProvider } from "styled-components"
import customTheme from "./styles/theme"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import firebase from "firebase/app"
import "firebase/analytics"

const initFirebase = () => {
	firebase.initializeApp({
		apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
		projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
		messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
		appId: process.env.REACT_APP_FIREBASE_APP_ID,
		measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
		databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL
	})

	firebase.analytics().setAnalyticsCollectionEnabled(false)
}

if (process.env.REACT_APP_HOST !== 'local') {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_CONFIG,
		environment: process.env.REACT_APP_HOST,
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: process.env.REACT_APP_HOST_TYPE === "local" ? 0 : 1
	})
}

initFirebase()

const { store, persistor } = configureStore()

export const reduxStore = store

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<ThemeProvider theme={customTheme}>
				<App/>
			</ThemeProvider>
		</BrowserRouter>
	</Provider>
	, document.getElementById("root"))
