import React from "react"
import GalleryList from "../common/GalleryList"
import styled from "styled-components"
import { FlexBox, ScrollbarStylesHorizontal } from "../../styles/Containers"
import { ActionRoundButton } from "../../styles/Semantic"
import { ChevronBack, ChevronForward } from "react-ionicons"

const HorizontalGallery = ({ gallery, onSelect, max }) => {

	const maxWidth = document.getElementById("chapter-sticky")?.clientWidth || 0

	const leftOverSpace = 100
	const tileWidth = 150
	const maxTiles = (maxWidth - leftOverSpace) / (tileWidth + 8)

	const id = new Date().getTime() + "ch-gallery"

	const scrollContainer = (direction) => {
		if (document.getElementById(id)) {
			direction === "left" ? document.getElementById(id).scrollLeft -= 158 : document.getElementById(id).scrollLeft += 158
		}
	}

	return (
		<FlexBox align={"center"}>
			<GalleryList
				id={id}
				style={{ maxWidth: maxWidth - leftOverSpace }}
				galleryContainer={HorizontalGalleryContainer}
				gallery={max ? gallery.slice(0, max) : gallery}
				selected={[]}
				imageSelected={onSelect}/>
			{gallery.length > maxTiles && <div>
				<ActionRoundButton style={{ marginLeft: 10 }} onClick={() => scrollContainer("left")}>
					<ChevronBack/>
				</ActionRoundButton>
				<div style={{ margin: "4px 0 4px 0" }}/>
				<ActionRoundButton style={{ marginLeft: 10 }} onClick={() => scrollContainer("right")}>
					<ChevronForward/>
				</ActionRoundButton>
			</div>}
		</FlexBox>
	)
}

export default HorizontalGallery

const HorizontalGalleryContainer = styled(FlexBox)`
	flex-wrap: unset;
	overflow-x: auto;
	${ScrollbarStylesHorizontal}
`
