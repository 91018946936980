import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import MemoriesReducer from "../../store/reducers/memories/actions.js";
import EmptyFeed from "./EmptyFeed.js";
import {Confirm, Segment} from "semantic-ui-react";
import styled from "styled-components";
import MemoryItem from "./MemoryItem.js";
import Root from "../../store/reducers/root/actions.js";
import {handleActionResponse} from "../../utils/messageResponse.js";
import {usePageScope} from "../../store/hooks/page.hooks.js";
import firebase from "firebase/app";

const PendingFeed = ({}) => {
  const [loading, setLoading] = useState('');
  const memoriesDispatch = new MemoriesReducer(useDispatch());
  const rootDispatch = new Root(useDispatch())
  const memories = useSelector(MemoriesReducer.pendingMemories);
  const [confirmDecline, setConfirmDecline] = useState(null)
  const {pageId} = usePageScope();

  const accept = (item) => {
    setLoading(item.id);
    firebase.analytics().logEvent("memory_accepted");
    handleActionResponse(memoriesDispatch.approvePost(item.id, pageId), rootDispatch, 'Post status updated', () => {
      setLoading('')
    })
  }

  const decline = (item) => {
    firebase.analytics().logEvent("memory_declined");
    setLoading(item.id);
    handleActionResponse(memoriesDispatch.declinePost(item.id, pageId), rootDispatch, 'Post status updated', () => {
      setLoading('')
    })
  }

  return (
    <div>
      <Confirm content={'Declining a memory will permanently delete it and it cannot be recovered. Are you sure you want to decline this memory?'}
               confirmButton={'Confirm'} open={!!confirmDecline} onCancel={() => setConfirmDecline(null)}
               onConfirm={() => {
                 decline(confirmDecline)
                 setConfirmDecline(null);
               }}/>
      {memories.length === 0 && <EmptyFeed pending/>}
      {memories.map(o => <MemoryItem loading={loading === o.id} item={o} actions={true} accept={accept}
                                     decline={setConfirmDecline}/>)}
    </div>
  );
};

export default PendingFeed;

export const MemorySegment = styled(Segment)`
  .ql-image {
    max-width: 340px !important;
    max-height: 200px !important;
    float: left;
    margin-right: 14px;
  }

  line-height: 25px;

  .quill-content {
    max-height: ${props => props.expand ? 'unset' : '200px'};
    overflow: hidden;
  }
`;
