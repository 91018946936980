import React from 'react'
import {FlexBox} from "../../../styles/Containers.js";
import HeirloomImage from "../../../components/common/HeirloomImage.js";
import FormInput from "../../../components/common/FormInput.js";
import OnboardingReducer from "../../../store/reducers/onboarding/action.js";
import {useDispatch, useSelector} from "react-redux";
import SetProfilePicture from "../../../components/media/SetProfilePicture.js";
import ImageNotFound from '@images/image-not-found.png';
import useWindowSize from "../../../utils/dimensions.js";

const StepOne = ({}) => {
  const onboarding = new OnboardingReducer(useDispatch());
  const data = useSelector(OnboardingReducer.formData)
  const errors = useSelector(OnboardingReducer.getErrors) || {}

  return (
    <div>
      <h1>Your Name and Photo</h1>

      <FlexBox style={{marginTop: 20}} justify={'space-between'} align={'center'}>
        <div>
          <h3>Upload a picture of yourself</h3>
          <h5>This photo will be associated with your Everloom and can be changed at any time.</h5>
        </div>

        <div style={{textAlign: 'center'}}>
          <div style={{marginBottom: 12, marginTop: 20}}>
            <HeirloomImage src={data.image || ImageNotFound} style={{maxWidth: 170, borderRadius: 4}}/>
          </div>
          <SetProfilePicture onBase64={base64 => onboarding.formChange(null, {name: 'image', value: base64})} style={{}}
                             img={data.image || ImageNotFound}/>
        </div>
      </FlexBox>


      <div style={{marginTop: 40}}>
        <h3>Add your name</h3>
        <h5>Please enter your name as given at birth.</h5>
      </div>
      <FlexBox style={{marginLeft: -12, marginTop: 20, width: '100%'}}>
        <FormInput fluid={'mobile'} placeholder={'First name'} value={data.firstName} name={'firstName'}
                   onChange={onboarding.formChange} error={errors.firstName}/>
        <FormInput fluid={'mobile'} placeholder={'Middle name'} value={data.middleName} name={'middleName'}
                   onChange={onboarding.formChange}/>
        <FormInput fluid={'mobile'} placeholder={'Last name'} value={data.lastName} name={'lastName'}
                   onChange={onboarding.formChange} error={errors.lastName}/>
      </FlexBox>

      <FormInput fluid={'mobile'} placeholder={'Maiden name'} value={data.maidenName} name={'maidenName'}
                 onChange={onboarding.formChange} error={errors.maidenName} formMargin={false} containerStyle={{marginTop: 12}}/>


      <div style={{marginTop: 40}}>
        <h3>Add a preferred name</h3>
        <h5>Go by a different name? Enter it here to use it as the displayed name for your Everloom.</h5>
      </div>
      <FlexBox style={{marginLeft: -12, marginTop: 20}}>
        <FormInput fluid={'mobile'} placeholder={'Name Preferences'} value={data.publicName} name={'publicName'}
                   onChange={onboarding.formChange}/>
      </FlexBox>


    </div>
  );
};

export default StepOne;
