import React from 'react'
import {Dropdown} from "semantic-ui-react";

const DirectoryAccessDropdown = ({permissions, setPermissions, loading = false, disabled}) => {

  return (
    <Dropdown loading={loading} disabled={disabled}
              style={{minWidth: 100}}
              text={permissions.DIRECTORY ? 'Can View' : 'None'}>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => {
          setPermissions({
            DIRECTORY: true
          })
        }} text='Can View' value={'Can View'}/>
        <Dropdown.Item onClick={() => {
          setPermissions({
            DIRECTORY: false
          })
        }} text='None' value={'NONE'}/>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DirectoryAccessDropdown;
