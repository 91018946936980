import API from "./api.js";

export default class BillingService {
    static base = 'billing'

    static giftPage(giftInfo) {
        return API.put(`${BillingService.base}/account/gift`, giftInfo)
    }

    static upgradeAccount(pageId, newPlan, paymentMethod, giftCode) {
        return API.post(`${BillingService.base}/account/${pageId}/upgrade`, {
            paymentMethod,
            newPlan,
            giftCode
        })
    }

    static deletePaymentMethod(paymentMethodId) {
        return API.post(`${BillingService.base}/account/remove-method`, {
            paymentMethodId
        })
    }

    static addPaymentMethod(billingData) {
        return API.put(`${BillingService.base}/account/add-method`, {
            billingData
        })
    }

    static getCodePromo(code) {
        return API.get(`${BillingService.base}/gifts/get-promo/${code}`)
    }
}
