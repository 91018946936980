import React from "react"
import { useSelector } from "react-redux"
import Media from "../../store/reducers/media/actions"
import styled from "styled-components"
import { CONSTANTS } from "../../config/constants"
import moment from 'moment';

const ImageEditor = ({}) => {
	const viewImage = useSelector(Media.viewImage)
	const imageData = viewImage.data

	if (imageData.date === "undefined") {
		imageData.date = ""
	}

	if (imageData.place === "undefined") {
		imageData.place = ""
	}

	if (imageData.description === "undefined") {
		imageData.description = ""
	}

	return (
		<EditorStyles>
			<div>{moment(imageData.date, CONSTANTS.dateFormat).isValid() ? moment(imageData.date, CONSTANTS.dateFormat).format(CONSTANTS.prettyDate) : ''}</div>
			<div>{imageData.place}</div>
			<br/>
			<div
				className={"desc"}>{imageData.description}</div>

			<br/>

			<div>{(imageData.tags || []).map((o, i) => {
				const comma = i < imageData.tags.length - 1 ? ", " : ""

				return o.pageCode ?
					<a href={window.location.origin + "/" + o.pageCode} target={"_blank"}
						 className={"tag-name"}>{o.name}{comma}</a> : o.name + comma
			})}</div>
		</EditorStyles>
	)
}

export default ImageEditor

const EditorStyles = styled.div`
	padding-top: 50px;
	font-size: 13px;
	line-height: 20px;

	.desc {
		line-height: 16px;
	}
`
