import * as React from "react"
import {useEffect} from "react"
import HeaderComponent from "./Header"
import {useHistory} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import Auth from "../store/reducers/auth/actions.js"
import Notifications from "../store/reducers/notifications/actions.js"
import Biography from "../store/reducers/biography/actions.js"
import Root from "../store/reducers/root/actions.js"
import {matchPath} from "react-router"
import styled from "styled-components"
import {FlexBox, ScrollbarStyles} from "../styles/Containers.js"
import {CONSTANTS} from "../config/constants.js"
import TicketReport from "../components/TicketReport.js"
import {DEFAULT_BIO} from "../store/reducers/biography/initialState"
import ProfileDetails from "../pages/Profile/ProfileDetails"
import Logo from "../assets/logos/themed/logo-row-dark.svg"
import MediaLayoutUsage from "../pages/Account/MediaLayoutUsage"
import TopPageNav from "./TopPageNav"
import useWindowSize from "../utils/dimensions.js"
import {usePageScope} from "../store/hooks/page.hooks.js";
import CookiesBanner from "../components/CookiesBanner.js";

const Layout = ({children}) => {
  const {width} = useWindowSize()
  const dispatch = useDispatch()
  const history = useHistory()
  const RootDispatch = new Root(dispatch)
  const NotificationDispatch = new Notifications(dispatch)
  const BiographyDispatch = new Biography(dispatch)
  const {page} = usePageScope()
  const userProfile = useSelector(Biography.getMyBriefBiography)
  const user = useSelector(Auth.user)

  const match = matchPath(window.location.pathname, {
    path: "/:pageId",
    exact: false,
    strict: false
  })

  const pageId = (match?.params.pageId?.indexOf("HD-") === -1 && match?.params.pageId?.indexOf("ED-") === -1) ? null : match?.params.pageId
  useEffect(() => {
    if (!page || !page.isDemo) {
      loadData()
    } else {
      RootDispatch.setDataFetched({value: true})
    }
  }, [pageId])

  const loadData = async () => {
    if (((!page && pageId) || (page && page.id !== pageId))) {
      console.log("Loading pageId from layout/index.js", pageId)
      RootDispatch.setPageLoading({value: true})
      if (user.myPages.indexOf(pageId) > -1) {
        NotificationDispatch.setNotificationsVisible(true, true, pageId)
      }
      await getContext(pageId, true)
      RootDispatch.setDataFetched({value: true})
      RootDispatch.setPageLoading({value: false})
    }
  }

  const getContext = async (pageId, init) => {
    try {
      const res = await BiographyDispatch.setPageContext(pageId, init)
      RootDispatch.setPageLoading({value: false})
      if (res?.message === "404") {
        throw new Error(res.message)
      }
      return res
    } catch (e) {
      RootDispatch.setPageLoading({value: false})
      handleFailedContext(e, pageId)
      return false
    }
  }

  const handleFailedContext = (e, navPageId) => {
    if (e.message === "404") {
      history.push("/not-found/page?pageId=" + navPageId)
    } else {
      history.push("/")
    }
  }


  const info = userProfile || DEFAULT_BIO.brief

  return (
    <TopContainer id={"sev"}>
      <HeaderComponent menuOpen={true} fluid={width <= CONSTANTS.collapsedSize}/>
      <FlexBox wrap={"unset"}>
        {width > CONSTANTS.collapsedSize && <div>
          <LogoLayout>
            <a target={"_blank"} style={{cursor: 'pointer'}} onClick={() => history.push("/")}>
              <img src={Logo}/>
            </a>
          </LogoLayout>
          <ProfileDetails image={info.image} preferredName={info.publicName} firstName={info.firstName}
                          lastName={info.lastName} lastUpdated={info.lastUpdated}/>
          <MediaLayoutUsage/>
        </div>}
        <div className={"nav-content"}>
          {width > CONSTANTS.navCollapse && <TopPageNav/>}
          <div style={{marginTop: 8}}/>
          <div className={"content"} id={"body-content"}>
            {children}
          </div>
        </div>
      </FlexBox>
      {pageId && <TicketReport/>}
      <CookiesBanner/>
    </TopContainer>
  )
}

export default Layout

const LogoLayout = styled.div`
  position: fixed;
  top: 34px;
  left: 80px;

  img {
    width: 100px;
  }
`

export const TopContainer = styled.div`
  position: fixed;
  height: ${CONSTANTS.navHeight}px;
  width: 100%;
  padding: 12px;
  max-width: 1600px;
  min-height: 700px;

  .nav-content {
    padding: 0 0 12px 12px;
    height: 90vh;
    width: 100%;
    @media only screen and (max-width: 560px) {
      padding: 0 0 12px 0;
    }
  }

  .content {
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 40px;
    height: 86vh;
    ${ScrollbarStyles}
  }
`

export const MainSideBar = styled(FlexBox)`
  min-height: 700px;

  .inner-container {
    height: 100vh;
    padding: 20px;
    width: calc(100% - ${props => props.menuOpen ? CONSTANTS.menuSize : 0}px);
    margin-left: ${props => props.menuOpen ? CONSTANTS.menuSize : 0}px;

    @media only screen and (max-width: 700px) {
      padding: 5px;
    }
  }
`
