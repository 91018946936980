import React, {useState} from 'react'
import { Modal } from "semantic-ui-react"

const HeirloomModal = ({ children, ...props }) => {

    return (
        <Modal {...props}>
					{children}
        </Modal>
    );
};

export default HeirloomModal;
