import React from 'react'
import {Button, Modal} from "semantic-ui-react";

const ConfirmationModal = ({children, open, onClose, title, body, onAccept, onDecline, text, loading}) => {
    return (
        <Modal
            onClose={onClose}
            open={open}
            trigger={children}
        >
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                <p>{body}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    disabled={loading}
                    onClick={onDecline}
                    negative>
                    {text.no || 'No'}
                </Button>
                <Button loading={loading}
                    onClick={onAccept} positive>
                    {text.yes || 'Yes'}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default ConfirmationModal;
