import React from 'react'
import Screen from "./Screen.js";
import HeaderComponent from "./Header";

const HomeLayout = ({children}) => {

  return (
    <Screen center>
      <HeaderComponent fluid />
      {children}
    </Screen>
  );
};

export default HomeLayout;
