import {useMutation, useQuery, useQueryClient} from "react-query";
import AuthService from "../../services/auth.js";

export const useReferrals = () => {
  return useQuery(["user", "referrals"], () =>
    AuthService.getMyReferrals().then(res => res), {
    placeholderData: {list: []}
  })
}

export const useClaimReferral = () => {
  const client = useQueryClient()
  return useMutation((data) => AuthService.claimReferral(), {
    onSuccess: async () => {
      await client.invalidateQueries(["user", "referrals"])
    }
  });
}
