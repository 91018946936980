import * as React from 'react'
import {useState} from 'react'
import {HoverableHome, NoneText} from "../styles/Text.js";
import {ArrowBack} from "react-ionicons";
import {useHistory, useParams} from "react-router-dom";
import {Button} from "semantic-ui-react";
import findGetParam from "../utils/findGetParam.js";
import {PagesService} from "../services/pages.js";
import {FlexBox} from "../styles/Containers.js";

const NotFoundComponent = ({}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [requestMessage, setRequestMessage] = useState('');
  const {type} = useParams();

  const pageId = findGetParam('pageId', true);

  if (pageId && !requestMessage) {
    const list = JSON.parse(localStorage.getItem('@heirloom:requested') || '[]') || [];
    if (list.indexOf(pageId) > -1) {
      setRequestMessage('Access to this page has been requested');
    }
  }

  const requestAccess = () => {
    setLoading(true);
    PagesService.requestAccessToPage(pageId)
      .finally(() => {
        const list = JSON.parse(localStorage.getItem('@heirloom:requested') || '[]') || [];
        const set = new Set(list);
        set.add(pageId);
        localStorage.setItem('@heirloom:requested', JSON.stringify(Array.from(set)));
        setLoading(false);
      })
  }

  const message = type === 'page' ?
    'The Everloom you searched for was not found' :
    'This page was not found'

  let component = <div/>;

  if (type === 'page' && pageId) {
    component = (
      <div>
        <h4>If you think the page exists, you can request access. If it exists a notification will be sent to the
          owner.</h4>

        {!requestMessage && <Button loading={loading} onClick={requestAccess} style={{marginTop: 12}} primary>Request Access</Button>}
        {!!requestMessage && <NoneText>{requestMessage}</NoneText>}
      </div>
    )
  }

  return (
    <FlexBox direction={'column'} align={'center'} justify={'center'} style={{textAlign: 'center'}}>
      <h1 style={{marginTop: 150, textTransform: 'capitalize'}}>
        {message}
      </h1>
      <HoverableHome onClick={() => history.push('/')} style={{marginTop: 0}} justify={'center'}
                     align={'center'} flex={0}><ArrowBack
        style={{marginRight: 10}}/> My Everloom</HoverableHome>
      <img src={'https://my-heirloom-dev.s3.us-east-2.amazonaws.com/assets/not-found.svg'} style={{maxWidth: 400, marginTop: 60}} />
      {/*{component}*/}
    </FlexBox>
  );
};

export default NotFoundComponent;
