import React from "react"
import {List, Radio, Segment} from "semantic-ui-react"
import {FlexBox} from "../../styles/Containers.js"
import {useDispatch, useSelector} from "react-redux"
import MemoriesReducer from "../../store/reducers/memories/actions.js"
import Root from "../../store/reducers/root/actions.js"
import {handleActionResponse} from "../../utils/messageResponse.js"
import {usePageScope} from "../../store/hooks/page.hooks.js";
import firebase from "firebase";

const MemorySettings = ({}) => {
  const memories = new MemoriesReducer(useDispatch())
  const settings = useSelector(MemoriesReducer.settings)
  const {pageId} = usePageScope()
  const rootDispatch = new Root(useDispatch())

  const updateSettings = (_, props) => {
    const sett = {...settings}
    if (props.name === "status") {
      sett.status = props.checked ? "OPEN" : "LOCKED"
      firebase.analytics().logEvent("media_submission_status", {
        newStatus: sett.status
      });
    } else {
      firebase.analytics().logEvent("media_submission_acceptance", {
        newStatus: props.checked
      });
      sett[props.name] = props.checked
    }
    handleActionResponse(memories.updateSettings(sett, settings, pageId), rootDispatch, "Settings updated")
  }

  return (
    <Segment>
      <List style={{paddingBottom: 20, paddingTop: 20}}>
        <List.Item>
          <FlexBox justify={"space-between"}>
            Allow memory submissions
            <Radio toggle name={"status"} checked={settings.status === "OPEN"} onChange={updateSettings}/>
          </FlexBox>
        </List.Item>
        <List.Item>
          <FlexBox justify={"space-between"} style={{marginTop: 20}}>
            Automatically accept all memory submissions
            <Radio name={"autoApproval"} toggle checked={settings.autoApproval} onChange={updateSettings}/>
          </FlexBox>
        </List.Item>
      </List>
    </Segment>
  )
}

export default MemorySettings
