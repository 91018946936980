import React from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Root from "../../store/reducers/root/actions.js";
import {useDispatch} from "react-redux";

const ClipCopy = ({text, children, type, sendMessage = true}) => {
    const root = new Root(useDispatch())
    return (
        <CopyToClipboard text={text} onCopy={() => sendMessage && root.setMessage({
            type: 'success',
            message: type ? `${type} copied to clipboard` : 'Copied to clipboard'
        })}>
            {children}
        </CopyToClipboard>
    );
};

export default ClipCopy;
