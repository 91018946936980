import {SettingsMenuItems} from "../../../config/options.map.js";

export const INITIAL_STATE = {
  loading: false,
  error: null,
  pageLoading: false,
  fetchedAuth: false,
  changeAccount: false,
  modals: {},
  accountSection: SettingsMenuItems[0],
  navOpen: true,
  chapterNavSection: null,
  payWallPrompt: null,
  showConfetti: false,
  viewUpgrade: false,
  manageImage: null,
  language: 'EN',
  nux: null,
  dimensions: {
    width: window.innerWidth,
    height: window.innerHeight
  }
};

const SET_LOADING = 'set_loading';
const SET_ERROR = 'set_error';
const PAGE_LOADING = 'PAGE_LOADING'
const SET_FETCHED = 'SET_FETCHED'
const SET_CHANGE_ACCOUNT = 'SET_CHANGE_ACCOUNT';
const SET_TRANSFER_MODAL = 'SET_TRANSFER_MODAL';
const SET_ACCOUNT_SECTION = 'SET_ACCOUNT_SECTION'
const TOGGLE_SIDE_NAV = 'TOGGLE_SIDE_NAV';
const SET_CHAPTER_NAV_SECTION = 'SET_CHAPTER_NAV_SECTION'
const SET_PROMPT_PAYWALL = 'SET_PROMPT_PAYWALL';
const SET_CONFETTI = 'SET_CONFETTI';
const SET_VIEW_UPGRADE = 'SET_VIEW_UPGRADE'
const SET_MANAGE_IMAGE = 'SET_MANAGE_IMAGE';
const SHOW_NUX = 'SHOW_NUX'
const SET_DIMENSIONS = 'SET_DIMENSIONS'

export {
  SET_CONFETTI,
  TOGGLE_SIDE_NAV,
  SET_LOADING,
  SET_ERROR,
  PAGE_LOADING,
  SET_FETCHED,
  SET_CHANGE_ACCOUNT,
  SET_TRANSFER_MODAL,
  SET_ACCOUNT_SECTION,
  SET_CHAPTER_NAV_SECTION,
  SET_PROMPT_PAYWALL,
  SET_VIEW_UPGRADE,
  SET_MANAGE_IMAGE,
  SHOW_NUX,
  SET_DIMENSIONS
}
