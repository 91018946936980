import React, {useEffect, useState} from "react"
import {formStyles} from "../../styles/Forms.js"
import {FamilyType, FlexBox, ProfilePicture} from "../../styles/Containers.js"
import {Button, Checkbox, Form, Image, Message, Modal} from "semantic-ui-react"
import MaleImageHolder from "../../assets/icons/male.png"
import FemaleImageHolder from "../../assets/icons/female.png"
import FormInput from "../../components/common/FormInput.js"
import FormDatePicker from "../../components/common/FormDatePicker.js"
import FormLocationPicker from "../../components/common/FormLocationPicker.js"
import useWindowSize from "../../utils/dimensions.js"
import AddModal from "./relatives/AddModal.js"
import findGetParam from "../../utils/findGetParam.js"
import {useDispatch} from "react-redux"
import Biography from "../../store/reducers/biography/actions.js"
import BiographyService from "../../services/biography.js"
import {DEFAULT_BIO} from "../../store/reducers/biography/initialState.js"
import {useHistory} from "react-router-dom"
import NotificationService from "../../services/notifications.js"
import {LISTS, mappedOptions, OtherTypes} from "../../config/options.map.js"
import PermissionsService from "../../services/permissions.js"
import {PagesService} from "../../services/pages.js"
import styled from "styled-components"
import SetProfilePicture from "../../components/media/SetProfilePicture.js"
import AuthService from "../../services/auth.js"
import Auth from "../../store/reducers/auth/actions.js"
import {ActionButton} from "../../styles/Semantic.js"
import Pill from "../../components/common/Pill.js"
import ModalLoader from "../../components/common/ModalLoader.js"
import customTheme from "../../styles/theme"
import FormDropDown from "../../components/common/FormDropDown.js"
import {usePageScope} from "../../store/hooks/page.hooks.js";
import firebase from "firebase/app";


const EditProfile = ({newPage, onCreation = null, close, type}) => {
  const history = useHistory()
  const {width, height} = useWindowSize()
  const BiographyDispatch = new Biography(useDispatch())
  const AuthDispatch = new Auth(useDispatch())
  const {pageId, permissions, page} = usePageScope()
  const [profile, setProfile] = useState(DEFAULT_BIO.brief)
  const [relatives, setRelatives] = useState(DEFAULT_BIO.relatives)

  const [addRelative, setAddRelative] = useState(null)
  const [isDeceased, setIsDeceased] = useState(!!profile.deceased)
  const [errors, setErrors] = useState({})
  const [isOnboarding, setIsOnboarding] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (LISTS.data.relationMapping) {
      const data = findGetParam("action", true)
      if (!!data) {
        runAction(data)
      }
    }
  }, [LISTS.data])

  useEffect(() => {
    if (!newPage) {
      const edit = PermissionsService.checkPermissions(permissions, "canEdit")
      if (!edit) {
        history.push("/")
      } else {
        setErrors({...errors, global: ""})
      }

      setRelatives({
        ...JSON.parse(JSON.stringify(relatives)),
        ...JSON.parse(JSON.stringify(page?.relations || {}))
      })
      setProfile({
        ...profile,
        ...(page?.brief || {})
      })
      setIsDeceased(!!page?.brief.deceased)
    }
  }, [page, pageId])

  const runAction = (actionType) => {
    history.push(`${history.location.pathname}`)
    switch (actionType) {
      case "INVITE_FAMILY":
        const data = NotificationService.mediumActionData?.INVITE_FAMILY
        if (data) {
          for (const type in OtherTypes) {
            const res = OtherTypes[type].find(o => o.key === data.data.relationship)
            if (res) {
              setAddRelative({
                type: res.alt,
                member: {
                  pageCode: data.data.pageId
                }
              })
              return
            }
          }
        }
    }
  }

  const updateForm = (_, {name, value}) => {
    setProfile({
      ...profile,
      [name]: value
    })
  }

  const removeRelative = (type, index) => {
    const rel = JSON.parse(JSON.stringify(relatives))
    const data = rel[type] || []
    data.splice(index, 1)
    setRelatives({
      ...rel,
      [type]: data
    })
  }

  const submitBiography = () => {
    if (newPage) {
      firebase.analytics().logEvent("create_new_everloom");
      createBio()
    } else {
      updateBio()
    }
  }

  const createBio = () => {
    if (isValid() && pageId) {
      setLoading(true)
      PagesService.createNewPage({
        relatives,
        biography: profile
      }).then((data) => {
        AuthService.getMe().then(async user => {
          AuthDispatch.setUser({value: user, fetched: true})
        }).finally(() => {
          setLoading(false)
          const page = data.pageId
          if (onCreation) {
            onCreation(page, profile)
          } else {
            close()
          }
        })
      }).catch(error => {
        const errs = {...errors}
        errs.global = error
        setErrors(errs)
        setLoading(false)
      })
    } else {
      window.scrollTo(0, 0)
    }
  }

  const updateBio = () => {
    if (isValid() && pageId) {
      setLoading(true)
      BiographyService.submitBiographyUpdates({
        relatives,
        biography: profile,
        pageId: pageId
      }).then(() => {
        return BiographyDispatch.setPageContext(pageId).then(() => {
          document.getElementById("brief-modal-content")?.scrollTo(0, 0)
          close()
        })
      }).catch(error => {
        document.getElementById("brief-modal-content")?.scrollTo(0, 0)
        const errs = {...errors}
        errs.global = error
        setErrors(errs)
      }).finally(() => setLoading(false))
    } else {
      window.scrollTo(0, 0)
    }
  }

  const isValid = () => {
    const errors = {}
    if (!profile.birthName) {
      errors.global = "You must at least specify a first name and last name"
      errors.birthName = "First name required"
    }
    if (!profile.lastName) {
      errors.lastName = "Last name required"
    }
    if (profile.about.length > 300) {
      errors.about = "Max length is 300 characters"
    }
    const hasErrors = Object.keys(errors).length > 0
    if (hasErrors && !errors.global) {
      errors.global = "You have one or more errors bellow"
    }
    setErrors(errors)
    return !hasErrors
  }

  const setUrl = (url) => {
    setProfile({
      ...profile,
      image: url
    })
  }

  const addRelativeMember = (formData) => {
    let rel = {...relatives}
    if (addRelative?.member && rel[addRelative.type].findIndex(o => o.pageCode === addRelative.member.pageCode) > -1) {
      delete formData.index
      const data = [...rel[addRelative.type]]
      data[addRelative?.member.index] = formData
      setRelatives({
        ...rel,
        [addRelative.type]: data
      })
    } else {
      const data = rel[addRelative.type] || []
      data.push(formData)
      setRelatives({
        ...rel,
        [addRelative.type]: data
      })
    }
    setAddRelative({})
  }

  const SubmitComponents = () => (
    <>
      {!isOnboarding && <Button basic disabled={loading} onClick={close}>
        Cancel
      </Button>}
      <Button primary onClick={submitBiography} disabled={loading}>
        {newPage ? "Create" : "Save"}
      </Button>
    </>
  )

  const ImageHolder = profile?.gender === 'female' ? FemaleImageHolder : MaleImageHolder

  return (
    <>
      <Modal.Content style={{maxHeight: height - 200, overflowY: "scroll"}} id={"brief-modal-content"}>
        <ModalLoader active={loading}/>
        <ModalContentContainer>
          <Form>
            <AddModal isOpen={!!addRelative?.type} type={addRelative?.type}
                      edit={!!addRelative?.member ? {...addRelative?.member} : null}
                      setClose={() => setAddRelative({})}
                      relatives={relatives}
                      saveForm={addRelativeMember}/>

            {errors.global && <Message negative>
              <Message.Header>Oops</Message.Header>
              <p>
                {errors.global}
              </p>
            </Message>
            }
            {(!type || type === 'bio') && <>

              <FlexBox justify={"space-between"} align={"center"} direction={"column"}>
                <ProfilePicture>
                  <Image src={profile.image || ImageHolder} size='small'/>
                </ProfilePicture>
                <SetProfilePicture onUrl={setUrl} style={styles.innerSectionMargin}
                                   img={profile.image || ImageHolder}/>
              </FlexBox>

              <div style={{color: customTheme.alert}}>* Indicates a required field</div>
              <ItemSeparator/>
              <ItemTitle>Birth name</ItemTitle>
              <FlexBox wrap={"unset"} style={{marginLeft: -formStyles.inputSideMargin.marginLeft}}>
                <FormInput value={profile.birthName} name='birthName' placeholder='First *'
                           required
                           onChange={updateForm} error={errors.birthName}/>

                <FormInput value={profile.middleName} name='middleName' placeholder='Middle'
                           onChange={updateForm}/>

                <FormInput value={profile.lastName} name='lastName' placeholder='Last *'
                           required
                           onChange={updateForm} error={errors.lastName}/>

                <FormInput value={profile.maidenName} name='maidenName' placeholder='Maiden'
                           onChange={updateForm}/>
              </FlexBox>

              <ItemSeparator/>
              <ItemTitle>Name Preferences</ItemTitle>
              <FlexBox wrap={"unset"}>


                <FormInput value={profile.firstName} name='firstName' placeholder={"Preferred first name"}
                           containerStyle={{marginTop: 4}}
                           onChange={updateForm} formMargin={false}/>

                <FormInput
                  containerStyle={{marginTop: 6}}
                  value={profile.suffix} name='suffix' placeholder={"Suffix"}
                  onChange={updateForm}/>

              </FlexBox>

              <ItemSeparator/>
              <FlexBox>

                <div style={{flex: 1}}>
                  <ItemTitle>Gender</ItemTitle>

                  <FlexBox style={{marginTop: 14}}>
                    {mappedOptions("gender").map(o => (
                      <div align={"center"} style={{marginRight: 18}}>
                        <Checkbox checked={profile.gender === o.value} style={{marginRight: 6}}
                                  onChange={(ev, {checked}) => updateForm(null, {
                                    name: "gender",
                                    value: checked ? o.value : ""
                                  })}/>
                        <span style={{position: "relative", top: -3}}>{o.text}</span>
                      </div>
                    ))
                    }
                  </FlexBox>

                </div>

                <div style={{flex: 1}}>
                  <ItemTitle>Pronouns</ItemTitle>

                  <FormDropDown
                    clearable
                    formMargin={false}
                    closeOnChange
                    closeOnBlur
                    name={"pronouns"}
                    value={profile.pronouns}
                    containerStyle={{marginTop: 4}}
                    placeholder={"Select"}
                    onChange={updateForm}
                    options={mappedOptions("pronouns")}
                  />
                </div>
              </FlexBox>

              <ItemSeparator/>
              <FlexBox>
                <div style={{flex: 1}}>
                  <ItemTitle>Birthdate</ItemTitle>
                  <div style={{}}>
                    <FormDatePicker isForm value={profile.birthday || ""} name='birthday'
                                    placeholder='Birthday' formMargin={false}
                                    onChange={updateForm}/>
                  </div>
                </div>
                <div style={{flex: 1}}>
                  <ItemTitle>Birthplace</ItemTitle>
                  <div style={{marginTop: 13}}>
                    <FormLocationPicker value={profile.birthplace} name='birthplace'
                                        placeholder='Birthplace' formMargin={false}
                                        onChange={updateForm}/>
                  </div>
                </div>
              </FlexBox>

              <ItemSeparator/>
              <ItemSeparator/>

              <div style={{marginTop: 12, marginBottom: 20}}>
                <FlexBox align={"center"}>
                  <Checkbox primary checked={isDeceased}
                            style={{marginRight: 10}}
                            onChange={(ev, data) => {
                              setIsDeceased(data.checked)
                              if (!data.checked) {
                                updateForm(null, {name: "deceased", value: ""})
                              }
                            }}
                  />
                  <ItemTitle>Deceased</ItemTitle>
                </FlexBox>
                    {isDeceased &&
                    <FormDatePicker value={profile.deceased} name='deceased' placeholder='Deceased date'
                                    onChange={updateForm} formMargin={false}/>}
              </div>

            </>}
            {(!type || type === 'family') && <>
              <ItemTitle>Your Family</ItemTitle>
              <div style={{fontSize: 12, fontWeight: 500}}>Manage your family members</div>

              <ItemSeparator/>

              {mappedOptions("relationTypes").map(type => (
                <FamilyType>
                  <FlexBox align={"center"} justify={"space-between"}>
                    <div className={"item-title"}>{type}</div>
                    <ActionButton onClick={() => setAddRelative({type})}>Add</ActionButton>
                  </FlexBox>
                  <FlexBox>
                    {relatives[type].map((relative, index) => (
                      <Pill {...relative} type={"profile"} cleared={() => removeRelative(type, index)} onClick={() => {
                        setAddRelative({
                          type,
                          member: {
                            index,
                            ...relative
                          }
                        })
                      }}>{relative.firstName} {relative.lastName}</Pill>
                    ))}
                  </FlexBox>
                </FamilyType>
              ))}
            </>}
          </Form>
        </ModalContentContainer>
      </Modal.Content>
      <Modal.Actions>
        <SubmitComponents/>
      </Modal.Actions>
    </>
  )
}

const styles = {
  mockBox: {
    height: 48, display: "flex", alignItems: "center"
  },
  contentMargin: {
    marginTop: 12
  },
  sectionMargin: {
    marginTop: 34
  },
  innerSectionMargin: {
    marginTop: 10
  }
}

const ItemTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
`

const ModalContentContainer = styled.div`
  .form-field-div {
    max-width: 160px;
  }

  .location-field-div {
    .form-field-div {
      max-width: unset;
    }
  }
`

const ItemSeparator = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`
export default EditProfile


