import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { Button, Search } from "semantic-ui-react"
import { matchPath } from "react-router"
import { useHistory } from "react-router-dom"
import { FlexBox, ProfileCircle } from "../../styles/Containers.js"
import { usePageScope, useTaggable } from "../../store/hooks/page.hooks.js"
import firebase from "firebase"
import { useCurrentUser } from "../../store/hooks/user.hooks.js"

const SearchBar = ({ fluid, action = null, ...props }) => {
	const history = useHistory()
	const user = useCurrentUser()
	const { page } = usePageScope()
	let { isFetching: fetchingTaggable } = useTaggable()
	const [loadingResults, setLoadingResults] = useState(false)
	const [searchValue, setSearchValue] = useState("")
	const [searchResults, setSearchResults] = useState([])
	let { data: options } = useTaggable()
	options = (options || []).filter(o => o.canAccess === true).map(o => ({
		...o,
		key: o.pageCode,
		title: o.name,
		value: {
			pageCode: o.pageCode,
			name: o.name,
			birthName: o.birthName
		}
	}))

	const search = useRef()

	useEffect(() => {
		const results = []
		const s = searchValue.toLowerCase()
		for (const opt of options) {
			if (opt.value.pageCode.toLowerCase().includes(s) || opt.value.name.toLowerCase().includes(s)
				|| (opt.value.birthName && opt.value.birthName.toLowerCase().includes(s))) {
				results.push(opt)
			}
		}
		setSearchResults(results)
	}, [searchValue])

	const goToPage = (pageCode) => {
		const match = matchPath(window.location.pathname, {
			path: "/:pageId",
			exact: false,
			strict: false
		})
		if (window.location.pathname.includes(`/${pageCode}`)) {
			history.push(`/${match.params.pageId}/chapters`)
		} else {
			history.push(`/${pageCode}/chapters`)
		}
	}

	if (page && page.isDemo) {
		return <SearchContainer style={{width: '100%'}}>
			<FlexBox justify={"space-between"} align={'center'} style={{width: '100%'}}>
				<b style={{ color: "#276f86" }}>
					Join Everloom to create your own and connect with your loved ones
				</b>
				<Button primary onClick={() => window.open("https://app.everloom.io", "_blank")}>
					Sign Up
				</Button>
			</FlexBox>
		</SearchContainer>
	}

	return (
		<SearchContainer>
			<SearchInputContainer active={true} fluid={fluid}>
				<Search
					input={{ ref: search }}
					icon={"search"}
					loading={loadingResults || fetchingTaggable}
					onResultSelect={(e, data) => {
						if (action) {
							action(data.result.value.pageCode)
						} else {
							firebase.analytics().logEvent("selected_page", {
								isPrimary: data.result.value.pageCode === user.primaryPageId,
								navType: "Search"
							})
							goToPage(data.result.value.pageCode)
						}
					}}
					onSearchChange={(ev, { value }) => setSearchValue(value)}
					resultRenderer={(props) =>
						<FlexBox wrap={"unset"} align={"center"}>
							<ProfileCircle src={props.image}/>
							<strong>{props.title}</strong>
						</FlexBox>
					}
					results={searchResults}
					value={searchValue}
					placeholder={"Search"}
					{...props}
				/>
			</SearchInputContainer>
		</SearchContainer>
	)
}

export default SearchBar

const SearchInputContainer = styled.div`
	overflow: ${props => props.active ? "unset" : "hidden"};
	transition: all 0.1s linear;
	width: ${props => props.fluid ? "100%" : "320px"};

	.ui.search .prompt {
		border-radius: 4px;
	}

	.ui.search > .results, .ui.search .prompt, .ui.input {
		min-width: 200px;
		width: ${props => props.fluid ? "100%" : "320px"};
		max-height: 400px;
		overflow-y: auto;
	}
`

const ContentTitle = styled.div`
	font-size: 14px;
	font-weight: 500;
`

const SearchContainer = styled.div`
	display: flex;
	align-items: center;

	.search-icon {
		margin-right: 10px;
		cursor: pointer;

		svg {
			color: white;
		}
	}
`
