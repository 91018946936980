import React, {useState} from "react"
import {Divider, Icon, List, Popup} from "semantic-ui-react"
import {useDispatch, useSelector} from "react-redux"
import {Link, useHistory} from "react-router-dom"
import styled from "styled-components"
import Auth from "../../store/reducers/auth/actions.js"
import Root from "../../store/reducers/root/actions.js"
import {FlexBox, IconImage, PopupListItem, ProfileCircle, ProfileIcon} from "../../styles/Containers.js"
import {PagesService} from "../../services/pages"
import {usePageScope} from "../../store/hooks/page.hooks.js";
import {useQueryClient} from "react-query";
import FemaleImageHolder from "../../assets/icons/female.png";
import MaleImageHolder from "../../assets/icons/male.png";

const AccountPopover = ({}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const userDispatch = new Auth(dispatch)
  const rootDispatch = new Root(dispatch)
  const [profileSelect, setProfileSelect] = useState(false)
  const user = useSelector(Auth.user)
  const {pageId} = usePageScope()
  const queryClient = useQueryClient()

  const primary = user?.pageData[user.primaryPageId]

  const logout = () => {
    close()
    queryClient.invalidateQueries()
    userDispatch.logout()
    history.push("/")
  }

  const close = () => {
    setProfileSelect(false)
    rootDispatch.setChangeAccount(false)
  }

  const ImageHolder = primary?.gender === 'female' ? FemaleImageHolder : MaleImageHolder

  return (
    <>
      <Link to={PagesService.navigatePageRoute(user.primaryPageId)}>
        <ProfileCircle src={primary?.image || ImageHolder}
                       style={{backgroundColor: 'white', borderRadius: 20, width: 40, height: 40}}/>
      </Link>
      <PopupContainer
        content={() =>
          <List relaxed>
            <PopupListItem onClick={() => {
              rootDispatch.setChangeAccount()
              close()
            }}>
              <ProfileIcon src={primary?.image || IconImage}/>
              <FlexBox direction={"column"}>
                <List.Header>{user.firstName} {user.lastName}</List.Header>
                <p style={{fontSize: 12}}>{user.email}</p>
              </FlexBox>
            </PopupListItem>
            <Divider/>
            {/*<Link to={"/home"}>*/}
            {/*	<PopupListItem onClick={close}>*/}
            {/*		<List.Icon name='home' size='large' verticalAlign='middle'/>*/}
            {/*		<List.Header>FamilyPage</List.Header>*/}
            {/*	</PopupListItem>*/}
            {/*</Link>*/}
            <Link to={PagesService.navigatePageRoute(user.primaryPageId)}>
              <PopupListItem onClick={close}>
                <List.Icon name='leaf' size='large' verticalAlign='middle'/>
                <List.Header>My Everloom</List.Header>
              </PopupListItem>
            </Link>
            <Link to={"/account"}>
              <PopupListItem onClick={close}>
                <List.Icon name='setting' size='large' verticalAlign='middle'/>
                <List.Header>Account</List.Header>
              </PopupListItem>
            </Link>
            <Divider style={{margin: 0}}/>
            <a href={process.env.REACT_APP_LANDING_URL} target={"_blank"}>
              <PopupListItem onClick={close}>
                <List.Icon name='world' size='large' verticalAlign='middle'/>
                <List.Header>Everloom</List.Header>
              </PopupListItem>
            </a>
            <a href={process.env.REACT_APP_LANDING_URL + "/help"} target={"_blank"}>
              <PopupListItem onClick={close}>
                <List.Icon name='help' size='large' verticalAlign='middle'/>
                <List.Header>Help Center</List.Header>
              </PopupListItem>
            </a>
            <Divider style={{margin: 0}}/>
            <PopupListItem onClick={logout} borderless>
              <List.Icon name='sign-out' size='large' verticalAlign='middle'/>
              <List.Header>Sign Out</List.Header>
            </PopupListItem>
          </List>
        }
        eventsEnabled={true}
        on='click'
        onClose={close}
        onOpen={() => setProfileSelect(true)}
        open={profileSelect}
        trigger={
          <DropIcon>
            <Icon name={"angle down"}/>
          </DropIcon>
        }
      />
    </>
  )
}

//            <ProfileCircle src={primary?.image || IconImage}/>
export default AccountPopover

export const PopupContainer = styled(Popup)`
  width: 300px;
`

export const DropIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: white;
  margin-left: 4px;

  i {
    margin-right: 0;
  }
`
export const UserIcon = styled.div`
`
