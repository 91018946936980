import React, { useEffect, useState } from "react"
import { Button, Grid, Message, Modal } from "semantic-ui-react"
import { SettingsPage } from "../../../styles/Containers.js"
import FormDatePicker from "../../../components/common/FormDatePicker.js"
import { useDispatch, useSelector } from "react-redux"
import Biography from "../../../store/reducers/biography/actions.js"
import ModalLoader from "../../../components/common/ModalLoader.js"
import BiographyService from "../../../services/biography.js"
import Root from "../../../store/reducers/root/actions.js"
import { CONSTANTS } from "../../../config/constants.js"
import moment from "moment"
import { NoneText, NoneTextInlineRegular } from "../../../styles/Text.js"
import { useHistory } from "react-router-dom"
import { LISTS } from "../../../config/options.map.js"
import { FormSectionTitle } from "../../../styles/Forms"
import styled from "styled-components"
import ManageBeneficiary from "./ManageBeneficiary"
import {usePageScope} from "../../../store/hooks/page.hooks.js";
import firebase from "firebase";

const BeneficiarySection = ({}) => {
	const history = useHistory()
	const [confirmDeath, setConfirmDeath] = useState(false)
	const [deceasedDate, setDeceasedDate] = useState("")
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const [beneficiary, setBeneficiary] = useState(false)
	const { page, pageId, permissions } = usePageScope()
	const root = new Root(useDispatch())
	const bio = new Biography(useDispatch())

	useEffect(() => {
		setError(null)
		setDeceasedDate("")
	}, [confirmDeath])

	const labelDeceased = () => {
		firebase.analytics().logEvent("marked_deceased");
		setLoading(true)
		BiographyService.setDeceased(deceasedDate, pageId)
			.then(() => {
				root.setMessage({
					type: "success",
					message: "Person labeled as deceased"
				})
				setConfirmDeath(false)
				bio.patchLocalBio({
					deceased: deceasedDate
				})
			})
			.catch(e => {
				setError(e)
			})
			.finally(() => setLoading(false))
	}

	const goToDeathChapter = () => {
		const existingChapter = page.chapters.find(o => o.chapterTitleType === LISTS.data.deathChapterId)
		if (existingChapter) {
			history.push(`/${pageId}/chapters/manage?chapterId=${existingChapter.chapterId}`)
		} else {
			history.push(`/${pageId}/chapters/manage`)
		}
	}

	const activateObituary = () => {
		root.setMessage({
			type: "Coming Soon",
			message: "This feature is under development"
		})
	}

	const confirmDeathModal = () => {
		return <Modal onClose={() => setConfirmDeath(false)} closeIcon open={confirmDeath} size={"small"}>
			<ModalLoader active={loading}/>
			<Modal.Header>Memorialize {page?.brief?.firstName} {page?.brief?.lastName}</Modal.Header>
			<Modal.Content>
				{!!error && <Message negative>{error}</Message>}
				<Modal.Description>
					To begin memorializing this Everloom please enter the date for when this person passed away.
					<br/>
					<br/>
					Please note that this will not send a notification to the people connected with this Everloom. Additionally,
					while an executor can memorialize an Everloom they cannot undo the action. Only the Everloom's owner or an
					existing collaborator can do so.
					<br/>
					<br/>
					<a target={"_blank"} href={process.env.REACT_APP_LANDING_URL + "/help-memorialization"}>Learn more about
						memorializing an Everloom.</a>
				</Modal.Description>
				<FormDatePicker fluid formMargin={false} onChange={(_, { value }) => setDeceasedDate(value)}
												value={deceasedDate}/>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={() => setConfirmDeath(false)}>
					Cancel
				</Button>
				<Button primary disabled={!deceasedDate} onClick={labelDeceased}>
					Save
				</Button>
			</Modal.Actions>
		</Modal>

	}

	const buttonWidth = 150
	return (
		<SettingsPage>
			<ModalLoader active={loading}/>
			<ManageBeneficiary isOpen={beneficiary} close={() => setBeneficiary(false)}/>

			<FormSectionTitle flip={445}>
				<div>
					After Life
					<div style={{ marginTop: 4 }}/>
					<NoneTextInlineRegular>
						Use this section to prepare and manage an Everloom for after life.
						<br/>
						<a href={process.env.REACT_APP_LANDING_URL + '/help-after-life'} target={'_blank'}>Learn more about the After Life settings.</a>
					</NoneTextInlineRegular>
					<div style={{ marginBottom: 8 }}/>
				</div>
				<Button style={{ minWidth: 160 }} primary onClick={() => setBeneficiary(true)} size={"small"} icon>
					Manage Executors
				</Button>
			</FormSectionTitle>

			{confirmDeathModal()}

			{permissions.indexOf("BENEFICIARY") > -1 && <Grid.Column>
				<Message>
					You are an Executor for this page and have the ability to<br/>
					<ul>
						<li>Memorialize this Everloom</li>
						<li>Create/Edit a chapter for Death</li>
						<li>Review/approve new memories</li>
						{/*<li>Activate the Obituary section</li>*/}
					</ul>
				</Message>
			</Grid.Column>}
			<Grid.Column style={{ marginTop: 20 }}>
				<OptionBox>
					<div>Mark this person as deceased</div>

					{!page?.brief?.deceased ?
						<Button style={{ width: buttonWidth }} secondary basic onClick={() => setConfirmDeath(true)}>Memorialize</Button>
						:
						<NoneText>Deceased as
							of {moment(page?.brief?.deceased, CONSTANTS.dateFormat).format(CONSTANTS.prettyDate)}</NoneText>}
				</OptionBox>
				<OptionBox>
					<div>Manage Chapter for Death</div>
					<Button style={{ width: buttonWidth }} secondary basic disabled={!page?.brief?.deceased}
									onClick={goToDeathChapter}>Manage Chapter</Button>
				</OptionBox>

				<OptionBox>
					<div>Add Obituary to Everloom</div>
					<Button onClick={activateObituary} style={{ width: buttonWidth }} secondary basic
									disabled={!page?.brief?.deceased}>Create Obituary</Button>
				</OptionBox>
			</Grid.Column>
		</SettingsPage>
	)
}

export default BeneficiarySection

export const OptionBox = styled.div`
	min-height: 85px;
	background-color: #FAFAFA;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 20px;
	padding-right: 20px;
	margin-bottom: 20px;
	border-radius: 6px;

	@media only screen and (max-width: 445px) {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.ui.button {
			margin-top: 8px;
		}
	}
`
