import React, {useEffect, useState} from 'react'
import {Button, Message, Modal} from "semantic-ui-react";
import ModalLoader from "../../components/common/ModalLoader.js";
import FormInput from "../../components/common/FormInput.js";
import {FlexBox} from "../../styles/Containers.js";
import FormLocationPicker from "../../components/common/FormLocationPicker.js";
import TreeService from "../../services/tree.js";
import Root from "../../store/reducers/root/actions.js";
import {useDispatch, useSelector} from "react-redux";
import Biography from "../../store/reducers/biography/actions.js";
import {usePageScope} from "../../store/hooks/page.hooks.js";
import firebase from "firebase/app";

const defaultForm = {
  email: '',
  phoneNumber: '',
  address: ''
}

const EditContact = ({isOpen, close, data}) => {
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const root = new Root(useDispatch())
  const {pageId} = usePageScope()

  useEffect(() => setForm((data || defaultForm)), [data]);

  const onChange = (_, {value, name}) => {
    setForm({
      ...form,
      [name]: value
    })
  }

  const setClose = (reload = false) => {
    setForm(defaultForm);
    setLoading(false);
    setError(null);
    close(reload)
  }

  const submit = () => {
    firebase.analytics().logEvent("contact_info_updated");
    setLoading(true);
    TreeService.updateContactBook(pageId, {
      ...form, pageAccess: data?.pageAccess || null
    }).then(() => {
      root.setMessage({
        type: 'success',
        message: 'Family Directory updated'
      });
      setClose(true);
    }).catch(e => {
      setError(e);
      setLoading(false);
    })
  }

  if (!form) {
    return <div/>
  }

  return (
    <Modal open={isOpen} closeIcon onClose={setClose} size={'mini'}>
      <ModalLoader active={loading}/>
      <Modal.Header>My Contact Info</Modal.Header>
      <Modal.Content>
        {!!error && <Message negative>
          {error}
        </Message>}

        <FlexBox direction={'column'} style={{padding: 20, maxWidth: 400, marginRight: 'auto'}}>

          <h4>Enter the fields you would like to add to the directory</h4>

          <FlexBox align={'center'} style={{width: '100%'}}>
            <FormInput fluid placeholder={'Email'} value={form.email} name={'email'} onChange={onChange}
                       formMargin={false}/>
          </FlexBox>

          <FlexBox align={'center'} style={{width: '100%', marginTop: 6}}>
            <FormInput fluid placeholder={'Phone number'} value={form.phoneNumber} name={'phoneNumber'}
                       onChange={onChange} formMargin={false}/>
          </FlexBox>

          <FlexBox align={'center'} style={{width: '100%'}}>
            <FormLocationPicker
              formMargin={false}
              setWidth={'100%'}
              containerStyle={{marginTop: 6, flex: 1}}
              placeholder={'Address'}
              name={'address'} value={form.address || ''} onChange={onChange}/>
          </FlexBox>

        </FlexBox>

      </Modal.Content>
      <Modal.Actions>
        <Button onClick={setClose}>Cancel</Button>
        <Button primary onClick={submit}>Submit</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default EditContact;
