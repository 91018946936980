import React, {useState} from 'react'
import {Fab} from "../styles/Containers.js";
import {Button, Icon, Message, Modal} from "semantic-ui-react";
import customTheme from "../styles/theme.js";
import QuillEditor from "./common/QuillEditor.js";
import styled from "styled-components";
import {useQuill} from "react-quilljs";
import API from "../services/api.js";
import AdminService from "../services/admin.js";
import ModalLoader from "./common/ModalLoader.js";
import {useDispatch, useSelector} from "react-redux";
import Biography from "../store/reducers/biography/actions.js";
import Root from "../store/reducers/root/actions.js";
import ChatBubble from '@images/icons/chat.svg';
import {usePageScope} from "../store/hooks/page.hooks.js";

const TicketQuillContainer = ({contentUpdate}) => {
  const {quill, quillRef} = useQuill({
    modules: {
      toolbar: ticketToolbar,
      counter: {
        container: '#counter',
        unit: 'word',
        max: 1000
      }
    },
    theme: 'snow',
    placeholder: 'Write feedback...'
  });

  return (
    <QuillEditor quill={quill} quillRef={quillRef} contentUpdate={() => contentUpdate(quill.getText())}
                 QContainer={TicketQuill}/>
  )
}

const TicketReport = ({}) => {
  const root = new Root(useDispatch())
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState('')
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {pageId} = usePageScope()

  const contentUpdate = (props) => {
    setContent(props)
  }

  const submit = () => {
    setLoading(true);
    return API.put(AdminService.base + "/support/ticket", {
      content,
      pageId,
      location: window.location.href,
      state: {}
    }).then(() => {
      setError(null);
      setContent('');
      setIsOpen(false);
      root.setMessage({
        type: 'success',
        message: 'Ticket successfully submitted'
      })
    })
      .catch(err => {
        setError(err)
      })
      .finally(() => setLoading(false));
  }


  return (
    <div>
      <Modal size={'small'} open={isOpen} closeIcon onClose={() => setIsOpen(false)}>
        <ModalLoader active={loading}/>
        <Modal.Header>Contact Everloom</Modal.Header>
        <Modal.Content style={{minHeight: 330}}>
          {!!error && <Message negative>{error}</Message>}

          <h4>Use this form to share feedback, make feature requests, or report any bugs.</h4>
          <h5 style={{marginTop: 0, marginBottom: 20}}>We'll follow up with you as soon as we can.</h5>

          {isOpen && <TicketQuillContainer contentUpdate={contentUpdate}/>}

        </Modal.Content>
        <Modal.Actions>
          <Button primary disabled={content.length < 1} onClick={submit}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>

      <Fab onClick={() => setIsOpen(true)}>
        <img style={{width: 30}} src={ChatBubble} />
      </Fab>
    </div>
  );
};

export default TicketReport;

const ticketToolbar = [
  [{'header': [2, 3, false]}],
  ['bold', 'italic', 'underline'],
  ['link', 'blockquote'],
  [{list: 'ordered'}, {list: 'bullet'}],
  [{'indent': '-1'}, {'indent': '+1'}],
  ['clean']
]

const TicketQuill = styled.div`
  height: 250px;

  .ql-editor {
    height: 200px;
  }

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    max-height: 35vh !important;
  }

  .ql-container {
    height: unset !important;
  }

  .ql-snow .ql-editor img {
    max-width: 340px !important;
    max-height: 200px !important;

    float: left;
    margin-right: 14px;
  }
`;
