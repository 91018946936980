import React from 'react'
import Screen from "../../layout/Screen.js";
import {LISTS, OtherTypes} from "../../config/options.map.js";
import {useNotificationsData, usePageScope} from "../../store/hooks/page.hooks.js";
import {Link, useHistory} from "react-router-dom";
import {PagesService} from "../../services/pages.js";
import customTheme from "../../styles/theme.js";
import styled from "styled-components";
import moment from "moment";
import {CONSTANTS} from "../../config/constants.js";
import HeirloomImage from "../../components/common/HeirloomImage.js";
import {FlexBox} from "../../styles/Containers.js";
import {Button, Card} from "semantic-ui-react";
import NotificationService from "../../services/notifications.js";
import Biography from "../../store/reducers/biography/actions.js";
import {useDispatch} from "react-redux";
import FamilyImage from "../../assets/icons/family.svg";
import useWindowSize from "../../utils/dimensions.js";
import DemoFamilyPage from "./demo.js";

const FamilyPageReduced = ({}) => {
  const {width} = useWindowSize()
  const {page, relatives, pageId} = usePageScope()
  const notifs = useNotificationsData()
  const history = useHistory()
  const briefDispatch = new Biography(useDispatch())
  const small = width <= CONSTANTS.hideSearch;
  const isDemo = window.origin.includes('demo');
  const myFamilyTypes = page ? (LISTS.data.relationTypes || []).filter(o => page.relations[o]?.length > 0) : []

  if (isDemo) {
    return <DemoFamilyPage />
  }

  const getRelation = (prop) => {
    if (prop.relation && prop.relation.relationshipType === "divorced") {
      return `${prop.firstName} ${prop.lastName}${prop.relation.to ? ` (${moment(prop.relation.from, CONSTANTS.dateFormat).format("YYYY")}-${moment(prop.relation.to, CONSTANTS.dateFormat).format("YYYY")})` : ""}`
    } else {
      return `${prop.firstName} ${prop.lastName}`
    }
  }

  const findRelationshipCounterpart = (relationship) => {
    for (const type in OtherTypes) {
      const desc = OtherTypes[type].find(o => o.value === relationship);
      if (desc) {
        return LISTS.data.relativeAsDescriptor[desc.alt]
      }
    }
  }

  const acceptAdd = (rec) => {
    NotificationService.mediumActionData = {
      INVITE_FAMILY: rec
    }
    history.push(`/${pageId}/family?action=INVITE_FAMILY`)
    briefDispatch.setEditBrief('family')
  }

  const recommendations = (notifs?.list || []).filter(o => {
    if (o.type === 'INVITE_FAMILY') {
      for (const type in relatives) {
        for (const person of relatives[type]) {
          if (o.data.pageId === person.pageCode) {
            return false;
          }
        }
      }
      return true;
    }
    return false
  });

  const emptyContent = () => {
    return (
      <FlexBox align={'center'} justify={'center'} direction={'column'}>
        <img src={FamilyImage} style={{marginTop: 20}}/>

        <FlexBox align={"center"} justify={"center"} direction={"column"} style={{maxWidth: 800, marginTop: 30}}>
          <h2>
            Everloom is for Families
          </h2>
          <p style={{fontSize: 16, textAlign: 'center', lineHeight: 2}}>
            Add immediate family members such as parents, siblings, spouses, and children.
            When you add a family member you can add them by name or by linking to their Everloom.
          </p>
          <p style={{fontSize: 16, textAlign: 'center'}}>
            Learn more about adding relatives in our <a target={'_blank'} href={process.env.REACT_APP_LANDING_URL + '/help'}>Help
            Center</a>.
          </p>
        </FlexBox>
        <Button style={{marginTop: 20}} onClick={() => briefDispatch.setEditBrief('family')} primary>Add Family</Button>
      </FlexBox>
    )
  }

  return (
    <>
      {myFamilyTypes.length > 0 && <div style={{textAlign: 'right', marginBottom: 20}}>
        <Button onClick={() => briefDispatch.setEditBrief('family')} primary>Manage Family</Button>
      </div>}
      {recommendations.length > 0 && <Screen style={{
        backgroundColor: "white",
        padding: 20,
        borderRadius: 4,
        height: 200,
        minHeight: 'unset',
        marginBottom: 20
      }}>
        <FlexBox justify={'space-between'} style={{marginBottom: 20}}>
          <h3>Family Connections</h3>
        </FlexBox>

        <div>
          {recommendations.map(o => (
            <RecommendationCard>
              <FlexBox align={'center'} justify={'space-between'}>
                <div>
                  {o.message}.
                  <br/>
                  Add {o.data.inviterName} as a {findRelationshipCounterpart(o.data.relationship)}?
                </div>
                <div>
                  <Button secondary onClick={() => acceptAdd(o)}>Accept</Button>
                </div>
              </FlexBox>
            </RecommendationCard>
          ))
          }
        </div>

      </Screen>}

      {myFamilyTypes.length > 0 ? <Screen style={{backgroundColor: "white", padding: 20, borderRadius: 4}}>
        {myFamilyTypes.map((relation, i) => (
          <RelativeSection>
            <h3 style={small ? {textAlign: 'center'} : {}}>{relation}</h3>
            <FlexBox className={"relative-content"} justify={small ? 'center' : 'flex-start'}>
              {
                page.relations[relation].map((relative, i) => (
                  <div style={{width: 120, marginRight: small ? 12 : 20}}>
                    <HeirloomImage src={relative.image} style={{width: 120, borderRadius: 6}}/>
                    <div style={{textAlign: 'center', marginTop: 8}}>
                      {relative.pageCode ? (
                        <Link to={PagesService.navigatePageRoute(relative.pageCode)} style={{
                          color: customTheme.primary
                        }}>{getRelation(relative)}</Link>
                      ) : getRelation(relative)
                      }
                    </div>
                  </div>
                ))
              }
            </FlexBox>
          </RelativeSection>
        ))
        }
      </Screen> : emptyContent()}
    </>
  );
};

export default FamilyPageReduced;

const RelativeSection = styled.div`
  margin-bottom: 40px;

  h3 {
    text-transform: capitalize;
  }

  .relative-content {
    font-size: 11px;
    margin-bottom: 20px;
  }


  a:hover {
    color: ${props => props.theme.quaternary} !important;
  }
`
const RecommendationCard = styled(Card)`
  width: 100% !important;
  margin-top: 12px !important;
  min-height: 60px !important;
  justify-content: center;
  padding: 6px 12px 6px 12px !important;
`
