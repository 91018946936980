import React, {useEffect, useState} from "react"
import {Dropdown, Menu} from "semantic-ui-react";
import styled from "styled-components";
import Biography from "../../store/reducers/biography/actions.js";
import {useDispatch} from "react-redux";
import {sortByIndex} from "../../utils/sortIndex.js";
import PermissionsService from "../../services/permissions.js";
import {VisibleViewTypes} from "../../components/common/GalleryList.js";
import MediaService from "../../services/media.js";
import {CustomGalleryElement, FlexBox, IconImage, OuterGalleryContainer} from "../../styles/Containers.js";
import Media from "../../store/reducers/media/actions.js";
import UploadGalleryImage from "../../components/ImageViewer/UploadGalleryImage.js";
import {useOnboarding, usePageScope, useTaggedGallery} from "../../store/hooks/page.hooks.js";
import Root from "../../store/reducers/root/actions.js";
import GalleryImage from '../../assets/icons/gallery.svg'
import ChapterImage from '../../assets/icons/chapter.svg'
import SaveImage from '../../assets/icons/save.svg'
import PhotoImage from '../../assets/icons/image.svg'
import TagImage from '../../assets/icons/subscribe.svg'
import {CONSTANTS} from "../../config/constants.js";
import useWindowSize from "../../utils/dimensions.js";

const Gallery = ({}) => {
  const {width} = useWindowSize()
  const dispatch = useDispatch()
  const MediaReducer = new Media(useDispatch())
  const BiographyDispatch = new Biography(dispatch)
  const {page, pageId, permissions} = usePageScope()
  const [activeItem, setActiveItem] = useState("Overview")
  const chaptersLoading = page?.chaptersLoading;
  const chapterError = page?.chaptersError;
  const canEdit = PermissionsService.checkPermissions(permissions, "canEdit")
  const {data: taggedGallery} = useTaggedGallery(!page?.isDemo)
  const onboarding = useOnboarding()
  const RootDispatch = new Root(useDispatch())

  useEffect(() => {
    if (!onboarding.gallery) {
      RootDispatch.setShowNux('gallery')
    }
  }, [])

  useEffect(() => {
    if (chapterError === undefined && !chaptersLoading && !page?.isDemo) {
      BiographyDispatch.fetchChapters(pageId);
    }
  }, [page])

  const emptyContent = () => (
    <FlexBox align={'center'} justify={'center'} direction={'column'}>
      <img src={GalleryImage} style={{marginTop: 20}}/>

      <FlexBox align={"center"} justify={"center"} direction={"column"} style={{maxWidth: 800, marginTop: 30}}>
        {canEdit ? <>
          <h2>
            Preserve and View Photos, Videos, and Audio Files
          </h2>
          <p style={{fontSize: 16, textAlign: 'center', lineHeight: 2}}>
            The Gallery contains all the media you add to an Everloom.
          </p>
          <p style={{fontSize: 16, textAlign: 'center'}}>
            Learn more about the Gallery in our <a target={'_blank'} href={process.env.REACT_APP_LANDING_URL + '/help'}>Help
            Center</a>.
          </p>
        </> : <p>This person has no media available</p>}
      </FlexBox>
    </FlexBox>
  )

  const chapterGalleries = sortByIndex(page.chapters || []).filter(o => !!o.gallery?.length).map(o => ({
    chapterTitle: o.chapterTitle,
    gallery: o.gallery,
    Location: o.gallery[0].Location
  }))
  const small = width <= CONSTANTS.hideSearch;

  const dropMenuProps = {
    onClick: (_, {value}) => {
      if (value === 'Chapters') {
        setActiveItem(value)
      } else if (value === 'Saved') {
        MediaReducer.setViewGallery((page.gallery || []).filter(o => o.from === 'saved'))
      } else if (value === 'Tagged') {
        MediaReducer.setViewGallery((taggedGallery || []).map(o => ({...o, Location: o.location})))
      } else {
        MediaReducer.setViewGallery(page.gallery || [])
      }
    }
  }

  return (
    <div>
      {!small ? <SecondNav pointing secondary>
        <Menu.Item
          name='Chapters'
          icon={<IconImage src={ChapterImage}/>}
          active={activeItem === "Chapters"}
          onClick={() => setActiveItem("Chapters")}
        />
        <Menu.Item
          name='Saved'
          icon={<IconImage src={SaveImage}/>}
          active={activeItem === "Saved"}
          onClick={() => MediaReducer.setViewGallery((page.gallery || []).filter(o => o.from === 'saved'))}
        />
        <Menu.Item
          name='Tagged'
          icon={<IconImage src={TagImage}/>}
          active={activeItem === "Tagged"}
          onClick={() => MediaReducer.setViewGallery((taggedGallery || []).map(o => ({...o, Location: o.location})))}
        />
        <Menu.Item
          name='All Media'
          icon={<IconImage src={PhotoImage}/>}
          active={activeItem === "All Media"}
          onClick={() => MediaReducer.setViewGallery(page.gallery || [])}
        />
        {canEdit && <Menu.Menu position='right'>
          <Menu.Item style={{padding: '0 0 6px 0'}}>
            <UploadGalleryImage gallery={page.gallery} selectableState={false}/>
          </Menu.Item>
        </Menu.Menu>}
      </SecondNav> : (
        <FlexBox justify={'space-between'} align={'center'} style={{ marginBottom: 20}}>
          <Dropdown style={{minWidth: 100}} text={activeItem}>
            <Dropdown.Menu>
              <Dropdown.Item {...dropMenuProps} text='Chapters' value={'Chapters'}/>
              <Dropdown.Item {...dropMenuProps} text='Saved' value={'Saved'}/>
              <Dropdown.Item {...dropMenuProps} text='All Media' value={'All Media'}/>
              <Dropdown.Item {...dropMenuProps} text='Tagged' value={'Tagged'}/>
            </Dropdown.Menu>
          </Dropdown>

            <UploadGalleryImage fab gallery={page.gallery} selectableState={false}/>
        </FlexBox>
      )}

      {chapterGalleries.length === 0 && emptyContent()}

      <OuterGalleryContainer small={small}>
        {chapterGalleries.map((o, i) => {
          const extension = MediaService.getType(o)
          return (
            <CustomGalleryElement style={{cursor: "pointer", marginRight: 20, marginBottom: 60}} key={"gall-" + i}
                                  onClick={() => MediaReducer.setViewGallery(o.gallery)}>
              <VisibleViewTypes data={o} extension={extension} clicked={() => null}/>
              <div style={{textAlign: 'center', marginTop: 4, fontWeight: 'bold'}}>{o.chapterTitle}</div>
            </CustomGalleryElement>
          )
        })
        }
      </OuterGalleryContainer>
    </div>
  )
}

const SecondNav = styled(Menu)`
  .active.item {
    border-color: ${props => props.theme.primary} !important;
  }

`

export default Gallery
