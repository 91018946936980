import {useMutation, useQuery, useQueryClient} from "react-query";
import PermissionsService from "../../services/permissions.js";
import Root from "../reducers/root/actions.js";
import {useDispatch} from "react-redux";

export const usePagePermissions = (pageId, page) => {
  return useQuery(["user", "permissions", pageId], () =>
    PermissionsService.getPermissions(pageId).then(results => {
      return {
        permissions: results.pagePermissions,
        permissionsRequests: results.requests,
        permissionsPending: results.pending,
        newAccess: results.newAccess
      }
    }), {
    placeholderData: {},
    enabled: !!pageId && !!page && !page.isDemo,
    refetchOnWindowFocus: true,
    refetchOnMount: 'always',
    staleTime: Infinity
  })
}

export const useAlterPermissions = (pageId, setLoading) => {
  const RootDispatch = new Root(useDispatch())
  const queryClient = useQueryClient()
  return useMutation(({type, data}) => {
    if (type === 'update') {
      return PermissionsService.addPermissionToPage(data, pageId)
    }
    if (type === 'revoke') {
      return PermissionsService.revokePermissions(data, pageId)
    }
    if (type === 'accept') {
      return PermissionsService.acceptInvite(data)
    }
    if (type === 'decline') {
      return PermissionsService.declineInvite(data)
    }
  }, {
    onMutate: () => {
      if (setLoading) {
        setLoading(true)
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(["user", "permissions", pageId])
    },
    onError: (err) => {
      RootDispatch.setMessage('Error updating permissions')
    },
    onSettled: () => {
      if (setLoading) {
        setLoading(false)
      }
    }
  })
}
