import React, {useEffect, useState} from "react"
import {Dropdown, Menu} from "semantic-ui-react"
import Permissions from "../Permissions"
import BeneficiarySection from "./BeneficiarySection"
import styled from "styled-components"
import ManagePageSettings from "./ManagePageSettings"
import {useOnboarding, usePageScope} from "../../../store/hooks/page.hooks.js";
import Root from "../../../store/reducers/root/actions.js";
import {useDispatch} from "react-redux";
import AfterLife from '../../../assets/icons/after-life.svg'
import Settings from '../../../assets/icons/settings.svg'
import PermissionIcon from '../../../assets/icons/permissions.svg'
import Website from '../../../assets/icons/website.svg'
import Info from '../../../assets/icons/info.svg'
import {IconImage} from "../../../styles/Containers.js";
import useWindowSize from "../../../utils/dimensions.js";
import {CONSTANTS} from "../../../config/constants.js";
import SharableEverloom from "./SharableEverloom.js"


const PageSettings = ({}) => {
  const {width} = useWindowSize()
  const small = width <= CONSTANTS.hideSearch;

  const {isMine} = usePageScope()
  const [activeItem, setActiveItem] = useState("Overview")
  const RootDispatch = new Root(useDispatch())
  const onboarding = useOnboarding()

  useEffect(() => {
    if (!onboarding.manage) {
      RootDispatch.setShowNux('manage')
    }
  }, [])

  const dropMenuProps =  {
    onClick: (_, {value}) => setActiveItem(value)
  }

  return (
    <div>
      {small ? (
        <Dropdown style={{minWidth: 100}} text={activeItem}>
          <Dropdown.Menu>
            <Dropdown.Item {...dropMenuProps} text='Overview' value={'Overview'}/>
            <Dropdown.Item {...dropMenuProps} text='Permissions' value={'Permissions'}/>
            <Dropdown.Item {...dropMenuProps} text='After Life' value={'After Life'}/>
            {isMine && <Dropdown.Item {...dropMenuProps} text='Settings' value={'Settings'}/>}
          </Dropdown.Menu>
        </Dropdown>
      ) : <SecondNav pointing secondary>
        <Menu.Item
          name='Overview'
          icon={<IconImage src={Website}/>}
          active={activeItem === "Overview"}
          onClick={() => setActiveItem("Overview")}
        />
        <Menu.Item
          name='Permissions'
          icon={<IconImage src={PermissionIcon}/>}
          active={activeItem === "Permissions"}
          onClick={() => setActiveItem("Permissions")}
        />
        <Menu.Item
          name='After Life'
          icon={<IconImage src={AfterLife}/>}
          active={activeItem === "After Life"}
          onClick={() => setActiveItem("After Life")}
        />
        {isMine && <Menu.Item
          name='Settings'
          icon={<IconImage src={Settings}/>}
          active={activeItem === "Settings"}
          onClick={() => setActiveItem("Settings")}
        />}
        <Menu.Menu position='right'>
          <Menu.Item
            name=''
            icon={<img style={{width: 20, height: 20}} src={Info}/>}
            active={activeItem === "Settings"}
            onClick={() => {
              RootDispatch.setShowNux('manage')
            }}
          />
        </Menu.Menu>
      </SecondNav>}


			{(activeItem === 'Permissions' || activeItem === 'Overview') && <Permissions/>}

      {(activeItem === 'After Life' || activeItem === 'Overview') && <BeneficiarySection/>}

      {(activeItem === 'Settings' || activeItem === 'Overview') && <ManagePageSettings/>}

    </div>
  )
}

export default PageSettings

const SecondNav = styled(Menu)`
  .active.item {
    border-color: ${props => props.theme.primary} !important;
  }

`
