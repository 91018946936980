import React, {useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {NoneText, WhiteIconButton} from "../../styles/Text.js"
import ErrorComponent from "../../components/messages/Error.js"
import Screen from "../../layout/Screen"
import FormDropDown from "../../components/common/FormDropDown.js"
import {LISTS, mappedOptions} from "../../config/options.map.js"
import {FlexBox} from "../../styles/Containers.js"
import {formStyles} from "../../styles/Forms.js"
import FormInput from "../../components/common/FormInput.js"
import styled from "styled-components"
import {Button, Confirm, Divider, Header, Icon, Message, Modal, Progress} from "semantic-ui-react"
import {Prompt, useHistory} from "react-router-dom"
import BiographyService from "../../services/biography.js"
import Auth from "../../store/reducers/auth/actions.js"
import findGetParam from "../../utils/findGetParam.js"
import Biography from "../../store/reducers/biography/actions.js"
import {useQuill} from "react-quilljs"
import "quill/dist/quill.snow.css"
import NewGallery from "./NewGallery.js"
import TagManager from "./ChapterTags.js"
import PermissionsService from "../../services/permissions.js"
import Root from "../../store/reducers/root/actions.js"
import useWindowSize from "../../utils/dimensions.js"
import Quill from "quill"
import QuillWordcounter from "../../utils/quillWordCounter.js"
import TipsModal from "../../components/common/TipsModal.js"
import calculateQuillLength from "../../utils/countQuill.js"
import {lang} from "../../config/languages"
import {CONSTANTS} from "../../config/constants"
import AddChapterMedia from "./AddChapterMedia.js";
import {CustomToggle, SelectableSegment} from "../../styles/Semantic.js";
import {useOnboarding, usePageScope} from "../../store/hooks/page.hooks.js";
import firebase from "firebase";
import {StatusButton} from "./SortChapters.js";
import {getUpgradeError} from "../../utils/checkUpgradeError.js";
import DeleteChapter from "../../components/DeleteChapter.js";


Quill.register("modules/counter", QuillWordcounter)

// const ImageTag = Quill.import('formats/image');
// ImageTag.onClick = () => alert(1)   // Quill uses <strong> by default
// Quill.register(ImageTag, true);


const AddChapter = ({}) => {
  const dispatch = useDispatch()
  const BiographyDispatch = new Biography(dispatch)
  const rootDispatch = new Root(dispatch)
  const history = useHistory()
  const [gallery, updateGallery] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [chapterTitle, setChapterTitle] = useState(null)
  const [confirmSubmit, setConfirmSubmit] = useState(null)
  const [uploadingState, setUploadingState] = useState("NONE")
  const [chapterTags, setChapterTags] = useState([])
  const [openTags, setOpenTags] = useState(false)
  const [promptForNewTitleName, setPromptForNewTitleName] = useState(null)
  const [deleteChapter, setDeleteChapter] = useState(null)
  const [imageToBeAdded, setImageToBeAdded] = useState(null)
  const user = useSelector(Auth.user)
  const {page, permissions, pageId, activePlan, basePath} = usePageScope()
  const {width} = useWindowSize()
  const counterRef = useRef()
  const onboarding = useOnboarding()
  const edit = PermissionsService.checkPermissions(permissions, "canEdit")
  const isBeneficiary = PermissionsService.checkPermissions(permissions, "isOnlyBeneficiary")
  const [tips, setTips] = useState([])
  const [viewTips, setViewTips] = useState(JSON.parse(localStorage.getItem("hideTips")))
  const [fullscreen, setFullscreen] = useState(false)
  const [addMedia, setAddMedia] = useState(null);
  const [chapterDataLoaded, setChapterDataLoaded] = useState(false)
  const [chapterStatus, setCurrentStatus] = useState('DRAFT')
  const validChapterTypes = [...mappedOptions("chapterData")
    .filter(o => o.active && (isBeneficiary ? o.id === LISTS.data.deathChapterId : true))]
    .sort((a, b) => a.index < b.index ? -1 : 1)

  if (page && permissions && (!edit && !isBeneficiary)) {
    history.push("/")
  }

  if (validChapterTypes.length === 1 && !chapterTitle) {
    setChapterTitle({
      ...validChapterTypes[0],
      display: validChapterTypes[0].text
    })
  }

  const hideExtras = isBeneficiary && !edit
  const isEditing = !!findGetParam("chapterId", true)

  const {quill, quillRef} = useQuill({
    modules: {
      toolbar: [
        [{"header": [2, 3, false]}],
        ["bold", "italic", "underline"],
        ["link", "image"],
        [{list: "ordered"}, {list: "bullet"}],
        [{"indent": "-1"}, {"indent": "+1"}],
        ["clean"]
      ],
      counter: {
        container: "#counter",
        unit: "word",
        max: activePlan.id === 0 ? activePlan.chapterWordCount : null
      }
    },
    theme: "snow",
    placeholder: "Begin writing your chapter"
  })

  if (quill) {
    const toolbar = quill.getModule("toolbar")
    toolbar.addHandler("image", () => setAddMedia('CHAPTER'))
  }

  useEffect(() => {
    if (!onboarding.chapters) {
      rootDispatch.setShowNux('chapters')
    }
    document.getElementById("body-content")?.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (page && quill) {
      const operations = fetchChapterData()
      if (operations) {
        firebase.analytics().logEvent("editing_chapter");
        quill.setContents(operations)
      } else {
        firebase.analytics().logEvent("creating_chapter");
      }
    }
  }, [quill, page])

  const formGallery = (chapter) => {
    const gal = chapter.gallery || []
    const pageGal = page.gallery || []
    return gal.map(o => {
      const foundData = pageGal.find(p => p.Location === o.Location)
      return foundData || {Location: o.Location}
    })
  }

  const onDeleteDone = () => {
    goBack();
  }

  const goBack = () => history.push(`${basePath}/${pageId}/chapters`)

  const fetchChapterData = () => {
    const chapterId = findGetParam("chapterId", true)
    if (chapterId && page) {
      const chapter = page.chapters?.find(o => o.chapterId === chapterId)
      if ((chapter && edit) || (chapter && (isBeneficiary && chapter.chapterTitleType === LISTS.data.deathChapterId))) {
        updateGallery(formGallery(chapter))
        setChapterTitle({
          value: chapter.chapterTitleType,
          display: chapter.chapterTitle
        })
        const chapterFound = validChapterTypes.find(o => o.value === chapter.chapterTitleType)
        setTips(chapterFound?.tips || [])
        setChapterTags(chapter.tags || [])
        setChapterDataLoaded(true)
        setCurrentStatus(chapter.status)
        return chapter.operations
      } else if (chapterId) {
        history.push(window.location.pathname)
      } else {
        setChapterDataLoaded(true)
      }
    } else {
      setChapterDataLoaded(true)
    }
  }

  const validateSubmission = () => {
    const ops = quill.getContents()
    if (calculateQuillLength(ops) === 0) {
      setError("Document must not be empty")
      return false
    }
    if (!chapterTitle || !chapterTitle.display) {
      setError("Chapter title must be set");
      return false
    }
    setError(null)
    return true
  }

  const removeIndexFromGallery = (i) => {
    const gal = [...gallery]
    gal.splice(i, 1)
    updateGallery(gal)
  }

  const getRefinedContents = () => {
    const contents = quill.getContents()
    return contents.ops
  }

  const submitChapter = () => {
    if (!validateSubmission()) {
      return
    }
    setLoading(true)
    const ops = getRefinedContents()
    quill.setContents(ops)
    const chapterId = findGetParam("chapterId", true)
    firebase.analytics().logEvent("chapter_submitted", {
      draft: confirmSubmit === 'DRAFT'
    });
    BiographyService.manageChapter(pageId, {
      chapterTitle: chapterTitle.display,
      chapterTitleType: chapterTitle.value,
      associatedId: user.uid,
      operations: ops,
      chapterId,
      gallery,
      tags: chapterTags.filter(o => !!o.name),
      status: confirmSubmit
    }).then(res => {
      BiographyDispatch.fetchChapters(pageId).then(chaptersFetched => {
        setConfirmSubmit(null)
        rootDispatch.setMessage({
          type: "success",
          message: confirmSubmit === "DRAFT" ? "Your draft has been saved." : "Your page has been published."
        })
        history.push(`/${pageId}/chapters`)
        if (chaptersFetched.length === 1) {
          if (!onboarding.shareEverloom && confirmSubmit !== "DRAFT") {
            rootDispatch.setShowNux('shareEverloom')
          }
        }
      })
    }).catch(err => {
      const upgradeError = getUpgradeError(err);
      if (!!upgradeError) {
        rootDispatch.promptPaywall(upgradeError)
      } else {
        setError(err)
      }
      setUploadingState("NONE")
      setLoading(false)
      setConfirmSubmit(null)
    }).finally(() => {
      window.scrollTo(0, 0)
    })
  }

  const runSave = () => {
    if (activePlan.id === 0) {
			if (!isNaN(+counterRef.current.nonce) && +counterRef.current.nonce <= activePlan.chapterWordCount) {
				setConfirmSubmit(chapterStatus)
      } else {
        rootDispatch.promptPaywall("CHAPTER_WORD_COUNT")
      }
    } else {
      setConfirmSubmit(chapterStatus)
    }
  }

  const addLibraryImage = (section) => {
    if (section === "DOCUMENT") {
      for (const img of imageToBeAdded) {
        quill.insertEmbed(quill.getSelection()?.index || quill.getContents().length, "image", img.Location)
      }
    } else if (section === "GALLERY") {
      updateGallery([...gallery, ...imageToBeAdded])
    } else {
      for (const img of imageToBeAdded) {
        quill.insertEmbed(quill.getSelection()?.index || quill.getContents().length, "image", img.Location)
      }
      updateGallery([...gallery, ...imageToBeAdded])
    }

    setImageToBeAdded(null)
  }

  const updateChapterType = (result) => {
    if (calculateQuillLength(quill.getContents()) > 0 && (result.sections || []).length > 0) {
      setPromptForNewTitleName({
        ...result,
        display: validChapterTypes.find(o => o.id === result.id)?.text || ""
      })
    } else if ((result.sections || []).length > 0) {
      updateChapterTypeContent(result)
    } else {
      const data = result || promptForNewTitleName
      const {tips} = data
      setChapterTitle({
        ...data,
        display: data.text
      })
      setTips(tips || [])
    }
  }

  const updateChapterTypeContent = (result = null) => {
    const data = result || promptForNewTitleName
    const {tips, sections} = data
    setChapterTitle({
      ...data,
      display: data.text
    })
    setTips(tips || [])
    const delta = []
    for (const section of (sections || [])) {
      delta.push({insert: section.header})
      delta.push({insert: "\n", attributes: {header: 2}})
      delta.push({insert: section.subHeader})
      delta.push({insert: "\n \n"})
    }
    quill.setContents(delta)
    setPromptForNewTitleName(null)
  }

  const clearTag = (o) => {
    const tags = [...chapterTags]
    const foundIndex = tags.findIndex(f => f.pageCode === o.pageCode || o.name === f.name)
    if (foundIndex > -1) {
      tags.splice(foundIndex, 1)
    }
    setChapterTags(tags)
  }

  const SubmitComponents = () => (
    <FlexBox direction={width > 831 ? "row" : "column"}>
      <Prompt
        when={!loading && chapterDataLoaded && !deleteChapter}
        message='You have unsaved changes, are you sure you want to leave?'
      />
      <FlexBox justify='flex-end'>
        <Button style={{width: 140, marginTop: 10, marginRight: 10}} onClick={runSave}
                loading={loading} primary
                disabled={loading || !chapterTitle?.value || !chapterTitle?.display}>Save</Button>
      </FlexBox>
    </FlexBox>
  )


  if (width < CONSTANTS.toSmallToEdit) {
    return (
      <FlexBox justify={"space-between"}>
        <h2 style={{...styles.sectionMargin, textAlign: "center", width: "100%"}}>
          Chapters
        </h2>

        <NoneText style={{textAlign: "center"}}>
          The process of creating your Everloom is best experienced on desktop or tablet.
          <br/>
          Please Log in on one of those.
          <br/>
          <br/>
          We're doing our best to bring you the best mobile experience possible.
        </NoneText>
      </FlexBox>
    )
  }


  const buttonProps = {
    style: {margin: 2}
  }

  return (
    <Screen style={{maxWidth: "calc(100% - 100px)", backgroundColor: 'white', borderRadius: 8, padding: 20}}>
      <TagManager currentTags={chapterTags} setOpenTags={setOpenTags} openTags={openTags}
                  setTags={setChapterTags}/>

      <AddChapterMedia isOpen={addMedia} onClose={images => {
        if (images) {
          if (addMedia === 'CHAPTER') {
            for (const img of images) {
              quill.insertEmbed(quill.getSelection()?.index || quill.getContents().length, "image", img.Location)
            }
            updateGallery([...gallery, ...images])
          } else {
            updateGallery([...gallery, ...images])
          }
        }
        setAddMedia(null);
      }}/>

      <Modal closeIcon open={!!imageToBeAdded} onClose={() => setImageToBeAdded(null)} size={"mini"}>
        <Modal.Content>
          <SelectableSegment onClick={() => addLibraryImage("DOCUMENT")}>
            <Header>Add to Chapter</Header>
            <p>
              Add image(s) to your chapter at the position of your cursor.
            </p>
          </SelectableSegment>
          <SelectableSegment onClick={() => addLibraryImage("GALLERY")}>
            <Header>Add to Gallery</Header>
            <p>
              Add image(s) to your chapters gallery.
            </p>
          </SelectableSegment>
          <Divider horizontal>Or</Divider>
          <Button primary {...buttonProps} onClick={() => addLibraryImage("BOTH")} fluid>Add to Both</Button>
        </Modal.Content>
      </Modal>

      <Confirm
        open={!!promptForNewTitleName}
        size={"medium"}
        cancelButton={"No, keep document"}
        confirmButton={"Yes, replace document"}
        content={"Would you like to clear your current document and replace it with this chapter type's guidance text?"}
        onCancel={() => {
          setChapterTitle(promptForNewTitleName)
          setPromptForNewTitleName(null)
        }}
        onConfirm={() => updateChapterTypeContent()}
      />

      <ErrorComponent header="Failed to add Everloom" message={error}/>

      <Modal onClose={() => setConfirmSubmit(null)} open={confirmSubmit}>

        <Modal.Header>{confirmSubmit === "DRAFT" ? "Save Draft" : "Publish Chapter"}</Modal.Header>
        <Modal.Content>
          {!!error && <Message negative>{error}</Message>}
          {uploadingState === "NONE" &&
          <Modal.Description>
            {confirmSubmit === "DRAFT" ? <>
              Would you like to save a draft of this chapter?
              <br/>
              <br/>
              Once you press Save Draft all updates will be saved and this Chapter will only be visible to you and any
              other collaborators. No one else will be able to view it until you publish it.
              <br/>
              <br/>
              If this Chapter is currently published, saving it as a draft will unpublish it.
            </> : <>
              Would you like to publish this chapter? Once you press Publish this chapter will be visible to all people
              who have permission to view your Chapters.
            </>}
          </Modal.Description>
          }
          {uploadingState === "CHAPTER_UPLOAD" &&
          <Modal.Description>
            <div style={{textAlign: "center"}}>Uploading chapter content...</div>
            <Progress style={{maxWidth: 400, margin: "auto", marginTop: 20}} percent={60}
                      indicating/>
          </Modal.Description>
          }
        </Modal.Content>

        <Modal.Actions>
          {uploadingState === "NONE" &&
          <Button color='black' onClick={() => setConfirmSubmit(null)}>
            Cancel
          </Button>
          }
          <WhiteIconButton
            disabled={loading}
            loading={loading}
            content={confirmSubmit === "DRAFT" ? "Save Draft" : "Publish"}
            labelPosition='right'
            icon='checkmark'
            positive
            onClick={submitChapter}
          />
        </Modal.Actions>
      </Modal>

      <FlexBox align={'center'} justify={'space-between'} flex={1} style={{marginBottom: 20}}>
        <FlexBox align={'center'} flex={1}>
          <Button style={{width: 100}} primary disabled={loading || !chapterTitle?.value || !chapterTitle?.display}
                  onClick={runSave}>Save</Button>
          {isEditing && <Button style={{width: 100}} basic color={'red'}
                                onClick={() => setDeleteChapter({chapterId: findGetParam("chapterId", true)})}>Delete</Button>}
          <StatusButton style={{marginLeft: 20, top: -3, position: 'relative'}}>
            <CustomToggle checked={chapterStatus === 'PUBLISH'} disabled={!activePlan.canPublish} onChange={(_, v) => setCurrentStatus(v.checked ? 'PUBLISH' : 'DRAFT')}/>
            {chapterStatus === 'PUBLISH' ? <span className={"pub"}>Published</span> :
              <span className={"unpub"}>Draft</span>}
          </StatusButton>
        </FlexBox>
        <div style={{textDecoration: 'underline', cursor: 'pointer'}}
             onClick={goBack}>Back
        </div>
      </FlexBox>
      <DeleteChapter chapter={deleteChapter} onDone={onDeleteDone} onCancel={() => setDeleteChapter(null)}/>

      <FlexBox justify={"space-between"}>
        <div style={{maxWidth: "calc(100% - 60px)"}}>
          {!isEditing && <h4>{lang["chapterPrompt"]}</h4>}
          <FlexBox
            style={{
              ...styles.sectionMargin,
              marginLeft: -formStyles.inputSideMargin.marginLeft
            }}
            align={"center"}>
            {!isEditing &&
            <FormDropDown label={"Chapter Guide"}
                          options={validChapterTypes}
                          value={chapterTitle?.value}
                          onChange={(ev, props) => {
                            const result = validChapterTypes.find(o => o.value === props.value)
                            updateChapterType(result)
                            firebase.analytics().logEvent("chapter_guide_selected", {
                              draft: result.value
                            });
                          }}/>}
            {!!chapterTitle?.value &&
            <FormInput placeholder={"Chapter title"} value={chapterTitle?.display}
                       label={"Chapter Title"} readonly={hideExtras}
                       containerStyle={{display: "flex", alignItems: "flex-end"}}
                       onChange={(ev, props) => setChapterTitle({...chapterTitle, display: props.value})}/>}

          </FlexBox>
          {(!viewTips && tips.length > 0) &&
          <div onClick={() => {
            localStorage.setItem("viewTips", "true")
            firebase.analytics().logEvent("viewing_tips", {
              guide: chapterTitle?.value
            });
            setViewTips(true)
          }} style={{textDecoration: 'underline', marginTop: 20}}>Writing Prompts</div>}
        </div>
      </FlexBox>

      {viewTips && <TipsModal tips={tips} setViewTips={setViewTips}/>}

      <div style={{marginBottom: 20}}/>
      {/*uncomment to Add back tagging*/}
      {/*<FlexBox align={"center"}>*/}
      {/*	{!hideExtras && <AddTag onClick={() => setOpenTags(true)}>*/}
      {/*		<PricetagOutline/>*/}
      {/*	</AddTag>}*/}
      {/*	{(chapterTags.length === 0 && width > 600 && !hideExtras) ?*/}
      {/*		<NoneText style={{ paddingLeft: 10 }}>{lang.tagFamily}</NoneText> : ""}*/}
      {/*	<div style={{ marginLeft: 12 }}>*/}
      {/*		{chapterTags.map(o => <Pill style={{ color: o.pageCode ? "blue" : "black" }}*/}
      {/*																cleared={() => clearTag(o)}>{o.name}</Pill>)}*/}
      {/*	</div>*/}
      {/*</FlexBox>*/}


      {width >= 870 && <FlexBox align={"center"} justify={"flex-end"} direction={width < 640 ? "column" : "row"}
                                style={{
                                  marginBottom: 12,
                                  position: 'relative',
                                  top: 51,
                                  width: 230,
                                  marginLeft: 'auto'
                                }}>
        <div id={"counter"} ref={counterRef} style={{marginRight: 10}}/>
        <Button color={'white'} icon onClick={() => setFullscreen(true)}>
          Fullscreen
        </Button>
      </FlexBox>}
      <CustomEditor fullscreen={fullscreen} style={{backgroundColor: "white"}}>
        <div ref={quillRef}/>

        {fullscreen && <MinimizeButton icon onClick={() => setFullscreen(false)}>
          <Icon name='compress'/>
          Minimize
        </MinimizeButton>}
      </CustomEditor>

      {(!hideExtras && !fullscreen) && <NewGallery gallery={gallery} width={width} setAddMedia={setAddMedia}
                                                   removeImageIndex={removeIndexFromGallery}/>}

      <div style={{marginTop: 20, paddingBottom: 30}}>
        {SubmitComponents()}
      </div>
    </Screen>
  )
}

export default AddChapter

const styles = {
  sectionMargin: {
    marginTop: 34
  }
}

const AddTag = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 20px;
  background-color: ${props => props.theme.primary};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
    fill: white;
    color: white;
  }
`

export const SaveText = styled.div`
  opacity: ${props => props.view ? 1 : 0};
  transition: opacity 0.2s ease-in-out;
`

export const CustomEditor = styled.div`
  height: 40vh;

  .ql-editor {
    min-height: 32vh;
  }

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    max-height: 35.2vh !important;
  }

  .ql-snow .ql-editor img {
    max-width: 340px !important;
    max-height: 200px !important;

    float: left;
    margin-right: 14px;

    &:hover {
      cursor: grab;
    }

    &:active {
      cursor: grabbing;
    }
  }

  ${props => props.fullscreen && `
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		height: 100vh;

	.ql-toolbar.ql-snow + .ql-container.ql-snow {
		max-height: 95vh !important;
	}

	`}
`
const MinimizeButton = styled(Button)`
  position: fixed;
  top: 3px;
  right: 1px;

`
