import {
	INITIAL_STATE,
	SET_ERROR,
	SET_IMAGES,
	SET_LOADING,
	SET_SELECTED_IMAGE,
	SET_VIEWING_GALLERY,
	SET_VIEWING_IMAGE
} from "./initialState"
import { RESET_STATE } from "../common/reducer.class"

export const mediaReducer = (state = INITIAL_STATE, action) => {
	const { payload, type, meta } = action

	switch (type) {
		case SET_LOADING:
			return {
				...state,
				loading: payload
			}
		case SET_ERROR:
			return {
				...state,
				error: payload
			}
		case SET_IMAGES:
			return {
				...state,
				data: {
					...state.data,
					[payload.pageId]: payload.value
				}
			}
		case SET_VIEWING_GALLERY: {
			return {
				...state,
				selectedGallery: payload.gallery,
				selectable: payload.selectable
			}
		}
		case SET_SELECTED_IMAGE: {
			return {
				...state,
				selectedImageData: payload
			}
		}
		case SET_VIEWING_IMAGE:
			return {
				...state,
				viewingImage: payload
			}
		case RESET_STATE:
			return INITIAL_STATE
		default:
			return state
	}
}
