import React, {useEffect, useState} from 'react'
import {Button, Modal, Segment} from "semantic-ui-react";
import Edit from "@images/icons/edit.svg";
import {CloseOutline} from "react-ionicons";
import {useDispatch} from "react-redux";
import AdminService from "../services/admin.js";
import Root from "../store/reducers/root/actions.js";
import FormInput from "../components/common/FormInput.js";
import {CustomTextArea} from "../styles/Text.js";
import {ActivityDot, FlexBox} from "../styles/Containers.js";
import PositionClicker from "../components/common/PositionClicker.js";
import HeirloomImage from "../components/common/HeirloomImage.js";
import ModalLoader from "../components/common/ModalLoader.js";

const defaultHeader = {
  header: '',
  subHeader: '',
  active: false
}
const AddChapterSection = ({isOpen, close, editingData}) => {
  const [formData, setFormData] = useState({});
  const [editHeader, setEditHeader] = useState(null);
  const [editHeaderType, setEditHeaderType] = useState('');
  const [loading, setLoading] = useState(false);
  const root = new Root(useDispatch());

  const submitData = () => {
    setLoading(true);
    AdminService.submitChapterSection(formData).then(() => {
      close(true);
      root.setMessage({
        type: 'success',
        message: `Section ${!!editingData ? 'updated!' : 'created!'}`
      })
    }).catch(e => {
      root.setMessage({
        type: 'error',
        message: e
      })
    }).finally(() => setLoading(false))
  }

  useEffect(() => {
    if (!!editingData) {
      setFormData({...editingData})
    }
  }, [editingData])

  useEffect(() => {
    if (!isOpen) {
      setFormData({});
      setEditHeader(null);
    }
  }, [isOpen])

  const saveSectionEdit = () => {
    if (!editHeader.header) {
      setEditHeader(null);
      return
    }
    const newData = [...formData[editHeaderType] || []]
    if (typeof editHeader.index === 'number') {
      newData[editHeader.index] = editHeader;
    } else {
      newData.push({...editHeader});
    }

    setFormData({
      ...formData,
      [editHeaderType]: newData
    })
    setEditHeader(null);
  }

  const removeItem = (i, type) => {
    setEditHeader(null)
    const newData = [...formData[type] || []]
    newData.splice(i, 1)
    setFormData({
      ...formData,
      [type]: newData
    })
  }

  const toggleActive = (i, type) => {
    setEditHeader(null)
    const newData = [...formData[type] || []]
    newData[i].active = !newData[i].active
    setFormData({
      ...formData,
      [type]: newData
    })
  }

  const editing = (type) => {
    return (
      <div style={{marginTop: 12}}>
        {(editHeader && type === editHeaderType) &&
        <Segment>
          <FormInput
            onChange={(_, {value}) => setEditHeader({
              ...editHeader,
              header: value
            })}
            value={editHeader.header}
            fluid placeholder={'Header level 2'} formMargin={false}/>
          <CustomTextArea
            onChange={(_, {value}) => setEditHeader({
              ...editHeader,
              subHeader: value
            })}
            style={{marginTop: 6}} value={editHeader.subHeader} placeholder={'Sub-header content...'}/>
          <FlexBox style={{marginTop: 12}} justify={'flex-end'}>
            <Button primary onClick={saveSectionEdit}>Done</Button>
          </FlexBox>
        </Segment>
        }
        <Segment style={{marginTop: 12}}>
          <Button fluid onClick={() => {
            setEditHeaderType(type);
            setEditHeader({...defaultHeader})
          }}>Add Section</Button>
        </Segment>
      </div>
    )
  }

  const reorderValues = (dir, index) => {
    const sort = [...(formData['tips'] || [])];
    if (index === 0 && dir === 'up') {
      return;
    }
    if (index === sort.length - 1 && dir === 'down') {
      return;
    }
    const i1 = sort.findIndex(o => o.index === index);
    const i2 = sort.findIndex(o => o.index === (dir === 'down' ? index + 1 : index - 1));

    if (i1 === -1 || i2 === -1) {
      return;
    }

    sort[i1].index = (dir === 'down' ? index + 1 : index - 1);
    sort[i2].index = index;
    setFormData({
      ...formData,
      tips: sort
    })
  }

  const loadSections = (type) => {
    return (
      <div>
        {(formData[type] || []).sort((a, b) => a.index < b.index ? -1 : 1).map((o, i) => (
          <Segment>
            <FlexBox align={'center'}>
              <PositionClicker styles={{width: 100, flex: 0}} onClick={dir => reorderValues(dir, i)}/>
              <FlexBox justify={'space-between'} style={{marginLeft: 20}}>
                <div>
                  {o.header}{o.index}
                </div>
                <div>
                  <HeirloomImage style={{cursor: 'pointer'}}
                                 onClick={() => {
                                   setEditHeaderType(type)
                                   setEditHeader({
                                     ...o,
                                     index: i
                                   })
                                 }} src={Edit}/>
                  <CloseOutline style={{marginLeft: 10, cursor: 'pointer'}}
                                onClick={() => removeItem(i, type)}/>
                  <ActivityDot style={{marginLeft: 10}} active={o.active}
                               onClick={() => toggleActive(i, type)}/>
                </div>
              </FlexBox>
            </FlexBox>
          </Segment>
        ))
        }
      </div>
    )
  }

  return (
    <Modal open={isOpen} close={close}>
      <ModalLoader active={loading}/>
      <Modal.Header>
        Chapter Section
      </Modal.Header>

      <Modal.Content>
        <FormInput value={formData.chapterTitle} onChange={(_, {value}) => {
          setFormData({
            ...formData,
            chapterTitle: value
          })
        }} label={'Section title'} formMargin={false}/>
        <h3>Tips</h3>
        {loadSections('tips')}
        {editing('tips')}

        <h3>Pre set headers</h3>
        {loadSections('sections')}
        {editing('sections')}

      </Modal.Content>
      <Modal.Actions>

        <Button onClick={close}>
          Cancel
        </Button>
        <Button primary disabled={!formData.chapterTitle} onClick={() => submitData()}>
          Submit
        </Button>

      </Modal.Actions>
    </Modal>
  );
};

export default AddChapterSection;
