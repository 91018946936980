export const INITIAL_STATE = {
	loading: false,
	error: null,
	permissions: {},
	taggable: {},
	fetchingTaggable: false,
}

const SET_LOADING = "set_loading"
const SET_ERROR = "set_error"
const SET_PAGE_PERMISSIONS = "SET_PAGE_PERMISSIONS"
const SET_PAGE_TAGGABLE = "SET_PAGE_TAGGABLE"
const FETCHING_TAGGABLE = "FETCHING_TAGGABLE"

export {
	SET_LOADING,
	SET_ERROR,
	SET_PAGE_PERMISSIONS,
	SET_PAGE_TAGGABLE,
	FETCHING_TAGGABLE
}
