import React, {useEffect, useState} from 'react'
import {Button, Message, Modal} from "semantic-ui-react";
import BillingInfo from "../../components/common/billing/BillingInfo.js";
import styled from "styled-components";
import {Elements} from "@stripe/react-stripe-js";
import PaymentMethod from "../../components/common/billing/PaymentMethod.js";
import {useDispatch, useSelector} from "react-redux";
import Auth from "../../store/reducers/auth/actions.js";
import Biography from "../../store/reducers/biography/actions.js";
import BillingService from "../../services/billing.js";
import ModalLoader from "../../components/common/ModalLoader.js";
import AuthService from "../../services/auth.js";
import {loadStripe} from "@stripe/stripe-js";
import Root from "../../store/reducers/root/actions.js";
import {usePageScope} from "../../store/hooks/page.hooks.js";

export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE);

const AddBillingMethod = ({isOpen, close}) => {
    const user = useSelector(Auth.user);
    const AuthDispatch = new Auth(useDispatch())
    const RootDispatch = new Root(useDispatch());
    const [submitting, setSubmitting] = useState(false);
    const {page} = usePageScope()
    const [error, setError] = useState(false);
    const [billingDetails, setBillingDetails] = useState({
        billing_details: {
            name: '',
            address: {}
        }
    })

    useEffect(() => {
        if (page && !billingDetails.billing_details.name) {
            setBillingDetails({
                billing_details: {
                    name: `${page?.brief?.firstName} ${page?.brief?.lastName}`,
                    email: user.email,
                    phone: user.phoneNumber || page?.brief?.phoneNumber || '',
                    address: {}
                }
            })
        }
    }, [page])

    const submit = () => {
        setSubmitting(true);
        BillingService.addPaymentMethod(billingDetails).then(res => {
            AuthService.getMe(true).then(async user => {
                AuthDispatch.setUser({value: user, fetched: true});
            }).finally(() => {
                setError(null);
                setSubmitting(false)
                setBillingDetails({
                    billing_details: {
                        name: '',
                        address: {}
                    }
                })
                RootDispatch.setMessage({
                    type: 'success',
                    message: 'Payment method added'
                })
                close();
            })
        }).catch(err => {
            setError(err);
            setSubmitting(false)
            RootDispatch.setMessage({
                type: 'error',
                message: 'Error adding payment method'
            })
        })
    }

    const submitDisabled = () => {
        const {name, email, phone, address} = billingDetails.billing_details || {};
        const {line1, line2, city, state, country, postal_code} = address || {};
        const id = billingDetails.id;
        return !name || !line1 || !city || !state || !country || !postal_code || !id;
    }


    return (
        <Modal open={isOpen} size={'small'}>
            <ModalLoader active={submitting}/>
            <Modal.Header>Add payment method</Modal.Header>
            <Modal.Content>
                {!!error && <Message negative>
                    {error}
                </Message>}
                <BillingContainer>
                    <h3>Billing information</h3>
                    <Elements stripe={stripePromise}>
                        <BillingInfo setBillingDetails={setBillingDetails} billingDetails={billingDetails}/>
                        <h3>Payment details</h3>

                        <PaymentMethod setBillingDetails={setBillingDetails} billingDetails={billingDetails}/>
                    </Elements>
                </BillingContainer>

            </Modal.Content>
            <Modal.Actions>
                <Button basic color={'blue'} onClick={close}>
                    Dismiss
                </Button>
                <Button primary disabled={submitDisabled()} onClick={submit}>
                    Save
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default AddBillingMethod;

const BillingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
