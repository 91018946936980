// const customTheme = {
// 	primary: "#25415C",
// 	secondary: "#0E313D",
// 	tertiary: "#F2F6F6",
// 	quaternary: "#FFB2B2",
// 	alert: "#FF6F7F",
// 	dark: "#2E4053",
// 	background: "#F9F6ED"
// }

const customTheme = {
	primary: "#118379",
	secondary: "#5DA29C",
	tertiary: "#F2F6F6",
	quaternary: "#F2764C",
	alert: "#B03321",
	dark: "#2E4053",
	background: "#F4F8FB",
	text: "#707070"
}

export default customTheme
