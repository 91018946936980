import React, {useState} from 'react'
import {Confirm, Divider, Dropdown} from "semantic-ui-react";
import firebase from "firebase/app";

const AccessDropdown = ({permissions, setPermissions, onRevoke, loading = false, disabled}) => {
  const [tryRevoke, setTryRevoke] = useState(false)

  return (
    <>
      <Confirm content={'Are you sure you want to revoke this users access?'} confirmButton={'Confirm'} open={tryRevoke} onCancel={() => setTryRevoke(false)}
      onConfirm={() => {
        setTryRevoke(false);
        onRevoke()
      }}/>
      <Dropdown loading={loading} disabled={disabled}
                style={{minWidth: 100}}
                text={permissions.WRITE ? 'Collaborate' : permissions.BRIEF && permissions.READ ? 'Can View' : 'None'}
      >
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => {
            setPermissions({
              BRIEF: true,
              READ: true,
              WRITE: false
            })
          }} text='Can View' value={'Can View'}/>
          <Dropdown.Item onClick={() => {
            firebase.analytics().logEvent("set_permissions_collaborator");
            setPermissions({
              BRIEF: true,
              READ: true,
              WRITE: true
            })
          }} text='Can Collaborate' value={'WRITE'}/>

          {!!onRevoke && <> <Divider/><Dropdown.Item onClick={() => setTryRevoke(true)} text='Revoke' value={'Revoke'}/></>}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default AccessDropdown;
