import React, {useEffect, useRef, useState} from "react"
import {FlexBox, SettingsPage} from "../../styles/Containers.js"
import {Button, Icon, Image, Message, Modal} from "semantic-ui-react"
import styled from "styled-components"
import {BillingOptionsFlex, ModalContent, PlanInfo} from "./Upgrade.js"
import UpgradeImage from "@images/illustrations/upgrade.png"
import {Swiper, SwiperSlide} from "swiper/react"
import "swiper/swiper.scss"
import ModalLoader from "../../components/common/ModalLoader.js"
import Root from "../../store/reducers/root/actions.js"
import {useDispatch, useSelector} from "react-redux"
import FormInput from "../../components/common/FormInput.js"
import {MyPaymentMethods} from "./Billing.js"
import Auth from "../../store/reducers/auth/actions.js"
import BillingService from "../../services/billing.js"
import {lang} from "../../config/languages"
import useWindowSize from "../../utils/dimensions.js"
import {modalFull} from "../../config/constants.js"
import ToolTip from "../../components/common/ToolTip.js";

const GiftPage = ({}) => {
  const {width} = useWindowSize()
  const [gifting, setGifting] = useState(false)
  const [plan, setPlan] = useState(null)
  const [allowSwipes, setAllowSwipes] = useState("")
  const [error, setError] = useState("")
  const rootDispatch = new Root(useDispatch())
  const [submitting, setSubmitting] = useState(false)
  const [giftInfo, setGiftInfo] = useState({
    email: "",
    paymentMethod: ""
  })
  const [confirmGiftReceipt, setConfirmGiftReceipt] = useState(null)
  const user = useSelector(Auth.user)
  const [useGiftCode, setUseGiftCode] = useState(null)

  let swiper = useRef()

  useEffect(() => {
    setError(null)
    setGiftInfo({
      email: "",
      paymentMethod: ""
    })
  }, [gifting])

  useEffect(() => {
    switch (allowSwipes) {
      case "NEXT":
        swiper.current.slideNext()
        break
      case "PREV":
        swiper.current.slidePrev()
    }
    setAllowSwipes("")
  }, [allowSwipes])

  const choosePlan = (plan) => {
    setAllowSwipes("NEXT")
    setPlan(plan)
  }

  const giftPage = () => {
    setSubmitting(true)
    console.log(useGiftCode)
    BillingService.giftPage({...giftInfo, plan, giftCode: useGiftCode?.discount?.code}).then(data => {
      setSubmitting(false)
      setGifting(false)
      rootDispatch.setConfetti(5000)
      rootDispatch.setMessage({
        type: "success",
        message: "Page successfully upgraded!"
      })
      setConfirmGiftReceipt(data)
    }).catch(err => {
      setError(err)
      setSubmitting(false)
    })
  }

  const saveInfo = () => {
    if (plan === null) {
      setError("Plan not selected")
      return
    }
    setError("")
    setAllowSwipes("NEXT")
  }

  const onChange = (_, {value, name}) => {
    setGiftInfo({
      ...giftInfo,
      [name]: value
    })
  }

  const backToChoosePlan = () => {
    setError("")
    setAllowSwipes("PREV")
    setPlan(null)
  }

  const i = swiper.current?.activeIndex || 0
  return (
    <SettingsPage className={"gift-settings"} style={{height: 80, padding: 0, minHeight: 0}}>
      <Modal open={gifting} closeIcon onClose={() => setGifting(false)} size={modalFull(width)}>
        <ModalLoader active={submitting}/>
        <Modal.Header>Gift an Everloom</Modal.Header>
        <ModalContent>
          {!!error && <Message negative>
            {error}
          </Message>}
          <Swiper
            ref={swiper}
            allowSlideNext={!!allowSwipes}
            allowSlidePrev={!!allowSwipes}
            onSwiper={sw => {
              swiper.current = sw
            }}
            slidesPerView={1}>
            <SwiperSlide>
              {width >= 830 && <Image src={UpgradeImage} style={{height: 150, margin: "0 40px 0 40px"}}/>}
              <Modal.Description style={{padding: width >= 830 ? 20 : 0, flex: 1}}>

                <div>{lang.giftPage}</div>
                <PlanInfo activePlan={{id: ""}} choosePlan={choosePlan}
                          applyGiftCode={giftCode => setUseGiftCode(giftCode)}
                          usingCode={!!useGiftCode}
                          discount={useGiftCode?.discount}/>
              </Modal.Description>
            </SwiperSlide>
            <SwiperSlide>
              <BillingOptionsFlex>
                <FlexBox direction={"column"} fluid align={"center"} style={{maxWidth: 500}}>
                  <div style={{textAlign: "center", flex: 1, width: "100%"}}>
                    <h3>Select A Payment Method</h3>
                    <MyPaymentMethods billing={user.billing || []} actionable
                                      onClick={o => setGiftInfo({
                                        ...giftInfo,
                                        paymentMethod: o.id
                                      })}
                                      active={giftInfo.paymentMethod}
                                      customClass={"upgrade-selector"}/>
                  </div>

                  <h3 style={{textAlign: "center"}}>Sent the gift by email?</h3>
                  <h6>The gift will be sent to both yours and their email.</h6>
                  <FlexBox align={"center"} justify={"center"} fluid>
                    <FormInput value={giftInfo.email} onChange={onChange} name={"email"}
                               placeholder={"Email"} fluid formMargin={false}/>
                  </FlexBox>
                </FlexBox>
              </BillingOptionsFlex>
            </SwiperSlide>
          </Swiper>
        </ModalContent>
        <Modal.Actions>
          {i === 0 && <Button color={"blue"} basic onClick={() => setGifting(false)}>Never mind</Button>}
          {i === 1 &&
          <Button color={"blue"} basic onClick={backToChoosePlan} style={{float: "left"}}>Back</Button>}
          {i === 1 && <Button primary onClick={giftPage}>Gift page</Button>}

        </Modal.Actions>
      </Modal>
      <Modal onClose={() => setConfirmGiftReceipt(false)} open={!!confirmGiftReceipt} closeIcon>
        <Modal.Header>Gift Sent</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            A gift code has been sent to both you and the email recipient.
          </Modal.Description>
          <Modal.Description style={{marginTop: 20}}>
            Here is your Gift Code: <strong>{confirmGiftReceipt?.giftCode}</strong>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setConfirmGiftReceipt(null)}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
      {/*<a href={process.env.REACT_APP_LANDING + '/pricing'} target={'_blank'}>*/}

        <ToolTip message={"Coming Soon!"}>
          <GiftFlex align={"center"} style={{height: 80, paddingLeft: 40}}>
            <Icon name={"gift"}/>
            <h2>Gift an Everloom</h2>
          </GiftFlex>
        </ToolTip>
      {/*</a>*/}
    </SettingsPage>
  )
}

export default GiftPage

const GiftFlex = styled(FlexBox)`
  border-radius: 4px;

  &:hover {
      //background-color: ${props => props.theme.quaternary};
    border: thin solid ${props => props.theme.primary};
    color: white;

    cursor: pointer;
  }

  i {
    font-size: 24px;
    height: 20px;
    color: ${props => props.theme.primary};
  }

  h2 {
    margin: 0;
    margin-left: 10px;
    color: ${props => props.theme.primary};
  }
`
