import React, {useEffect, useState} from "react"
import {Button, Form, Icon, Message, Modal} from "semantic-ui-react"
import {useDispatch, useSelector} from "react-redux"
import Biography from "../../../store/reducers/biography/actions.js"
import Auth from "../../../store/reducers/auth/actions.js"
import {FlexBox} from "../../../styles/Containers.js"
import ModalLoader from "../../../components/common/ModalLoader.js"
import {PagesService} from "../../../services/pages.js"
import Root from "../../../store/reducers/root/actions.js"
import FormInput from "../../../components/common/FormInput.js"
import {validateEmail} from "../../../utils/validateEmail.js"
import HeirloomModal from "../../../components/common/HeirloomModal.js"
import {ChipBtn} from "../../../styles/Forms.js"
import {usePageScope} from "../../../store/hooks/page.hooks.js";
import firebase from "firebase";
import {useCurrentUser} from "../../../store/hooks/user.hooks.js";
import {usePagePermissions} from "../../../store/hooks/permissions.hooks.js";

const ManageBeneficiary = ({isOpen, close}) => {
  const {page, pageId} = usePageScope()
  const user = useCurrentUser()
  const [selected, setSelected] = useState([])
  const myPages = useSelector(Auth.user)?.myPages || []
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [addingEmail, setAddingEmail] = useState("")
  const [addError, setAddError] = useState(false)
  const [removedEmails, setRemovedEmails] = useState([])
  const root = new Root(useDispatch())
  const bio = new Biography(useDispatch())
  const {data: permissions} = usePagePermissions(pageId)
  const currentPermissions = permissions?.permissions || []

  useEffect(() => {
    if (isOpen) {
      firebase.analytics().logEvent("clicked_manage_executor");
    }
    setSelected([...(page?.beneficiaries || []).map(o => ({
      email: o,
      confirmed: true
    })), ...(page?.beneficiaryRequests || []).map(o => ({
      email: o,
      confirmed: false
    }))])
    setRemovedEmails([])
    setAddError(false)
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      for (const item of selected) {
        if (!currentPermissions.find(o => o.email === item.email) && item.confirmed === true && pageId === user.primaryPageId) {
          console.log('Removing')
          removeEmail(item)
        }
      }
      if (removedEmails.length > 0) {
        submit(false);
      }
    }
  }, [isOpen])

  const updateNewEmail = (_, {value}) => setAddingEmail(value)

  const addToList = () => {
    if (selected.findIndex(o => o.email === addingEmail) > -1 || !validateEmail(addingEmail)) {
      setAddError(true)
    } else {
      setAddError(false)
      setSelected([...selected, {email: addingEmail}])
      setAddingEmail("")
    }
  }

  const submit = (shouldClose = true) => {
    firebase.analytics().logEvent("set_executor");
    setLoading(true)
    setError(null)
    PagesService.setBeneficiary(selected.map(o => o.email), removedEmails, pageId).then(() => {
      bio.patchLocalPage({
        beneficiaries: selected.filter(o => o.confirmed).map(o => o.email).filter(o => removedEmails.indexOf(o) === -1),
        beneficiaryRequests: selected.filter(o => !o.confirmed).map(o => o.email).filter(o => removedEmails.indexOf(o) === -1)
      })
      if (shouldClose) {
        root.setMessage({
          type: "success",
          message: "New Beneficiary requests updated"
        })
        close()
      }
    }).catch(e => {
      console.log(e)
      setError(e)
    }).finally(() => setLoading(false))
  }

  const removeEmail = (o) => {
    const rm = [...removedEmails].filter(f => f.email !== o.email)
    rm.push(o.email)
    setRemovedEmails(rm)
    const sel = [...selected]
    sel.splice(sel.findIndex(f => f.email === o.email), 1)
    setSelected(sel)
  }

  const list = []
  if (page) {
    const relatives = page.relations
    for (const section in relatives) {
      for (const rel of relatives[section]) {
        if (rel.pageCode && myPages.indexOf(rel.pageCode) === -1) {
          list.push(rel)
        }
      }
    }
  }

  return (
    <HeirloomModal open={isOpen} closeIcon onClose={close} size={"tiny"}>
      <Modal.Header>Manage Executors</Modal.Header>
      <ModalLoader active={loading}/>
      <Modal.Content>
        {!!error && <Message negative>{error}</Message>}
        <Modal.Description style={{fontWeight: 500}}>
          Request a family member to become an Executor for your Everloom.
          <br/>
          <br/>
          Your Executor will have the ability to:
          <ul>
            <li>Set your deceased date</li>
            <li>Add a Deceased chapter to your Everloom</li>
            <li>Review and approve new Memory submissions</li>
          </ul>
          In the field below you can send a request to a family member to add as an Executor. Once you press Invite we
          will send them an email with your request. You may want to talk to this person before making the request.
          Please note that the person you request will have to option to accept or decline the request. We will notify
          you of their decision.
          <br/>
          <br/>
          <a href={process.env.REACT_APP_LANDING_URL + "/help-addexecutors"} target={"_blank"}>
            Learn more about adding Executors to an Everloom.
          </a>
          <br/>
          <br/>
          <strong style={{lineHeight: 3}}>Enter the email address of a family member below</strong>

          <Form onSubmit={addToList}>
            <FlexBox>
              <FormInput error={addError} placeholder={"Email"}
                         fluid formMargin={false}
                         onChange={updateNewEmail} value={addingEmail}/>
              <Button secondary style={{marginLeft: 6}}>
                Send Request
              </Button>
            </FlexBox>
          </Form>

          <FlexBox style={{marginTop: 12, marginBottom: 20}} direction={"column"}>
            {selected.map(o => (
              <FlexBox justify={"space-between"} style={{width: "100%", marginTop: 12}}>
                <div>
                  {o.confirmed ? <Icon name={"check"} style={{color: "green"}}/> : <Icon name={"wait"}/>} <span
                  style={o.confirmed ? {} : {opacity: 0.5}}>{o.email}</span>
                </div>
                <ChipBtn onClick={() => removeEmail(o)}>Remove</ChipBtn>
              </FlexBox>
            ))}
          </FlexBox>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={close}>
          Cancel
        </Button>
        <Button primary disabled={!selected} onClick={submit}>
          Submit
        </Button>
      </Modal.Actions>
    </HeirloomModal>
  )
}

export default ManageBeneficiary
