import React from "react"
import {Message} from "semantic-ui-react"

const SuccessComponent = ({message, header}) => {

    if (!message) {
        return <div/>
    }

    return (
        <Message positive>
            {!!header && <Message.Header>{header}</Message.Header>}
            <p>{message}</p>
        </Message>
    )
}

export default SuccessComponent
