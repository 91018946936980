import React, { useEffect, useState } from "react"
import { AuthCardContainer } from "../../styles/Containers.js"
import { Button, Card, Form, Loader } from "semantic-ui-react"
import { StyledLink } from "../../styles/Text.js"
import { validateAuthForm } from "../../utils/validateAuthForm.js"
import AuthService from "../../services/auth.js"
import SuccessComponent from "../../components/messages/Success.js"
import Countdown from "../../components/Countdown.js"
import ErrorComponent from "../../components/messages/Error.js"
import { useHistory } from "react-router-dom"
import { formStyles } from "../../styles/Forms.js"
import FormInput from "../../components/common/FormInput.js"
import PasswordInput from "../../components/common/PasswordInput"

const ForgotComponent = ({ setting }) => {
	const history = useHistory()
	const [error, setError] = useState("")
	const [success, setSuccess] = useState("")
	const [submitting, setSubmitting] = useState(false)
	const [invalidFields, setInvalidFields] = useState({})
	const [form, setForm] = useState({ email: "", password: "" })
	const [timeLeft, setTimeLeft] = useState(0)
	const onChange = (event, { value, name }) => setForm({ ...form, [name]: value })

	useEffect(() => {
		if (timeLeft !== 0) {

		}
	}, [timeLeft])

	const submit = () => {
		const f = { ...form }
		if (setting) {
			delete f.email
		} else {
			delete f.password
		}
		const invalids = validateAuthForm(f)
		setInvalidFields(invalids)
		setError("")
		setSuccess("")
		setTimeLeft(0)
		if (Object.keys(invalids).length === 0) {
			setSubmitting(true)
			if (setting) {
				updatePass()
			} else {
				forgotPass()
			}
		}
	}

	const updatePass = () => {
		AuthService.updatePass(form).then(message => {
			history.push("/login?message=" + message)
		})
			.catch(error => setError(error))
			.finally(() => setSubmitting(false))
	}

	const forgotPass = () => {
		AuthService.forgotPassword(form).then(data => {
			if (typeof data === "string") {
				setSuccess(data)
				setTimeLeft(30)
			} else {
				setSuccess(data.message)
				setTimeLeft(Math.floor(data.timeLeft))
			}
		})
			.catch(error => {
				setTimeLeft(30)
				setError("You can't send another reset request yet.")
			})
			.finally(() => setSubmitting(false))
	}

	return (
		<AuthCardContainer>
			<SuccessComponent message={success}/>
			<ErrorComponent message={error}/>
			<Card>
				<Loader active={submitting}/>
				<Card.Content header={setting ? "Reset" : "Forgot" + " password"}/>
				<Form onSubmit={submit}>
					{setting ?
						<Card.Content style={{ marginLeft: -formStyles.inputSideMargin.marginLeft }}>
							<PasswordInput onChange={onChange} value={form.password} placeholder={"New password"}
														 error={invalidFields.password}/>
						</Card.Content>
						: <Card.Content style={{ marginLeft: -formStyles.inputSideMargin.marginLeft }}>
							<FormInput icon="user" iconPosition="left" placeholder="Email" fluid name="email"
												 error={invalidFields.email}
												 autoComplete="username" onChange={onChange} value={form.email}/>
						</Card.Content>
					}

					<Card.Content>
						{setting ?
							<Button fluid primary disabled={submitting} type="submit">
								Submit
							</Button>
							:
							<Button fluid primary disabled={submitting || timeLeft > 0} type="submit">
								{timeLeft > 0 ? <Countdown time={timeLeft} done={() => setTimeLeft("")}/> : "Reset"}
							</Button>
						}
					</Card.Content>
				</Form>
			</Card>

			<div className="sub-item-text">Have an account? <StyledLink to="/login">Log in</StyledLink></div>
		</AuthCardContainer>
	)
}

export default ForgotComponent
