import { INITIAL_STATE, SET_ERROR, SET_LOADING, SET_MEMORY_DATA, SET_MEMORY_SETTINGS, SET_WRITE } from "./initialState"
import { RESET_STATE } from "../common/reducer.class"

export const memoriesReducer = (state = INITIAL_STATE, action) => {
	const { payload, type, meta } = action

	switch (type) {
		case SET_LOADING:
			return {
				...state,
				loading: payload
			}
		case SET_ERROR:
			return {
				...state,
				error: payload
			}
		case SET_WRITE: {
			return {
				...state,
				writingMemory: payload
			}
		}
		case SET_MEMORY_DATA:
			return {
				...state,
				data: {
					...state.data,
					[payload.pageId]: {
						...state.data[payload.pageId],
						...payload.data
					}
				}
			}
		case SET_MEMORY_SETTINGS: {
			return {
				...state,
				data: {
					...state.data,
					[payload.pageId]: {
						...state.data[payload.pageId],
						settings: payload.data
					}
				}
			}
		}
		case RESET_STATE:
			return INITIAL_STATE;
		default:
			return state
	}
}
