import React, {useEffect, useState} from 'react'
import HeirloomImage from "../components/common/HeirloomImage.js";
import Logo from "@logo/themed/logo-icon.svg";
import {CONSTANTS} from "../config/constants.js";
import {MainSideBar} from "./index.js";
import useWindowSize from "../utils/dimensions.js";
import {Route, Switch, useHistory} from "react-router-dom";
import AdminChapterSections from "../admin/ChapterSections.js";
import {Clickable} from "../styles/Containers.js";
import {LogOutOutline} from "react-ionicons";
import {BottomNavContainer, LogoPosition, ProfileContent, SideNav} from "../styles/Layout.js";
import {useDispatch, useSelector} from "react-redux";
import Auth from "../store/reducers/auth/actions.js";
import AuthService from "../services/auth.js";
import Root from "../store/reducers/root/actions.js";
import GiftCodes from "../admin/giftCodes.js";

const items = {
    chapterSections: {
        key: 'chapter-sections',
        title: 'Chapter Sections'
    },
    giftCodes: {
        key: 'gift-codes',
        title: 'Gift Code & Promotions'
    }
}
const AdminLayout = ({}) => {
    const {width} = useWindowSize();
    const [sideNavOpen, setSideNavOpen] = useState(true);
    const dispatch = useDispatch()
    const history = useHistory();
    const userDispatch = new Auth(dispatch)
    const authLoaded = useSelector(Root.isFetched)
    const AuthDispatch = new Auth(dispatch)
    const isAuthenticated = useSelector(Auth.isAuthenticated)
    const tokenData = useSelector(Auth.tokenData)
    const user = useSelector(Auth.user);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!authLoaded) {
            AuthService.setTokens(tokenData);
            AuthService.getMe(true).then(async user => {
                AuthDispatch.setUser({value: user, fetched: true});
            });
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }, [loading])

    const logout = () => {
        userDispatch.logout()
        history.push('/');
    }

    const selectMenuItem = (key) => {
        history.push('/admin/' + key);
        setLoading(true);
    }

    const isActive = (key) => {
        return history.location.pathname.indexOf(key) > -1;
    }

    return (
        <MainSideBar menuOpen={sideNavOpen && width >= CONSTANTS.collapsedSize}>

            <SideNav open={sideNavOpen}>
                <LogoPosition>
                    <HeirloomImage src={Logo}/>
                </LogoPosition>

                <div style={{flex: 1, marginTop: 30, marginBottom: 40}}>
                    <ProfileContent>
                        <div className={'content-item'}
                             onClick={() => selectMenuItem(items.chapterSections.key)}>
                            {isActive(items.chapterSections.key) ? <span
                                className={'active-item'}>{items.chapterSections.title}</span> : items.chapterSections.title}
                        </div>

                        <div className={'content-item'}
                             onClick={() => selectMenuItem(items.giftCodes.key)}>
                            {isActive(items.giftCodes.key) ? <span
                              className={'active-item'}>{items.giftCodes.title}</span> : items.giftCodes.title}
                        </div>
                    </ProfileContent>
                </div>

                <BottomNavContainer align={'center'} justify={'flex-end'}>
                    <Clickable style={{color: 'white', display: 'flex', alignItems: 'center'}} onClick={logout}>
                        Log out
                        <LogOutOutline width={20} height={20} color={'#fff'} style={{marginLeft: 10}}/>
                    </Clickable>
                </BottomNavContainer>
            </SideNav>

            {(user && user.roles) &&
            <div id={'layout-navigation'} className={'inner-container'}>
                <Switch>
                    <Route exact path="/admin/chapter-sections" component={AdminChapterSections}/>
                    <Route exact path="/admin/gift-codes" component={GiftCodes}/>

                </Switch>
            </div>
            }
        </MainSideBar>
    );
};

export default AdminLayout;
