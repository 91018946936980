import React, { useState } from "react"
import { Button, Image, Message, Modal } from "semantic-ui-react"
import { useDispatch, useSelector } from "react-redux"
import Auth from "../store/reducers/auth/actions.js"
import Root from "../store/reducers/root/actions.js"
import { PagesService } from "../services/pages.js"
import AuthService from "../services/auth.js"
import ModalLoader from "../components/common/ModalLoader.js"
import ImageHolder from "@images/image-not-found.png"
import { FlexBox } from "../styles/Containers.js"

const PendingPages = ({}) => {
	const user = useSelector(Auth.user)
	const dispatch = useDispatch()
	const RootDispatch = new Root(dispatch)
	const AuthDispatch = new Auth(dispatch)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState("")
	const [hide, setHide] = useState(false)
	const isAuthenticated = useSelector(Auth.isAuthenticated)

	if (hide) {
		return null;
	}

	const hideTillLater = () => {
    	setHide(true)
	}

	const acceptPage = (pageId, isPrimary) => {
		setLoading(true)
		PagesService.acceptPageTransfer(pageId, isPrimary).then((message) => {
			AuthService.getMe(true).then(async user => {
				AuthDispatch.setUser({ value: user, fetched: true })
			}).finally(() => {
				setLoading(false)
				RootDispatch.setMessage({
					type: "success",
					message: message
				})
			})
		}).catch((e) => {
			setLoading(false)
			setError(e)
			RootDispatch.setMessage({
				type: "error",
				message: e
			})
		})
	}

	const declinePage = (pageId) => {
		setLoading(true)
		PagesService.cancelPageTransfer(pageId).then((message) => {
			AuthService.getMe(true).then(async user => {
				AuthDispatch.setUser({ value: user, fetched: true })
			}).finally(() => {
				setLoading(false)
				RootDispatch.setMessage({
					type: "success",
					message: message
				})
			})
		}).catch((e) => {
			setLoading(false)
			RootDispatch.setMessage({
				type: "error",
				message: e
			})
		})
	}

	const pendingPage = (user?.pendingPages || [])[0]
	return (
		<Modal open={!!user?.pendingPages?.length && !!isAuthenticated}>
			<ModalLoader active={loading}/>
			<Modal.Header>
				<FlexBox justify={'space-between'} align={'center'}>
					<div>
						Transfer request
					</div>
					<Button onClick={hideTillLater}>
						Later
					</Button>
				</FlexBox>
			</Modal.Header>
			<Modal.Content image>
				<Image style={{ maxWidth: 200 }} src={pendingPage?.user.image || ImageHolder}/>
				<Modal.Description>
					{error && <Message negative>
						{error}
					</Message>}
					<p>
						A user has request to transfer a page to you. Would you like to accept it?
					</p>
					<p>
						<div>Name: <strong>{pendingPage?.user.firstName} {pendingPage?.user.lastName}</strong></div>
					</p>
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={() => declinePage(pendingPage?.pageId)}>
					Decline
				</Button>
				<Button primary onClick={() => acceptPage(pendingPage?.pageId, true)}>
					Accept as primary Everloom
				</Button>
				<Button primary onClick={() => acceptPage(pendingPage?.pageId, false)}>
					Accept as new Everloom
				</Button>
			</Modal.Actions>
		</Modal>
	)
}

export default PendingPages
