import React, {useEffect} from "react"
import {Button} from "semantic-ui-react"
import {FlexBox} from "../../styles/Containers.js"
import {ArrowBack, MailUnreadOutline} from "react-ionicons"
import styled from "styled-components"
import {useHistory} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import Auth from "../../store/reducers/auth/actions.js"
import AuthService from "../../services/auth.js"
import LogoLoader from "../../components/common/LogoLoader.js"
import Countdown from "../../components/Countdown.js"
import firebase from "firebase";
import {useCurrentUser} from "../../store/hooks/user.hooks.js";

const VerifyEmail = ({}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const userDispatch = new Auth(dispatch)
  const user = useCurrentUser()
  const [isLoading, setIsLoading] = React.useState(false)
  const tokenData = useSelector(Auth.tokenData)
  const [timeLeft, setTimeLeft] = React.useState(-1)
  const [message, setMessage] = React.useState(null)

  const logout = () => {
    userDispatch.logout()
    history.push("/")
  }

  useEffect(() => {
    const ref = firebase.database().ref(`emailVerification/${user.uid}/`);
    ref.on('value', data => {
      const value = data.val()
      if (value === true) {
        checkEmailVerified()
      }
    })

    return () => ref.off();
  }, [])

  const checkEmailVerified = () => {
    setIsLoading(true)
    AuthService.setTokens(tokenData)
    AuthService.getMe(true).then(async user => {
      userDispatch.setUser({value: user, fetched: true})
      if (!user.emailVerified) {
        setMessage({
          type: "ERROR",
          message: "Email still not verified"
        })
      }
    }).finally(() => setIsLoading(false))
  }

  const resend = () => {
    firebase.analytics().logEvent("resend_verification_email");
    setTimeLeft(10)
    setIsLoading(true)
    AuthService.setTokens(tokenData)
    AuthService.resendVerification().then(() => {
      setTimeLeft(30)
      setMessage({
        type: "SUCCESS",
        message: "Email sent!"
      })
    }).catch(() => {
      setTimeLeft(30)
      setMessage({
        type: "ERROR",
        message: "Can't yet resend a verification email."
      })
    })
      .finally(() => setIsLoading(false))
  }

  return (
    <div style={{textAlign: "center", padding: 20}}>
      {isLoading ? <LogoLoader alt/>
        :
        <>
          <Logout align={"center"} onClick={logout}>
            <ArrowBack/>
            Logout
          </Logout>

          <TopText>Thank you for signing up</TopText>


          <MailIcon/>

          <h4 style={{textAlign: "center", maxWidth: 500, padding: 20}}>
            We’ve sent you an email to verify your account. Please open the email to continue setting up your Everloom.
            <br/>
            <br/>
            You may need to check your Spam or Promotions folder.
          </h4>

          {!!message &&
          <h6 style={{
            color: message.type === "ERROR" ? "red" : "green",
            lineHeight: 0,
            margin: 0,
            position: "relative",
            top: 10
          }}>{message.message}</h6>}

          {/*<TopText>Email verified?</TopText>*/}
          {/*<Button style={{maxWidth: 500}} fluid color={'green'} onClick={checkEmailVerified}>Let me in!</Button>*/}


          <TopText>Didn't get an email?</TopText>
          <Button style={{maxWidth: 500}} fluid disabled={timeLeft > 0} onClick={resend}>
            {timeLeft > 0 ? <Countdown label={"Send again in "} time={timeLeft}
                                       done={() => setTimeLeft(-1)}/> : "Resend"}
          </Button>
          <p style={{marginTop: 4}}>
            You will be redirected when you verify your email.
          </p>
        </>
      }
    </div>
  )
}

export default VerifyEmail

const TopText = styled.h5`
  opacity: 0.5;
  font-weight: bold;
`

const Logout = styled(FlexBox)`
  position: absolute;
  top: 30px;
  left: 30px;
  color: ${props => props.theme.quaternary};
  font-weight: bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  span {
    margin-right: 10px;

    svg {
      color: ${props => props.theme.quaternary};
    }
  }

`

export const MailIcon = styled(MailUnreadOutline)`
  svg {
    font-size: 100px;
    width: 100px;
    height: 100px;
    color: ${props => props.theme.quaternary};
    fill: ${props => props.theme.quaternary};
  }
`
