export const setDirty = (pageId) => {
  const dirt = JSON.parse(localStorage.getItem('dirtyPages') || "[]");
  dirt.push(pageId);
  localStorage.setItem('dirtyPages', JSON.stringify(Array.from(new Set(dirt).add(pageId))));
}

export const getDirty = (pageId) => {
  const dirt = JSON.parse(localStorage.getItem('dirtyPages') || "[]");
  const i = dirt.indexOf(pageId);
  if (i > -1) {
    dirt.splice(i, 1);
  }
  localStorage.setItem('dirtyPages', JSON.stringify(Array.from(new Set(dirt))));
  return i > -1;
}
